import import_latest_version_module from '@/component/admin-layout/import_latest_version_module';
import page_import_latest_version_module from '@/page/admin/statistical-report/marketing-report/import_latest_version_module';
export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
      {
        path: '/admin/marketing-report',
        name: 'admin-marketing-report',
        component: () => page_import_latest_version_module(),
        meta: {
            enable_keep_alive: false,
          data_for_admin_layout: {
                    data_for_history_tag: {
                              entity: {
                                        name: "营收报表"
                              }
                    },
                    data_for_breadcrumb: {
                              entity_list: [
                                        {
                                                  name: "数据统计"
                                        },
                                        {
                                                  name: "营收报表"
                                        }
                              ]
                    }
          }
},
      },
    ],
  };
  