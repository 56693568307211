import Q from 'q';

// 个人用户列表
import web_personage_personageList from '@/lib/data-service/person/web_personage_personageList.js';
// 强制退出
import web_common_kickOutUser from '@/lib/data-service/person/web_common_kickOutUser.js';
// 启用
import web_personage_startUsingPersonage from '@/lib/data-service/person/web_personage_startUsingPersonage.js';
// 禁用
import web_personage_disablePersonage from '@/lib/data-service/person/web_personage_disablePersonage.js';
// 删除
import web_personage_deletePersonage from '@/lib/data-service/person/web_personage_deletePersonage.js';

import Citys from '@/component/citys/index.vue';

import moment from 'moment'


export default {
  components: {
    Citys
  },
  data () {
    return {
      form: {
        currentPage: 1,
        pageSize: 10,
        userName: '',
        phone: '',
        eMail: '',
        realName: '',
        idCard: '',
        beginFlightLastDealTime: '',
        endFlightLastDealTime: '',
        areaRegionId: '',
        areaProvinceId: '',
        areaCityId: '',
        warnLevel: '',
      },
      pager: {
        total: 0,
        pageSizes: [10, 20, 30, 40, 50]
      },
      paymentDate: '',
      warnLevelOptions: [
        {label: '两个月之内无交易记录', value: 2},
        {label: '一个月之内无交易记录', value: 1},
        {label: '正常', value: 0},
      ],
      list: [],
      show: false,
      moment: moment,
      onlyCheckDetail: false
    }
  },
  methods: {
    req_list (params) {
      const _this = this;
      Q.when()
        .then(function () {
          return web_personage_personageList(params);
        })
        .then(function (res) {
          _this.list = res.pageResult.pageData;
          _this.pager.total = res.pageResult.totalCount;
          ;
        })
    },
    req_start (params) {
      const _this = this;
      Q.when()
        .then(function () {
          return web_personage_startUsingPersonage(params);
        })
        .then(function (res) {
          _this.$message.success('设置启用成功');
          _this.search(_this.form);
        })
    },
    req_disable (params) {
      const _this = this;
      Q.when()
        .then(function () {
          return web_personage_disablePersonage(params);
        })
        .then(function (res) {
          _this.$message.success('设置禁用成功');
          _this.search(_this.form);
        })
    },
    req_log_out (params) {
      const _this = this;
      Q.when()
        .then(function () {
          return web_common_kickOutUser(params);
        })
        .then(function (res) {
          _this.$message.success(res.msg);
        })
    },
    req_delete (params) {
      const _this = this;
      Q.when()
        .then(function () {
          return web_personage_deletePersonage(params);
        })
        .then(function (res) {
          _this.$message.success('删除账号成功');
          _this.search(_this.form);
        })
    },
    search () {
      this.form.currentPage = 1;
      this.req_list(this.form);
    },
    reset () {
      this.paymentDate = '';
      this.form = {
        currentPage: 1,
        pageSize: 10,
        userName: '',
        phone: '',
        eMail: '',
        realName: '',
        idCard: '',
        paymentDateStart: '',
        paymentDateEnd: '',
        areaCityId: '',
        warnLevel: '',
      };
      this.$refs.fromCity.reset_fromArea();
      this.req_list(this.form);
    },
    getFromArea (val) {
      this.form.areaRegionId = val;
    },
    getFromProvince (val) {
      this.form.areaProvinceId = val;
    },
    getFromCity (val) {
      this.form.areaCityId = val;
    },
    to_detail (buyerId, userId, type) {
      this.$router.push({
        name: 'admin-person-detail',
        query: {
          buyerId,
          userId,
          type
        }
      })
    },
    change_status (buyerId, buyerStatus, userId) {
      let message;
      if (buyerStatus == 2 || buyerStatus == 3) {
        message = '停';
      } else if (buyerStatus == 4) {
        message = '启';
      }
      this.$confirm(`是否确定${message}用此帐号?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (buyerStatus == 2 || buyerStatus == 3) {
          this.req_disable({buyerId, userId});
        } else if (buyerStatus == 4) {
          this.req_start({buyerId, userId});
        }
      }).catch(() => {

      });
    },
    log_out (userId) {
      this.$confirm('是否确定强制退出此帐号?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.req_log_out({userId})
      }).catch(() => {

      });
    },
    delete_account (buyerId, userId) {
      this.$confirm('是否确定删除此帐号?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.req_delete({buyerId, userId});
      }).catch(() => {

      });
    },
    handleSizeChange (pageSize) {
      this.form.pageSize = pageSize;
      this.req_list(this.form);
    },
    handleCurrentChange (currentPage) {
      this.form.currentPage = currentPage;
      this.req_list(this.form);
    }
  },
  watch: {
    paymentDate (val) {
      if (val) {
        this.form.beginFlightLastDealTime = `${val[0]} 00:00:00`;
        this.form.endFlightLastDealTime = `${val[1]} 23:59:59`;
      }
    }
  },
  mounted () {

  },
  activated () {
    let onlyCheckDetail = this.$route.meta.onlyCheckDetail
    if (onlyCheckDetail) {
      this.onlyCheckDetail = onlyCheckDetail
    }
    this.req_list(this.form);
  }
}
