// web_common_kickOutUser 强制登出

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口地址：http://192.168.0.15:8080/doc.html#/所有接口/

export default function web_common_kickOutUser(pParameter) {
  if (!pParameter) pParameter = {};
  const userId = pParameter.userId || ''
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/common/kickOutUser'
  params.data = {
    userId
  }
  params.process_response_data = false
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}
