import web_managerSupplier_list from '@/lib/data-service/default/web_managerSupplier_list'
import web_common_user_getDeveloper from '@/lib/data-service/default/web_common_user_getDeveloper'
import web_common_area_getAreaDataByAreaType from '@/lib/data-service/default/web_common_area_getAreaDataByAreaType'
import web_common_area_getInlandAreaChildListByParentId from '@/lib/data-service/default/web_common_area_getInlandAreaChildListByParentId'
import web_managerSupplier_exportExcel from '@/lib/data-service/default/web_managerSupplier_exportExcel'
import web_managerSupplier_updateStatus from '@/lib/data-service/default/web_managerSupplier_updateStatus'
import web_managerSupplier_supplierDelete from '@/lib/data-service/default/web_managerSupplier_supplierDelete'
import web_common_kickOutUser from '@/lib/data-service/default/web_common_kickOutUser'
import Pagination from '@/component/base-pagination/index.vue'
import moment from 'moment'
export default {
  data () {
    return {
      loading: false,
      params: {
        period: [],

        currentPage: 1,
        pageSize: 10,
        userName: '',
        companyName: '',
        cityId: '',
        regionId: '',
        provinceId: '',
        applicationType: '',
        startTime: '',
        endTime: '',
        supplierStatus: '',
        telephone: '',
        accountManagerId: '',
        developerId: '',
        supplierLevel: '',
        address: '',
        officeId: '',
        orderByLastLoginTime: '', // 最后登录时间排序填值: asc:升序,desc:降序,不需要为空即可
        orderByGmtCreate: '', // 创建时间排序填值: asc:升序,desc:降序,不需要为空即可
        total: 0
      },
      userTypeList: [{value: '', label: '全部'}, {value: 1, label: '机票'}, {value: 2, label: '旅游'}, {value: '1+2', label: '机票+旅游'}],
      developerList: [],
      provinceList: [],
      cityList: [],
      supplierStatusList: [{value: '', label: '全部'}, {value: 2, label: '部分启用'}, {value: 3, label: '启用'}, {value: 4, label: '平台禁用'}],
      showMore: false,
      userList: [],
      fileUrl: '',
      moment: moment,
      onlyCheckDetail: false
    }
  },
  components: {
    Pagination
  },
  created () {
  },
  mounted () {
    //this.getDeveloperList()
    //this.getAreaDataByAreaType()
    //this.getSupplierList()
  },
  activated () {
    let onlyCheckDetail = this.$route.meta.onlyCheckDetail
    if (onlyCheckDetail) {
      this.onlyCheckDetail = onlyCheckDetail
    }
    this.getDeveloperList()
    this.getAreaDataByAreaType()
    this.getSupplierList()
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {
    filterTravelPaymentType(val) {
      if (val === 1) {
        return 'T'
      } else if (val === 2) {
        return '回团后'
      } else if (val === 3) {
        return '出团前'
      } else {
        return '--'
      }
    },
    filterSettleDepositType(val) {
      if (val === 0) {
        return '全额'
      } else {
        return '订金'
      }
    },
    filterSettleDepositTypeFun(val) {
      if (val === 0) {
        return '--'
      } else if (val === 1) {
        return '比例'
      } else {
        return '定额'
      }
    },
    filterPressAmountType(val) {
      if (val === 0) {
        return '不压款'
      } else {
        return '压款'
      }
    },
    filterPressAmountTypeFun(val) {
      if (val === 0) {
        return '--'
      } else if (val === 1) {
        return '比例'
      } else {
        return '定额'
      }
    },
  },
  methods: {
    onSearch() {
      this.params.currentPage = 1
      this.getSupplierList()
    },
    handleFilter() {
      this.onSearch()
    },
    showOrHideForm() {
      const showMore = this.showMore
      this.showMore = !showMore
    },
    onReset() {
      this.$refs['userTable'].clearSort()
      this.params = {
        period: [],
        currentPage: 1,
        pageSize: 10,
        userName: '',
        companyName: '',
        cityId: '',
        regionId: '',
        provinceId: '',
        applicationType: '',
        startTime: '',
        endTime: '',
        supplierStatus: '',
        telephone: '',
        accountManagerId: '',
        developerId: '',
        supplierLevel: '',
        address: '',
        officeId: '',
        orderByLastLoginTime: '', // 最后登录时间排序填值: asc:升序,desc:降序,不需要为空即可
        orderByGmtCreate: '', // 创建时间排序填值: asc:升序,desc:降序,不需要为空即可
        total: 0
      }
      this.onSearch()
    },
    changeParamsTime(val) {
      if (val === '' || val === null) {
        this.params.startTime = ''
        this.params.endTime = ''
      } else {
        this.params.startTime = val[0]
        this.params.endTime = val[1]
      }
    },
    changeProvince(val) {
      this.params.cityId = ''
      this.cityList = []
      if (val !== '') {
        this.getCity(val)
      }
    },

    handleCreate() {
      this.$router.push({
        name: 'admin-supplier-add'
      })
    },
    uploadExcel(val) {
      let params
      if (val === 'current') {
        params = this.params
      } else {
        params = ''
      }
      web_managerSupplier_exportExcel(params).then(res => {
        if (res.url) {
          // window.open(res.url)
          this.fileUrl = res.url
          this.$nextTick(() => {
            console.log(res.url)
            this.$refs.downloadBtn.click()
          })
        }
      })
    },

    // 获取开发者列表 - （机票经理）
    getDeveloperList() {
      web_common_user_getDeveloper().then((res) => {
        this.developerList = res.getAllDeveloperResult
      })
    },
    // 获取省份
    getAreaDataByAreaType(areaType = 1) {
      web_common_area_getAreaDataByAreaType({areaType}).then(res => {
        this.provinceList = res
      })
    },
    // 获取城市
    getCity(id) {
      web_common_area_getInlandAreaChildListByParentId({id}).then(res => {
        this.cityList = res
      })
    },
    getSupplierList() {
      this.loading = true
      web_managerSupplier_list(this.params).then((res) => {
        let userList = res.pageResult.pageData
        userList.forEach(value => {
          if (value.lastLoginTime === null) {
            value.lastLoginTime = ''
          }
        })
        this.userList = userList
        this.params.total = res.pageResult.totalCount
        this.loading = false
      }).catch(() => {
        this.loading = false
      }, new Error(() => {
        this.loading = false
      }))
    },
    statusFormatter(val) {
      let status = ''
      switch (val.supplierStatus) {
        case 0:
          status = '待审核'
          break
        case 1:
          status = '审核失败'
          break
        case 3:
          status = '启用'
          break
        case 2:
          status = '部分启用'
          break
        case 4:
          status = '平台禁用'
          break
        case 5:
          status = '已删除'
          break
        case 6:
          status = '未签约'
          break
        case 7:
          status = '签约失败'
          break
        case 8:
          status = '待设置合同文件'
          break
        default:
          status = '平台禁用'
      }
      return status
    },
    handleCheck(val) {
      this.$router.push({
        name: 'admin-supplier-detail',
        query: {
          id: val.supplierId
        }
      })
    },
    handleSetting(val) {
      this.$router.push({
        name: 'admin-supplier-set',
        query: {
          id: val.supplierId
        }
      })
    },
    handleStopUserStatus(index, val) {
      this.$confirm('供应商将会被停用，是否继续？', '提示', {
        type: 'warning'
      }).then(() => {
        this.updateStatus(val.supplierId, 4).then((res) => {
          // this.stuList.splice(index, 1)
          console.log(res)
          this.userList[index].supplierStatus = 4
        }).catch(() => {

        })
      }).catch(() => {
      })
    },
    handleStartUserStatus(index, val) {
      this.$confirm('供应商将会被启用，是否继续？', '提示', {
        type: 'warning'
      }).then(() => {
        this.updateStatus(val.supplierId, 3).then((res) => {
          console.log(res)
          this.userList[index].supplierStatus = 3
        }).catch(() => {

        })
      }).catch(() => {
      })
    },
    async updateStatus(supplierId, supplierStatus) {
      return await web_managerSupplier_updateStatus({supplierId, supplierStatus})
    },
    handleDel(index, val) {
      this.$confirm('供应商将会被删除，是否继续？', '提示', {
        type: 'warning'
      }).then(() => {
        web_managerSupplier_supplierDelete({supplierId: val.supplierId}).then((res) => {
          // this.stuList.splice(index, 1)
          console.log(res)
          this.getSupplierList()
        }).catch(() => {

        })
      }).catch(() => {
      })
    },
    handleSetTourLine(val) {
      this.$router.push({
        name: 'admin-supplier-tour-line-set',
        query: {
          id: val.supplierId
        }
      })
    },
    handleLogOut(index, val) {
      this.$confirm('确定强制退出吗?', '提示', {
        type: 'warning'
      }).then(() => {
        web_common_kickOutUser({userId: val.userId}).then(res => {
          if (res.code === '000000') {
            this.$message({
              message: res.msg,
              type: 'success'
            })
          } else {
            this.$message({
              message: res.msg,
              type: 'warning'
            })
          }
        })
      }).catch(() => {
      })
    },
    handleContract(val) {
      this.$router.push({
        name: 'admin-contract-set',
        query: {
          supplierId: val.supplierId,
          type: (val.supplierStatus === 6 || val.supplierStatus === 7 || val.supplierStatus === 8) ? 'audit' : 'look'
        }
      })
    },
    changeSort({ column, prop, order }) {
      // orderByLastLoginTime: '', // 最后登录时间排序填值: asc:升序,desc:降序,不需要为空即可
      // orderByGmtCreate: '', // 创建时间排序填值: asc:升序,desc:降序,不需要为空即可
      if (order === null) {
        this.params.orderByLastLoginTime = ''
        this.params.orderByGmtCreate = ''
      } else if (prop === 'registerTime') {
        this.params.orderByLastLoginTime = ''
        this.params.orderByGmtCreate = order === 'ascending' ? 'asc' : 'desc'
      } else {
        this.params.orderByGmtCreate = ''
        this.params.orderByLastLoginTime = order === 'ascending' ? 'asc' : 'desc'
      }
      this.onSearch()
      // this.getSupplierList()
    }
  }
}




/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
