// web_staffManage_update 修改员工信息

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口地址：http://192.168.0.15:8080/doc.html#/所有接口/staff-management-controller/updateStaffUsingPOST

export default function web_staffManage_update(pParameter) {
  if (!pParameter) pParameter = {};
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/staffManage/update'
  params.data = pParameter
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}
