
// 获取前缀
import get_url_prefix from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/get_url_prefix';
// 获取token
import get_token from 'nllib/src/model/get_token';


const get_preview_url = function (productNo) {
  const url_prefix = get_url_prefix({
    appKey: `frontend-distributor`,
  });
  const token = get_token();
  const href = `${url_prefix}/redirect/?redirectType=402&token=${token}&productNo=${productNo}`;
  
  window.open(href,'_blank');
}

export default get_preview_url;