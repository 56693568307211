// web_common_user_getUserInfo 查看用户信息

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口地址：http://192.168.0.15:8080/doc.html#/所有接口/user-controller/getUserInfoUsingPOST

export default function web_common_user_getUserInfo(pParameter) {
  if (!pParameter) pParameter = {};
  const id = pParameter.id || ''

  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/common/user/getUserInfo'
  params.data = {
    id
  }
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}
