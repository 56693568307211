import import_latest_version_module from '@/component/admin-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/customer-supplier-list',
            name: 'admin-customer-supplier-list',
            component: () => import(/* webpackChunkName: "page-admin-customer-management-supplier-list" */ `@/page/admin/supplier-management/supplier-list/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `供应商列表`,
                },
                entity_list_for_breadcrumb: [
                    {name: `客户管理`},
                    {name: `供应商列表`},
                ],
                onlyCheckDetail: true
            },
        },
    ],
};