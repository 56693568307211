import moment from 'moment'
import _ from 'underscore';
import Pagination from '@/component/base-pagination/index.vue'
import web_credit_getEnum from '@/lib/data-service/default/web_credit_getEnum'
import web_credit_getCreditAccount from '@/lib/data-service/default/web_credit_getCreditAccount'
import web_credit_startCredit from '@/lib/data-service/default/web_credit_startCredit' // 启用授信客户
import web_credit_closeCredit from '@/lib/data-service/default/web_credit_closeCredit' // 停用授信客户

export default {
    data () {
        return {
            loading: false,
            form: {
                accountName: '',
                accountStatus: '1',
                companyName: '',
                creditMode: '',
                orderStatus: '',
            },
            page:   {
                current: 1,
                size: 10,
                total: 0
            },
            datas: {},
            list: [],
            allocation: {
                creditModeList: [],
                creditAccountStatusList: [],
                creditProductStatusList: [],
            }
        }
    },
    components: {
        Pagination
    },
    methods: {
        getEnum() {
            const __this = this;
            web_credit_getEnum(['CreditMode', 'CreditAccountStatus', 'CreditProductStatus']).then((res) => {
                __this.allocation.creditModeList = res.result.CreditMode;
                __this.allocation.creditAccountStatusList = res.result.CreditAccountStatus;
                __this.allocation.creditProductStatusList = res.result.CreditProductStatus;
            })
        },
        getCreditAccount() {
            const __this = this;
            const params = {
                current: __this.page.current,
                size: __this.page.size,
                query: __this.form
            };
            __this.loading = true;
            web_credit_getCreditAccount(params).then(res => {
                const list = res.creditAccounts.records;
                __this.datas = res;
                __this.list = list;
                __this.page.total = res.creditAccounts.total;
                __this.loading = false
            }).catch(() => {
                __this.loading = false
            }, new Error(() => {
                __this.loading = false
            }))
        },
        onSearch() {
            const __this = this;
            __this.page.current = 1;
            __this.getCreditAccount()
        },
        onReset() {
            const __this = this;
            __this.form = {
                accountName: '',
                    accountStatus: '1',
                    companyName: '',
                    creditMode: '',
                    orderStatus: '',
            };
            __this.page = {
                current: 1,
                size: 10,
            };
            __this.onSearch()
        },
        handleClick() {
            const __this = this;
            __this.onSearch();
        },
        onStartCredit(row) {
            const __this = this;
            web_credit_startCredit({companyId: row.companyId}).then(res => {
                __this.$message.success('启用成功');
                __this.onSearch();
            }).catch(err => {
                __this.$message.success('启用失败');
            });
        },
        onCloseCredit(row) {
            const __this = this;
            web_credit_closeCredit({companyId: row.companyId}).then(res => {
                __this.$message.success('停用成功');
                __this.onSearch();
            }).catch(err => {
                __this.$message.success('停用失败');
            });
        },
        to_detail(row) {
            const __this = this;

            const get_route_name = function () {
                let r = `admin-credit-customer-detail`;

                const meta = __this.$route.meta;
                if (!meta) return r;

                const data_app_for_npm_yinzhilv_frontend_platform_v2 = meta.data_app_for_npm_yinzhilv_frontend_platform_v2;
                if (!data_app_for_npm_yinzhilv_frontend_platform_v2) return r;

                const get_route_detail_page = data_app_for_npm_yinzhilv_frontend_platform_v2.get_route_detail_page;
                if (!_.isFunction(get_route_detail_page)) return r;

                const route = get_route_detail_page();
                if (!route) return r;

                const route_name = route.route_name;
                if (!route_name) return r;

                r = route_name;
                return r;
            };

            const route_name = get_route_name();

            __this.$router.push({
                name: route_name,
                query: {
                    companyId: row.companyId
                }
            })
        }
    },
    activated () {
        const __this = this;
        __this.getEnum();
        __this.getCreditAccount();
    },
}
