import Q from 'q';
// 详情
import web_common_platformSpecPricePrdController_auditProduct from '@/lib/data-service/default/web_common_platformSpecPricePrdController_auditProduct'
// 审核
import web_common_platformSpecPricePrdController_auditSpecProduct from '@/lib/data-service/default/web_common_platformSpecPricePrdController_auditSpecProduct'

import FullCalendar from '../component/FullCalendar/audit/index.vue';



import api from '@/component/admin-layout/1.0.0/component/history-tag/api.js';

export default {
  data () {
    const validateAmount = (rule, value, callback) => {
      const form = this.form;
      if (form.productStatus === 5) {
        if (form.specialPlatformServiceAmount === '' || form.specialPlatformServiceAmount === undefined) {
          callback(new Error('请输入平台服务费'));
        }
        callback();
      } else if (form.productStatus === '') {
        callback();
      }
    };
    const validateReason = (rule, value, callback) => {
      const form = this.form;
      if (form.productStatus === 2) {
        if (form.failReason === '' || form.failReason === undefined) {
          callback(new Error('请输入失败原因'));
        }
        callback();
      } else if (form.productStatus === '') {
        callback();
      }
    };
    return {
      detail: {},
      calendarData: {
				events: [],
        locale: '',
				selectStatus: 2,
				firstDay: 0,
				initialDayTime: 0,
				deleteGroupDate: [],
      },
      form: {
        productNo: '',
        productStatus: '',
        specialPlatformServiceAmount: '',
        failReason: '',
      },
      formRules: {
        specialPlatformServiceAmount: [
          { validator: validateAmount, trigger: 'blur', required: true }
        ],
        auditContent:[
          { validator: validateReason, trigger: 'blur', required: true }
        ]
      },
      dialogTitle: '',
      dialogVisible: false,
    }
  },
  components: {
    FullCalendar,
  },
  methods: {
    req_tourDetail (params) {
      const _this = this;
      Q.when()
        .then(function () {
          return web_common_platformSpecPricePrdController_auditProduct(params);
        })
        .then(function (res) {
          _this.detail = res;
          _this.calendarData.events = res.viewDetailsTemResult;
        })
    },
    req_audit (params) {
      const _this = this;
      Q.when()
        .then(function () {
          return web_common_platformSpecPricePrdController_auditSpecProduct(params);
        })
        .then(function (res) {
          if (params.productStatus === 5) {
            _this.$message.success('特价设置审核通过成功')
          } else if (params.productStatus === 2) {
            _this.$message.success('特价设置审核不通过成功');
            _this.dialogVisible = false;
            api.remove_tag_by_route({route: _this.$route})
          }
          _this.handleClose();
          _this.$router.push({
            name: 'admin-special-offer-product-audit-list',
          })
        })
    },
    pass_audit () {
      this.form.productStatus = 5;
      this.dialogTitle = '审核意见：审核通过'
      this.dialogVisible = true;
    },
    fall_audit () {
      this.form.productStatus = 2;
      this.dialogTitle = '审核意见：审核不通过'
      this.dialogVisible = true;
    },
    handleClose () {
      this.dialogVisible = false;
      this.form.productStatus = '';
      this.dialogTitle = '';
      this.form.specialPlatformServiceAmount = '';
      this.form.failReason = '';

      this.$refs.form.validate((valid) => {

      })
    },
    commit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.req_audit(this.form);
        }
      })
    }
  },
  created () {
  },
  mounted () {},
  activated () {
    this.form.productNo = this.$route.query.productNo;
    this.req_tourDetail({productNo: this.$route.query.productNo});
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {
    labelWidth: function () {
      const productStatus = this.form.productStatus;
      if (productStatus === 5) {
        return '130px';
      } else if (productStatus === 2) {
        return '90px';
      }
    }
  },
  filters: {

  },
}
