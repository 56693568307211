const __request = require(`./__request/__request_contentType_json`);

// 微商城详情
// http://tour-alpha.yinzhilv.com/doc.html#/yinzhilv_travel/btoc-controller/btocUpdateUsingPOST
const request = (pParameter) => {
  if (!pParameter) pParameter = {};

  // const buyerId = pParameter.buyerId
  // const supplierTravelRequst = pParameter.supplierTravelRequst // 对象

  const p = {
    method: 'post',
    urlSuffix: '/web/btoc/update',
    data: pParameter
  };
  return new Promise(function (resolve, reject) {
    resolve(__request(p))
  })
};
module.exports = request;