// web_credit_closeCredit 停用授信账户

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口文档地址： http://192.168.0.15:8080/doc.html#/所有接口/credit-account-controller/closeCreditAccountUsingPOST
export default function web_credit_closeCredit(pParameter) {
  let params = {}
  if (!pParameter) pParameter = {};
  const companyId = pParameter.companyId || ''
  params.method = 'POST'
  params.urlSuffix = '/web/credit/closeCredit'
  params.data = {
    companyId
  }
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
