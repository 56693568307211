export default {
    name: 'FullImage',
    data () {
        return {}
    },
    props: {
        dialogVisible: {
            type: Boolean,
            default: false
        },
        src: {
            type: String,
            default: ''
        }
    },
    components: {},
    created () {
    },
    mounted () {},
    activated () {
    },
    deactivated() {},
    destroyed () {
    },
    watch: {},
    computed: {
        dialogFullPicVisible: {
            get() {
                return this.dialogVisible
            },
            set(val) {
                this.$emit('update:dialogVisible', val)
            }
        }
    },
    filters: {},
    methods: {
        closeDialog() {
            this.dialogFullPicVisible = false
        }
    }
}