import web_common_updateSolrProduct from '@/lib/data-service/default/web_common_updateSolrProduct'
import wallet_account_initAccount from '@/lib/data-service/default/wallet_account_initAccount'
export default {
  data () {
    return {
      updateShopLoading: false,
      canUpdateShop: true,
      updateShopText: '更新商城',
      updateUserLoading: false,
      canUpdateUser: true,
      updateUserText: '更新钱包账户'
    }
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
    handleUpdateShop() {
      if (!this.canUpdateShop) {
        return
      }
      this.updateShopLoading = true
      this.updateShopText = '更新中'
      web_common_updateSolrProduct().then(res => {
        this.updateShopLoading = false
        if (res.code === '000000') {
          this.canUpdateShop = false
          this.updateShopText = '更新成功'
          this.$message({
            message: '更新成功',
            type: 'success'
          });
        } else {
          this.updateShopText = '更新商城'
          this.$message({
            message: res.msg,
            type: 'warning'
          });
        }
      }).catch(() => {
        this.updateShopLoading = false
        this.updateShopText = '更新商城'
      })
    },
    handleUpdatePro() {},
    handleUpdateUser() {
      if (!this.canUpdateShop) {
        return
      }
      this.updateUserLoading = true
      this.updateUserText = '更新中'
      wallet_account_initAccount().then(res => {
        this.updateUserLoading = false
        if (res.code === '000000') {
          this.canUpdateUser = false
          this.updateUserText = '更新成功'
          this.$message({
            message: '更新成功',
            type: 'success'
          });
        } else {
          this.updateUserText = '更新商城'
          this.$message({
            message: res.msg,
            type: 'warning'
          });
        }
      }).catch(() => {
        this.updateUserLoading = false
        this.updateUserText = '更新商城'
      })
    }
  }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
