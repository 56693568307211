

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 查看出团通知书： http://192.168.0.13:8080/doc.html#/%E6%89%80%E6%9C%89%E6%8E%A5%E5%8F%A3/tour-order-controller/lookDepartInformUsingPOST_1
export default function web_common_lookDepartInform(pParameter) {
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/common/lookDepartInform';
  params.data = pParameter;

  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
