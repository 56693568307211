import web_common_upload_aptitudeSingle from '@/lib/data-service/default/web_common_upload_aptitudeSingle'
import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client";
import addImgSuffix from '@/lib/common-service/add_img_suffix'
export default {
  name: 'UploadImage',
  data () {
    return {
      ossClient: new OssClient()
    }
  },
  props: {
    imgList: {
      type: Array,
      default: () => []
    },
    idName: {
      type: String,
      default: ''
    },
    formName: {
      type: String,
      default: ''
    },
    arrListName: {
      type: String,
      default: ''
    }
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {
    filterImg(val) {
      let result = addImgSuffix(val)
      return result
    }
  },
  methods: {
    //获取产品图片上传的地址
    uploadChange(file, name, from) {
      this.ossClient.multipartUpload({
        file: file.file
      }).then(data => {
        this.$message({ type: 'success', message: '上传图片成功' })
        this.$emit('uploadChange', file, name, from, this.arrListName, data)
      }, error => {
        this.$message({ type: 'error', message: '图片上传失败！' })
        this.$refs[name].clearFiles()
      }).catch(() => {
        this.$message({ type: 'error', message: '图片上传失败！' })
        this.$refs[name].clearFiles()
      })

      /*web_common_upload_aptitudeSingle(file.file).then(data => {
        // this.$emit('uploadChange', file, name, from, this.arrListName)
        this.$message({ type: 'success', message: '上传图片成功' })
        this.$emit('uploadChange', file, name, from, this.arrListName, data)
      }, error => {
        this.$message({ type: 'error', message: '图片上传失败！' })
        this.$refs[name].clearFiles()
      })*/

    },
    /* showImg(name, showStatus, from) {
      let self = this
      document.querySelector(`#${name} .el-upload`).style.display = showStatus
    }, */
    //检验img格式
    detectionImg(file) {
      const reg2 = /^(\s|\S)+(jpg|png|bmp)+$/
      const isLt2M = (file.size / 1024) < (10 * 1024)
      if (!reg2.test(file.name)) {
        this.$message({ type: 'warning', message: '图片格式上传错误！' })
        return false
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 10M!');
        return false
      }
    },
    handleRemoveImg() {
      this.$refs[this.idName].clearFiles()
      // this.showImg(this.idName, 'inline-block', this.formName)
      this.$emit('handleRemoveImg', this.idName, this.arrListName, this.formName)

    },
    handlePictureCardPreview(file) {
      this.$emit('handlePictureCardPreview', file)
    },
  }
}
