import import_latest_version_module from '@/component/admin-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/customer-buyer-list',
            name: 'admin-customer-buyer-list',
            component: () => import(/* webpackChunkName: "page-admin-customer-management-buyer-list" */ `@/page/admin/buyer-management/buyer-list/1.0.1/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `分销商列表`,
                },
                entity_list_for_breadcrumb: [
                    {name: `客户管理`},
                    {name: `分销商列表`},
                ],
                onlyCheckDetail: true
            },
        },
    ],
};