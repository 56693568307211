import Q from "q";
import moment from 'moment';

// 平台订单管理
import web_salesOrder_platformOrderList from "@/lib/data-service/default/web_salesOrder_platformOrderList";
// 查看确认单
import web_common_affirmBill from "@/lib/data-service/default/web_common_affirmBill";
// 查看出团通知书
import web_common_lookDepartInform from "@/lib/data-service/default/web_common_lookDepartInform";

import Citys from "@/component/citys/index.vue";

export default {
  components: {
    Citys
  },
  data() {
    return {
      form: {
        currentPage: 1,
        pageSize: 10,
        productNo: "",
        productName: "",
        buyerName: '',
        orderNo: "",
        departtureDateStart: "",
        departtureDateEnd: "",
        orderDateStart: "",
        orderDateEnd: "",
        collectGuestType: "",
        setOutCountry: "",
        setoutProvince: "",
        setoutCity: "",
        productType: "",
        productAreasType: "",
        destinationCountry: "",
        destinationProvince: "",
        destinationCity: "",
        orderType: "",
        orderState: ""
      },
      allocation: {
        collectGuestType: [
          { label: "全部", value: "" },
          { label: "散拼团", value: 1 },
          { label: "小包团", value: 2 }
        ],
        productType: [
          { label: "全部", value: "" },
          { label: "跟团游", value: 1 },
          { label: "自由行", value: 2 },
          { label: "当地游", value: 3 }
        ],
        productAreasType: [
          { label: "全部", value: "" },
          { label: "国内", value: 1 },
          { label: "出境", value: 2 }
        ],
        orderType: [
          { label: "全部", value: "" },
          { label: "普通订单", value: 1 },
          { label: "切位订单", value: 2 },
          { label: "特价订单", value: 3 }
        ],
        state: [
          { label: "全部", value: 0, orderState: '', finalPayment: '', orderCancel: '', },
          { label: "待支付", value: 3, orderState: 3, finalPayment: '', orderCancel: '', },
          { label: "待供应商确认", value: 1, orderState: 1, finalPayment: '', orderCancel: '', },
          { label: "待平台确认", value: 2, orderState: 2, finalPayment: '', orderCancel: '', },
          { label: "待确认出团", value: 11, orderState: 4, finalPayment: '', orderCancel: '', },
          { label: "已确认出团", value: 4, orderState: 5, finalPayment: '', orderCancel: '', },
          { label: "已出团", value: 5, orderState: 6, finalPayment: '', orderCancel: '', },
          { label: "已回团", value: 6, orderState: 7, finalPayment: '', orderCancel: '', },
          { label: "已分账", value: 7, orderState: 8, finalPayment: '', orderCancel: '', },
          { label: "待缴尾款", value: 8, orderState: 10, finalPayment: 0, orderCancel: '', },
          { label: "已缴尾款", value: 9, orderState: 10, finalPayment: 1, orderCancel: '', },
          { label: "已取消", value: 10, orderState: '', finalPayment: '', orderCancel: 2, },
        ]
      },
      state: 0,
      loading: false,
      pager: {
        pageSizes: [10, 20, 30, 40, 50],
        totalCount: 0
      },
      orderList: [],
      show: false
    };
  },
  methods: {
    req_order_list(params, type) {
      this.loading = true;
      const _this = this;
      Q.when()
        .then(function() {
          return web_salesOrder_platformOrderList(params);
        })
        .then(function(res) {
          const orderList = res.pageResult.pageData;
          orderList.forEach(function (item) {
            if (item.departureDate) item.departureDate = moment(item.departureDate).format('YYYY-MM-DD');
            // if (`${item.finalPayment}` === '0') item.orderStateText = '待缴尾款';
            // if (item.finalPayment === 1) item.orderStateText = '已缴尾款';


            if (item.orderCancel === 2) item.orderStateText = '已取消';


            // if (item.orderState === 1) item.orderStateText = '待供应商确认';
            // if (item.orderState === 2) item.orderStateText = '待平台确认';
            // if (item.orderState === 3) item.orderStateText = '待支付';
            if (item.orderState === 4) item.orderStateText = '待确认出团';
            if (item.orderState === 5) item.orderStateText = '已确认出团';
            // if (item.orderState === 6) item.orderStateText = '已出团';
            // if (item.orderState === 7) item.orderStateText = '已回团';
            // if (item.orderState === 8) item.orderStateText = '已分账';
          })
          _this.orderList = orderList;
          _this.pager.totalCount = res.pageResult.totalCount;
          if (type) document.documentElement.scrollTop = 0;
          _this.loading = false;
        });
    },
    fold_change() {
      this.show = !this.show;
    },
    req_affirmBill(params) {
      return Q.when().then(function() {
        return web_common_affirmBill(params);
      });
    },
    req_departInform(params) {
      return Q.when().then(function() {
        return web_common_lookDepartInform(params);
      });
    },
    getFromCountry(value) {
      this.form.setOutCountry = value;
    },
    getFromProvince(value) {
      this.form.setOutProvince = value;
    },
    getFromCity(value) {
      this.form.setOutCity = value;
    },
    getToCountry(value) {
      this.form.destinationCountry = value;
    },
    getToProvince(value) {
      this.form.destinationProvince = value;
    },
    getToCity(value) {
      this.form.destinationCity = value;
    },
    search() {
      this.req_order_list(this.form);
    },
    reset() {
      const pageSize = this.form.pageSize;
      const orderState = this.form.orderState;
      this.form = {
        currentPage: 1,
        pageSize,
        productNo: "",
        productName: "",
        buyerName: '',
        orderNo: "",
        departtureDateStart: "",
        departtureDateEnd: "",
        orderDateStart: "",
        orderDateEnd: "",
        collectGuestType: "",
        productType: "",
        productAreasType: "",
        orderType: "",
        orderState,
        setOutCountry: "",
        setoutProvince: "",
        setoutCity: "",
        destinationCountry: "",
        destinationProvince: "",
        destinationCity: ""
      };
      this.$refs.fromCity.reset();
      this.$refs.toCity.reset();
      this.req_order_list(this.form);
    },
    select_state(state) {
      let stateObj = {};
      this.allocation.state.forEach(function (item) {
        if (item.value === state) stateObj = item;
      });
      console.log(stateObj);
      this.form.orderState = stateObj.orderState;
      this.form.finalPayment = stateObj.finalPayment;
      this.form.orderCancel = stateObj.orderCancel;
      this.form.currentPage = 1;
      this.req_order_list(this.form);
    },
    to_detail(id) {
      this.$router.push({
        name: "admin-plain-order-detail",
        query: { id }
      });
      document.documentElement.scrollTop = 0;
    },
    look_affirmBill(orderId, type) {
      const _this = this;
      Q.when()
        .then(function() {
          return _this.req_affirmBill({ orderId });
        })
        .then(function(res) {
          let url = "";
          if (type === "supplier") {
            url = res.confirmationUrlResult.supplierAffirmBill.split("?")[0];
          } else if (type === "buyer") {
            url = res.confirmationUrlResult.buyerAffirmBill.split("?")[0];
          }
          if (url) {
            window.open(url, "_blank");
          } else {
            _this.$message.error("返回url为空，请联系管理员");
          }
        });
    },
    look_departInform(orderId) {
      const _this = this;
      Q.when()
        .then(function() {
          return _this.req_departInform({ orderId });
        })
        .then(function(res) {
          const url = res.url.split("?")[0];
          if (url) {
            window.open(url, "_blank");
          } else {
            _this.$message.error("返回url为空，请联系管理员");
          }
        });
    },
    handleSizeChange(pageSize) {
      this.form.pageSize = pageSize;
      this.req_order_list(this.form, "pageSize");
    },
    handleCurrentChange(currentPage) {
      this.form.currentPage = currentPage;
      this.req_order_list(this.form, "currentPage");
    }
  },
  created() {},
  mounted() {},
  activated() {
    this.req_order_list(this.form);
  },
  deactivated() {},
  destroyed() {},
  watch: {},
  computed: {}
};
