// web_managerSupplier_flightSupplier_getServiceFee 查询供应商审核详情

const __request = require(`@/lib/data-service/default/__request/__request_contentType_json`);

// 接口地址：http://192.168.0.19:9999/doc.html#/yinzhilv_travel/manager-supplier-controller/getServiceFeeUsingPOST

export default function web_managerSupplier_flightSupplier_getServiceFee() {
    let params = {}
    params.method = 'POST'
    params.urlSuffix = '/web/managerSupplier/flightSupplier/getServiceFee'
    params.data = {}
    return new Promise(function (resolve, reject) {
        resolve(__request(params))
    })
}
