import history_tag_api from '@/component/admin-layout/1.0.0/component/history-tag/api'
import web_btoc_check from '@/lib/data-service/default/web_btoc_check'
import web_btoc_check_submit from '@/lib/data-service/default/web_btoc_check_submit'

export default {
    data() {
        const validateNumber = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入门店数量'))
            } else {
                if (!(/(^[1-9]\d*$)/.test(value))) {
                    callback(new Error('请输入正整数'))
                } else {
                    callback()
                }
            }
        };
        return {
            loading: false,
            details: {
                btocRecord: {}, // 微商城信息
                tUsBuyerAptitude: {}, // 旅游资质
            },
            form: {
                btocVersion: '', // 店铺版本
                accountName: '', // 微商城账号
                storeName: '', // 微商城名称
                storeLimit: -1, // 多店版 门店数量限制 -1 代表不限
            },
            rules:{
                storeLimit: [{required: true, validator: validateNumber, trigger: 'blur'}],
            },
            storeAmount: 1,

            dialogForm: {
                rejectionReason: '',
            },
            dialogVisible: false,
        }
    },
    methods: {
        getDetail() { // 获取详情
            const __this = this;
            __this.loading = true;
            web_btoc_check({id: parseInt(__this.$route.query.id)}).then(data => {
                __this.details.btocRecord = data.btocRecord;
                __this.details.tUsBuyerAptitude = data.tUsBuyerAptitude || {};

                history_tag_api.modify_tag_name_by_route({
                    route: __this.$route,
                    tag_name: `${data.btocRecord.buyerCompanyName}-申请审批`,
                });
            }).finally(() => {
                __this.loading = false
            })
        },

        onCompanyDetail() { // 查看公司信息
            const __this = this;
            __this.$router.push({
                path: '/admin/buyer-detail',
                query: {
                    id: __this.details.btocRecord.buyerId,
                },
            })
        },

        onSubmit() { // 审核通过
            const __this = this;
            __this.$refs.form.validate((valid) => {
                if(valid) {
                    __this.$confirm('确认审批通过', '审批通过', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        const params = {
                            id: __this.$route.query.id,
                            status: 3,
                            storeLimit: __this.form.storeLimit
                        };
                        __this.loading = true;
                        web_btoc_check_submit(params).then(() => {
                            __this.$message.success('审核已通过');
                            __this.backRouter()
                        }).finally(() => {
                            __this.loading = false;
                        })
                    }).catch(() => {})
                }
            });
        },

        onRefuseSubmit() { // 审核不通过
            const __this = this;
            __this.$refs.dialogForm.validate((valid) => {
                if (valid) {
                    const body = {
                        id: __this.$route.query.id,
                        rejectionReason: __this.dialogForm.rejectionReason,
                        status: 2,
                        buyerAptitudeResult: {
                            commonSeal: __this.details.tUsBuyerAptitude.commonSeal
                        },
                    };
                    __this.loading = true;
                    web_btoc_check_submit(body).then(() => {
                        __this.dialogVisible = false;
                        __this.$message({
                            type: 'success',
                            message: '已拒绝此申请'
                        });
                        __this.backRouter()
                    }).finally(() => {
                        __this.loading = false;
                    })
                } else {
                    return false
                }
            })
        },
        backRouter() {
            const __this = this;
            history_tag_api.remove_tag_by_route({
                route: __this.$route,
            });
            __this.$router.replace('/admin/mall-list')
        },
    },
    activated() {
        const __this = this;
        __this.getDetail()
    },
    watch: {
        storeAmount(val) {
            const __this = this;
            if (val === 1) {
                __this.form.storeLimit = -1;
            } else {
                __this.form.storeLimit = 5;
            }
        }
    }
}
