const __request = require(`./__request/__request_contentType_json`);

// 开通分销商机票所有权限
export default function web_buyer_air_status(pParameter) {
  if (!pParameter) pParameter = {};
  const buyerId = pParameter.buyerId || ''
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/buyer/updateAirStatus'
  params.data = {
    buyerId,
  }
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}