import Q from 'q';
import _ from 'underscore';
import get_query_string_object from 'nllib/src/uri/get_query_string_object';
import set_token from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/set_token';
import consumer_tokenLogin from '@/lib/common-service/consumer_tokenLogin';

export default {
    components: {},
    data() {
        return {}
    },
    methods: {},
    created() {
        const __this = this;

        const aQueryStringObject = get_query_string_object({
            isLowerCase: true,
        });
        console.log(aQueryStringObject);

        const token = aQueryStringObject.token;
        if (!token) {
            const msg = 'redirect parameter error, code=001';
            console.error(msg);
            return;
        }

        set_token({
            token: token,
        });

        const redirectType = aQueryStringObject.redirecttype;
        switch (redirectType) {
            case `000`:
            default:
                consumer_tokenLogin().then(function () {
                    __this.$router.push({
                        name: `admin-home`,
                    });
                })
                break;
        }
    },
    mounted() {
        // const __this = this;
    },
    activated() {
        // const __this = this;
    },
    deactivated() {
        // const __this = this;
    },
}
