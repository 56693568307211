import Q from 'q';

// 待审核产品列表
import web_common_platfromOrder from '@/lib/data-service/default/web_common_platfromOrder';



export default {
  components: {
    
  },
  data() {
    return {
      htmlStr: null,
    };
  },
  methods: {
  
  },
  created() {},
  mounted() {},
  activated() {
    this.htmlStr = this.$route.query.htmlStr;
  },
  deactivated() {},
  destroyed() {},
  watch: {
    htmlStr: function () {
      setTimeout(() => {
        const form = document.getElementsByName('punchout_form');
        form[0].submit();
      }, 0);
    }
  },
  computed: {}
};
