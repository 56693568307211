import _ from "underscore";
import Q from "q";
import extend from "extend";
import to_string from "nllib/src/convert/to_string";
import to_boolean from "nllib/src/convert/to_boolean";

import all_routes_processor from "@/router/init_router/all_routes_processor";
import redirect_to_login_page from "@/lib/common-service/redirect_to_login_page";
import get_enable_check_token from "@/lib/common-service/route_helper/get_enable_check_token";

const debug = false;

const get_token = function () {
    // return ``;
    return `mock-token`;
};

const check_token = function () {
    // return Q.reject({});
    return Q.resolve({
        flag: true,
        // flag: false,
    });
};

const handler = function ({to, from, next}) {
    const get_all_route_list = all_routes_processor.get_all_route_list;

    const all_route_list = get_all_route_list();

    const route_name_list_enable_check_token = _.chain(all_route_list)
        .filter(function (m) {
            const enable_check_token = get_enable_check_token({
                $route: m,
            });
            return enable_check_token;
        })
        .map(function (m) {
            return m.name;
        })
        .value()
    ;

    const to_name = to.name;

    if (!_.contains(route_name_list_enable_check_token, to_name)) {
        if (debug) console.log(`check_token_processor::to_name=${to_name}|enable_check_token=false，不需要执行check_token操作`);
        return Q.resolve();
    }

    if (debug) console.log(`check_token_processor::to_name=${to_name}|enable_check_token=true，需要执行check_token操作`);
    const token = get_token();
    if (!token) {
        console.log(`check_token_processor::to_name=${to_name}|token为假值，执行【redirect_to_login_page】`);
        redirect_to_login_page();
        return Q.reject();
    }

    const defer = Q.defer();

    check_token().then(
        function (data) {
            const flag = data.flag;
            if (flag) {
                if (debug) console.log(`check_token_processor::to_name=${to_name}|check_token操作验证通过，执行【resolve】`);
                defer.resolve();
                return;
            }

            if (debug) console.log(`check_token_processor::to_name=${to_name}|check_token操作验证不通过，执行【redirect_to_login_page】`);
            redirect_to_login_page();
            defer.reject();
        },
        function () {
            if (debug) console.log(`check_token_processor::to_name=${to_name}|check_token操作验证不通过，执行【redirect_to_login_page】`);
            redirect_to_login_page();
            defer.reject();
        }
    );

    return defer.promise;

};

const next = handler;

export default {
    next,
}
