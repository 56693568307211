import Q from 'q';
// 国内
import web_common_area_getInlandAreaChildListByParentId from '@/lib/data-service/default/web_common_area_getInlandAreaChildListByParentId'
// 国际
import web_common_area_getAreaDataByAreaType from '@/lib/data-service/default/web_common_area_getAreaDataByAreaType'
// 国际
import web_common_area_getInternationalAreaChildListByParentId from '@/lib/data-service/default/web_common_area_getInternationalAreaChildListByParentId'

export default {
  props: {
    // type为toCity（可选区域为国内或国外） 或 fromCity（国内使用）
    type: String,
    // type为toCity时返回省份
    getToProvince: Function,
    // type为toCity时返回城市
    getToCity: Function,
    // type为fromCity时返回大区
    getFromArea: Function,
    // type为fromCity时返回省份
    getFromProvince: Function,
    // type为fromCity时返回城市
    getFromCity: Function,


    getCountry: Function,
    getProvince: Function,
    getCity: Function,
  },
  data () {
    return {
      areaType: '',
      province: '',
      city: '',
      areaList: [],
      international: [
        {areaName: '全部', id: ''},
        {areaName: '国内', id: 1},
        {areaName: '国际', id: 2},
      ],
      inland: [],
      provinceList: [],
      cityList: [],



      areaCountry: '',
      region: '',
      areaProvince: '',
      areaCity: '',
      areaCountryList: [
        {areaName: '全部', id: ''},
        {areaName: '国内', id: 1},
        {areaName: '国际', id: 2},
      ],
      regionList: [],
      areaProvinceList: [],
      areaCityList: [],
    }
  },
  created () {
  },
  mounted () {
    this.initialize();
  },
  methods: {
    // 国际
    req_international_list (params) {
      return Q.when()
        .then(function () {
          return web_common_area_getAreaDataByAreaType(params);
        })
    },
    req_international_second_list (params) {
      return Q.when()
        .then(function () {
          return web_common_area_getInternationalAreaChildListByParentId(params);
        })
    },
    // 国内
    req_inland_list (params) {
      return Q.when()
        .then(function () {
          return web_common_area_getInlandAreaChildListByParentId(params);
        })
    },
    // type为toCity时调用重置
    reset_toArea () {
      this.areaType = '';
      this.province = '';
      this.city = '';
      this.areaList = this.international;
      this.provinceList = [];
      this.cityList = [];
    },
    // type为fromCity时调用重置
    reset_fromArea () {
      this.areaType = '';
      this.province = '';
      this.city = '';
      this.areaList = [{areaName: '全部', id: ''},...this.inland];
      this.provinceList = [];
      this.cityList = [];
    },
    reset () {
      this.areaCountry = '';
      this.region = '';
      this.areaProvince = '';
      this.areaCity = '';

      this.regionList = [];
      this.areaProvinceList = [];
      this.areaCityList = [];
    },
    initialize () {
      if (this.type === 'toCity') {
        this.areaList = this.international;
      } else if (this.type === 'fromCity') {
        const _this = this;
        Q.when()
          .then(function () {
            return _this.req_inland_list({id: 100000});
          })
          .then(function (res) {
            _this.inland = res;
            _this.areaList = [{areaName: '全部', id: ''},..._this.inland];
          })
      }
    }
  },
  watch: {
    areaType: function (areaType) {
      const _this = this;
      const type = _this.type;
      _this.province = '';
      _this.city = '';
      _this.provinceList = [];
      _this.cityList = [];
      if (type === 'toCity') {
        if (this.getToProvince) this.getToProvince('');
        if (this.getToCity) this.getToCity('');
        if (areaType === '') return;
        Q.when()
        .then(function () {
          return _this.req_international_list({areaType});
        })
        .then(function (res) {
          _this.provinceList = res;
        })
      } else if (type === 'fromCity') {
        if(this.getFromProvince) this.getFromProvince('');
        if(this.getFromCity) this.getFromCity('');
        if (this.getFromArea) this.getFromArea(areaType);
        if (areaType === '') return;
        Q.when()
          .then(function () {
            return _this.req_inland_list({id: areaType});
          })
          .then(function (res) {
            _this.provinceList = res
          })
      };
      if (_this.getToCountry) _this.getToCountry(areaType);
    },
    province: function (id) {
      const _this = this;
      const type = _this.type;
      _this.city = '';
      _this.cityList = [];
      if (type === 'toCity') {
        if (_this.getToProvince) _this.getToProvince(id);
        if (id === '') return;
        const areaType = _this.areaType;
        Q.when()
          .then(function () {
            if (areaType === 1) {
              return _this.req_inland_list({id});
            } else if (areaType === 2) {
              return _this.req_international_second_list({id});
            }
          })
          .then(function (res) {
            _this.cityList = res;
          })
      } else if (type === 'fromCity') {
        if (_this.getFromProvince) _this.getFromProvince(id);
        if (id === '') return;
        Q.when()
          .then(function () {
            return _this.req_inland_list({id});
          })
          .then(function (res) {
            _this.cityList = res;
          })
      }
    },
    city: function (city) {
      const type = this.type;
      if (type === 'toCity') {
        if (this.getToCity) this.getToCity(city);
      } else if (type === 'fromCity') {
        if (this.getFromCity) this.getFromCity(city);
      }
    },



    areaCountry (country) {
      const _this = this;
      if (_this.getCountry) _this.getCountry(country);
      _this.region = '';
      _this.regionList = [];
      if (country === '') {

      } else {
         Q.when()
          .then(function () {
            if (country === 1) {
              return _this.req_inland_list({ id: 100000 });
            } else if (country === 2) {
              return _this.req_international_second_list({ id: 0 });
            }
          })
          .then(function (res) {
              _this.regionList = res;
          });
      }
    },
    region (region) {
      const _this = this;
      _this.areaProvince = '';
      _this.areaProvinceList = [];
      Q.when()
        .then(function () {
          if (_this.areaCountry === 1) {
            return _this.req_inland_list({ id: region });
          } else if (_this.areaCountry === 2) {
            return _this.req_international_second_list({ id: region });
          }
        })
        .then(function (res) {
          _this.areaProvinceList = res;
        })
    },
    areaProvince (province) {
      const _this = this;
      if (_this.getProvince) _this.getProvince(province);
      _this.areaCity = '';
      _this.areaCityList = [];
      Q.when()
        .then(function () {
          if (_this.areaCountry === 1) {
            return _this.req_inland_list({ id: province });
          } else if (_this.areaCountry === 2) {
            return _this.req_international_second_list({ id: province });
          }
        })
        .then(function (res) {
          _this.areaCityList = res;
        })
    },
    areaCity (city) {
      if (this.getCity) this.getCity(city);
    }
  },
  computed: {},
}
