
// 当前用户修改密码接口文档地址：http://192.168.0.12:8080/doc.html#/yinzhilv_travel/user-controller/updatePasswordUsingPOST
const Q = require(`q`);
const _ = require(`underscore`);
const moment = require(`moment`);
const __request = require(`./__request/__request_contentType_json`);

const web_common_user_updatePassword = (pParams) => {
  pParams = pParams || {};
  const p = {
    method: 'post',
    urlSuffix: '/web/user/updatePassword',
    data: pParams
  };
  return Q.when()
    .then(function () {
      return __request(p);
    })
    .then(function (data) {

      return Q.resolve(data);
    })
    .catch(function (ex) {
      console.error(ex);
      if (ex) {
        if (ex.stack) {
          console.error(ex.stack);
        }
      }
      return Q.reject(ex);
    })
    ;

};
module.exports = web_common_user_updatePassword;
