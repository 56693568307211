// web_common_area_getAreaDataByAreaType

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口地址：

export default function web_common_area_getAreaDataByAreaType(pParameter) {
  if (!pParameter) pParameter = {};
  const areaType = pParameter.areaType || 1
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/common/area/getAreaDataByAreaType'
  params.data = {areaType};
  params.process_response_data = false;
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}
