const Q = require('q');
const __request = require(`./__request/__request_contentType_json`);

const web_credit_updateOrCreateProduct = function (pParameter) {
    if (!pParameter) pParameter = {};

    // 新增或修改授信产品：http://tour-alpha.yinzhilv.com/doc.html#/yinzhilv_travel/credit-account-controller/updateOrCreateProductUsingPOST

    const id = pParameter.id || ``;
    const creditPaymentDays = pParameter.creditPaymentDays || ``;
    const creditPaymentRate = pParameter.creditPaymentRate || ``;
    const productType = pParameter.productType || ``;
    const remark = pParameter.remark || ``;
    const productStatus = pParameter.productStatus || ``;
    const productName = pParameter.productName || ``;

    return Q.when()
        .then(function () {
            return __request({
                method: 'POST',
                urlSuffix: '/web/credit/updateOrCreateProduct',
                data: {
                    id: id,
                    creditPaymentDays: creditPaymentDays,
                    creditPaymentRate: creditPaymentRate,
                    productType: productType,
                    remark: remark,
                    productStatus: productStatus,
                    productName: productName
                },
            });
        })
        .catch(function (ex) {
            console.error(ex);
            if (ex) {
                if (ex.stack) {
                    console.error(ex.stack);
                }
            }
            return Q.reject(ex);
        })
        ;

};

module.exports = web_credit_updateOrCreateProduct;