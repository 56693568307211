import Q from 'q';
import moment from 'moment';
// 订单详情
import trains_platformOrder_getPlatformDetail from '@/lib/data-service/train/trains_platformOrder_getPlatformDetail';
// 更新保险状态
import trains_order_updateInsuranceStatus from '@/lib/data-service/train/trains_order_updateInsuranceStatus';
// 日志
import trains_OrderLog_getPlatformOrderLogList from '@/lib/data-service/train/trains_OrderLog_getPlatformOrderLogList';


export default {
  data () {
    return {
      loading: true,
      showType: 1,
      showLog: false,
      detail: {},
      allocation: {
        showType: [
          { label: 1, text: '订单详情'},
          { label: 2, text: '订单日志'},
        ]
      },
      insuranceVisble: false,
      insurance: {
        insuranceName: "",
        insuranceNo: "",
        insuranceStatus: "",
        insurancePrice: "",
        insuranceStatusText: ""
      },
      timer: null,

      logList: [],
      logForm: {
        orderNo: '',
        currentPage: 1,
        pageSize: 10
      },
      logPager: {
        pageSize: [10, 20, 30, 40, 50],
        total: 0,
      }
    }
  },
  methods: {
    req_detail (orderNo) {
      clearInterval(this.timer);
      this.loading = true;
      const _this = this;
      Q.when()
        .then(function () {
          return trains_platformOrder_getPlatformDetail({orderNo});
        })
        .then(function (res) {
          let isFirst = true;
          const detail = res;
          detail.detailResults.forEach(function (item) {
            item.forEach(function (params, index) {
              params.key = index;
              
              params.gmtStart = moment(params.gmtStart).format('YYYY-MM-DD HH:mm');
            })
            if (isFirst && detail.orderStatus === 4 && item[0].insuranceStatus !== 2) {
              isFirst = false;
              _this.req_update({orderNo: detail.orderNo, thirdOrderNo: detail.thirdOrderNo});
            }

            if (item[0].passengerType) {
              switch (item[0].passengerType) {
                case 1:
                  item[0].passengerTypeText = '成人';
                  break;
                case 2:
                  item[0].passengerTypeText = '儿童';
                break;
                case 3:
                  item[0].passengerTypeText = '学生票';
                  break;
                case 4:
                  item[0].passengerTypeText = '残军票';
                  break;
              }
            }

            if (item[0].passengerCardType) {
              switch (item[0].passengerCardType) {
                case '1':
                  item[0].passengerCardTypeText = '身份证';
                  break;
                case '2':
                  item[0].passengerCardTypeText = '儿童无证件';
                break;
                case '3':
                  item[0].passengerCardTypeText = '港澳通行证';
                  break;
                case '4':
                  item[0].passengerCardTypeText = '台湾通行证';
                  break;
                case '5':
                  item[0].passengerCardTypeText = '护照';
                  break;
              }
            }

            if (item[0].passengerCardNumber) {
              item[0].passengerCardNumberText = item[0].passengerCardNumber.substr(0, 3) + '***********' + item[0].passengerCardNumber.substr(-3, 3);
            }



          });
          _this.count_down(detail);
          _this.detail = detail;
          _this.loading = false;
          _this.timer = setInterval(function () {
            _this.count_down(_this.detail);
          }, 1000)
        })
    },
    req_update (params) {
      Q.when()
        .then(function () {
          return trains_order_updateInsuranceStatus(params);
        })
    },
    req_log_list (params) {
      this.loading = true;
      const _this = this;
      Q.when()
        .then(function () {
          return trains_OrderLog_getPlatformOrderLogList(params);
        })
        .then(function (res) {
          _this.logPager.total = res.pageResult.totalCount;
          _this.logList = res.pageResult.pageData;
          document.documentElement.scrollTop = 0;
          _this.loading = false;
        })
    },
    count_down (detail) {
      if (detail.orderStatus !== 2) return clearInterval(this.timer);
      const gmtLastPayment = detail.gmtLastPayment;
      const now = moment();
      const diff = moment(gmtLastPayment).diff(now, "seconds");
      if (diff > 0) {
        detail.countdownToPayment =
          Math.floor(diff / 60) + "分" + (diff % 60) + "秒";
      } else if (diff <= 0) {
        detail.countdownToPayment = "00分00秒";
        if (diff === 0) this.req_detail(detail.orderNo);
      }
    },
    // 保险
    popup_insurance(row) {
      const insurance = {};
      insurance.insuranceName = row.insuranceName;
      insurance.insuranceNo = row.insuranceNo;
      insurance.insuranceStatus = row.insuranceStatus;
      insurance.insurancePrice = row.insurancePrice;
      insurance.insuranceStatusText = row.insuranceStatusText;
      this.insurance = [insurance];
      this.insuranceVisble = true;
    },
    handleClose() {
      this.insuranceVisble = false;
      this.insurance = null;
    },
    change_log_size(pageSize) {
      this.logForm.pageSize = pageSize;
      this.logForm.currentPage = 1;
      this.req_log_list(this.logForm);
    },
    change_log_current(currentPage) {
      this.logForm.currentPage = currentPage;
      this.req_log_list(this.logForm);
    },
  },
  mounted () {

  },
  activated () {
    document.documentElement.scrollTop = 0;
    this.logForm.orderNo = this.$route.query.orderNo;
    this.req_detail(this.$route.query.orderNo);
  },
  deactivated () {
    clearInterval(this.timer);
  },
  watch: {
    showType (val) {
      if (val === 2) {
        if (!this.showLog) {
          this.req_log_list(this.logForm);
        }
        this.showLog = true;
      }
    }
  }
}
