// web_common_user_getDeveloper
const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口地址：

export default function web_common_user_getDeveloper(pParameter) {
  if (!pParameter) pParameter = {};
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/common/user/getDeveloper'
  params.data = {}
  params.process_response_data = false
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}
