

const __request = require(`./__request/__request_contentType_json`);

// 火车票改签订单列表接口地址：http://219.128.52.2:9900/doc.html#/trains/order-controller/platformChangeOrderListUsingPOST

export default function trains_order_platformChangeOrderList(pParameter) {
  if (!pParameter) pParameter = {};
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/trains/order/platformChangeOrderList'
  params.data = pParameter;
  params.process_response_data = false
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}
