import detail from "@/lib/data-service/hotel/hotel_manager_order_queryHotelPlatformOrderDetail";
import confirmCancelOrder from "@/lib/data-service/hotel/hotel_platFormOrder_confirmCancelOrder";
import _ from 'underscore'
import moment from 'moment'
import to_boolean from 'nllib/src/convert/to_boolean'
export default {
    name: "component_name",
    data() {
        return {
            supplierLabel: '',
            tableData: [],
            textarea: "",
            detail: null
        };
    },
    methods: {
        getDetail() {
            let orderNo = this.$route.query.orderNo;
            detail({orderNo}).then(res => {
                this.detail = res;
            });
        },
        _confirmCancelOrder() {
            let params = this.detail;
            let data = {orderNo: this.$route.query.orderNo};
            confirmCancelOrder(data).then(res => {
                console.log(res);
                this.$message({type: "success", message: "操作成功！"});
                this.getDetail();
            });
        }
    },
    created() {
        switch (String(this.$route.query.supplierId)) {
            case '1':
                this.supplierLabel = '捷旅结算价';
                break;
            case '2':
                this.supplierLabel = '美团结算价';
                break;
            case '3':
                this.supplierLabel = '艺龙结算价';
                break;
            case '4':
                this.supplierLabel = '千淘结算价';
                break;
            case '5':
                this.supplierLabel = '美团商旅结算价';
                break;
            case '7':
                this.supplierLabel = '要出发结算价';
                break;
        }
        this.getDetail();
    }
};
