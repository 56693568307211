import Q from 'q';
// 详情
import web_supplier_touristDetails from '@/lib/data-service/default/web_supplier_touristDetails'
// 审核
import web_supplier_auditProduct from '@/lib/data-service/default/web_supplier_auditProduct';
// 产品级别枚举
import web_common_product_getProductLevel from "@/lib/data-service/default/web_common_product_getProductLevel";

import FullCalendar from '../component/FullCalendar/audit/index.vue';

export default {
  data () {
    return {
      loading: false,
      detail: {},
      calendarData: {
				events: [],
        locale: '',
				selectStatus: 2,
				firstDay: 0,
				initialDayTime: 0,
				deleteGroupDate: [],
      },
      form: {
        productNo: '',
        productStatus: '',
        productLevel: '0',
        auditContent: '',
      },
      formRules: {
        auditContent:[
          {required: true, message: '请输入失败原因', trigger: 'blur'}
        ]
      },
      productLevelList:[],
      fallVisible: false,
    }
  },
  components: {
    FullCalendar,
  },
  methods: {
    req_tourDetail (params) {
      this.loading = true;
      const _this = this;
      Q.when()
        .then(function () {
          return web_supplier_touristDetails(params);
        })
        .then(function (res) {
          const detail = res.supplierViewDetailsResult
          detail.pictureList = detail.productPicture.split('$$');
          switch (detail.hotelLevel) {
            case 1:
              detail.hotelLevelText = "民宿";
              break;
            case 2:
              detail.hotelLevelText = "二星级及以下";
              break;
            case 3:
              detail.hotelLevelText = "准三星";
              break;
            case 4:
              detail.hotelLevelText = "三星级";
              break;
            case 5:
              detail.hotelLevelText = "准四星";
              break;
            case 6:
              detail.hotelLevelText = "四星级";
              break;
            case 7:
              detail.hotelLevelText = "准五星";
              break;
            case 8:
              detail.hotelLevelText = "五星";
              break;
            default:
              detail.hotelLevelText = "暂无星级";
              break;
          }
          _this.detail = detail;
          _this.calendarData.events = res.supplierViewDetailsResult.tPdTourismTeams;
          _this.loading = false;
        }).catch((err) => {
          _this.loading = false;
        })
    },
    req_audit (params) {
      const _this = this;
      Q.when()
        .then(function () {
          return web_supplier_auditProduct(params);
        })
        .then(function (res) {
          if (params.productStatus === 3) {
            _this.$message.success('设置审核通过成功')
          } else if (params.productStatus === 2) {
            _this.$message.success('设置审核不通过成功');
            _this.fallVisible = false;
          }
          _this.$router.push({
            name: 'admin-tourism-product-audit-managenment',
          })
        })
    },
    pass_audit () {
      this.form.productStatus = 3;
      this.req_audit(this.form);
    },
    fall_audit () {
      this.fallVisible = true;
    },
    handleCloseFall () {
      this.fallVisible = false;
    },
    commit_fall () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.productStatus = 2;
          this.req_audit(this.form);
        }
      })
    }
  },
  created () {
  },
  mounted () {},
  activated () {
    this.form.productNo = this.$route.query.productNo;
    this.req_tourDetail({productNo: this.$route.query.productNo});
    web_common_product_getProductLevel().then((res) => {
      this.productLevelList = res.list;
    })
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {
    
  },
}
