// web_common_credit_cutCreditBill 回款列表

const __request = require(`@/lib/data-service/default/__request/__request_contentType_json`);

// 接口文档地址： http://192.168.0.15:8080/doc.html#/credit/credit-account-controller/cutCreditBillUsingPOST
export default function web_common_credit_cutCreditBill(pParameter) {
  let params = {}
  if (!pParameter) pParameter = {};
  params.method = 'POST'
  params.urlSuffix = '/web/common/credit/cutCreditBill'
  params.data = pParameter
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
