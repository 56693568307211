// web_buyer_findAuditAll 采购商审核列表

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口文档地址： http://192.168.0.15:8080/doc.html#/所有接口/buyer-controller/findAuditAllUsingPOST
export default function web_buyer_findAuditAll(pParameter) {
  let params = {}
  if (!pParameter) pParameter = {};
  const userName = pParameter.userName || ''
  const companyName = pParameter.companyName || ''
  const regionId = pParameter.regionId || ''
  const provinceId = pParameter.provinceId || ''
  const cityId = pParameter.cityId || ''
  const buyerType = pParameter.buyerType || ''
  const buyerStatus = pParameter.buyerStatus || 0
  const currentPage = pParameter.currentPage || ''
  const pageSize = pParameter.pageSize || ''
  params.method = 'POST'
  params.urlSuffix = '/web/buyer/findAuditAll'
  params.data = {
    userName,
    companyName,
    regionId,
    buyerType,
    provinceId,
    cityId,
    buyerStatus,
    currentPage,
    pageSize
  }
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
