import moment from 'moment';
// /hotel/offlineOrder/listPage
// 线下酒店订单列表
import hotel_offlineOrder_listPage from "@/lib/data-service/hotel/hotel_offlineOrder_listPage.js";

export default {
  components: {},
  data() {
    return {
      moment: moment,
      form: {
        currentPage: 1,
        pageSize: 10,
        requestData: {
          state: 1, //状态，固定只查未被取消的
          orderNo: "",
          hotelName: '',
          linkmanName: '',
          linkmanPhone: '',
          checkIn: '',
          checkOut: '',
          sellOrderTime: '',
          sellCustomerName: '',
          generateDepartment: '',
          generatePersonName: '',
        }
      },
      pager: {
        pageSizes: [10, 15, 20, 25, 30],
        total: 0,
      },
      list: [],
      loading: false,
    };
  },
  methods: {
    req_list() {
      this.loading = true;
      const { state, currentPage, pageSize, requestData } = this.form;
      let params = { state, currentPage, pageSize, requestData: {} };

      for (const key in requestData) {
        if (requestData[key]) params.requestData[key] = requestData[key];
      }

      hotel_offlineOrder_listPage(params)
        .then((result) => {
          const datas = result.data;
          this.list = datas.list;
          this.pager.total = datas.total;
          this.loading = false;
        })
        .catch((err) => {
          this.list = [];
          this.pager.total = 0;
          this.loading = false;
        });
    },
    search() {
      this.change_currentPage(1);
    },
    reset() {
      const form = this.form.requestData;
      // const noreset = ['state', 'currentPage', 'pageSize'];
      const noreset = ['state'];
      for (const key in form) {
        // form[key] = '';
        if (!noreset.includes(key)) form[key] = '';
      }
      this.change_currentPage(1);
    },
    to_detail(orderNo) {
      this.$router.push({
        name: "admin-hotel-offline-order-detail",
        query: {
          orderNo,
          type: 'detail',
        }
      });
    },
    cancel(orderNo) {
      this.$router.push({
        name: "admin-hotel-offline-order-detail",
        query: {
          orderNo,
          type: 'cancel',
        }
      });
    },
    change_currentPage(currentPage) {
      this.form.currentPage = currentPage;
      this.req_list();
    },
    change_pageSize(pageSize) {
      this.form.pageSize = pageSize;
      this.change_currentPage(1);
    },
  },
  mounted() {},
  activated() {
    this.req_list();
  },
  deactivated() {},
  watch: {},
};
