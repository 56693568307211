import web_buyer_findOne from '@/lib/data-service/default/web_buyer_findOne'
import web_common_user_getDeveloper from '@/lib/data-service/default/web_common_user_getDeveloper'
import web_buyer_alter from '@/lib/data-service/default/web_buyer_alter'
import PreviewImage from '@/page/admin/supplier-management/component/previewImage/index.vue'
import addImgSuffix from '@/lib/common-service/add_img_suffix'
import FullImage from '@/page/admin/supplier-management/component/fullImage/index.vue'
import history_tag_api from '@/component/admin-layout/1.0.0/component/history-tag/api';
import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client";
import findPaymentChannel from '@/lib/data-service/default/web_buyer_flightBuyer_findPaymentChannel'
import web_buyer_flightBuyer_findWithHoldType from '@/lib/data-service/default/web_buyer_flightBuyer_findWithHoldType'
import web_buyer_flightBuyer_findCarryRule from '@/lib/data-service/default/web_buyer_flightBuyer_findCarryRule'

export default {
  data () {
    const validateAmount = (rule, value, callback) => {
      if (this.buyerParams.attachAmountType !== 0) {
        if (value === '') {
          callback(new Error('不能为空'))
        } else {
          if (value >= 0) {
            callback()
          } else  {
            callback(new Error('不能为负数'))
          }
        }
      } else {
        callback()
      }
    };
    const validateIncreaseDepositRate = (rule, value, callback) => {
      if (value >= 0) {
        callback()
      } else {
        callback(new Error('不能为负数'))
      }
    };
    return {
      buyer: {},
      loading: false,
      id: '',
      srcList: [],
      paymentChannelTypeList: [],
      developerList: [],
      buyerParams: {
        buyerId: '',
        travelAccountManagerId: '', // 旅游经理
        travelAccountManager: '',
        airAccountManagerId: '',
        airAccountManager: '',
        developerId: '',
        developer: '',
        carryRules: '',
        isOperationFlow: '',

        rebate: 0,//返点
        specialRebate: 0,//机票特殊政策返点
        paymentChannelTypeList: [1,2],//支付方式类型，1.余额支付，2.在线收单

        attachAmountType: '',
        attachAdultAmount: '',
        noattachChildAmount: '',
        attachChildAmount: '',
        attachAgedAmount: '',

        // travelDepositType: 0, // 订金 0-不收取 1比例 2定额
        finalCycle: '', // 尾款周期，天数
        increaseDepositRate: '', // 定金增加比
        isHasDeposit: 0, // 是否收订金 0-不收 1收 自增
        depositType: 1, // 收取方式 1-比例 2-定额 自增
        finalCycleType: 1, // 尾期类型 1-出团前

        agreementFile: '',

        carryRule: 1, // 说明：进位规则，机票专用(1.进位到元 2.进位到分)
        yeePayAccount: '',
        withHoldType: 1, // 说明：代扣关系 1：未配置 2：已配置
      },
      rule: {
        travelAccountManagerId: [
          {required: true, message: '旅游客户经理不能为空', trigger: 'change'}
        ],
        airAccountManagerId: [
          {required: true, message: '机票客户经理不能为空', trigger: 'change'}
        ],
        developerId: [
          {required: true, message: '开发者不能为空', trigger: 'change'}
        ],
        paymentChannelTypeList: [
          {required: true, message: '请选择机票支付方式', trigger: 'change'}
        ],
        attachAdultAmount: [
          {validator: validateAmount, trigger: 'blur'}
        ],
        noattachChildAmount: [
          {validator: validateAmount, trigger: 'blur'}
        ],
        attachChildAmount: [
          {validator: validateAmount, trigger: 'blur'}
        ],
        attachAgedAmount: [
          {validator: validateAmount, trigger: 'blur'}
        ],
        increaseDepositRate: [
          {required: true, message: '增幅不能为空', trigger: 'blur'},
          {validator: validateIncreaseDepositRate, trigger: 'blur'}
        ],
        finalCycle: [
          {required: true, message: '尾款周期天数不能为空', trigger: 'blur'},
          {validator: validateIncreaseDepositRate, trigger: 'blur'}
        ]
      },
      dialogFullPicVisible: false,
      fullPicSrc: '',

      withHoldTypeList: [],
      findCarryRuleList: [],
    }
  },
  components: {
    FullImage,
    PreviewImage
  },
  created () {
    this.id = this.$route.query.id
  },
  mounted () {
    //await this.getDeveloperList()
    // await this.getBuyerDetail(this.id)
    this.findPaymentChannel()
    this.findWithHoldType()
    this.findCarryRule()
  },
  async activated () {
    this.id = this.$route.query.id
    await this.getDeveloperList()
    await this.getBuyerDetail(this.id)
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {
    filterSupplierStatus(val) {
      if (val === '0') {
        return '待审核'
      } else if (val === '1') {
        return '审核失败'
      } else if (val === '2') {
        return '部分启用'
      } else if (val === '3') {
        return '启用'
      } else if (val === '4') {
        return '平台禁用'
      } else if (val === '5') {
        return '已删除'
      } else if (val === '6') {
        return '未签约'
      } else if (val === '7') {
        return '签约失败'
      } else if (val === '8') {
        return '待设置合同文件'
      } else {
        return ''
      }
    },
    filterAppType(val) {
      if (val === '1') {
        return '机票'
      } else if (val === '2') {
        return '旅游'
      } else {
        return '机票+旅游'
      }
    },
    filterCarryRules(val) {
      let type = ''
      if (val === 0) {
        type = '进位到元'
      } else {
        type = '进位到分'
      }
      return type
    },
    filterImg(val) {
      let result = addImgSuffix(val)
      return result
    }
  },
  methods: {
    // 获取代扣关系枚举
    findWithHoldType() {
      web_buyer_flightBuyer_findWithHoldType().then(res => {
        this.withHoldTypeList = res.resultList
      })
    },
    // 获取进位规则枚举
    findCarryRule() {
      web_buyer_flightBuyer_findCarryRule().then(res => {
        this.findCarryRuleList = res.enumList
      })
    },
    picLoading() {
      let _this = this
      let picSetTimeOut = ''
      picSetTimeOut = setTimeout(() => {
        let formPicArr = document.getElementsByClassName('form-pic')
        let formPicStatus = []
        for (let i in formPicArr.length) {
          formPicStatus.push(-1)
        }
        formPicArr.forEach((value, index) => {
          let complete = value.complete
          formPicStatus[index] = complete ? 1 : -1
        })
        if (formPicStatus.indexOf(-1) >= 0) {
          this.picLoading()
        } else {
          _this.loading = false
        }
      }, 500)
    },
    async getBuyerDetail(buyerId) {
      this.loading = true
      let res = await web_buyer_findOne({buyerId})
      this.buyer = res.result
      // this.businessLicensePic = this.buyer.businessLicensePic
      let developer = ''
      for (let i = 0, l = this.developerList.length; i < l; i++) {
        if (this.developerList[i].id === parseInt(this.buyer.developerId)) {
          developer = this.developerList[i].realName
          break
        }
      }
      let buyerParams = {
        buyerId: parseInt(this.id),
        travelAccountManagerId: this.buyer.trevelAccountManagerId === '' ? '' : parseInt(this.buyer.trevelAccountManagerId), // 旅游经理
        travelAccountManager: this.buyer.trevelAccountManager,
        airAccountManagerId: this.buyer.airAccountManagerId === '' ? '' : parseInt(this.buyer.airAccountManagerId),
        airAccountManager: this.buyer.airAccountManager,
        developerId: this.buyer.developerId === '' ? '' : parseInt(this.buyer.developerId),
        developer: developer,
        carryRules: this.buyer.carryRules,
        isOperationFlow: this.buyer.isOperationFlow,
        rebate: this.buyer.rebate,//返点
        specialRebate: this.buyer.specialRebate,//机票特殊政策返点
        paymentChannelTypeList: this.buyer.paymentChannelTypeList,//支付方式类型，1.余额支付，2.在线收单
        attachAmountType: this.buyer.travelDepositType === null ? 0 : this.buyer.travelDepositType,
        //attachAdultAmount: this.buyer.travelDepositType === 0 ? '' : (this.buyer.travelDepositType === 1 ? this.buyer.depositAdultPercent : this.buyer.depositAdultAmount),
        //attachAgedAmount: this.buyer.travelDepositType === 0 ? '' : (this.buyer.travelDepositType === 1 ? this.buyer.depositAgedPercent : this.buyer.depositAgedAmount),
        //noattachChildAmount: this.buyer.travelDepositType === 0 ? '' : (this.buyer.travelDepositType === 1 ? this.buyer.depositChildPercent : this.buyer.depositChildAmount),
        //attachChildAmount: this.buyer.travelDepositType === 0 ? '' : (this.buyer.travelDepositType === 1 ? this.buyer.depositChildBedPercent : this.buyer.depositChildBedAmount)
        travelDepositType: this.buyer.travelDepositType === null ? 0 : this.buyer.travelDepositType, // 订金 0-不收取 1比例 2定额
        finalCycle: this.buyer.finalCycle === null ? '' : this.buyer.finalCycle, // 尾款周期，天数
        increaseDepositRate: this.buyer.increaseDepositRate === null ? '' : this.buyer.increaseDepositRate, // 定金增加比
        isHasDeposit: 0, // 是否收订金 0-不收 1收 自增
        depositType: 1, // 收取方式 1-比例 2-定额 自增
        finalCycleType: 1, // 尾期类型 1-出团前

        agreementFile: this.buyer.agreementFile ? this.buyer.agreementFile : '',

        carryRule: this.buyer.carryRule ? this.buyer.carryRule : 1, // 说明：进位规则，机票专用(1.进位到元 2.进位到分)
        yeePayAccount: this.buyer.yeePayAccount || '',
        withHoldType: this.buyer.withHoldType ? this.buyer.withHoldType : 1, // 说明：代扣关系 1：未配置 2：已配置
      }
      if (buyerParams.travelDepositType === 0) {
        buyerParams.isHasDeposit = 0
        buyerParams.depositType = 1
      } else {
        buyerParams.isHasDeposit = 1
        buyerParams.depositType = buyerParams.travelDepositType
      }
      if (this.buyer.agreementFile) {
        let num = this.buyer.agreementFile.lastIndexOf('/')+1
        let name = this.buyer.agreementFile.substring(num)
        const file = {
          name,
          url: this.buyer.agreementFile
        }
        buyerParams.fileList = [file]
      }
      this.buyerParams = buyerParams
      this.$nextTick(() => {
        this.picLoading()
      })
    },
    // 获取开发者列表 - （机票经理）
    async getDeveloperList() {
      let res = await web_common_user_getDeveloper()
      this.developerList = res.getAllDeveloperResult
    },
    // 改变机票返点
    handleRebateChange(num) {
      const value = Number(num.replace(/[^\d|^\.|^\-]/gi, ""))
          ? Number(num.replace(/[^\d|^\.|^\-]/gi, ""))
          : 0
      if (value >= 100) {
        this.buyerParams.rebate = Number(100).toFixed(2)
      } else if (value <= -100) {
        this.buyerParams.rebate = Number(-100).toFixed(2)
      } else {
        this.buyerParams.rebate = value.toFixed(2)
      }
    },
    // 改变机票特殊政策返点
    handleSpecialRebateChange(num) {
      const value = Number(num.replace(/[^\d|^\.|^\-]/gi, ""))
          ? Number(num.replace(/[^\d|^\.|^\-]/gi, ""))
          : 0
      if (value >= 100) {
        this.buyerParams.specialRebate = Number(100).toFixed(2)
      } else if (value <= -100) {
        this.buyerParams.specialRebate = Number(-100).toFixed(2)
      } else {
        this.buyerParams.specialRebate = value.toFixed(2)
      }
    },
    //获取支付方式类型枚举
    async findPaymentChannel() {
      const res = await findPaymentChannel()
      this.paymentChannelTypeList = res.paymentChannelTypeList
    },
    // 改变机票支付方式
    handlePaymentChannelTypeListChange(value) {
      this.buyerParams.paymentChannelTypeList = value
    },
    showFullPic(val) {
      this.dialogFullPicVisible = true
      this.fullPicSrc = addImgSuffix(val)
    },
    // 选择开发者时
    changeDeveloper(value) {
      for (let i = 0, l = this.developerList.length; i < l; i++) {
        if (this.developerList[i].id === value) {
          this.buyerParams.developer = this.developerList[i].realName
          break
        }
      }
    },
    // 选择经理时
    changeAirAccountManager(value) {
      for (let i = 0, l = this.developerList.length; i < l; i++) {
        if (this.developerList[i].id === value) {
          this.buyerParams.airAccountManager = this.developerList[i].realName
          break
        }
      }
    },
    // 选择旅游经理时
    changeAccountManager(value) {
      for (let i = 0, l = this.developerList.length; i < l; i++) {
        if (this.developerList[i].id === value) {
          this.buyerParams.travelAccountManager = this.developerList[i].realName
          break
        }
      }
    },
    back() {
      history_tag_api.remove_tag_by_route({
        route: this.$route,
      });
      this.$router.push({
        name: `admin-buyer-list`
      });
    },
    submit() {
      let status
      let formDataList
      if (this.buyer.buyerType === '1') {
        status = [-1, -1]
        formDataList = ['form5', 'form7']
      } else if (this.buyer.buyerType === '2') {
        status = [-1, -1, -1]
        formDataList = ['form5', 'form6-1', 'form6-2']
      } else {
        status = [-1, -1, -1, -1]
        formDataList = ['form5', 'form6-1', 'form6-2', 'form7']
      }
      formDataList.map((value, index) => {
        this.$refs[value].validate((valid) => {
          valid ? status[index] = 1 : status[index] = -1
          if (status.indexOf(-1) === -1) {
            // 开始提交
            this.loading = true
            if (this.buyerParams.isHasDeposit === 0) {
              this.buyerParams.attachAmountType = 0
            } else {
              this.buyerParams.attachAmountType = this.buyerParams.depositType
            }
            web_buyer_alter(this.buyerParams).then(res => {
              this.loading = false
              this.$alert('修改成功', '温馨提示', {
                confirmButtonText: '确定',
                callback: action => {
                  // 跳转
                  this.back();
                }
              })
            }).catch(() => {
              this.loading = false
            })
          }
        })
      })
    },
    handleSuccesspictureB(file, fileList) {
      const size = file.size / 1024 / 1024;
      if (size > 10) {
        return this.$message.error("文件大小不能超过10M");
      }
      const _this = this;
      const aOssClient = new OssClient();
      aOssClient.multipartUpload({
        file: file.raw,
        progress: function* (p) {

        }
      })
          .then(function (data) {
            _this.$message.success("文件上传成功");
            _this.buyerParams.agreementFile = data.url;
            _this.$refs.uploadB.clearValidate();
          })
    },
    removePDFB() {
      this.buyerParams.agreementFile = "";
    },
    openPDFB(file) {
      window.open(this.buyerParams.agreementFile);
    },
    onExceed(files, fileList) {
      console.log('duo1', files, fileList)
      this.$message({
        type: "warning",
        message: '请先删除现有文件'
      })
    },
  }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
