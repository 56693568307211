import web_common_user_getDeveloper from '@/lib/data-service/default/web_common_user_getDeveloper'
import web_common_area_getAreaDataByAreaType from '@/lib/data-service/default/web_common_area_getAreaDataByAreaType'
import web_common_area_getInlandAreaChildListByParentId from '@/lib/data-service/default/web_common_area_getInlandAreaChildListByParentId'
import web_buyer_findAll from '@/lib/data-service/default/web_buyer_findAll'
import web_buyer_exportExcel from '@/lib/data-service/default/web_buyer_exportExcel'
import web_buyer_updateStatus from '@/lib/data-service/default/web_buyer_updateStatus'
import web_common_kickOutUser from '@/lib/data-service/default/web_common_kickOutUser'
import web_buyer_buyerDelete from '@/lib/data-service/default/web_buyer_buyerDelete'
import web_btoc_apply_status from '@/lib/data-service/default/web_btoc_apply_status'
import web_buyer_air_status from '@/lib/data-service/default/web_buyer_air_status'
import Pagination from '@/component/base-pagination/index.vue'
import moment from 'moment'
export default {
  data() {
    return {
      loading: false,
      params: {
        period: [],

        currentPage: 1,
        pageSize: 10,
        userName: '',
        companyName: '',
        cityId: '',
        regionId: '',
        provinceId: '',
        buyerType: '',
        startTime: '',
        endTime: '',
        userStatus: '',
        realStatus: '',
        phone: '',
        accountManagerId: '',
        developerId: '',
        officeId: '',
        orderByLastLoginTime: '', // 最后登录时间排序填值: asc:升序,desc:降序,不需要为空即可
        orderByGmtCreate: '', // 创建时间排序填值: asc:升序,desc:降序,不需要为空即可
        total: 0,
        buyerSource: '',
      },
      userTypeList: [{ value: '', label: '全部' }, { value: 1, label: '机票' }, { value: 2, label: '旅游' }, { value: '1+2', label: '机票+旅游' }],
      developerList: [],
      provinceList: [],
      cityList: [],
      supplierStatusList: [{ value: '', label: '全部' }, { value: 2, label: '部分启用' }, { value: 3, label: '启用' }, { value: 4, label: '平台禁用' }],
      realStatusList: [{ value: '', label: '全部' }, { value: 0, label: '未实名' }, { value: 1, label: '已实名' }],
      showMore: false,
      userList: [],
      fileUrl: '',
      buyerSourceList: [{ value: '', label: '全部' }, { value: 1, label: '普通注册' }, { value: 2, label: '客服添加' }, { value: 3, label: '平台添加' }, { value: 4, label: '扫码注册' }],
      userStatusList: [],
      moment: moment,
      showNum: 0,
      onlyCheckDetail: false
    }
  },
  components: {
    Pagination
  },
  created() { },
  mounted() { },
  activated() {
    let onlyCheckDetail = this.$route.meta.onlyCheckDetail
    if (onlyCheckDetail) {
      this.onlyCheckDetail = onlyCheckDetail
    }
    this.getDeveloperList()
    this.getAreaDataByAreaType()
    this.getBuyerList()
  },
  deactivated() { },
  destroyed() {
  },
  watch: {},
  computed: {},
  filters: {
    filterTravelDepositType(val) {
      if (val === 0 || val === null) {
        return '全额'
      } else {
        return '订金'
      }
    },
    filterTravelDepositTypeFun(val) {
      if (val === 0) {
        return ''
      } else if (val === 1) {
        return '比例'
      } else {
        return '定额'
      }
    }
  },
  methods: {
    handleFilter() {
      this.onSearch()
    },
    showOrHideForm() {
      const showMore = this.showMore
      this.showMore = !showMore
    },
    changeProvince(val) {
      this.params.cityId = ''
      this.cityList = []
      if (val !== '') {
        this.getCity(val)
      }
    },
    changeParamsTime(val) {
      if (val === '' || val === null) {
        this.params.startTime = ''
        this.params.endTime = ''
      } else {
        this.params.startTime = val[0]
        this.params.endTime = val[1]
      }
    },
    onSearch() {
      this.params.currentPage = 1
      this.getBuyerList()
    },
    onReset() {
      this.$refs['userTable'].clearSort()
      this.params = {
        period: [],
        currentPage: 1,
        pageSize: 10,
        userName: '',
        companyName: '',
        cityId: '',
        regionId: '',
        provinceId: '',
        buyerType: '',
        startTime: '',
        endTime: '',
        userStatus: '',
        phone: '',
        accountManagerId: '',
        developerId: '',
        officeId: '',
        orderByLastLoginTime: '', // 最后登录时间排序填值: asc:升序,desc:降序,不需要为空即可
        orderByGmtCreate: '', // 创建时间排序填值: asc:升序,desc:降序,不需要为空即可
        total: 0
      }
      this.onSearch()
    },
    getBuyerList() {
      this.loading = true
      web_buyer_findAll(this.params).then((res) => {
        let userList = res.pageResult.pageData
        userList.forEach((value) => {
          if (value.lastLoginTime === null) {
            value.lastLoginTime = ''
          }
        })
        this.userList = userList
        this.params.total = res.pageResult.totalCount
        this.loading = false
      }).catch(() => {
        this.loading = false
      }, new Error(() => {
        this.loading = false
      }))
    },
    // 获取开发者列表 - （机票经理）
    getDeveloperList() {
      web_common_user_getDeveloper().then((res) => {
        this.developerList = res.getAllDeveloperResult
      })
    },
    // 获取省份
    getAreaDataByAreaType(areaType = 1) {
      web_common_area_getAreaDataByAreaType({ areaType }).then(res => {
        this.provinceList = res
      })
    },
    // 获取城市
    getCity(id) {
      web_common_area_getInlandAreaChildListByParentId({ id }).then(res => {
        this.cityList = res
      })
    },
    handleCreate() {
      this.$router.push({
        name: 'admin-buyer-add'
      })
    },
    uploadExcel(val) {
      let params
      if (val === 'current') {
        params = this.params
      } else {
        params = ''
      }
      web_buyer_exportExcel(params).then(res => {
        if (res.url) {
          // window.open(res.url)
          this.fileUrl = res.url
          this.$nextTick(() => {
            this.$refs.downloadBtn.click()
          })
        }
      })
    },
    carryRulesFormatter(val) {
      let type = ''
      if (val.carryRules === 0) {
        type = '进位到元'
      } else {
        type = '进位到分'
      }
      return type
    },
    travelDepositTypeFormatter(val) {
      let type = ''
      if (val.travelDepositType === 0) {
        type = '无订金'
      } else if (val.travelDepositType === 1) {
        type = '百分比'
      } else {
        type = '固定金额'
      }
      return type
    },
    buyerStatusFormatter(val) {
      let type = ''
      if (val.buyerStatus === 0) {
        type = '待审核'
      } else if (val.buyerStatus === 1) {
        type = '审核失败'
      } else if (val.buyerStatus === 2) {
        type = '部分启用'
      } else if (val.buyerStatus === 3) {
        type = '启用'
      } else if (val.buyerStatus === 4) {
        type = '平台禁用'
      } else if (val.buyerStatus === 5) {
        type = '已删除'
      } else if (val.buyerStatus === 6) {
        type = '未签约'
      } else if (val.buyerStatus === 7) {
        type = '签约失败'
      } else if (val.buyerStatus === 8) {
        type = '待设置合同文件'
      } else {
        type = '平台禁用'
      }
      return type
    },
    realStatusFormatter(val) {
      let type = ''
      if (val.realStatus === 0) {
        type = '未实名'
      } else if (val.realStatus === 1) {
        type = '已实名'
      } else {
        type = ''
      }
      return type
    },
    handleCheck(val) {
      this.$router.push({
        name: 'admin-buyer-detail',
        query: {
          id: val.buyerId
        }
      })
    },
    handleSetting(val) {
      this.$router.push({
        name: 'admin-buyer-set',
        query: {
          id: val.buyerId
        }
      })
    },
    handleStartUserStatus(index, val) {
      this.$confirm('分销商将会被启用，是否继续？', '提示', {
        type: 'warning'
      }).then(() => {
        this.updateStatus(val.buyerId, 3).then((res) => {
          this.userList[index].buyerStatus = 3
        }).catch(() => {

        })
      }).catch(() => {
      })
    },
    async updateStatus(buyerId, buyerStatus) {
      return await web_buyer_updateStatus({ buyerId, buyerStatus })
    },
    handleStopUserStatus(index, val) {
      this.$confirm('分销商将会被停用，是否继续？', '提示', {
        type: 'warning'
      }).then(() => {
        this.updateStatus(val.buyerId, 4).then((res) => {
          // this.stuList.splice(index, 1)
          this.userList[index].buyerStatus = 4
        }).catch(() => {

        })
      }).catch(() => {
      })
    },
    handleLogOut(index, val) {
      this.$confirm('确定强制退出吗?', '提示', {
        type: 'warning'
      }).then(() => {
        web_common_kickOutUser({ userId: val.userId }).then(res => {
          if (res.code === '000000') {
            this.$message({
              message: res.msg,
              type: 'success'
            })
          } else {
            this.$message({
              message: res.msg,
              type: 'warning'
            })
          }
        })
      }).catch(() => {
      })
    },
    handleDel(index, val) {
      this.$confirm('分销商将会被删除，是否继续？', '提示', {
        type: 'warning'
      }).then(() => {
        web_buyer_buyerDelete({ buyerId: val.buyerId }).then((res) => {
          // this.stuList.splice(index, 1)
          this.getBuyerList()
        }).catch(() => {

        })
      }).catch(() => {
      })
    },
    changeSort({ column, prop, order }) {
      // orderByLastLoginTime: '', // 最后登录时间排序填值: asc:升序,desc:降序,不需要为空即可
      // orderByGmtCreate: '', // 创建时间排序填值: asc:升序,desc:降序,不需要为空即可
      if (order === null) {
        this.params.orderByLastLoginTime = ''
        this.params.orderByGmtCreate = ''
      } else if (prop === 'registerTime') {
        this.params.orderByLastLoginTime = ''
        this.params.orderByGmtCreate = order === 'ascending' ? 'asc' : 'desc'
      } else {
        this.params.orderByGmtCreate = ''
        this.params.orderByLastLoginTime = order === 'ascending' ? 'asc' : 'desc'
      }
      this.onSearch()
      // this.getBuyerList()
    },
    onMallSetting(row) {
      this.$router.push({
        path: '/admin/buyer/mall-setting',
        query: { id: row.buyerId },
      })
    },
    // 开通商城
    handleOpenMall(row) {
      web_btoc_apply_status({ buyerId: row.buyerId }).then(data => {
        const haveTouristBusinessLicense = data.haveTouristBusinessLicense
        if (haveTouristBusinessLicense) {
          this.$router.push({
            path: '/admin/buyer/opening-mall-yes',
            query: { id: row.buyerId, userName: row.userName },
          })
        } else {
          this.$router.push({
            path: '/admin/buyer/opening-mall-no',
            query: {
              id: row.buyerId,
              companyName: row.companyName,
              userName: row.userName
            },
          })
        }
      })
    },
    // b2b 线路设置
    onMallRouteSetting(row) {
      this.$router.push({
        path: '/admin/buyer/mall-route-setting-list',
        query: { id: row.buyerId },
      })
    },
    handleOpenAirStatus(index, row) {
      this.$confirm('开通分销商机票所有权限，是否继续？', '提示', {
        type: 'warning'
      }).then(() => {
        web_buyer_air_status({ buyerId: row.buyerId }).then(res => {
          if (res.code === '000000') {
            this.$message({
              message: res.msg,
              type: 'success'
            })
            this.userList[index].airStatus = 3
          } else {
            this.$message({
              message: res.msg,
              type: 'warning'
            })
          }
        })
      }).catch(() => {
      })
    },
  }
}
