import { Loading } from 'element-ui';

// 线下酒店订单详情
import hotel_offlineOrder_getOne from "@/lib/data-service/hotel/hotel_offlineOrder_getOne.js";
// 取消线下酒店订单
import hotel_offlineOrder_cancelOne from "@/lib/data-service/hotel/hotel_offlineOrder_cancelOne.js";
export default {
  components: {},
  data() {
    return {
      loading: false,
      loadingInstance: null,
      type: "",
      detail: {},
      form: {
        orderNo: "",
        refundPurchasePrice: "", //退订信息-采购退款金额
        refundSellPrice: "", //退订信息-销售退款金额
        refundPurchaseSerialNumber: "", //退订信息-采购退款流水号
        refundSellSerialNumber: "", //退订信息-销售退款流水号
      },
      rules: {
        refundPurchasePrice: [
          { required: true, message: "请输入采购退款金额", trigger: "blur" },
        ],
        refundSellPrice: [
          { required: true, message: "请输入销售退款金额", trigger: "blur" },
        ],
        refundPurchaseSerialNumber: [
          {
            required: true,
            message: "请输入采购退款金额流水号",
            trigger: "blur",
          },
        ],
        refundSellSerialNumber: [
          {
            required: true,
            message: "请输入销售退款金额流水号",
            trigger: "blur",
          },
        ],
      },
    };
  },
  filters: {
    format_payType(val) {
      switch (val) {
        case 0:
          return "未知";
          break;
        case 1:
          return "预付款";
          break;
        case 2:
          return "现结";
          break;
        case 3:
          return "周结";
          break;
        case 4:
          return "月结";
          break;
      }
    },
    format_purchaseInvoiceType(val) {
      switch (val) {
        case 0:
          return "未知";
          break;
        case 1:
          return "胤之旅";
          break;
        case 2:
          return "供应商";
          break;
        case 3:
          return "该酒店";
          break;
      }
    },
  },
  methods: {
    req_detail() {
      this.loading = true;
      hotel_offlineOrder_getOne({ orderNo: this.form.orderNo })
        .then((result) => {
          this.detail = result.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    init() {
      const { type, orderNo } = this.$route.query;
      this.type = type;
      this.form.orderNo = orderNo;
      this.req_detail();
    },
    commit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loadingInstance = Loading.service({text: '正在请求取消订单...'});
          hotel_offlineOrder_cancelOne(this.form)
            .then((result) => {
              this.loadingInstance.close();
              this.$message.success("取消成功");
              this.back();
            })
            .catch((err) => {
              this.loadingInstance.close();
            });
        } else {
          return;
        }
      });
    },
    back() {
      this.$router.back();
    },
  },
  mounted() {},
  activated() {
    this.init();
  },
  deactivated() {
    this.loadingInstance.close();
  },
  watch: {},
};
