// web_settleAccounts_settleOrder 订单结算

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口地址：http://192.168.0.15:8080/doc.html#/所有接口/settle-accounts-controller/getSettlementProcessingListUsingPOST

export default function web_settleAccounts_settleOrder(pParameter) {
  if (!pParameter) pParameter = {};
  const settleIds = pParameter.settleIds || ''
  const settleWayVal = pParameter.settleWayVal || ''
  const settleVoucher = pParameter.settleVoucher || ''
  const bankNumber = pParameter.bankNumber || ''

  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/settleAccounts/settleOrder'
  params.data = {
    settleIds,
    settleWayVal,
    settleVoucher,
    bankNumber
  }
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}

