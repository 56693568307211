import Q from 'q';
import moment from 'moment';
// 回款列表
import web_common_credit_countermandOdList from '@/lib/data-service/default/web_common_credit_countermandOdList.js';
// 确认回款金额
import web_common_credit_creditAmountBackSet from '@/lib/data-service/default/web_common_credit_creditAmountBackSet.js';
// 上传文件
import OssClient from 'yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client';

export default {
  data () {
    return {
      loading: false,
      show: false,
      form: {
        currentPage: 1,
        pageSize: 10,
        orderNo: '',
        productNo: '',
        productName: '',
        departtureDateStart: '',
        departtureDateEnd: '',
        productAreasType: '',
        chopStatus: '',
      },
      departDate: null,
      returnedForm: {
        // 订单id
        orderId: '',
        // 订单号
        orderNo: '',
        // 结算表id
        chargesId: '',
        // 公司id
        companyId: '',
        // 金额
        actualBackAmount: '',
        // 流水号
        backFlowNo: '',
        // 凭证
        voucherBack: '',
      },
      returnedRules: {
        actualBackAmount: [
          { required: true, message: '请输入实际回款金额', trigger: 'blur' }
        ],
        backFlowNo: [
          { required: true, message: '请输入回款流水号', trigger: 'blur' }
        ],
        voucherBack: [
          { required: true, message: '请输入上传回款凭证', trigger: 'blur' }
        ]
      },
      allocation: {
        productAreasType: [
          { label: '全部', value: '' },
          { label: '国内', value: 1 },
          { label: '出境', value: 2 },
        ],
        chopStatus: [
          { label: '全部', value: ''},
          { label: '未回款', value: 0},
          { label: '已回款', value: 1},
        ],
        dialogVisible: false,
      },
      pager: {
        pageSizes: [10, 20, 30, 40, 50],
        total: 0,
      },
      list: [],
    }
  },
  methods: {
    req_list (params, type) {
      this.loading = true;
      const _this = this;
      Q.when()
        .then(function () {
          return web_common_credit_countermandOdList(params);
        })
        .then(function (res) {
          const list = res.pageResult.pageData;
          list.forEach(item => {
            if (item.departureDate) item.departureDate = moment(item.departureDate).format('YYYY-MM-DD');
            if (item.settleDate) item.settleDate = moment(item.settleDate).format('YYYY-MM-DD');
          })
          _this.list = list;
          _this.loading = false;
          _this.pager.total = res.pageResult.totalCount;
          if (type) document.documentElement.scrollTop = 0;
        })
    },
    req_set_amount (params) {
      const _this = this;
      Q.when()
        .then(function () {
          return web_common_credit_creditAmountBackSet(params);
        })
        .then(function (res) {
          _this.handleClose();
          _this.$message.success('设置成功');
          _this.req_list(_this.form);
        })
    },
    fold_change () {
      this.show = !this.show;
    },
    search () {
      this.form.currentPage = 1;
      this.req_list(this.form);
    },
    reset () {
      const pageSize = this.form.pageSize;
      this.departDate = null;
      this.form = {
        currentPage: 1,
        pageSize,
        orderNo: '',
        productNo: '',
        productName: '',
        departtureDateStart: '',
        departtureDateEnd: '',
        productAreasType: '',
        chopStatus: '',
      }
      this.req_list(this.form);
    },
    returned_affirm (row) {
      const form = this.returnedForm;
      form.orderId = row.orderId;
      form.orderNo = row.orderNo;
      form.chargesId = row.chargesId;
      form.companyId = row.companyId;
      this.allocation.dialogVisible = true;
    },
    handleClose () {
      this.allocation.dialogVisible = false;
      this.returnedForm = {
        orderId: '',
        orderNo: '',
        chargesId: '',
        companyId: '',
        actualBackAmount: '',
        serialNo: '',
        backFlowNo: '',
        voucherBack: '',
      };
      this.$refs.form.resetFields();
			this.$refs.upload.clearFiles();
    },
    inputAmount (val) {
      const reg = /[\D|\-]/g;
      this.returnedForm.actualBackAmount = val.replace(reg, '');
    },
    handleSuccesspicture(file, fileList) {
      const size = file.size / 1024 / 1024;
      if (size > 5) {
        return this.$message.error("文件大小不能超过5M");
      }
      const _this = this;
      const aOssClient = new OssClient();
      aOssClient.multipartUpload({
        file: file.raw,
        progress: function* (p) {

        }
      })
      .then(function (data) {
        _this.$message.success("文件上传成功");
        _this.$refs.formFile.clearValidate();
        _this.returnedForm.voucherBack = data.url;
      })
    },
    removePDF() {
      this.returnedForm.voucherBack = "";
    },
    openPDF(file) {
      window.open(this.returnedForm.voucherBack);
    },
    commit () {
      const _this = this;
      this.$refs.form.validate((valid) => {
        if (valid) {
          _this.req_set_amount(_this.returnedForm);
        } else {
          return false;
        }
      })
    },
    size_change (pageSize) {
      this.form.pageSize = pageSize;
      this.form.currentPage = 1;
      this.req_list(this.form, 'pageSize');
    },
    current_change (currentPage) {
      this.form.currentPage = currentPage;
      this.req_list(this.form, 'currentPage');
    },
    to_detail (id) {
      this.$router.push({
        name: 'admin-plain-order-detail',
        query: {
          id,
        }
      })
    },
  },
  mounted () {

  },
  activated () {
    this.req_list(this.form);
  },
  watch: {
    departDate (val) {
      if (val) {
        this.form.departtureDateStart = val[0];
        this.form.departtureDateEnd = val[1];
      } else {
        this.form.departtureDateStart = '';
        this.form.departtureDateEnd = '';
      }
    }
  }
}
