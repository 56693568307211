import import_latest_version_module from '@/component/admin-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
      {
        path: '/admin/daily-report',
        name: 'admin-daily-report',
        component: () => import(/* webpackChunkName: "page-admin-statistical-report-daily-report" */ `./index.vue`),
        meta: {
          data_for_admin_layout: {
                    data_for_history_tag: {
                              entity: {
                                        name: "数据统计"
                              }
                    },
                    data_for_breadcrumb: {
                              entity_list: [
                                        {
                                                  name: "数据统计"
                                        },
                                        {
                                                  name: "数据统计"
                                        }
                              ]
                    }
          }
},
      },
    ],
  };
  