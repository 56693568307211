// web_managerSupplier_auditDetails 查询供应商审核详情

const __request = require(`@/lib/data-service/default/__request/__request_contentType_json`);

// 接口文档地址： http://192.168.0.19:9999/doc.html#/yinzhilv_travel/manager-supplier-controller/findSupplierAuditDetailsUsingPOST

export default function web_managerSupplier_auditDetails(pParameter) {
    let params = {}
    if (!pParameter) pParameter = {};
    params.method = 'POST'
    params.urlSuffix = '/web/managerSupplier/auditDetails'
    params.data = pParameter
    return new Promise(function(resolve, reject) {
        resolve(__request(params))
    })
}

