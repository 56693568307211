// web_common_credit_cutCreditBillDetails 回款详情

const __request = require(`@/lib/data-service/default/__request/__request_contentType_json`);

// 接口文档地址： http://192.168.0.15:8080/doc.html#/credit/credit-account-controller/countermandOdListUsingPOST
export default function web_common_credit_cutCreditBillDetails(pParameter) {
  let params = {}
  if (!pParameter) pParameter = {};
  params.method = 'POST'
  params.urlSuffix = '/web/common/credit/cutCreditBillDetails'
  params.data = pParameter
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
