// web_data_dataOperation 添加或更新数据维度

const __request = require(`@/lib/data-service/default/__request/__request_contentType_json`);

// 接口文档地址： http://192.168.0.15:8080/doc.html#/yinzhilv_travel/dimension-controller/setDimensionUsingPOST
export default function web_data_dataOperation(pParameter) {
    let params = {}
    if (!pParameter) pParameter = {};
    params.method = 'POST'
    params.urlSuffix = '/web/data/dataOperation'
    params.data = pParameter
    return new Promise(function(resolve, reject) {
        resolve(__request(params))
    })
}