import Q from 'q';

// 待审核产品列表
import web_common_platfromOrder from '@/lib/data-service/default/web_common_platfromOrder';



export default {
  components: {
    
  },
  data() {
    return {
      form: {
        productName: '',
        productAmount: '',
      },
      formRules: {
        productName: [
          { required: true, message: '请输入产品名称', trigger: 'blur' }
        ],
        productAmount: [
          { required: true, message: '请输入金额', trigger: 'blur' }
        ]
      },
    };
  },
  methods: {
    req_place_an_order (params) {
      const _this = this;
      return Q.when()
        .then(function () {
          return web_common_platfromOrder(params);
        })
        .then(function (res) {
          console.log(res)
          const { href } = _this.$router.resolve({
            name: 'admin-payment-order-amount',
            query: {htmlStr: res.htmlStr},
          })
          window.open(href, '_blank');
        })
    },
    commit () {
      const _this = this;
      this.$refs.form.validate((valid) => {
        if (valid) {
          _this.req_place_an_order(_this.form);
        } else {
          return false;
        }
      })
    }
  },
  created() {},
  mounted() {
    
  },
  activated() {},
  deactivated() {},
  destroyed() {},
  watch: {
    
  },
  computed: {}
};
