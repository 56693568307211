const Q = require('q');
const __request = require(`./__request/__request_contentType_json`);

const web_credit_orderBillList = function (pParameter) {
    if (!pParameter) pParameter = {};

    // 旅游授信订单分页列表 ：http://219.128.52.2:9999/doc.html#/yinzhilv_travel/credit-account-controller/orderBillListUsingPOST

    const orderNo = pParameter.orderNo;
    const accountName = pParameter.accountName;
    const companyName = pParameter.companyName;
    const refundStatus = pParameter.refundStatus;
    const refundDateBegin = pParameter.refundDateBegin; // 还款开始时间
    const refundDateEnd = pParameter.refundDateEnd; // 还款结束时间
    const isOverdue = pParameter.isOverdue;
    const currentPage = pParameter.currentPage;
    const pageSize = pParameter.pageSize;

    return Q.when()
        .then(function () {
            return __request({
                method: 'POST',
                urlSuffix: '/web/credit/orderBillList',
                data: {
                    orderNo: orderNo,
                    accountName: accountName,
                    companyName: companyName,
                    refundStatus: refundStatus,
                    refundDateBegin: refundDateBegin,
                    refundDateEnd: refundDateEnd,
                    isOverdue: isOverdue,
                    currentPage: currentPage,
                    pageSize: pageSize
                },
            });
        })
        .catch(function (ex) {
            console.error(ex);
            if (ex) {
                if (ex.stack) {
                    console.error(ex.stack);
                }
            }
            return Q.reject(ex);
        });

};

module.exports = web_credit_orderBillList;