// web_staffManage_list 分页查询平台员工列表

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口地址：http://192.168.0.15:8080/doc.html#/所有接口/staff-management-controller/getStaffListUsingPOST

export default function web_staffManage_list(pParameter) {
  if (!pParameter) pParameter = {};
  const currentPage = pParameter.currentPage || 1
  const pageSize = pParameter.pageSize || 10
  const id = pParameter.id || ''
  const userName = pParameter.userName || ''
  const realName = pParameter.realName || ''
  const userStatus = pParameter.userStatus || ''
  const userType = pParameter.userType || ''
  const userTypeTwo = pParameter.userTypeTwo || ''

  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/staffManage/list'
  params.data = {
    currentPage,
    pageSize,
    userName,
    realName,
    id,
    userStatus,
    userType,
    userTypeTwo,
  }
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}
