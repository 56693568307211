const Q = require('q');
const __request = require(`./__request/__request_contentType_json`);

const web_credit_dealStatistic = function (pParameter) {
    if (!pParameter) pParameter = {};
    // 查询交易情况 ：http://219.128.52.2:9999/doc.html#/yinzhilv_travel/credit-account-controller/dealStatisticUsingPOST
    const companyId = pParameter.companyId;

    return Q.when().then(function () {
        return __request({
            method: 'POST',
            urlSuffix: '/web/credit/dealStatistic',
            data: {
                companyId: companyId
            },
        });
    }).catch(function (ex) {
        console.error(ex);
        if (ex) {
            if (ex.stack) {
                console.error(ex.stack);
            }
        }
        return Q.reject(ex);
    });
};

module.exports = web_credit_dealStatistic;