const __request = require(`./__request/__request_contentType_json`);

// http://219.128.52.2:8765/doc.html#/btoc-biz-service/BTOC%20-%20%E7%BA%BF%E8%B7%AF/saveOrUpdateUsingPOST
const biz_line_select = (pParameter) => {
  if (!pParameter) pParameter = {};

  const id = pParameter.id // 修改时要传
  const attachAdultAmount = pParameter.attachAdultAmount
  const attachAgedAmount = pParameter.attachAgedAmount
  const attachAmountType = pParameter.attachAmountType
  const attachChildAmount = pParameter.attachChildAmount
  const attachChildBedAmount = pParameter.attachChildBedAmount
  const attachType = pParameter.attachType
  const buyerId = pParameter.buyerId
  const finalPaymentDays = pParameter.finalPaymentDays
  const lineId = pParameter.lineId
  const lineName = pParameter.lineName
  const pressAmount = pParameter.pressAmount
  const pressAmountType = pParameter.pressAmountType
  const presslPaymentCycle = pParameter.presslPaymentCycle
  const serviceFeePercent = pParameter.serviceFeePercent
  const serviceFeeType = pParameter.serviceFeeType
  const settleDeposit = pParameter.settleDeposit
  const settleDepositType = pParameter.settleDepositType
  const attachAdultPercent = pParameter.attachAdultPercent
  const attachChildBedPercent = pParameter.attachChildBedPercent
  const attachChildPercent = pParameter.attachChildPercent
  const attachAgedPercent = pParameter.attachAgedPercent

  const p = {
    method: 'post',
    urlSuffix: '/biz/line/saveOrUpdate',
    data: {
      id,
      attachAdultAmount,
      attachAgedAmount,
      attachAmountType,
      attachChildAmount,
      attachChildBedAmount,
      attachType,
      attachAdultPercent,
      attachChildBedPercent,
      attachChildPercent,
      attachAgedPercent,
      buyerId,
      finalPaymentDays,
      lineId,
      lineName,
      pressAmount,
      pressAmountType,
      presslCycleUnit: 1,
      presslPaymentCycle,
      serviceFeePercent,
      serviceFeeType,
      settleDeposit,
      settleDepositType,

    }
  };
  return new Promise(function (resolve, reject) {
    resolve(__request(p))
  }).then(data => {
    return data
  })
};
module.exports = biz_line_select;