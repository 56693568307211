import Q from 'q';

import moment from 'moment';

// 平台订单详情
import web_salesOrder_tourOrderDetails from '@/lib/data-service/default/web_salesOrder_tourOrderDetails';
// 修改审核状态
import web_salesOrder_orderState from '@/lib/data-service/default/web_salesOrder_orderState';
import web_tourismFullReduction_selectByOrderNo from '@/lib/data-service/default/web_tourismFullReduction_selectByOrderNo';


import OssClient from 'yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client';

import format from 'element-ui/src/locale/format';


export default {
  data() {
    const opinion = (rule, value, callback) => {
      const status = this.form.supplyAuditStatus;
      if (status === 2) {
        if (!this.form.supplierAuditContents) {
          callback(new Error('请输入审核意见'));
        }
         callback();
      } else {
        callback();
      }
    };
    return {
      detail: {},
      loading: false,
      state: 1,
      stateList: [
        {text: '待确认', key: 1},
        {text: '待支付', key: 2},
        {text: '已支付', key: 3},
        {text: '确认出团', key: 4},
        {text: '已出团', key: 5},
        {text: '已回团', key: 6},
        {text: '已分账', key: 7},
      ],
      dialogVisible: false,
      form: {
        orderId: '',
        supplierAuditContents: '',
        supplyAuditStatus: '',
        platformAffirmOrderUrl: '',
        platformBuyerAffirm: '',
      },
      formRules: {
        supplierAuditContents: [
          { validator: opinion, trigger: 'blur', required: true }
        ],
      },
      fileList: [],
      reductionGradient: [] // 优惠说明
    };
  },
  methods: {
    getSelectByOrderNo(row) { // 获取优惠说明
      web_tourismFullReduction_selectByOrderNo({orderNo: row.orderNo}).then((res) => {
        if (res.code === '000000') {
          res.tourismFullReductionBuyerResult.rule = res.tourismFullReductionBuyerResult.rule.split("\n");
          this.reductionGradient = res.tourismFullReductionBuyerResult;
        }
      });
    },


    req_detail (params) {
      this.loading = true;
      const _this = this;
      Q.when()
        .then(function () {
          return web_salesOrder_tourOrderDetails(params);
        })
        .then(function (res) {
          const detail = res.result;
          if (detail.departureDate) detail.departureDate = moment(detail.departureDate).format('YYYY-MM-DD');
          if (detail.returnDate) detail.returnDate = moment(detail.returnDate).format('YYYY-MM-DD');
          const reg = /\$\$/g;
          detail.fromCityText = detail.setOutCityName.replace(reg, ',');
          detail.toCityText = detail.aimCityName.replace(reg, ',');

          if (detail.supplierAffirm) {
            if (detail.supplierAffirm.indexOf('attname') !== -1) {
              detail.pdf = detail.supplierAffirm.split('?');
              detail.pdfUrl = detail.pdf[0];
              detail.pdfName = decodeURI(detail.pdf[1].replace('attname=',''));
            } else {
              const index = detail.supplierAffirm.lastIndexOf('/');
              const length = detail.supplierAffirm.length;
              detail.pdfUrl = detail.supplierAffirm;
              detail.pdfName = detail.supplierAffirm.slice(index + 1, length);
            }
          }

          switch (detail.orderState) {
            case 0:
              detail.orderStateText = '审核失败';
              _this.state = 1;
              break;
            case 1:
              detail.orderStateText = '待供应商确认';
              _this.state = 1;
              break;
            case 2:
              detail.orderStateText = '待平台确认';
              _this.state = 1;
              break;
            case 3:
              detail.orderStateText = '待支付';
              _this.state = 2;
              break;
            case 4:
              detail.orderStateText = '已支付';
              _this.state = 3;
              break;
            case 5:
              detail.orderStateText = '确认出团';
              _this.state = 4;
              break;
            case 6:
              detail.orderStateText = '已出团';
              _this.state = 5;
              break;
            case 7:
              detail.orderStateText = '已回团';
              _this.state = 6;
              break;
            case 8:
              detail.orderStateText = '已分账';
              _this.state = 7;
              break;
            case 9:
              detail.orderStateText = '新增订单';
              _this.state = 1;
              break;
            default:
              _this.state = 1;
          }

          switch (detail.attachType) {
            case 1:
              detail.attachTypeText = '加价';
              break;
            case 0:
              detail.attachTypeText = '减免';
              break;
          }
          switch (detail.depositType) {
            case 0:
              detail.depositTypeText = '无订金';
              break;
            case 1:
              detail.depositTypeText = '百分比';
              break;
            case 2:
              detail.depositTypeText = '固定金额';
              break;
          }

          switch (detail.attachAmountType) {
            case 1:
              detail.attachAmountTypeText = '固定金额';
              break;
            case 2:
              detail.attachAmountTypeText = '百分比';
              break;
          }

          switch (detail.buyerCarryRules) {
            case 0:
              detail.buyerCarryRulesText = '进位到元';
              break;
            case 1:
              detail.buyerCarryRulesText = '进位到分';
              break;
          }

          detail.tourTravellerInformations.forEach(function (item) {
            if (item.birthDate) item.birthDateText = moment(item.birthDate).format('YYYY-MM-DD');
            switch (item.crowdType) {
              case 1:
                item.crowdTypeText = '成人';
                break;
              case 2:
                item.crowdTypeText = '儿童不占床';
                break;
              case 3:
                item.crowdTypeText = '老人';
                break;
              case 4:
                item.crowdTypeText = '儿童占床';
                break;
            };

            switch (item.touristGender) {
              case 0:
                item.sex = '男';
                break;
              case 1:
                item.sex = '女';
                break;
            };


            switch (item.certificatesType) {
              case 1:
                item.certificate = '身份证';
                break;
              case 2:
                item.certificate = '护照';
                break;
              case 3:
                item.certificate = '军官证';
                break;
              case 4:
                item.certificate = '学生证';
                break;
              case 5:
                item.certificate = '其他';
                break;
            };
          });
          _this.detail = detail;
          _this.getSelectByOrderNo(detail);
          _this.loading = false;
        })
    },
    req_audit (params) {
      const _this = this;
      Q.when()
        .then(function () {
          return web_salesOrder_orderState(params);
        })
        .then(function (res) {
          if (params.supplyAuditStatus === 1) {
            _this.$message.success('设置审核通过成功');
          } else if (params.supplyAuditStatus === 2) {
            _this.$message.success('设置审核不通过成功');
          }
          _this.$router.back(-1);
        })
    },
    pass (supplyAuditStatus) {
      const _this = this;
      this.$confirm('是否确认通过审核?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.form.supplyAuditStatus = supplyAuditStatus;
        if (_this.$route.query.type === 'plain_order_audit' || _this.$route.query.type === 'difengyun_order_audit') {
          _this.$refs.fileForm.validate((valid) => {
            if (valid) {
              _this.req_audit (this.form);
            } else {
              return false;
            }
          })
        } else {
          _this.req_audit (this.form);
        }
        
      }).catch(() => {

      })
    },
    fall (supplyAuditStatus) {
      this.form.supplyAuditStatus = supplyAuditStatus;
      this.dialogVisible = true;
    },
    handleClose () {
      this.dialogVisible = false;
    },
    commit_fall () {
      const _this = this;
      this.$refs.form.validate((valid) => {
          if (valid) {
            _this.req_audit(this.form);
          } else {
            return false;
          }
        });
    },
    handleSuccesspictureB(file, fileList) {
      const size = file.size / 1024 / 1024;
      if (size > 5) {
        return this.$message.error("文件大小不能超过5M");
      }
      const _this = this;
      const aOssClient = new OssClient();
      aOssClient.multipartUpload({
        file: file.raw,
        progress: function* (p) {
          
        }
      })
      .then(function (data) {
        _this.$message.success("文件上传成功");
        _this.form.platformBuyerAffirm = data.url;
        _this.$refs.uploadB.clearValidate();
      })
    },
    removePDFB() {
      this.form.platformBuyerAffirm = "";
    },
    openPDFB(file) {
      window.open(this.form.platformBuyerAffirm);
    },
    handleSuccesspicture(file, fileList) {
      const size = file.size / 1024 / 1024;
      if (size > 5) {
        return this.$message.error("文件大小不能超过5M");
      }
      const _this = this;
      const aOssClient = new OssClient();
      aOssClient.multipartUpload({
        file: file.raw,
        progress: function* (p) {
          
        }
      })
      .then(function (data) {
        _this.$message.success("文件上传成功");
        _this.form.platformAffirmOrderUrl = data.url;
        _this.$refs.upload.clearValidate();
      })
    },
    removePDF() {
      this.form.platformAffirmOrderUrl = "";
    },
    openPDF(file) {
      window.open(this.form.platformAffirmOrderUrl);
    },
  },
  created() {
  },
  mounted() {},
  activated() {
    this.form.orderId = this.$route.query.id
    this.req_detail({id: this.$route.query.id});

  },
  deactivated() {},
  destroyed() {},
  watch: {

  },
  computed: {}
};
