import import_latest_version_module from '@/component/admin-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/hotel-cancel-order-list',
            name: 'hotel-cancel-order-list',
            component: () => import(/* webpackChunkName: "page-admin-hotel-order-manage-cancelList" */ `./1.0.1/index.vue`),
            meta: {
          data_for_admin_layout: {
                    data_for_history_tag: {
                              entity: {
                                        name: "酒店取消订单"
                              }
                    },
                    data_for_breadcrumb: {
                              entity_list: [
                                        {
                                                  name: "取消订单列表"
                                        }
                              ]
                    }
          }
},
        },
    ],
};
