import Q from 'q';
import moment from 'moment';


// 城市插件
import TrainCitySelector from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/train-city-selector/1.1.0/index.vue';

// 火车票订单列表
import trains_order_platformChangeOrderList from '@/lib/data-service/train/trains_order_platformChangeOrderList';

export default {
  components: {
    TrainCitySelector
  },
  data () {
    return {
      loading: false,
      show: false,
      form: {
        currentPage: 1,
        pageSize: 10,
        orderNo: '',
        changeNo: '',
        changeTrainCode: '',
        originalTrainCode: '',
        changeFromStationSn: '',
        changeToStationSn: '',
        passengerName: '',
        beginChangeStartDate: '',
        endChangeStartDate: '',
        beginOriginalChangeStartDate: '',
        originalEndChangeStartDate: '',
        buyerName: '',
        changeOrderStatus: '',
      },
      departDate: null,
      createDate: null,
      fromCity: null,
      toCity: null,
      orderStatus: '',
      list: [],
      allocation: {
        orderType: [
          { label: '全部', value: '' },
          { label: '预订', value: '1' },
          { label: '抢票', value: '2' },
        ],
        orderStatus: [
          {code: '', msg: '全部'},
          // {code: '0', msg: '申请改签'},
          {code: '1', msg: '待支付'},
          {code: '2', msg: '改签中'},
          {code: '3', msg: '改签成功'},
          {code: '4', msg: '改签失败'},
          {code: '5', msg: '取消'},
        ],
      },
      timer: null,
      pager: {
        total: 0,
        pageSizes: [10, 20, 30, 40, 50]
      }
    }
  },
  methods: {
    req_list (params, type) {
      clearInterval(this.timer);
      this.loading = true;
      const _this = this;
      Q.when()
        .then(function () {
          return trains_order_platformChangeOrderList(params);
        })
        .then(function (res) {
          if (type) document.documentElement.scrollTop = 0;
          const list = res.pageResult.pageData;
          _this.count_down(list);
          _this.pager.total = res.pageResult.totalCount;
          list.forEach(function (item) {
            if (moment(item.changeStartDate).format('YYYY-MM-DD') === moment(item.changeArriveDate).format('YYYY-MM-DD')) {
              item.changeDate = moment(item.changeStartDate).format('YYYY-MM-DD');
              item.changeTime =  moment(item.changeStartDate).format('HH:mm') + '-' + moment(item.changeArriveDate).format('HH:mm');
            }
            if (moment(item.originalStartDate).format('YYYY-MM-DD') === moment(item.originalArriveDate).format('YYYY-MM-DD')) {
              item.originDate = moment(item.originalStartDate).format('YYYY-MM-DD');
              item.originTime =  moment(item.originalStartDate).format('HH:mm') + '-' + moment(item.originalArriveDate).format('HH:mm');
            }
          })
          _this.list = list;
          _this.loading = false;
          _this.timer = setInterval(function () {
            _this.count_down(_this.list);
          }, 1000);
        })
    },
    count_down (list) {
      const _this = this;
      list.forEach(function (item) {
        if (+item.changeOderStatus !== 1) return;
        if (item.second >= 0) {
          item.second -= 1;
          let minute = Math.floor(item.second / 60);
          minute = minute > 9 ? minute : '0' + minute;
          let second = item.second % 60;
          second = second > 9 ? second : '0' + second;
          item.countdownToPayment = `${minute}分${second}秒`;
          if (item.second === 0) _this.req_list(_this.form);
        } else {
          item.countdownToPayment = '00分00秒'
        }
      });
    },
    search () {
      this.form.currentPage = 1;
      this.req_list(this.form);
    },
    reset () {
      const pageSize = this.form.pageSize;
      const changeOrderStatus = this.form.changeOrderStatus;
      this.departDate = null;
      this.createDate = null;
      this.fromCity = null;
      this.toCity = null;
      this.form = {
        currentPage: 1,
        pageSize,
        orderNo: '',
        changeNo: '',
        changeTrainCode: '',
        originalTrainCode: '',
        changeFromStationSn: '',
        changeToStationSn: '',
        passengerName: '',
        beginChangeStartDate: '',
        endChangeStartDate: '',
        beginOriginalChangeStartDate: '',
        originalEndChangeStartDate: '',
        buyerName: '',
        changeOrderStatus,
      };
      this.req_list(this.form);
    },
    fold_change () {
      this.show = !this.show;
    },
    select_state (val) {
      this.form.changeOrderStatus = val;
      this.form.currentPage = 1;
      this.req_list(this.form);
    },
    to_detail (changeNo, orderNo) {
      this.$router.push({
        name: 'admin-train-change-detail',
        query: {
          changeNo,
          orderNo
        }
      })
    },
    handleSizeChange (pageSize) {
      this.form.pageSize = pageSize;
      this.form.currentPage = 1;
      this.req_list(this.form, 'pageSize');
    },
    handleCurrentChange (currentPage) {
      this.form.currentPage = currentPage;
      this.req_list(this.form, 'currentPage');
    }
  },
  mounted () {

  },
  activated () {
    this.req_list(this.form);
  },
  deactivated () {
    clearInterval(this.timer);
  },
  watch: {
    departDate (val) {
      const form = this.form;
      form.beginChangeStartDate = val ? val[0] + ' 00:00:00' : '';
      form.endChangeStartDate = val ? val[1] + ' 23:59:59' : '';
    },
    createDate (val) {
      const form = this.form;
      form.beginOriginalChangeStartDate = val ? val[0] + ' 00:00:00' : '';
      form.originalEndChangeStartDate = val ? val[1] + ' 23:59:59' : '';
    },
    fromCity (val) {
      this.form.changeFromStationSn = val ? val.sn : '';
    },
    toCity (val) {
      this.form.changeToStationSn = val ? val.sn : '';
    }
  }
}
