const __request = require(`./__request/__request_contentType_json`);

// http://219.128.52.2:8765/doc.html#/btoc-biz-service/BTOC%20-%20%E7%BA%BF%E8%B7%AF/deleteUsingPOST
const biz_line_select = (pParameter) => {
  if (!pParameter) pParameter = {};

  const id = pParameter.id

  const p = {
    method: 'post',
    urlSuffix: '/biz/line/delete',
    data: {
      id,
    }
  };
  return new Promise(function (resolve, reject) {
    resolve(__request(p))
  }).then(data => {
    return data
  })
};
module.exports = biz_line_select;