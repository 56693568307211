// web_common_register

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口文档地址： http://192.168.0.15:8080/doc.html#/所有接口/send-sms-message-and-validation/validationUsingPOST_3
export default function web_common_register(pParameter) {
  let params = {}
  if (!pParameter) pParameter = {};
  const phoneNumber = pParameter.phoneNumber || ''
  const verificationCode = pParameter.verificationCode || ''
  const roleType = pParameter.roleType || ''
  const uuid = pParameter.uuid || ''
  params.method = 'POST'
  params.urlSuffix = '/web/common/register'
  params.data = {
    phoneNumber,
    verificationCode,
    roleType,
    uuid
  }
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
