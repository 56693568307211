

const __request = require(`./__request/__request_contentType_json`);

// 产品级别枚举

export default function web_common_product_getProductLevel(pParameter) {
  if (!pParameter) pParameter = {};
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/common/product/getProductLevel'
  params.data = pParameter;
  params.process_response_data = false
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}
