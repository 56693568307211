// /web/common/upload/aptitudeSingle 旧平台上传图片接口
import UploadImage from '@/page/admin/supplier-management/component/uploadImage/index.vue'
import web_common_area_getInlandAreaChildListByParentId from '@/lib/data-service/default/web_common_area_getInlandAreaChildListByParentId'
import web_common_user_getDeveloper from '@/lib/data-service/default/web_common_user_getDeveloper'
import web_clud_list from '@/lib/data-service/default/web_clud_list'
import web_circuit_findTouristTour from '@/lib/data-service/default/web_circuit_findTouristTour'
import web_managerSupplier_register from '@/lib/data-service/default/web_managerSupplier_register'
import web_common_user_vueCheckUserName from '@/lib/data-service/default/web_common_user_vueCheckUserName'
//获取机票供应商默认技术服务费
import getServiceFee from '@/lib/data-service/default/web_managerSupplier_flightSupplier_getServiceFee'
import is_mobile from 'yinzhilv-js-sdk/src/common-service/other/is_mobile';
import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client";
export default {
  data () {
    const validateUserName = (rule, value, callback) => {
      const regExp = /^\w+$/
      if (regExp.test(value)) {
        web_common_user_vueCheckUserName({userName: value}).then(res => {
          if (res.code === '000000' && res.flag === true) {
            callback()
          } else if (res.code === '000000' && res.flag === false) {
            callback(new Error('用户已存在不可使用'))
          } else {
            callback(new Error(res.msg))
          }
        }).catch((err) => {
          callback(new Error(err.data.msg))
        })
      } else {
        callback(new Error('只能输入字母/数字/下划线'))
      }
    };
    const validateLicenseNumber = (rule, value, callback) => {
      const regExp = /^\w+$/
      if (regExp.test(value)) {
        callback()
      } else {
        callback(new Error('只能输入字母/数字/下划线'))
      }
    }
    const validatePassword = (rule, value, callback) => {
      const regExp = /^[A-Za-z0-9]+$/
      if (regExp.test(value)) {
        callback()
      } else {
        callback(new Error('只能输入字母/数字'))
      }
    };
    const validateSubmitPassword = (rule, value, callback) => {
      if (value === this.params.password) {
        callback()
      } else {
        callback(new Error('与密码不一致'))
      }
    };
    const validatePhone = (rule, value, callback) => {
      if (is_mobile(value)) {
        callback()
      } else {
        callback(new Error('不符合手机号码格式'))
      }
    };
    const validateCompanyName = (rule, value, callback) => {
      const regExp = /^(?!_)(?!.*?_$)[a-zA-Z0-9_\u4e00-\u9fa5]+$/
      if (regExp.test(value)) {
        callback()
      } else {
        callback(new Error('只能输入字母/中文/数字/下划线'))
      }
    };
    const validateIdBusinessLicensePic = (rule, value, callback) => {
      if (this.params.businessLicensePic === '') {
        callback(new Error('请传入营业执照照片'))
      } else {
        callback()
      }
    };
    /*const validateIdCardFrontArr = (rule, value, callback) => {
      if (value.length > 0) {
        callback()
      } else {
        callback(new Error('请传入法人代表身份证(正面)'))
      }
    };*/
    const validateIdCardFront = (rule, value, callback) => {
      if (this.params.idCardFront === '') {
        callback(new Error('请传入法人代表身份证(正面)'))
      } else {
        callback()
      }
    };
    /*const validateIdCardReverseArr = (rule, value, callback) => {
      if (value.length > 0) {
        callback()
      } else {
        callback(new Error('请传入法人代表身份证(反面)'))
      }
    };*/
    const validateIdCardReverse = (rule, value, callback) => {
      if (this.params.idCardReverse === '') {
        callback(new Error('请传入法人代表身份证(反面)'))
      } else {
        callback()
      }
    };
    const validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback()
        return
      }
      const regExp = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
      if (regExp.test(value)) {
        callback()
      } else {
        callback(new Error('邮件格式不正确'))
      }
    };
    const validateQQ = (rule, value, callback) => {
      if (value === '') {
        callback()
        return
      }
      const regExp = /^[1-9][0-9]{4,9}$/gim
      if (regExp.test(value)) {
        callback()
      } else {
        callback(new Error('QQ格式不正确'))
      }
    };
    const validateTravelBusinessLicense = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请传入旅游经营许可证照片'))
      } else {
        callback()
      }
    };
    const validateAmount = (rule, value, callback) => {
      if (this.tourParams.attachType !== 0 && this.tourParams.attachAmountType === 1) {
        if (value === '') {
          callback(new Error('不能为空'))
        } else {
          if (value >= 0) {
            callback()
          } else {
            callback(new Error('不能输入负数'))
          }
        }
      } else {
        callback()
      }
    };
    const validatePercent = (rule, value, callback) => {
      if (this.tourParams.attachType !== 0 && this.tourParams.attachAmountType === 2) {
        if (value === '') {
          callback(new Error('不能为空'))
        } else {
          if (value >= 0) {
            callback()
          } else {
            callback(new Error('不能输入负数'))
          }
        }
      } else {
        callback()
      }
    };
    const validateServiceFeePercent = (rule, value, callback) => {
      if (this.tourParams.serviceFeeType !== 0) {
        if (value === '') {
          callback(new Error('不能为空'))
        } else {
          if (value >= 0) {
            callback()
          } else {
            callback(new Error('不能输入负数'))
          }
        }
      } else {
        callback()
      }
    };
    const validateIataProve = (rule, value, callback) => {
      if (this.params.iataProve === '') {
        callback(new Error('请传入IATA认可证书'))
      } else {
        callback()
      }
    };
    const validateGuarantee = (rule, value, callback) => {
      if (this.params.guarantee === '') {
        callback(new Error('请传入担保函'))
      } else {
        callback()
      }
    };
    const validateUnWithdrawalRate = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('不能为空'))
      } else {
        if (value >= 0 && value <= 100) {
          callback()
        } else {
          callback(new Error('请输入0-100的数'))
        }
      }
      callback()
    }
    const validateDepositAmount = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('不能为空'))
      } else {
        if (value >= 0) {
          callback()
        } else {
          callback(new Error('不能为负数'))
        }
      }
      callback()
    }
    const validateAccountName = (rule, value, callback) => {
      if (!value) {
        if (this.params.accountBankNm || this.params.accountNo) {
          callback(new Error('请输入账户名'))
        }
      }
      callback()
    }
    const validateAccountBankNm = (rule, value, callback) => {
      if (!value) {
        if (this.params.accountName || this.params.accountNo) {
          callback(new Error('请输入开户行'))
        }
      }
      callback()
    }
    const validateAccountNo = (rule, value, callback) => {
      if (!value) {
        if (this.params.accountName || this.params.accountBankNm) {
          callback(new Error('请输入银行账号'))
        }
      }
      callback()
    }
    const validateAlipayName = (rule, value, callback) => {
      if (!value) {
        if (this.params.alipayAccount) {
          callback(new Error('请输入账户名'))
        }
      }
      callback()
    }
    const validateAlipayAccount = (rule, value, callback) => {
      if (!value) {
        if (this.params.alipayName) {
          callback(new Error('请输入支付宝账号'))
        }
      }
      callback()
    }
    const validateOfExpirationTimeTrue = (rule, value, callback) => {
      if (this.params.isOperationFlow === 1) {
        if (value === '') {
          callback(new Error('流水到期时间不能为空'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    const validateDomesticServiceFee = (rule, value, callback) => {
      if (value >= 0) {
        callback()
      } else {
        callback(new Error('不能输入负数'))
      }
    }
    const validatePaymentServiceRate = (rule, value, callback) => {
      if (value === '' || value >= 0) {
        callback()
      } else {
        callback(new Error('不能输入负数'))
      }
    }
    const validateIDCard = (rule, value, callback) => {
      const regIdCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (!regIdCard.test(value)) {
        callback(new Error('身份证号填写有误'))
      } else {
        callback()
      }
    }
    const validateSettleDeposit = (rule, value, callback) => {
      if (value >= 0) {
        callback()
      } else {
        callback(new Error('不能输入负数'))
      }
    }
    const validateBusinessLicenseNum = (rule, value, callback) => {
      if (value.indexOf(' ') >= 0) {
        callback(new Error('不能输入空格'))
      } else {
        const reg = /^([159Y]{1})([1239]{1})([0-9ABCDEFGHJKLMNPQRTUWXY]{6})([0-9ABCDEFGHJKLMNPQRTUWXY]{9})([0-9ABCDEFGHJKLMNPQRTUWXY])$/;
        if (!value) {
          return callback(new Error("请输入营业执照"));
        } else if (!reg.test(value)) {
          callback(new Error("请输入正确的营业执照"));
        } else {
          callback();
        }
        // callback()
      }
    }
    return {
      loading: false,
      params: {
        supCanConfigList:[
          {
            // 申请--是否24小时(取位设置) 0：否 1：是
            allDayForCanPnr: 1,
            // 退票--是否24小时(退票取位设置) 0：否 1：是
            allDayForRefPnr: 1,
            // 申请--自动取位开始时间
            canPnrTimeBegin: '00:00:00',
            // 申请--自动取位结束时间
            canPnrTimeEnd: '23:59:59',
            // 申请--取位方式，1-自动取位，2-人工取位
            canPnrType: 1,
            // 退票--自动取位开始时间
            refPnrTimeBegin: '00:00:00',
            // 退票--自动取位结束时间
            refPnrTimeEnd: '23:59:59',
            // 退票--取位方式，1-自动取位，2-人工取位
            refPnrType: 1,
            // 票证类型：1.BSP 2.B2T 3.B2B 4.OT 5.B2C
            ticketVoucherType: 1,
            // 申请--自动取位开始时间
            canPnrTime: ['00:00:00','23:59:59'],
            // 退票--自动取位开始时间
            refPnrTime: ['00:00:00','23:59:59'],
            // 申请--是否24小时(取位设置)布尔值
            allDayForCanPnrBoolean: true,
            // 退票--是否24小时(退票取位设置)布尔值
            allDayForRefPnrBoolean: true,
          },
          {
            // 申请--是否24小时(取位设置) 0：否 1：是
            allDayForCanPnr: 1,
            // 退票--是否24小时(退票取位设置) 0：否 1：是
            allDayForRefPnr: 1,
            // 申请--自动取位开始时间
            canPnrTimeBegin: '00:00:00',
            // 申请--自动取位结束时间
            canPnrTimeEnd: '23:59:59',
            // 申请--取位方式，1-自动取位，2-人工取位
            canPnrType: 1,
            // 退票--自动取位开始时间
            refPnrTimeBegin: '00:00:00',
            // 退票--自动取位结束时间
            refPnrTimeEnd: '23:59:59',
            // 退票--取位方式，1-自动取位，2-人工取位
            refPnrType: 1,
            // 票证类型：1.BSP 2.B2T 3.B2B 4.OT 5.B2C
            ticketVoucherType: 3,
            // 申请--自动取位开始时间
            canPnrTime: ['00:00:00','23:59:59'],
            // 退票--自动取位开始时间
            refPnrTime: ['00:00:00','23:59:59'],
            // 申请--是否24小时(取位设置)布尔值
            allDayForCanPnrBoolean: true,
            // 退票--是否24小时(退票取位设置)布尔值
            allDayForRefPnrBoolean: true,
          },
          {
            // 申请--是否24小时(取位设置) 0：否 1：是
            allDayForCanPnr: 1,
            // 退票--是否24小时(退票取位设置) 0：否 1：是
            allDayForRefPnr: 1,
            // 申请--自动取位开始时间
            canPnrTimeBegin: '00:00:00',
            // 申请--自动取位结束时间
            canPnrTimeEnd: '23:59:59',
            // 申请--取位方式，1-自动取位，2-人工取位
            canPnrType: 1,
            // 退票--自动取位开始时间
            refPnrTimeBegin: '00:00:00',
            // 退票--自动取位结束时间
            refPnrTimeEnd: '23:59:59',
            // 退票--取位方式，1-自动取位，2-人工取位
            refPnrType: 1,
            // 票证类型：1.BSP 2.B2T 3.B2B 4.OT 5.B2C
            ticketVoucherType: 2,
            // 申请--自动取位开始时间
            canPnrTime: ['00:00:00','23:59:59'],
            // 退票--自动取位开始时间
            refPnrTime: ['00:00:00','23:59:59'],
            // 申请--是否24小时(取位设置)布尔值
            allDayForCanPnrBoolean: true,
            // 退票--是否24小时(退票取位设置)布尔值
            allDayForRefPnrBoolean: true,
          },
        ],

        //submitPassword: '',
        businessLicenseArr: [], // 营业执照图片数组
        travelBusinessLicensePicArr: [],
        idCardFrontArr: [],
        idCardReverseArr: [],
        commonSealArr: [], // 公章照片数组
        brandLogoArr: [], // logo照片数组
        iataProveArr: [],
        guaranteeArr: [],
        financeFaxTit: '',
        financeFaxNum: '',
        circuitListArr: [],

        userName: '',
        password: '',
        phone: '',
        companyName: '',
        areaRegion: '',
        areaProvince: '',
        areaCity: '',
        areaRegionName: '', // 公司所在大区名字
        areaProvinceName: '', // 公司所在省份名字
        areaCityName: '', // 公司所在城市名字
        companyAddress: '',
        businessLicenseNum: '',
        businessLicensePic: '',
        idCard: '',
        idCardFront: '',
        idCardReverse: '',
        tenpay: '', // 财付通账号
        alipay: '', // 支付宝账号
        yeepay: '', // 易宝账号
        clubId: '', // 中间社id
        financeFax: '',
        supplierLevel: '', // 供应商等级：与等级表关联
        roleId: '',
        travelLinkman: '',
        travelPhone: '',
        travelQq: '',
        travelEmail: '',
        airLinkman: '',
        airPhone: '',
        airEmail: '',
        airQq: '',
        developerId: '',
        developer: '',
        travelBusinessLicenseNum: '',
        travelBusinessLicensePic: '',
        brandName: '',
        brandLogo: '',
        commonSeal: '',
        iataProve: '',
        guarantee: '',
        airTicketType: '',
        applicationType: '1',
        domesticServiceType: '',
        domesticServiceFee: '',
        internationalServiceType: '',
        internationalServiceFee: '',
        specialServiceType: '',//国内机票特殊政策收取方式
        specialServiceFee: '',//国内机票特殊政策服务费
        airAccountManagerId: '',
        airAccountManager: '',
        travelPaymentType: '',
        travelPaymentDays: '',
        ticketPaymentType: '',
        ticketPaymentDays: '',
        hotelPaymentType: '',
        hotelPaymentDays: '',
        serviceFeePercent: 0,
        serviceFeeType: '2',
        accountManagerId: '',
        accountManager: '',
        isOperationFlow: 0,
        ofExpirationTimeTrue: '',
        paymentServiceRate: '',
        circuitList: '',
        unWithdrawalRate: '', // 不可提现比例
        depositAmount: '', // 压款金额
        accountName: '', // 银行账户名
        accountBankNm: '', // 银行开户行
        accountNo: '', // 银行账号
        alipayName: '', // 支付宝账户名
        alipayAccount: '', // 支付宝账号
        canPnrType:1,
        refPnrType:1,
        cancelTime:['00:00:00','23:59:59'],
        allDayForCanPnrType:true,
        refCancelTime:['00:00:00','23:59:59'],
        allDayForRefPnrType:true,
      },
      addParams: {
        settleDepositType: 0, // 订金收取方式 0 -不收 2定额 1比例
        settleDeposit: 0, // 订金
        finalPaymentCycle: 0, // 尾款周期，出团前
        finalCycleUnit: 1, // 尾款周期单位 1-天 2-周 3-月 默认天
        finalPaymentType: 1, // 默认出团前 自增
        isHasSettle: 1, // 收订金还是全款 1-订金 0全款 自增
        settleType: 2, // 1比例，2定额 自增

        pressAmountType: 0, // 压款收取方式 0-不压 1 比例 2定额
        pressAmount: 0, // 压款金额
        presslPaymentCycle: 0, // 压款周期，出团后
        presslCycleUnit: 1, // 压款周期单位 1-天 2-周 3-月 默认天
        pressPaymentType: 1, // 默认出团后 自增
        isHasPress: 1, // 是否压款 0-不压 1压 自增
        pressType: 2, // 1比例，2定额 自增
      },
      fileParams: {
        agreementFile: '',
      },
      rule: {
        userName: [
          {required: true, message: '请输入账户名称', trigger: 'blur'},
          {min: 4, max: 16, message: '长度在4~16个字符', trigger: 'blur'},
          {validator: validateUserName, trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 6, max: 20, message: '长度在6~20个字符', trigger: 'blur'},
          {validator: validatePassword, trigger: 'blur'}
        ],
        submitPassword: [
          {required: true, message: '请再次输入密码', trigger: 'blur'},
          {validator: validateSubmitPassword, trigger: 'blur'}
        ],
        phone: [
          {required: true, message: '请输入手机号码', trigger: 'blur'},
          {max: 11, message: '不符合手机号码长度', trigger: 'blur'},
          {validator: validatePhone, trigger: 'blur'}
        ],
        companyName: [
          {required: true, message: '请输入公司全称', trigger: 'blur'},
          {max: 30, message: '不符合公司名称长度', trigger: 'blur'},
          {validator: validateCompanyName, trigger: 'blur'}
        ],
        areaCity: [
          {required: true, message: '请填入所属大区', trigger: 'blur'},
        ],
        companyAddress: [
          {required: true, message: '请输入公司地址', trigger: 'blur'},
          {max: 30, message: '不符合公司地址长度', trigger: 'blur'},
          {validator: validateCompanyName, trigger: 'blur'}
        ],
        businessLicenseNum: [
          // {required: true, message: '请输入统一社会信用代码', trigger: 'blur'},
          // {validator: validateBusinessLicenseNum, trigger: 'blur'}
        ],
        /*idCardFrontArr: [
          {required: true, validator: validateIdCardFrontArr, trigger: 'change'}
        ],*/
        businessLicensePic: [
          {required: true, validator: validateIdBusinessLicensePic}
        ],
        idCard: [
          {required: true, message: '请输入法人身份证号码', trigger: 'blur'},
          {validator: validateIDCard, trigger: 'blur'}
        ],
        idCardFront: [
          {required: true, validator: validateIdCardFront}
        ],
        idCardReverse: [
          {required: true, validator: validateIdCardReverse}
        ],
        travelLinkman: [
          {required: true, message: '请输入联系人', trigger: 'blur'},
          {max: 30, message: '不符合联系人长度', trigger: 'blur'}
        ],
        travelPhone: [
          {required: true, message: '请输入手机号码', trigger: 'blur'},
          {max: 11, message: '不符合手机号码长度', trigger: 'blur'},
          {validator: validatePhone, trigger: 'blur'}
        ],
        travelEmail: [
          {validator: validateEmail, trigger: 'blur'}
        ],
        travelQq: [
          {validator: validateQQ, trigger: 'blur'}
        ],
        travelBusinessLicenseNum: [
          {required: true, message: '请输入旅游经营许可证', trigger: 'blur'},
          {max: 30, message: '不符合旅游经营许可证长度', trigger: 'blur'},
          {validator: validateLicenseNumber, trigger: 'blur'}
        ],
        travelBusinessLicensePic: [
          {required: true, validator: validateTravelBusinessLicense}
        ],
        airLinkman: [
          {required: true, message: '请输入联系人', trigger: 'blur'},
          {max: 30, message: '不符合联系人长度', trigger: 'blur'}
        ],
        airPhone: [
          {required: true, message: '请输入手机号码', trigger: 'blur'},
          {max: 11, message: '不符合手机号码长度', trigger: 'blur'},
          {validator: validatePhone, trigger: 'blur'}
        ],
        airEmail: [
          {validator: validateEmail, trigger: 'blur'}
        ],
        airQq: [
          {validator: validateQQ, trigger: 'blur'}
        ],
        iataProve: [
          {required: true, validator: validateIataProve}
        ],
        guarantee: [
          {required: true, validator: validateGuarantee}
        ],
        developerId: [
          {required: true, message: '开发人不能为空', trigger: 'change'}
        ],
        isOperationFlow: [
          {required: true, message: '操作流水不能为空', trigger: 'change'}
        ],
        domesticServiceType: [
          {required: true, message: '请选择收取方式', trigger: 'change'}
        ],
        domesticServiceFee: [
          {required: true, message: '请输入服务费', trigger: 'blur'},
          {validator: validateDomesticServiceFee, trigger: 'blur'}
        ],
        internationalServiceType: [
          {required: true, message: '请选择收取方式', trigger: 'change'}
        ],
        internationalServiceFee: [
          {required: true, message: '请输入服务费', trigger: 'blur'},
          {validator: validateDomesticServiceFee, trigger: 'blur'}
        ],
        specialServiceType: [
          {required: true, message: '请输入国内机票特殊政策收取方式', trigger: 'change'}
        ],
        specialServiceFee: [
          {required: true, message: '请输入国内机票特殊政策服务费', trigger: 'blur'},
          {validator: validateDomesticServiceFee, trigger: 'blur'}
        ],
        airAccountManagerId: [
          {required: true, message: '机票客户经理不能为空', trigger: 'change'}
        ],
        unWithdrawalRate: [
          {required: true, message: '请输入不可提现比例', trigger: 'blur'},
          {validator: validateUnWithdrawalRate, trigger: 'blur'}
        ],
        depositAmount: [
          {required: true, message: '请输入压款金额', trigger: 'blur'},
          {validator: validateDepositAmount, trigger: 'blur'}
        ],
        accountName: [
          {validator: validateAccountName, trigger: 'blur'}
        ],
        accountBankNm: [
          {validator: validateAccountBankNm, trigger: 'blur'}
        ],
        accountNo: [
          {validator: validateAccountNo, trigger: 'blur'}
        ],
        alipayName: [
          {validator: validateAlipayName, trigger: 'blur'}
        ],
        alipayAccount: [
          {validator: validateAlipayAccount, trigger: 'blur'}
        ],
        serviceFeeType: [
          {required: true, message: '请选择收取方式', trigger: 'change'}
        ],
        serviceFeePercent: [
          {required: true, message: '请输入技术服务费', trigger: 'blur'},
          {validator: validateDomesticServiceFee, trigger: 'blur'}
        ],
        paymentServiceRate: [
          {validator: validatePaymentServiceRate, trigger: 'blur'}
        ],
        accountManagerId: [
          {required: true, message: '旅游客户经理不能为空', trigger: 'change'}
        ],
        ofExpirationTimeTrue: [
          {validator: validateOfExpirationTimeTrue, trigger: 'change'}
        ],
        settleDeposit: [
          {required: true, message: '订金金额不能为空', trigger: 'blur'},
          {validator: validateSettleDeposit, trigger: 'blur'}
        ],
        finalPaymentCycle: [
          {required: true, message: '尾款周期不能为空', trigger: 'blur'},
          {validator: validateSettleDeposit, trigger: 'blur'}
        ],
        pressAmount: [
          {required: true, message: '压款金额不能为空', trigger: 'blur'},
          {validator: validateSettleDeposit, trigger: 'blur'}
        ],
        presslPaymentCycle: [
          {required: true, message: '压款周期不能为空', trigger: 'blur'},
          {validator: validateSettleDeposit, trigger: 'blur'}
        ]
      },
      inLandList: [],
      provinceList: [],
      cityList: [],
      fileImg: { show: false, url: '' },
      developerList: [],
      clubList: [],
      airAccountManagerList: [],
      tourList: [], // 旅游线路
      dialogVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '创建'
      },
      tourParams: {
        attachAdultAmount: '',
        attachAdultPercent: '',
        attachAgedAmount: '',
        attachAgedPercent: '',
        attachAmountType: 1,
        attachChildAmount: '',
        attachChildBedAmount: '',
        attachChildBedPercent: '',
        attachChildPercent: '',
        attachType: 1,
        isDelete: 0,
        lineId: '',
        lineName: '',
        lineType: '',
        serviceFeePercent: '',
        serviceFeeType: 0,

        settleDepositType: 0, // 订金收取方式 0 -不收 2定额 1比例
        settleDeposit: 0, // 订金
        finalPaymentCycle: 0, // 尾款周期，出团前
        finalCycleUnit: 1, // 尾款周期单位 1-天 2-周 3-月 默认天
        finalPaymentType: 1, // 默认出团前 自增
        isHasSettle: 1, // 收订金还是全款 1-订金 0全款 自增
        settleType: 2, // 1比例，2定额 自增

        pressAmountType: 0, // 压款收取方式 0-不压 1 比例 2定额
        pressAmount: 0, // 压款金额
        presslPaymentCycle: 0, // 压款周期，出团后
        presslCycleUnit: 1, // 压款周期单位 1-天 2-周 3-月 默认天
        pressPaymentType: 1, // 默认出团后 自增
        isHasPress: 1, // 是否压款 0-不压 1压 自增
        pressType: 2, // 1比例，2定额 自增
      },
      tourRule: {
        lineId: [
          {required: true, message: '请选择线路', trigger: 'change'},
        ],
        attachAdultAmount: [
          {validator: validateAmount, trigger: 'blur'}
        ],
        attachChildBedAmount: [
          {validator: validateAmount, trigger: 'blur'}
        ],
        attachChildAmount: [
          {validator: validateAmount, trigger: 'blur'}
        ],
        attachAgedAmount: [
          {validator: validateAmount, trigger: 'blur'}
        ],
        attachAdultPercent: [
          {validator: validatePercent, trigger: 'blur'}
        ],
        attachChildBedPercent: [
          {validator: validatePercent, trigger: 'blur'}
        ],
        attachChildPercent: [
          {validator: validatePercent, trigger: 'blur'}
        ],
        attachAgedPercent: [
          {validator: validatePercent, trigger: 'blur'}
        ],
        serviceFeePercent: [
          {validator: validateServiceFeePercent, trigger: 'blur'}
        ],
        settleDeposit: [
          {required: true, message: '订金金额不能为空', trigger: 'blur'},
          {validator: validateSettleDeposit, trigger: 'blur'}
        ],
        finalPaymentCycle: [
          {required: true, message: '尾款周期不能为空', trigger: 'blur'},
          {validator: validateSettleDeposit, trigger: 'blur'}
        ],
        pressAmount: [
          {required: true, message: '压款金额不能为空', trigger: 'blur'},
          {validator: validateSettleDeposit, trigger: 'blur'}
        ],
        presslPaymentCycle: [
          {required: true, message: '压款周期不能为空', trigger: 'blur'},
          {validator: validateSettleDeposit, trigger: 'blur'}
        ]
      },
      defServiceFeeObj: {},//默认的技术服务费
    }
  },
  components: {
    UploadImage
  },
  created () {
  },
  mounted () {
    // this.getInlandAreaChildList({id: 100000})
    this.getServiceFee()
    this.initAreaList()
    this.getDeveloperList()
    this.getClubList()
    this.findTourListTour()
  },
  activated () {},
  deactivated() {},
  destroyed () {},
  watch: {
    params: {
      handler(val){
        if (!val.unWithdrawalRate && !val.depositAmount) {
          if (this.$refs["withdrawalForm"]) {
            this.$refs["withdrawalForm"].clearValidate()
          }
        }
        if (!val.accountName && !val.accountBankNm && !val.accountNo) {
          if (this.$refs["bankAccountForm"]) {
            this.$refs["bankAccountForm"].clearValidate()
          }
        }
        if (!val.alipayName && !val.alipayAccount) {
          if (this.$refs["alipayAccountForm"]) {
            this.$refs["alipayAccountForm"].clearValidate()
          }
        }
      },
      deep: true,
    },
  },
  computed: {},
  filters: {
    filterAttachType(val) {
      if (val === 1) {
        return '加价'
      } else if (val === 2) {
        return '减免'
      } else {
        return '不附加'
      }
    },
    filterServiceFeeType(val) {
      if (val === 0) {
        return '默认设置'
      } else if (val === 1) {
        return '定额'
      } else {
        return '比例'
      }
    },
  },
  methods: {
    formatterAttachType(val) {
      if (val.attachType === 1) {
        return '加价'
      } else if (val.attachType === 2) {
        return '减免'
      } else {
        return '不附加'
      }
    },
    formatterSettleDepositType(val) {
      if (val.settleDepositType === 0) {
        return '全额'
      } else {
        return '订金'
      }
    },
    formatterSettleDepositFun(val) {
      if (val.settleDepositType === 0) {
        return '--'
      } else if (val.settleDepositType === 1) {
        return '比例'
      } else {
        return '定额'
      }
    },
    formatterSettleDeposit(val) {
      if (val.settleDepositType === 0) {
        return '--'
      } else if (val.settleDepositType === 1) {
        return val.settleDeposit + '%'
      } else {
        return val.settleDeposit
      }
    },
    formatterFinalPaymentCycle(val) {
      if (val.settleDepositType === 0) {
        return '--'
      } else {
        return `出团前${val.finalPaymentCycle}天`
      }
    },
    formatterPressAmountType(val) {
      if (val.pressAmountType === 0) {
        return '不压款'
      } else {
        return '压款'
      }
    },
    formatterPressAmountFun(val) {
      if (val.pressAmountType === 0) {
        return '--'
      } else if (val.pressAmountType === 1) {
        return '百分比'
      } else {
        return '定额'
      }
    },
    formatterPressAmount(val) {
      if (val.pressAmountType === 0) {
        return '--'
      } else if (val.pressAmountType === 1) {
        return val.pressAmount + '%'
      } else {
        return val.pressAmount
      }
    },
    formatterPresslPaymentCycle(val) {
      if (val.pressAmountType === 0) {
        return '--'
      } else {
        return `出团后${val.presslPaymentCycle}天`
      }
    },
    //获取技术服务费默认值
    async getServiceFee() {
      let res = await getServiceFee()
      this.params.domesticServiceType = res.domesticServiceType
      this.params.domesticServiceFee = res.domesticServiceFee
      this.params.internationalServiceType = res.internationalServiceType
      this.params.internationalServiceFee = res.internationalServiceFee
      this.params.specialServiceType = res.specialServiceType
      this.params.specialServiceFee = res.specialServiceFee
    },
    // 获取开发者列表 - （机票经理）
    getDeveloperList() {
      web_common_user_getDeveloper().then((res) => {
        this.developerList = res.getAllDeveloperResult
      })
    },
    // 获取中间社列表
    getClubList() {
      web_clud_list().then(res => {
        this.clubList = res.results
      })
    },
    // 获取旅行线路
    findTourListTour() {
      web_circuit_findTouristTour().then(res => {
        this.tourList = res.result
      })
    },
    async initAreaList() {
      const params = {
        id: 0
      }
      let res = await web_common_area_getInlandAreaChildListByParentId(params)
      let id = res[0].id
      this.getInlandAreaChildList({id})
    },
    // 获取地区
    async getInlandAreaChildList (params, index = 0) {
      let res = await web_common_area_getInlandAreaChildListByParentId(params)
      let inLandList = res
      inLandList.forEach(value => {
        value.value = value.id
        value.label = value.areaName
      })
      if (index === 0) {
        this.inLandList = res
      } else if (index === 1) {
        this.provinceList = res
      } else {
        this.cityList = res
      }
    },
    // 下拉大区改变时
    changeInLand(value) {
      if (value === '') {
        this.clearAreaList(2)
        this.params.areaRegionName = ''
      } else {
        for (let i = 0, l = this.inLandList.length; i < l; i++) {
          if (this.inLandList[i].id === value) {
            this.params.areaRegionName = this.inLandList[i].areaName
            break
          }
        }
        this.clearAreaList(2)
        this.getInlandAreaChildList({id: value}, 1)
      }
    },
    // 改变省份时
    changeProvince(value) {
      if (value === '') {
        this.clearAreaList(3)
        this.params.areaProvinceName = ''
      } else {
        for (let i = 0, l = this.provinceList.length; i < l; i++) {
          if (this.provinceList[i].id === value) {
            this.params.areaProvinceName = this.provinceList[i].areaName
            break
          }
        }
        this.clearAreaList(3)
        this.getInlandAreaChildList({id: value}, 2)
      }
    },
    // 改变城市时
    changeCity(value) {
      if (value === '') {
        this.params.areaCityName = ''
      } else {
        for (let i = 0, l = this.cityList.length; i < l; i++) {
          if (this.cityList[i].id === value) {
            this.params.areaCityName = this.cityList[i].areaName
            break
          }
        }
      }
    },
    clearAreaList(listNum) {
      for (let i = 1, l = 4; i < l; i++) {
        if (i === 1 && i >= listNum) {
          this.params.areaRegion = ''
        }
        if (i === 2 && i >= listNum) {
          this.params.areaProvince = ''
          this.provinceList = []
        }
        if (i === 3 && i >= listNum) {
          this.params.areaCity = ''
          this.cityList = []
        }
      }
    },
    //获取产品图片上传的地址
    uploadChange(file, name, from, arrListName, data) {
      this.params[arrListName] = [data.url]
      this.params[name] = data.url
    },
    handleRemoveImg(name, arrListName, from) {
      this.params[name] = ''
      this.params[arrListName] = []
    },
    handlePictureCardPreview(file) {
      this.fileImg.url = file
      this.fileImg.show = true
    },
    // 选择开发人
    changeDeveloper(value) {
      for (let i = 0, l = this.developerList.length; i < l; i++) {
        if (this.developerList[i].id === value) {
          this.params.developer = this.developerList[i].realName
          break
        }
      }
    },
    // 选择经理时
    changeAirAccountManager(value) {
      for (let i = 0, l = this.developerList.length; i < l; i++) {
        if (this.developerList[i].id === value) {
          this.params.airAccountManager = this.developerList[i].realName
          break
        }
      }
    },
    changeAccountManager(value) {
      for (let i = 0, l = this.developerList.length; i < l; i++) {
        if (this.developerList[i].id === value) {
          this.params.accountManager = this.developerList[i].realName
          break
        }
      }
    },
    setLine() {
      this.reactTourParams()
      this.tourParams = Object.assign({}, this.tourParams, this.addParams)
      this.dialogVisible = true
      this.dialogStatus = 'create'
      this.$nextTick(() => {
        this.$refs['form10-1'].clearValidate()
        this.$refs['form10-2'].clearValidate()
        this.$refs['form10-3'].clearValidate()
        this.$refs['form10-4'].clearValidate()
        this.$refs['form10-5'].clearValidate()
      })
    },
    reactTourParams() {
      this.tourParams = {
        attachAdultAmount: '',
        attachAdultPercent: '',
        attachAgedAmount: '',
        attachAgedPercent: '',
        attachAmountType: 1,
        attachChildAmount: '',
        attachChildBedAmount: '',
        attachChildBedPercent: '',
        attachChildPercent: '',
        attachType: 1,
        isDelete: 0,
        lineId: '',
        lineName: '',
        lineType: '',
        serviceFeePercent: '',
        serviceFeeType: 0,

        settleDepositType: 0, // 定金收取方式 0 -不收 2定额 1比例
        settleDeposit: 0, // 定金
        finalPaymentCycle: 0, // 尾款周期，出团前
        finalCycleUnit: 1, // 尾款周期单位 1-天 2-周 3-月 默认天
        finalPaymentType: 1, // 默认出团前 自增
        isHasSettle: 1, // 收定金还是全款 1-定金 0全款 自增
        settleType: 2, // 1比例，2定额 自增

        pressAmountType: 0, // 压款收取方式 0-不压 1 比例 2定额
        pressAmount: 0, // 压款金额
        presslPaymentCycle: 0, // 压款周期，出团后
        presslCycleUnit: 1, // 压款周期单位 1-天 2-周 3-月 默认天
        pressPaymentType: 1, // 默认出团后 自增
        isHasPress: 1, // 是否压款 0-不压 1压 自增
        pressType: 2, // 1比例，2定额 自增
      }
    },
    handleClose() {
      this.dialogVisible = false
    },
    changeTourLine(val) {
      for (let i = 0, l = this.tourList.length; i < l; i++) {
        if (this.tourList[i].id === val) {
          this.tourParams.lineName = this.tourList[i].lineName
          this.tourParams.lineType = this.tourList[i].lineType
          // this.tourList[i].disabled = true // 禁用
          break
        }
      }
    },
    submitTour() {
      let formDataList = ['form10-1', 'form10-2', 'form10-3', 'form10-4', 'form10-5']
      let status = [-1, -1, -1, -1, -1]
      formDataList.map((value, index) => {
        this.$refs[value].validate((valid) => {
          valid ? status[index] = 1 : status[index] = -1
          if (status.indexOf(-1) === -1) {
            if (this.tourParams.isHasSettle === 0) {
              this.tourParams.settleDepositType = 0
            } else {
              this.tourParams.settleDepositType = this.tourParams.settleType === 1 ? 1 : 2
            }
            if (this.tourParams.isHasPress === 0) {
              this.tourParams.pressAmountType = 0
            } else {
              this.tourParams.pressAmountType = this.tourParams.pressType === 1 ? 1 : 2
            }
            let params = Object.assign({}, this.tourParams)
            console.log(params)
            this.params.circuitListArr.push(params)
            let circuitList = this.params.circuitListArr.concat()
            this.params.circuitList = JSON.stringify(circuitList)
            this.dialogVisible = false
            for (let i = 0, l = this.tourList.length; i < l; i++) {
              if (this.tourList[i].id === params.lineId) {
                this.tourList[i].disabled = true // 禁用
                break
              }
            }
          }
        })
      })
    },
    submitEditTour() {
      let formDataList = ['form10-1', 'form10-2', 'form10-3', 'form10-4', 'form10-5']
      let status = [-1, -1, -1, -1, -1]
      formDataList.map((value, index) => {
        this.$refs[value].validate((valid) => {
          valid ? status[index] = 1 : status[index] = -1
          if (status.indexOf(-1) === -1) {
            if (this.tourParams.isHasSettle === 0) {
              this.tourParams.settleDepositType = 0
            } else {
              this.tourParams.settleDepositType = this.tourParams.settleType === 1 ? 1 : 2
            }
            if (this.tourParams.isHasPress === 0) {
              this.tourParams.pressAmountType = 0
            } else {
              this.tourParams.pressAmountType = this.tourParams.pressType === 1 ? 1 : 2
            }
            let params = Object.assign({}, this.tourParams)
            for (let i = 0, l = this.params.circuitListArr.length; i < l; i++) {
              if (this.params.circuitListArr[i].lineId === params.lineId) {
                // this.params.circuitListArr[i] = params
                this.params.circuitListArr.splice(i, 1, params)
                break
              }
            }
            let circuitList = this.params.circuitListArr.concat()
            this.params.circuitList = JSON.stringify(circuitList)
            this.dialogVisible = false
            for (let i = 0, l = this.tourList.length; i < l; i++) {
              if (this.tourList[i].id === params.lineId) {
                this.tourList[i].disabled = true // 禁用
                break
              }
            }
          }
        })
      })
    },
    delTourList(index, val) {
      this.params.circuitListArr.splice(index, 1)
      let circuitList = this.params.circuitListArr.concat()
      this.params.circuitList = JSON.stringify(circuitList)
      for (let i = 0, l = this.tourList.length; i < l; i++) {
        if (this.tourList[i].id === val.lineId) {
          this.tourList[i].disabled = false // 解除禁用
          break
        }
      }
    },
    editTour(index, val) {
      this.reactTourParams()
      this.tourParams = Object.assign({}, this.tourParams, val)
      this.dialogVisible = true
      this.dialogStatus = 'update'
    },
    submit() {
      let status
      let formDataList
      if (this.params.applicationType === '1') {
        status = [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1]
        formDataList = ['form1', 'form2', 'form5', 'form6', 'form7', 'form8-1', 'form8-2', 'form8-3', 'form8-4', 'bankAccountForm', 'alipayAccountForm']
      } else if (this.params.applicationType === '2') {
        status = [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1]
        formDataList = ['form1', 'form2', 'form3', 'form4', 'form7', 'bankAccountForm', 'alipayAccountForm', 'form9-1', 'form9-2', 'form9-3', 'form9-4']
      } else {
        status = [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1]
        formDataList = ['form1', 'form2', 'form3', 'form4', 'form5', 'form6', 'form7', 'form8-1', 'form8-2', 'form8-3', 'form8-4', 'bankAccountForm', 'alipayAccountForm', 'form9-1', 'form9-2', 'form9-3', 'form9-4']
      }
      formDataList.map((value, index) => {
        this.$refs[value].validate((valid) => {
          valid ? status[index] = 1 : status[index] = -1
          if (status.indexOf(-1) === -1) {
            // 开始提交
            this.loading = true
            if (this.addParams.isHasSettle === 0) {
              this.addParams.settleDepositType = 0
            } else {
              this.addParams.settleDepositType = this.addParams.settleType === 1 ? 1 : 2
            }
            if (this.addParams.isHasPress === 0) {
              this.addParams.pressAmountType = 0
            } else {
              this.addParams.pressAmountType = this.addParams.pressType === 1 ? 1 : 2
            }
            let params = Object.assign({}, this.params, this.addParams, this.fileParams)

            params.supCanConfigList.forEach((item,index) => {
              delete item.allDayForRefPnrBoolean;
              delete item.allDayForCanPnrBoolean;
              delete item.refPnrTime;
              delete item.canPnrTime;
            });

            web_managerSupplier_register(params).then(res => {
              this.loading = false;
              this.$alert('供应商创建成功', '温馨提示', {
                confirmButtonText: '确定',
                callback: action => {
                  // 跳转
                  // this.$router.go();
                  this.$router.push({
                    name: 'admin-flight-supplier-list',
                  })
                }
              });

              this.params = {
                //submitPassword: '',
                businessLicenseArr: [], // 营业执照图片数组
                travelBusinessLicensePicArr: [],
                idCardFrontArr: [],
                idCardReverseArr: [],
                commonSealArr: [], // 公章照片数组
                brandLogoArr: [], // logo照片数组
                iataProveArr: [],
                guaranteeArr: [],
                financeFaxTit: '',
                financeFaxNum: '',
                circuitListArr: [],

                userName: '',
                password: '',
                phone: '',
                companyName: '',
                areaRegion: '',
                areaProvince: '',
                areaCity: '',
                areaRegionName: '', // 公司所在大区名字
                areaProvinceName: '', // 公司所在省份名字
                areaCityName: '', // 公司所在城市名字
                companyAddress: '',
                businessLicenseNum: '',
                businessLicensePic: '',
                idCard: '',
                idCardFront: '',
                idCardReverse: '',
                tenpay: '', // 财付通账号
                alipay: '', // 支付宝账号
                yeepay: '', // 易宝账号
                clubId: '', // 中间社id
                financeFax: '',
                supplierLevel: '', // 供应商等级：与等级表关联
                roleId: '',
                travelLinkman: '',
                travelPhone: '',
                travelQq: '',
                travelEmail: '',
                airLinkman: '',
                airPhone: '',
                airEmail: '',
                airQq: '',
                developerId: '',
                developer: '',
                travelBusinessLicenseNum: '',
                travelBusinessLicensePic: '',
                brandName: '',
                brandLogo: '',
                commonSeal: '',
                iataProve: '',
                guarantee: '',
                airTicketType: '',
                applicationType: '1',
                domesticServiceType: '',
                domesticServiceFee: '',
                internationalServiceType: '',
                internationalServiceFee: '',
                airAccountManagerId: '',
                airAccountManager: '',
                travelPaymentType: '',
                travelPaymentDays: '',
                ticketPaymentType: '',
                ticketPaymentDays: '',
                hotelPaymentType: '',
                hotelPaymentDays: '',
                serviceFeePercent: 0,
                serviceFeeType: '2',
                accountManagerId: '',
                accountManager: '',
                isOperationFlow: 0,
                ofExpirationTimeTrue: '',
                paymentServiceRate: '',
                circuitList: '',
                unWithdrawalRate: '', // 不可提现比例
                depositAmount: '', // 压款金额
                accountName: '', // 银行账户名
                accountBankNm: '', // 银行开户行
                accountNo: '', // 银行账号
                alipayName: '', // 支付宝账户名
                alipayAccount: '', // 支付宝账号
              };

              this.fileParams = {
                agreementFile: ''
              }

            }).catch(() => {
              this.loading = false
            })
          }
        })
      })
    },
    setFax() {
      this.params.financeFax = `${this.params.financeFaxTit}-${this.params.financeFaxNum}`
    },
    changeAirTime(val) {
      this.params[val + 'Time'] = this.params[val] + ':00'
    },
    filterBlank(label,val) {
      let str = val
      let params = this.params
      params[label] = str.replace(/\s+/g,'')
      this.params = params
    },
    handleSuccesspictureB(file, fileList) {
      const size = file.size / 1024 / 1024;
      if (size > 10) {
        return this.$message.error("文件大小不能超过10M");
      }
      const _this = this;
      const aOssClient = new OssClient();
      aOssClient.multipartUpload({
        file: file.raw,
        progress: function* (p) {

        }
      })
          .then(function (data) {
            _this.$message.success("文件上传成功");
            _this.fileParams.agreementFile = data.url;
            _this.$refs.uploadB.clearValidate();
          })
    },
    removePDFB() {
      this.fileParams.agreementFile = "";
    },
    openPDFB(file) {
      window.open(this.fileParams.agreementFile);
    },
    cancelChangeClick(val){
      const __this = this;
      if(val===1){
        // 取位
        if(__this.params.allDayForCanPnrType){
          __this.params.cancelTime=['00:00:00','23:59:59']
        }
        return;
      }
      if(val===2){
        // 退票取位
        if(__this.params.allDayForRefPnrType){
          __this.params.refCancelTime=['00:00:00','23:59:59']
        }
        return;
      }
    },
    cancelChangeClickCanPnr(index) {
      let allDayForCanPnrBoolean = this.params.supCanConfigList[index].allDayForCanPnrBoolean;
      this.params.supCanConfigList[index].allDayForCanPnr = allDayForCanPnrBoolean ? 1 : 0;
      if (this.params.supCanConfigList[index].allDayForCanPnr == 1) {
        this.params.supCanConfigList[index].canPnrTime = ['00:00:00','23:59:59'];
        this.params.supCanConfigList[index].canPnrTimeBegin = '00:00:00';
        this.params.supCanConfigList[index].canPnrTimeEnd = '23:59:59';
      }
    },
    cancelBlurPickerCanPnrTime(index){
      let canPnrTime = this.params.supCanConfigList[index].canPnrTime;
      if (canPnrTime[0] == '00:00:00' && canPnrTime[1] == '23:59:59') {
        this.params.supCanConfigList[index].allDayForCanPnr = 1;
        this.params.supCanConfigList[index].allDayForCanPnrBoolean = true;
      }
    },
    cancelChangePickerCanPnrTime(index){
      let canPnrTime = this.params.supCanConfigList[index].canPnrTime;
      if (!canPnrTime) {
        this.params.supCanConfigList[index].allDayForCanPnr = 0;
        this.params.supCanConfigList[index].allDayForCanPnrBoolean = false;
        return
      }
      this.params.supCanConfigList[index].canPnrTimeBegin = canPnrTime[0];
      this.params.supCanConfigList[index].canPnrTimeEnd = canPnrTime[1];
      if (canPnrTime[0] == '00:00:00' && canPnrTime[1] == '23:59:59') {
        this.params.supCanConfigList[index].allDayForCanPnr = 1;
        this.params.supCanConfigList[index].allDayForCanPnrBoolean = true;
      }
    },
    cancelChangeClickRefPnr(index) {
      let allDayForRefPnrBoolean = this.params.supCanConfigList[index].allDayForRefPnrBoolean;
      this.params.supCanConfigList[index].allDayForRefPnr = allDayForRefPnrBoolean ? 1 : 0;
      if (this.params.supCanConfigList[index].allDayForRefPnr == 1) {
        this.params.supCanConfigList[index].refPnrTime = ['00:00:00','23:59:59'];
        this.params.supCanConfigList[index].refPnrTimeBegin = '00:00:00';
        this.params.supCanConfigList[index].refPnrTimeEnd = '23:59:59';
      }
    },
    cancelBlurPickerRefPnrTime(index){
      let canPnrTime = this.params.supCanConfigList[index].canPnrTime;
      if (canPnrTime[0] == '00:00:00' && canPnrTime[1] == '23:59:59') {
        this.params.supCanConfigList[index].allDayForCanPnr = 1;
        this.params.supCanConfigList[index].allDayForCanPnrBoolean = true;
      }
    },
    cancelChangePickerRefPnrTime(index){
      let refPnrTime = this.params.supCanConfigList[index].refPnrTime;
      if (!refPnrTime) {
        this.params.supCanConfigList[index].allDayForRefPnr = 0;
        this.params.supCanConfigList[index].allDayForRefPnrBoolean = false;
        return
      }
      this.params.supCanConfigList[index].refPnrTimeBegin = refPnrTime[0];
      this.params.supCanConfigList[index].refPnrTimeEnd = refPnrTime[1];
      if (refPnrTime[0] == '00:00:00' && refPnrTime[1] == '23:59:59') {
        this.params.supCanConfigList[index].allDayForRefPnr = 1;
        this.params.supCanConfigList[index].allDayForRefPnrBoolean = true;
      }
    },
    cancelChangePicker(val){
      const __this = this;
      let supplier = JSON.parse(JSON.stringify(__this.params))
      if(val === 1){
        // 取位
        const t = __this.params.cancelTime;
        if(t[0]==='00:00:00' && t[1] === '23:59:59'){
          supplier.allDayForCanPnrType=true;
          supplier.allDayForCanPnr=1;
        } else {
          supplier.allDayForCanPnrType=false;
          supplier.allDayForCanPnr=0;
        }
        __this.params = supplier;
        return;
      }
      if(val === 2){
        // 退票取位
        let t2 =  __this.params.refCancelTime;
        if(t2[0]==='00:00:00' && t2[1] === '23:59:59'){
          supplier.allDayForRefPnrType=true;
          supplier.allDayForRefPnr = 1;
        } else {
          supplier.allDayForRefPnrType=false;
          supplier.allDayForRefPnr = 0;
        }
        __this.params = supplier;
        return;
      }
    },
  }
}



/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
