import moment from 'moment';
import web_btoc_apply_statusEnum from '@/lib/data-service/default/web_btoc_apply_statusEnum';
import web_btoc_apply_list from '@/lib/data-service/default/web_btoc_apply_list';

export default {
    data() {
        return {
            moment: moment,
            loading: false,
            form: {
                applyNo: null,
                buyerCompanyName: null,
                gmtCreate: null,
                btocVersion: '',
                status: 1,
                currentPage: 1,
                pageSize: 10,
            },
            totalCount: 0,
            tableData: [],
            allocation: {
                btocVersionList: [],
            },

        }
    },
    methods: {
        getStatusEnum() {
            const __this = this;
            web_btoc_apply_statusEnum().then(res => {
                __this.allocation.btocVersionList = res.data.btocVersionEnum
            })
        },
        setTableList() {
            const __this = this;
            __this.loading = true;
            web_btoc_apply_list(__this.form).then(data => {
                __this.loading = false;
                __this.tableData = data.pageResult.pageData;
                __this.totalCount = data.pageResult.totalCount;
            }).catch(err => {
                __this.loading = false;
            })
        },
        changeStatus(status) {
            const __this = this;
            __this.tableData = [];
            __this.$set(__this.form, 'status', status);
            __this.setTableList()
        },
        // 审核
        onDetail(item) {
            const __this = this;
            __this.$router.push({
                name: 'admin-buyer-mall-examine',
                query: {
                    id: item.id
                },
            })
        },
        handleCurrentChange(page) {
            const __this = this;
            __this.form.currentPage = page;
            __this.setTableList()
        },
        handleSizeChange(size) {
            const __this = this;
            __this.form.pageSize = size;
            __this.form.currentPage = 1;
            __this.setTableList();
        },
        // 查询
        onSearch() {
            const __this = this;
            __this.form.currentPage = 1;
            __this.setTableList();
        },
        // 查看原因
        showRejectionReason(row) {
            const __this = this;
            __this.$alert(row.rejectionReason, '拒绝原因', {
                confirmButtonText: '确定',
                closeOnClickModal: true,
            });
        },
        // 重置
        onReset() {
            const __this = this;
            __this.form = Object.assign(__this.form, {
                applyNo: null,
                buyerCompanyName: null,
                gmtCreate: null,
                btocVersion: '',
                currentPage: 1,
                pageSize: 10,
            });
            __this.setTableList();
        },
    },
    activated() {
        const __this = this;
        __this.getStatusEnum();
        __this.setTableList()
    },

}
