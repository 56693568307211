// web_managerSupplier_getSupplierBoundBuyerList 查询供应商绑定关系和非绑定关系的分销商

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口地址： http://192.168.0.15:8080/doc.html#/所有接口/manager-supplier-controller/updateOfExpirationTimeUsingPOST

export default function web_managerSupplier_getSupplierBoundBuyerList(pParameter) {
  if (!pParameter) pParameter = {};
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/managerSupplier/getSupplierBoundBuyerList'
  params.data = pParameter
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
