import web_buyer_findOne from '@/lib/data-service/default/web_buyer_findOne';
import web_tourismFullReduction_list from '@/lib/data-service/default/web_tourismFullReduction_list';
import web_tourismFullReduction_statistics from '@/lib/data-service/default/web_tourismFullReduction_statistics';
import get_sample_data_entity from '@/lib/data-service/default/get_sample_data_entity';
import web_tourismFullReduction_open from '@/lib/data-service/default/web_tourismFullReduction_open';
import web_tourismFullReduction_close from '@/lib/data-service/default/web_tourismFullReduction_close';

import history_tag_api from '@/component/admin-layout/1.0.0/component/history-tag/api';
import breadcrumb_api from '@/component/admin-layout/1.0.0/component/breadcrumb/api';
import Pagination from '@/component/base-pagination/index.vue'
import merge from 'webpack-merge'

export default {
    components: {
        Pagination
    },
    directives: {
        positiveInt: { // 自定义指令，正整数
            bind: function (el) {
                el.handler = function () {
                    el.value = Number(el.value.replace(/\D+/, ''))
                };
                el.addEventListener('input', el.handler)
            },
            unbind: function (el) {
                el.removeEventListener('input', el.handler)
            }
        }
    },
    data() {
        return {
            loading: false,
            params: {
                // 分页所需参数
                total: 0,
                currentPage: 1,
                pageSize: 10,
                // 分页 end
            },
            buyerInfo: {}, // 分销商信息
            discountsRecordList: [], // 优惠记录
            usedLimitAmountTotal: 0, // 分销商已使用满减金额的总和
            availableLimitAmount: 0, // 分销商当前可用金额
            discountsActiveList: [] // 优惠活动
        }
    },
    methods: {
        // 格式化数据
        carryRulesFormatter(val) {
            let type = '';
            if (val.carryRules === 0) {
                type = '进位到元'
            } else {
                type = '进位到分'
            }
            return type
        },
        buyerStatusFormatter(val) {
            let type = '';
            if (val.buyerStatus === 0 || val.buyerStatus === '0') {
                type = '待审核'
            } else if (val.buyerStatus === 1 || val.buyerStatus === '1') {
                type = '审核失败'
            } else if (val.buyerStatus === 2 || val.buyerStatus === '2') {
                type = '部分启用'
            } else if (val.buyerStatus === 3 || val.buyerStatus === '3') {
                type = '启用'
            } else if (val.buyerStatus === 4 || val.buyerStatus === '4') {
                type = '平台禁用'
            } else if (val.buyerStatus === 5 || val.buyerStatus === '5') {
                type = '已删除'
            } else if (val.buyerStatus === 6 || val.buyerStatus === '6') {
                type = '未签约'
            } else if (val.buyerStatus === 7 || val.buyerStatus === '7') {
                type = '签约失败'
            } else if (val.buyerStatus === 8 || val.buyerStatus === '8') {
                type = '待设置合同文件'
            } else {
                type = '平台禁用'
            }
            return type
        },
        realStatusFormatter(val) {
            let type = '';
            if (val.realStatus === 0) {
                type = '未实名'
            } else if (val.realStatus === 1) {
                type = '已实名'
            } else {
                type = ''
            }
            return type
        },
        offerStatusFormatter(val) {
            let type = '';
            if (val.preferentialState === 0) {
                type = '无效'
            } else if (val.preferentialState === 1) {
                type = '有效'
            } else {
                type = ''
            }
            return type
        },
        // 格式化数据 end
        picLoading() {
            let _this = this
            let picSetTimeOut = ''
            picSetTimeOut = setTimeout(() => {
                let formPicArr = document.getElementsByClassName('form-pic')
                let formPicStatus = []
                for (let i in formPicArr.length) {
                    formPicStatus.push(-1)
                }
                formPicArr.forEach((value, index) => {
                    let complete = value.complete
                    formPicStatus[index] = complete ? 1 : -1
                })
                if (formPicStatus.indexOf(-1) >= 0) {
                    this.picLoading()
                } else {
                    _this.loading = false
                }
            }, 500)
        },
        getBuyerInfo(buyerId) { // 获取分销商信息
            const __this = this;
            __this.loading = true
            web_buyer_findOne({buyerId}).then(res => {
                __this.buyerInfo = res.result
                this.$nextTick(() => {
                    this.picLoading()
                });
                __this.loading = false
            }).catch(() => {
                __this.loading = false
            });
        },

        getDiscountsRecordList(buyerId) { // 获取已开通优惠记录
            const __this = this;
            __this.loading = true;
            web_tourismFullReduction_statistics({buyerId}).then(res => {
                __this.usedLimitAmountTotal = res.usedLimitAmountTotal;
                __this.availableLimitAmount = res.availableLimitAmount;
                for(let item of res.list) {
                    item.reductionGradientMsg = item.reductionGradientMsg.split("\r\n");
                    item.rule = item.rule.split("\r\n");
                }
                __this.discountsRecordList = res.list;
                __this.loading = false
            }).catch(() => {
                __this.loading = false
            });
        },
        // 分页
        handleSizeChange(pageSize) {
            this.params.pageSize = pageSize;
            this.req_order_list(this.params, 'pageSize');
        },

        getDiscountsActiveList() { // 获取优惠列表
            const __this = this;
            __this.loading = true;
            web_tourismFullReduction_list().then(res => {
                for(let item of res.tourismFullReductionResult) {
                    item.checked = false;
                    item.money = '';
                    item.reductionGradientMsg = item.reductionGradientMsg ? item.reductionGradientMsg.split("\r\n") : '';
                    item.rule = item.rule.split("\r\n");
                }
                __this.discountsActiveList = res.tourismFullReductionResult;
                __this.loading = false
            }).catch(() => {
                __this.loading = false
            });
        },
        rowClick(row) {
            console.log(row)
            this.discountsActiveList.forEach(item => {
                item.checked = item.id === row.id ? !item.checked : false;
                item.money = ''
            });
        },
        to_discounts_open(type, row) {
            const __this = this;
            __this.$router.push({
                name: 'admin-discounts-detail',
                query: {
                    type: type,
                    buyerId: __this.$route.query.buyerId
                }
            })
        },
        inputChange(row) { // CheckBox 选择优惠
            console.log(row)
            this.discountsActiveList.forEach(item => {
                item.checked = item.id === row.id ? item.checked : false;
                item.money = ''
            });
        },
        confirmOpen() { // 确认开通优惠
            const __this = this;
            let activeId = 0,
                limitAmount = 0,
                isChecked = false;
            for(let item of __this.discountsActiveList) {
                isChecked = item.checked;
                activeId = item.id;
                limitAmount = item.money;
                if (item.checked) break;
            }
            if (!isChecked) {
                __this.$message({showClose: true, message: '请选择一项优惠活动', type: 'warning'});
                return;
            }
            if (!limitAmount || limitAmount === '') {
                __this.$message({showClose: true, message: '请输入优惠总额度', type: 'warning'});
                return;
            }
            const query = {
                companyId: __this.$route.query.buyerId,
                tourismFullReductionId: activeId,
                limitAmount: limitAmount
            };
            __this.$confirm('是否确认开通优惠?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                web_tourismFullReduction_open(query).then(res => {
                    if (res.code === '000000') {
                        __this.$message({showClose: true, message: '开通优惠成功', type: 'success',
                            onClose:()=>{
                                __this.goBack();
                            }
                        });
                    }
                    __this.loading = false
                })
            }).catch(() => {
                __this.loading = false
            })
        },
        close_discount(row) { // 关闭优惠
            const __this = this;
            const query = {
                companyId: __this.$route.query.buyerId,
            };
            __this.$confirm('确认关闭优惠吗？', '确认关闭', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                web_tourismFullReduction_close(query).then((res) => {
                    if (res.code === '000000') {
                        __this.$message({showClose: true, message: '关闭优惠成功', type: 'success',
                            onClose:()=>{
                                __this.goBack();
                            }
                        });
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            }).catch(() => {

            });
        },
        goBack() { // 返回
            const __this = this;
            history_tag_api.remove_tag_by_route({
                route: __this.$route,
            });
            __this.$router.push({
                name: `admin-buyer-discounts-list`
            });
        }
    },
    created() {},
    mounted() {
        const __this = this;
    },
    activated() {
        const __this = this;
        const buyerId = __this.$route.query.buyerId;
        this.getDiscountsActiveList();
        this.getBuyerInfo(buyerId);
        this.getDiscountsRecordList(buyerId);


        // 动态修改 breadcrumb 面包屑 tab 文字
        get_sample_data_entity({buyerId}).then(function (data) {
            __this.entity = data.entity;

            const tag_name = __this.$route.query.type === 'discount_open' ? '开通优惠' : '优惠详情';
            history_tag_api.modify_tag_name_by_route({
                route: __this.$route,
                tag_name: tag_name,
            });
            breadcrumb_api.set_entity_list_by_get_entity_list({
                get_entity_list(p) {
                    const original_entity_list = p.original_entity_list;
                    if (original_entity_list.length <= 0) return original_entity_list;
                    const last = _.last(original_entity_list);
                    last.name = tag_name;
                    return original_entity_list;
                },
            });
        });
    },
    filters: {
        filterTravelDepositType(val) {
            if (val === 0 || val === null) {
                return '全额'
            } else {
                return '订金'
            }
        },
        filterTravelDepositTypeFun(val) {
            if (val === 0) {
                return ''
            } else if (val === 1) {
                return '比例'
            } else {
                return '定额'
            }
        }
    }
}
