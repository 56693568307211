
const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 获取客服经理接口地址：http://192.168.0.11:8080/doc.html#/yinzhilv_travel/tour-order-controller/getTravelAccountUsingPOST

export default function web_common_user_getDeveloper(pParameter) {
  if (!pParameter) pParameter = {};
  const userId = pParameter.userId || ''
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/common/user/getDeveloper'
  params.data = {
    userId
  }
  params.process_response_data = false
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}
