

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 设置附加金额接口地址： http://219.128.52.2:9999/doc.html#/yinzhilv_travel/product-controller/updateSpecialAdditionUsingPOST
export default function web_product_updateSpecialAddition(pParameter) {
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/product/updateSpecialAddition';
  params.data = pParameter;
  
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
