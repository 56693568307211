// web_circuit_findAll 线路列表

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口地址：http://192.168.0.15:8080/doc.html#/所有接口/circuit-controller/findCircuitUsingPOST

export default function web_circuit_findAll(pParameter) {
  if (!pParameter) pParameter = {};
  const currentPage = pParameter.currentPage || 1
  const pageSize = pParameter.pageSize || 10
  const id = pParameter.id || ''
  const lineName = pParameter.lineName || ''
  const lineType = pParameter.lineType || ''
  const areaOne = pParameter.areaOne || ''
  const areaTwo = pParameter.areaTwo || ''
  const areaThree = pParameter.areaThree || ''
  const FindCircuitRequest = pParameter.FindCircuitRequest || ''

  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/circuit/findAll'
  params.data = {
    currentPage,
    pageSize,
    lineName,
    lineType,
    id,
    areaOne,
    areaTwo,
    areaThree,
    FindCircuitRequest,
  }
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}
