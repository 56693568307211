

const __request = require(`./__request/__request_contentType_json`);

// 火车票订单详情接口地址：http://219.128.52.2:9901/doc.html#/trains/platform-order-controller/getPlatformDetailUsingPOST

export default function trains_platformOrder_getPlatformDetail(pParameter) {
  if (!pParameter) pParameter = {};
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/trains/platformOrder/getPlatformDetail'
  params.data = pParameter;
  params.process_response_data = false
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}
