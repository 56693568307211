// web_buyer_flightBuyer_findWithHoldType
const __request = require(`./__request/__request_contentType_json`);
export default function web_buyer_flightBuyer_findWithHoldType(pParameter) {
    if (!pParameter) pParameter = {};
    let params = {}
    params.method = 'POST'
    params.urlSuffix = '/web/buyer/flightBuyer/findWithHoldType'
    params.data = pParameter
    return new Promise(function(resolve, reject) {
        resolve(__request(params))
    })
}