const __request = require(`./__request/__request_contentType_json`);

// 微商城申请列表
// http://tour-alpha.yinzhilv.com/doc.html#/yinzhilv_travel/btoc-controller/btocApplyListUsingPOST
const request = (pParameter) => {
    if (!pParameter) pParameter = {};

    const applyNo = pParameter.applyNo;
    const buyerCompanyName = pParameter.buyerCompanyName;
    const status = pParameter.status || 1;
    const gmtCreate = pParameter.gmtCreate;
    const btocVersion = pParameter.btocVersion;
    const currentPage = pParameter.currentPage || 1;
    const pageSize = pParameter.pageSize || 10;

    const p = {
        method: 'post',
        urlSuffix: '/web/btoc/apply/list',
        data: {
            applyNo,
            buyerCompanyName,
            status,
            gmtCreate,
            btocVersion,
            currentPage,
            pageSize,
        }
    };
    return new Promise(function (resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = request;