import import_latest_version_module from '@/component/admin-layout/import_latest_version_module';

export default {
  path: '',
  component: () => import_latest_version_module(),
  children: [
    {
      path: '/admin/supplier-bind-list',
      name: 'admin-supplier-bind-list',
      component: () => import(/* webpackChunkName: "page-admin-supplier-management-supplier-bind-list" */ `./index.vue`),
      meta: {
          data_for_admin_layout: {
                    data_for_history_tag: {
                              entity: {
                                        name: "供应商绑定管理"
                              }
                    },
                    data_for_breadcrumb: {
                              entity_list: [
                                        {
                                                  name: "供应商管理"
                                        },
                                        {
                                                  name: "供应商绑定管理"
                                        }
                              ]
                    }
          }
},
    },
  ],
};
