import web_common_user_getDeveloper from '@/lib/data-service/default/web_common_user_getDeveloper'
import web_buyer_getBuyerRelevanceList from '@/lib/data-service/default/web_buyer_getBuyerRelevanceList'
import web_buyer_getSupplierBoundBuyerList from '@/lib/data-service/default/web_buyer_getSupplierBoundBuyerList'
import web_buyer_updateSupplierBoundBuyer from '@/lib/data-service/default/web_buyer_updateSupplierBoundBuyer'
import Pagination from '@/component/base-pagination/index.vue'
export default {
  data () {
    return {
      loading: false,
      params: {
        period: [],

        currentPage: 1,
        pageSize: 10,
        userName: '',
        companyName: '',
        buyerType: '',
        startTime: '',
        endTime: '',
        supplierStatus: '',
        telephone: '',
        accountManagerId: '',
        developerId: '',
        total: 0
      },
      showMore: false,
      userTypeList: [{value: '', label: '全部'}, {value: 1, label: '机票'}, {value: 2, label: '旅游'}, {value: '1+2', label: '机票+旅游'}],
      developerList: [],
      provinceList: [],
      supplierStatusList: [{value: '', label: '全部'}, {value: 2, label: '部分启用'}, {value: 3, label: '启用'}, {value: 4, label: '平台禁用'}],
      userList: [],
      dialogStatus: '',
      textMap: {
        check: '查看明细',
        set: '绑定供应商'
      },
      dialogDetailVisible: false,
      boundBuyerList: [],
      boundBuyerParams: {
        currentPage: 1,
        pageSize: 10,
        id: '',
        isOperationFlow: 1,
        total: 0
      },
      dialogListVisible: false,
      boundBuyerChangeList: [], // 选中的分销商的合集
      showNum: 0
    }
  },
  components: {
    Pagination
  },
  created () {
  },
  mounted () {
    //this.getDeveloperList()
    //this.getBuyerList()
  },
  activated () {
    this.getDeveloperList()
    this.getBuyerList()
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
    handleFilter() {
      this.onSearch()
    },
    showOrHideForm() {
      const showMore = this.showMore
      this.showMore = !showMore
    },
    changeParamsTime(val) {
      if (val === '' || val === null) {
        this.params.startTime = ''
        this.params.endTime = ''
      } else {
        this.params.startTime = val[0]
        this.params.endTime = val[1]
      }
    },
    onSearch() {
      this.params.currentPage = 1
      this.getBuyerList()
    },
    onReset() {
      this.params = {
        period: [],
        currentPage: 1,
        pageSize: 10,
        userName: '',
        companyName: '',
        buyerType: '',
        startTime: '',
        endTime: '',
        supplierStatus: '',
        telephone: '',
        accountManagerId: '',
        developerId: '',
        total: 0
      }
      this.onSearch()
    },
    getBuyerList() {
      this.loading = true
      web_buyer_getBuyerRelevanceList(this.params).then((res) => {
        this.userList = res.pageResult.pageData
        this.params.total = res.pageResult.totalCount
        this.loading = false
      }).catch(() => {
        this.loading = false
      }, new Error(() => {
        this.loading = false
      }))
    },
    // 获取开发者列表 - （机票经理）
    getDeveloperList() {
      web_common_user_getDeveloper().then((res) => {
        this.developerList = res.getAllDeveloperResult
      })
    },
    handleCheck(val) {
      this.dialogStatus = 'check'
      this.dialogDetailVisible = true
      this.resetBoundBuyer()
      this.boundBuyerParams.id = val.buyerId
      this.boundBuyerParams.isOperationFlow = 1
      this.getBoundBuyer()
    },
    handleBind(val) {
      this.dialogStatus = 'set'
      this.dialogListVisible = true
      this.resetBoundBuyer()
      this.boundBuyerParams.id = val.buyerId
      this.boundBuyerParams.isOperationFlow = 2
      this.getBoundBuyer()
    },
    resetBoundBuyer() {
      this.boundBuyerParams = {
        currentPage: 1,
        pageSize: 10,
        id: '',
        isOperationFlow: 1,
        total: 0
      }
    },
    handleDetailDialogClose() {
      this.dialogDetailVisible = false
    },
    handleCancelBind(val) {
      this.$confirm('确定取消绑定吗?', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = {
          supplierIds: val.buyerId,
          buyerIds: this.boundBuyerParams.id,
          boundStatus: 0
        }
        web_buyer_updateSupplierBoundBuyer(params).then(res => {
          if (res.code === '000000') {
            this.$message({
              message: '成功解除绑定',
              type: 'success'
            })
            this.getBoundBuyer()
            // this.handleListDialogClose()
          } else {
            this.$message({
              message: res.msg,
              type: 'warning'
            })
          }
        })
      }).catch(() => {});
    },
    getBoundBuyer() {
      web_buyer_getSupplierBoundBuyerList(this.boundBuyerParams).then(res => {
        this.boundBuyerList = res.pageResult.pageData
        this.boundBuyerParams.total = res.pageResult.totalCount
      })
    },
    handleListDialogClose() {
      this.dialogListVisible = false
      this.boundBuyerChangeList = []
    },
    handleSelectionUserChange (val) {
      this.boundBuyerChangeList = val
    },
    submitBind() {
      if (this.boundBuyerChangeList.length === 0) {
        this.$message('请选择要绑定的分销商')
        return false
      }
      let buyerIds = []
      this.boundBuyerChangeList.forEach(value => {
        buyerIds.push(value.buyerId)
      })
      let params = {
        supplierIds: buyerIds.join(','),
        buyerIds: this.boundBuyerParams.id,
        boundStatus: 1
      }
      web_buyer_updateSupplierBoundBuyer(params).then(res => {
        if (res.code === '000000') {
          this.$message({
            message: '绑定成功',
            type: 'success'
          })
          this.handleListDialogClose()
        } else {
          this.$message({
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
  }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
