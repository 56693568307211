import web_settleAccounts_getSettleAccountsList from '@/lib/data-service/default/web_settleAccounts_getSettleAccountsList'
import web_settleAccounts_getSettlementConfirmation from '@/lib/data-service/default/web_settleAccounts_getSettlementConfirmation'
import Pagination from '@/component/base-pagination/index.vue'
import PreviewImage from '@/page/admin/supplier-management/component/previewImage/index.vue'
export default {
  data () {
    return {
      show: false,
      loading: false,
      params: {
        currentPage: 1,
        pageSize: 10,
        orderNo: '',
        userName: '',
        productNo: '',
        companyName: '',
        productName: '',
        startSettleDate: '',
        endSettleDate: '',
        actualStartSettleDate: '',
        actualEndSettleDate: '',
        total: 0,
        period: [],
        actualPeriod: [],
        settleBillsType: '',
      },
      settleAccountsList: [],
      dialogVisible: false,
      settleParams: {},
      srcList: [],
      settleBillsType: [
        { label: '全部', value: '' },
        { label: '定金结算单', value: 1 },
        { label: '尾款结算单', value: 2 },
        { label: '压款结算单', value: 3 },
        { label: '全额结算', value: 4 },
      ],
    }
  },
  components: {
    Pagination,
    PreviewImage
  },
  created () {
  },
  mounted () {
    this.getSettleAccountsList()
  },
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
    fold_change () {
      this.show = !this.show;
    },
    onSearch() {
      this.params.currentPage = 1
      this.getSettleAccountsList()
    },
    onReset() {
      this.params = {
        currentPage: 1,
        pageSize: 10,
        orderNo: '',
        userName: '',
        productNo: '',
        companyName: '',
        productName: '',
        startSettleDate: '',
        endSettleDate: '',
        actualStartSettleDate: '',
        actualEndSettleDate: '',
        total: 0,
        period: [],
        actualPeriod: []
      }
      this.onSearch()
    },
    changeParamsTime(val) {
      if (val === '' || val === null) {
        this.params.startSettleDate = ''
        this.params.endSettleDate = ''
      } else {
        this.params.startSettleDate = val[0]
        this.params.endSettleDate = val[1]
      }
    },
    changeActualParamsTime(val) {
      if (val === '' || val === null) {
        this.params.actualStartSettleDate = ''
        this.params.actualEndSettleDate = ''
      } else {
        this.params.actualStartSettleDate = val[0]
        this.params.actualEndSettleDate = val[1]
      }
    },
    getSettleAccountsList() {
      this.loading = true
      web_settleAccounts_getSettleAccountsList(this.params).then(res => {
        this.loading = false
        this.settleAccountsList = res.pageResult.pageData
        this.params.total = res.pageResult.totalCount
      }).catch(() => {
        this.loading = false
      })
    },
    handleCheck(val) {
      this.settleParams = {}
      web_settleAccounts_getSettlementConfirmation({settleAccountId: val.id}).then(res => {
        this.settleParams = Object.assign({}, val, res)
        this.dialogVisible = true
      })
    },
    handleClose() {
      this.dialogVisible = false
    },
    showFullPic(val) {
      this.srcList = [val]
    },
  }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
