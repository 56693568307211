

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 待审核产品接口地址： http://219.128.52.2:9999/doc.html#/%E6%89%80%E6%9C%89%E6%8E%A5%E5%8F%A3/tourism-product-controller/findProductUsingPOST
export default function web_supplier_findProduct(pParameter) {
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/supplier/findProduct';
  params.data = pParameter;
  
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
