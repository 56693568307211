// web_common_area_getInlandAreaChildListByParentId

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 根据id获取城市省份接口地址：http://219.128.52.2:9999/doc.html#/%E6%89%80%E6%9C%89%E6%8E%A5%E5%8F%A3/area-controller/getInlandAreaChildListByParentIdUsingPOST

export default function web_common_area_getInlandAreaChildListByParentId(pParameter) {
  if (!pParameter) pParameter = {};
  const id = pParameter.id || 0
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/common/area/getInlandAreaChildListByParentId'
  params.data = {id};
  params.process_response_data = false;
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}

