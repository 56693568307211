import web_buyer_findOne from '@/lib/data-service/default/web_buyer_findOne'
import web_common_user_getDeveloper from '@/lib/data-service/default/web_common_user_getDeveloper'
import web_buyer_audit from '@/lib/data-service/default/web_buyer_audit'
import PreviewImage from '@/page/admin/supplier-management/component/previewImage/index.vue'
import addImgSuffix from '@/lib/common-service/add_img_suffix'
import FullImage from '@/page/admin/supplier-management/component/fullImage/index.vue'
import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client";
import findPaymentChannel from '@/lib/data-service/default/web_buyer_flightBuyer_findPaymentChannel'
import web_buyer_flightBuyer_findWithHoldType from '@/lib/data-service/default/web_buyer_flightBuyer_findWithHoldType'
import web_buyer_flightBuyer_findCarryRule from '@/lib/data-service/default/web_buyer_flightBuyer_findCarryRule'
import getInlandAreaChildListByParentIdResponse
  from "@/lib/data-service/default/web_common_area_getInlandAreaChildListByParentIdResponse.js";

export default {
  data () {
    const validateAmount = (rule, value, callback) => {
      if (this.buyerParams.attachAmountType !== 0) {
        if (value === '') {
          callback(new Error('不能为空'))
        } else {
          if (value >= 0) {
            callback()
          } else {
            callback(new Error('不能为负数'))
          }
        }
      } else {
        callback()
      }
    };
    const validateIncreaseDepositRate = (rule, value, callback) => {
      if (value >= 0) {
        callback()
      } else {
        callback(new Error('不能为负数'))
      }
    };
    return {
      ossClient: new OssClient(),
      buyer: {},
      loading: false,
      id: '',
      srcList: [],
      paymentChannelTypeList: [],
      developerList: [],
      buyerParams: {
        buyerId: '',
        travelAccountManagerId: '', // 旅游经理
        travelAccountManager: '',
        airAccountManagerId: '',
        airAccountManager: '',
        developerId: '',
        developer: '',
        carryRules: 0,
        isOperationFlow: '',

        rebate: 0,//返点
        specialRebate: 0,//机票特殊政策返点
        paymentChannelTypeList: [1,2],//支付方式类型，1.余额支付，2.在线收单

        attachAmountType: '',
        attachAdultAmount: '',
        attachChildAmount: '', // 儿童占床
        noattachChildAmount: '', // 儿童不占床
        attachAgedAmount: '',

        buyerStatus: 3,
        buyerType: '',
        failReason: '',

        agreementFile: '',

        carryRule: 1, // 说明：进位规则，机票专用(1.进位到元 2.进位到分)
        yeePayAccount: '',
        withHoldType: 1, // 说明：代扣关系 1：未配置 2：已配置
      },
      rule: {
        travelAccountManagerId: [
          {required: true, message: '旅游客户经理不能为空', trigger: 'change'}
        ],
        airAccountManagerId: [
          {required: true, message: '机票客户经理不能为空', trigger: 'change'}
        ],
        developerId: [
          {required: true, message: '开发者不能为空', trigger: 'change'}
        ],
        paymentChannelTypeList: [
          {required: true, message: '请选择机票支付方式', trigger: 'change'}
        ],
        attachAdultAmount: [
          {validator: validateAmount, trigger: 'blur'}
        ],
        attachChildBedAmount: [
          {validator: validateAmount, trigger: 'blur'}
        ],
        attachChildAmount: [
          {validator: validateAmount, trigger: 'blur'}
        ],
        attachAgedAmount: [
          {validator: validateAmount, trigger: 'blur'}
        ],
        failReason: [
          {required: true, message: '审核失败原因不能为空', trigger: 'blur'}
        ],
        increaseDepositRate: [
          {required: true, message: '增幅不能为空', trigger: 'blur'},
          {validator: validateIncreaseDepositRate, trigger: 'blur'}
        ],
        finalCycle: [
          {required: true, message: '尾款周期天数不能为空', trigger: 'blur'},
          {validator: validateIncreaseDepositRate, trigger: 'blur'}
        ]
      },
      dialogFullPicVisible: false,
      fullPicSrc: '',

      withHoldTypeList: [],
      findCarryRuleList: [],
      siteList: {}, //地址
      fileListImg: {
        idCardFront: [],
        idCardReverse: [],
        businessLicensePic: [],
        commonSeal: [],
        touristBusinessLicense: [],
      },
      uploadLoading: {
        businessLicensePic: false,
        idCardFront: false,
        idCardReverse: false,
        touristBusinessLicense: false,
        commonSeal: false,
      },
      imageUrl: {
        businessLicensePic: '',
        idCardFront: '',
        idCardReverse: '',
        touristBusinessLicense: '',
        commonSeal: '',
      },
      /*failRule: {
        failReason: [
          {required: true, message: '审核失败原因不能为空', trigger: 'blur'}
        ]
      },
      dialogVisible: false*/
    }
  },
  components: {
    FullImage,
    PreviewImage
  },
  created () {
    this.id = this.$route.query.id
    this._getInlandArea("100000", (res) => {
      this.$set(this.siteList, "area", res);
    });
  },
  mounted () {
    this.findPaymentChannel()
    this.findWithHoldType()
    this.findCarryRule()
  },
  async activated () {
    await this.getDeveloperList()
    await this.getBuyerDetail(this.id)
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {
    filterSupplierStatus(val) {
      if (val === '0') {
        return '待审核'
      } else if (val === '1') {
        return '审核失败'
      } else if (val === '2') {
        return '部分启用'
      } else if (val === '3') {
        return '启用'
      } else if (val === '4') {
        return '平台禁用'
      } else if (val === '5') {
        return '已删除'
      } else if (val === '6') {
        return '未签约'
      } else if (val === '7') {
        return '签约失败'
      } else if (val === '8') {
        return '待设置合同文件'
      } else {
        return ''
      }
    },
    filterAppType(val) {
      if (val === '1') {
        return '机票'
      } else if (val === '2') {
        return '旅游'
      } else {
        return '机票+旅游'
      }
    },
    filterCarryRules(val) {
      let type = ''
      if (val === 0) {
        type = '进位到元'
      } else {
        type = '进位到分'
      }
      return type
    },
    filterImg(val) {
      let result = addImgSuffix(val)
      return result
    }
  },
  methods: {
    // 获取代扣关系枚举
    findWithHoldType() {
      web_buyer_flightBuyer_findWithHoldType().then(res => {
        this.withHoldTypeList = res.resultList
      })
    },
    // 获取进位规则枚举
    findCarryRule() {
      web_buyer_flightBuyer_findCarryRule().then(res => {
        this.findCarryRuleList = res.enumList
      })
    },
    picLoading() {
      let _this = this
      let picSetTimeOut = ''
      picSetTimeOut = setTimeout(() => {
        let formPicArr = document.getElementsByClassName('form-pic')
        let formPicStatus = []
        for (let i in formPicArr.length) {
          formPicStatus.push(-1)
        }
        formPicArr.forEach((value, index) => {
          let complete = value.complete
          formPicStatus[index] = complete ? 1 : -1
        })
        if (formPicStatus.indexOf(-1) >= 0) {
          this.picLoading()
        } else {
          _this.loading = false
        }
      }, 500)
    },
    async getBuyerDetail(buyerId) {
      this.loading = true
      let res = await web_buyer_findOne({buyerId})
      this.buyer = res.result

      // this.businessLicensePic = this.buyer.businessLicensePic
      let developer = ''
      for (let i = 0, l = this.developerList.length; i < l; i++) {
        if (this.developerList[i].id === this.buyer.developerId) {
          developer = this.developerList[i].realName
          break
        }
      }
      let buyerParams = {
        buyerId: this.id,
        travelAccountManagerId: this.buyer.trevelAccountManagerId === '' ? '' : parseInt(this.buyer.trevelAccountManagerId), // 旅游经理
        travelAccountManager: this.buyer.trevelAccountManager,
        airAccountManagerId: this.buyer.airAccountManagerId === '' ? '' : parseInt(this.buyer.airAccountManagerId),
        airAccountManager: this.buyer.airAccountManager,
        developerId: this.buyer.developerId === '' ? '' : this.buyer.developerId,
        developer: developer,
        carryRules: this.buyer.carryRules === null ? 0 : this.buyer.carryRules,
        isOperationFlow: this.buyer.isOperationFlow === null ? 0 : this.buyer.isOperationFlow,
        rebate: this.buyer.rebate,//返点
        specialRebate: this.buyer.specialRebate,//机票特殊政策返点
        paymentChannelTypeList: this.buyer.paymentChannelTypeList,//支付方式类型，1.余额支付，2.在线收单
        attachAmountType: 0,
        attachAdultAmount: '',
        attachChildAmount: '', // 儿童占床
        noattachChildAmount: '', // 儿童不占床
        attachAgedAmount: '',
        buyerStatus: 3,
        buyerType: this.buyer.buyerType,
        failReason: '',

        travelDepositType: this.buyer.travelDepositType === null ? 0 : this.buyer.travelDepositType, // 订金 0-不收取 1比例 2定额
        finalCycle: this.buyer.finalCycle === null ? '' : this.buyer.finalCycle, // 尾款周期，天数
        increaseDepositRate: this.buyer.increaseDepositRate === null ? '' : this.buyer.increaseDepositRate, // 定金增加比
        isHasDeposit: 0, // 是否收订金 0-不收 1收 自增
        depositType: 1, // 收取方式 1-比例 2-定额 自增
        finalCycleType: 1, // 尾期类型 1-出团前

        agreementFile: this.buyer.agreementFile ? this.buyer.agreementFile : '',

        carryRule: this.buyer.carryRule ? this.buyer.carryRule : 1, // 说明：进位规则，机票专用(1.进位到元 2.进位到分)
        yeePayAccount: this.buyer.yeePayAccount || '',
        withHoldType: this.buyer.withHoldType ? this.buyer.withHoldType : 1, // 说明：代扣关系 1：未配置 2：已配置
        companyName: res.result.companyName, // 公司名称
        companyAddress: res.result.companyAddress, // 公司地址
        businessLicenseNum: res.result.businessLicenseNum, // 社会信用代码
        businessLicensePic: res.result.businessLicensePic, // 社会信用代码图片
        idCard: res.result.idCard, // 法人身份证号
        idCardFront: res.result.idCardFront, // 法人身份证 正面图
        idCardReverse: res.result.idCardReverse, // 法人身份证 反面图
        areaCity: res.result.areaCity, // 城市代码
        areaProvince: res.result.areaProvince, // 省份代码
        areaRegion: res.result.areaRegion, // 大区代码
        areaRegionName: res.result.areaRegionName, // 大区代码
        areaProvinceName: res.result.areaProvinceName, // 大区代码
        areaCityName: res.result.areaCityName, // 大区代码
      }
      this.buyerParams = buyerParams
      this.$nextTick(() => {
        this.picLoading()
      })
      // this.buyerParams.buyerId = this.id
    },
    // 获取开发者列表 - （机票经理）
    async getDeveloperList() {
      let res = await web_common_user_getDeveloper()
      this.developerList = res.getAllDeveloperResult
    },
    // 改变机票返点
    handleRebateChange(num) {
      const value = Number(num.replace(/[^\d|^\.|^\-]/gi, ""))
          ? Number(num.replace(/[^\d|^\.|^\-]/gi, ""))
          : 0
      if (value >= 100) {
        this.buyerParams.rebate = Number(100).toFixed(2)
      } else if (value <= -100) {
        this.buyerParams.rebate = Number(-100).toFixed(2)
      } else {
        this.buyerParams.rebate = value.toFixed(2)
      }
    },
    // 改变机票特殊政策返点
    handleSpecialRebateChange(num) {
      const value = Number(num.replace(/[^\d|^\.|^\-]/gi, ""))
          ? Number(num.replace(/[^\d|^\.|^\-]/gi, ""))
          : 0
      if (value >= 100) {
        this.buyerParams.specialRebate = Number(100).toFixed(2)
      } else if (value <= -100) {
        this.buyerParams.specialRebate = Number(-100).toFixed(2)
      } else {
        this.buyerParams.specialRebate = value.toFixed(2)
      }
    },
    //获取支付方式类型枚举
    async findPaymentChannel() {
      const res = await findPaymentChannel()
      this.paymentChannelTypeList = res.paymentChannelTypeList
    },
    // 改变机票支付方式
    handlePaymentChannelTypeListChange(value) {
      this.buyerParams.paymentChannelTypeList = value
    },
    showFullPic(val) {
      this.dialogFullPicVisible = true
      this.fullPicSrc = addImgSuffix(val)
    },
    // 选择开发者时
    changeDeveloper(value) {
      for (let i = 0, l = this.developerList.length; i < l; i++) {
        if (this.developerList[i].id === value) {
          this.buyerParams.developer = this.developerList[i].realName
          break
        }
      }
    },
    // 选择经理时
    changeAirAccountManager(value) {
      for (let i = 0, l = this.developerList.length; i < l; i++) {
        if (this.developerList[i].id === value) {
          this.buyerParams.airAccountManager = this.developerList[i].realName
          break
        }
      }
    },
    // 选择旅游经理时
    changeAccountManager(value) {
      for (let i = 0, l = this.developerList.length; i < l; i++) {
        if (this.developerList[i].id === value) {
          this.buyerParams.travelAccountManager = this.developerList[i].realName
          break
        }
      }
    },
    back() {
      this.$router.go(-1)
    },
    submit() {
      let status
      let formDataList
      if (this.buyerParams.buyerStatus === 1) {
        status = [-1]
        formDataList = ['form11']
      } else if (this.buyer.buyerType === '1') {
        status = [-1, -1]
        formDataList = ['form5', 'form7']
      } else if (this.buyer.buyerType === '2') {
        status = [-1, -1, -1]
        formDataList = ['form5', 'form6-1', 'form6-2']
      } else {
        status = [-1, -1, -1, -1]
        formDataList = ['form5', 'form6-1', 'form6-2', 'form7']
      }
      formDataList.map((value, index) => {
        this.$refs[value].validate((valid) => {
          valid ? status[index] = 1 : status[index] = -1
          if (status.indexOf(-1) === -1) {
            // 开始提交
            this.loading = true
            // this.buyerParams.buyerStatus = 3
            if (this.buyerParams.isHasDeposit === 0) {
              this.buyerParams.attachAmountType = 0
            } else {
              this.buyerParams.attachAmountType = this.buyerParams.depositType
            }
            web_buyer_audit(this.buyerParams).then(res => {
              this.loading = false
              this.$alert('审核成功', '温馨提示', {
                confirmButtonText: '确定',
                callback: action => {
                  // 跳转
                  this.$router.push({
                    name: 'admin-buyer-check-list'
                  })
                }
              })
            }).catch(() => {
              this.loading = false
            })
          }
        })
      })
    },
    handleSuccesspictureB(file, fileList) {
      const size = file.size / 1024 / 1024;
      if (size > 10) {
        return this.$message.error("文件大小不能超过10M");
      }
      const _this = this;
      const aOssClient = new OssClient();
      aOssClient.multipartUpload({
        file: file.raw,
        progress: function* (p) {

        }
      })
          .then(function (data) {
            _this.$message.success("文件上传成功");
            _this.buyerParams.agreementFile = data.url;
            _this.$refs.uploadB.clearValidate();
          })
    },
    removePDFB() {
      this.buyerParams.agreementFile = "";
    },
    openPDFB(file) {
      window.open(this.buyerParams.agreementFile);
    },
    //获取城市列表
    _getInlandArea(id, callback) {
      getInlandAreaChildListByParentIdResponse({id: id}).then((res) => {
        if (res.code === '000000') {
          callback(res.areaTreeResult);
        }
      });
    },
    //选中大区
    _getArea(val) {
      this.selectCityStatu = true;
      this._getInlandArea(val[0], (res) => {
        this.buyerParams.areaProvinces ? (this.buyerParams.areaProvinces = "") : "";
        this.buyerParams.areaCitys ? (this.buyerParams.areaCitys = "") : "";
        this.$set(this.siteList, "province", res);
        this.$set(this.siteList, "city", []);
      });
    },
    //选中省份
    _getProvince(val) {
      this._getInlandArea(val[0], (res) => {
        this.buyerParams.areaCitys ? (this.buyerParams.areaCitys = "") : "";
        this.$nextTick(() => {
          this.$set(this.siteList, "city", res);
        });
      });
    },
    //检验img格式
    detectionImg(file) {
      var reg2 = /^(\s|\S)+(jpg|png|bmp|jpeg)+$/;
      const isLt2M = file.size / 1024 < 10240;
      if (!reg2.test(file.name)) {
        this.$message({type: "warning", message: "图片格式上传错误！"});
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 10M!");
        return false;
      }
    },
    //获取产品图片上传的地址
    uploadChange(file, name, from) {
      this.$set(this.uploadLoading, name, true);
      this.ossClient.multipartUpload({
        file: file.file,
      })
          .then((res) => {
            this.buyerParams[name] = res.url;
            this.$message({type: "success", message: "上传图片成功"});
            if (name === "businessLicensePic") this.$refs[from].validateField("businessLicenseNum");
            if (name === 'touristBusinessLicense') this.$refs[from].validateField("touristBusinessLicenseNumber");
            this.$set(this.imageUrl, name, res.url)

            // this.$refs[from].validateField(name);
            // document.querySelector(`#${name} .el-upload`).style.display = "none";
          })
          .catch((err) => {
            console.log('-------->>>>>>', err)
            this.$message.error('上传图片失败');
          })
          .finally(() => {
            this.$set(this.uploadLoading, name, false);
          });
    },
    /*submitFail() {
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false
    },
    toSubmitFail() {
      this.$refs['form10'].validate((valid) => {
        if (valid) {
          this.buyerParams.buyerStatus = 1
          // 开始提交
          this.loading = true
          web_buyer_audit(this.buyerParams).then(res => {
            this.loading = false
            this.$alert('提交成功', '温馨提示', {
              confirmButtonText: '确定',
              callback: action => {
                this.handleClose()
                // 跳转
                this.$router.push({
                  name: 'admin-discounts-detail'
                })
              }
            })
          }).catch(() => {
            this.loading = false
          })
        }
      })
    }*/
  }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
