import Q from 'q';
import web_personage_personageAuditList from '@/lib/data-service/person/web_personage_personageAuditList.js';

import Citys from '@/component/citys/index.vue';

import moment from 'moment'

export default {
  components: {
    Citys
  },
  data () {
    return {
      form: {
        currentPage: 1,
        pageSize: 10,
        userName: '',
        phone: '',
        eMail: '',
        realName: '',
        idCard: '',
        buyerStatus: 0,
        areaRegionId: '',
        areaProvinceId: '',
        areaCityId: '',
      },
      stateOptions: [
        { label: '待审核', value: 0 },
        { label: '审核失败', value: 1 },
      ],
      pager: {
        pageSizes: [10, 20, 30, 40, 50],
        total: 0,
      },
      list: [],
      dialogVisible: false,
      failReason: '',
      show: false,
      moment: moment,
    }
  },
  methods: {
    req_list (params, type) {
      const _this = this;
      Q.when()
        .then(function () {
          return web_personage_personageAuditList(params);
        })
        .then(function (res) {
          _this.list = res.pageResult.pageData;
          _this.pager.total = res.pageResult.totalCount;
          if (type) document.documentElement.scrollTop = 0;
        })
    },
    getFromArea (val) {
      this.form.areaRegionId = val;
    },
    getFromProvince (val) {
      this.form.areaProvinceId = val;
    },
    getFromCity (val) {
      this.form.areaCityId = val;
    },
    search () {
      this.form.currentPage = 1;
      this.req_list(this.form);
    },
    reset () {
      this.$refs.fromCity.reset_fromArea();
      const buyerStatus = this.form.buyerStatus;
      this.form = {
        currentPage: 1,
        pageSize: 10,
        userName: '',
        phone: '',
        eMail: '',
        realName: '',
        idCard: '',
        buyerStatus,
        areaRegionId: '',
        areaProvinceId: '',
        areaCityId: '',
      };
      this.search(this.form);
    },
    chagne_state (val) {
      this.form.currentPage = 1;
      this.req_list(this.form);
    },
    to_detail (buyerId, userId, type) {
      this.$router.push({
        name: 'admin-person-detail',
        query: {
          type,
          userId,
          buyerId
        }
      })
    },
    look_reason (failReason) {
      this.failReason = failReason;
      this.dialogVisible = true;
    },
    handleClose () {
      this.dialogVisible = false;
      this.failReason = '';
    },
    handleSizeChange (pageSize) {
      this.form.pageSize = pageSize;
      this.req_list(this.form, 'pageSize');
    },
    handleCurrentChange (currentPage) {
      this.form.currentPage = currentPage;
      this.req_list(this.form, 'currentPage');
    }
  },
  mounted () {

  },
  activated () {
    this.req_list(this.form);
  }
}
