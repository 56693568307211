const extend = require(`extend`);
const Q = require(`q`);
const _ = require(`underscore`);
const moment = require(`moment`);

const __get_menu_data = require(`yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/get_menu_data`).default;
const web_btoc_check_pendingNum = require(`@/lib/data-service/default/web_btoc_check_pendingNum`);

const get_menu_data = function (pParameter) {
    if (!pParameter) pParameter = {};
    const $router = pParameter.$router;

    return Q.when()
        .then(function () {

            const list = [];
            list.push(__get_menu_data({
                $router,
            }));
            list.push(web_btoc_check_pendingNum());

            return Q.all(list);
        })
        .spread(function (data_get_menu_data, data_web_btoc_check_pendingNum) {
            const all_menu_list = data_get_menu_data.all_menu_list;
            const count = data_web_btoc_check_pendingNum.count;

            const menu = _.chain(all_menu_list)
                .find(function (m) {
                    const routeName = m.routeName;
                    return routeName === `admin-mall-list`;
                })
                .value()
            ;
            if (menu) {
                menu.count = count;
            }

            return Q.resolve(data_get_menu_data);
        })
        .catch(function (ex) {
            if (!ex) return Q.reject(ex);
            const enable_log_error = !ex.disable_log_error;
            if (!enable_log_error) return Q.reject(ex);
            console.error(ex);
            console.trace();
            if (ex.stack) console.error(ex.stack);
            return Q.reject(ex);
        })
        ;
};

module.exports = get_menu_data;
