// 上传文件组件
import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client/index";
// 获取城市列表
import web_common_area_getStoreAreaTree from '@/lib/data-service/default/web_common_area_getStoreAreaTree'
// 平台轮播图列表
import web_common_advert_list from '@/lib/data-service/default/web_common_advert_list'
// 新增广告
import web_common_advert_add from '@/lib/data-service/default/web_common_advert_add'
// 编辑广告信息
import web_common_advert_updateAdvertById from '@/lib/data-service/default/web_common_advert_updateAdvertById'
// 编辑广告的上/下架状态
import web_common_advert_updateAdvertStateById from '@/lib/data-service/default/web_common_advert_updateAdvertStateById'
// 根据id删除广告
import web_common_advert_deleteAdvertById from '@/lib/data-service/default/web_common_advert_deleteAdvertById'

export default {
    data() {
        return {
            loading: false,
            params: {
                advertPictureType: 1, // 广告图片类型 1：登录页轮播图 2：首页轮播图
                // 分页参数
                currentPage: 1,
                pageSize: 10,
                totalCount: 0
            },
            tableLoading: false,
            tableList: [],
            form: {
                id: '',
                advertPictureType: 1, // 广告图片类型 1：登录页轮播图 2：首页轮播图
                advertTitile: '', // 广告标题
                advertPictureName: '',
                advertPictureUrl: '', // 广告图片地址
                advertForwardType: 1, // 跳转方式 1：按链接 2：按关键字
                advertLink: '', // 跳转链接
                advertKeyword: '', // 搜索关键字
                advertSortNo: '', // 排序字段
                advertIncludeCity: 0,
                areaId: '', // 城市id
                advertWaitSecond: '6', // 等待时间
            },
            cityList: [],
            advertLoading: false,
        };
    },
    methods: {
        get_city_list() {
            const __this = this;
            web_common_area_getStoreAreaTree(__this.params).then(res => {
                __this.cityList = res.storeAreaTreeResults;
            }).finally(() => {
            })
        },
        get_advert_list() {
            const __this = this;
            __this.tableLoading = true;
            web_common_advert_list(__this.params).then(res => {
                __this.tableList = res.pageResult.pageData;
                __this.params.totalCount = res.pageResult.totalCount;

            }).finally(() => {
                __this.tableLoading = false;
            })
        },
        handleSizeChange(size) {
            const __this = this;
            __this.params.currentPage = 1;
            __this.params.pageSize = size;
            __this.get_advert_list()
        },
        handleCurrentChange(page) {
            const __this = this;
            __this.params.currentPage = page;
            __this.get_advert_list()
        },
        change_advert(file) {
            const __this = this;
            const myUpLoad = new OssClient();
            const isJPG_PNG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/jpg' || file.raw.type === 'image/png';
            if (!isJPG_PNG) {
                __this.$message.error('上传图片格式有误!');
                return false;
            }
            __this.advertLoading = true;
            myUpLoad.multipartUpload({
                file: file.raw,
                progress: function* (p) {
                },
            }).then((data) => {
                __this.advertLoading = false;
                if(isJPG_PNG) {
                    __this.form.advertPictureName = file.name;
                    __this.form.advertPictureUrl = data.url;
                }
            }).catch((error) => {
                __this.advertLoading = false;
                this.$message.error("上传失败");
            });
        },
        add_advert() { // 新增广告
            const __this = this;
            if (__this.form.advertTitile === '') {
                __this.$message.warning('请输入标题!');
                return false;
            }
            if (__this.form.advertPictureUrl === '') {
                __this.$message.warning('请上传图片!');
                return false;
            }
            __this.loading = true;
            web_common_advert_add(__this.form).then(res => {
                __this.$message.success('添加成功');
                __this.get_advert_list();

                // 保存成功清空数据
                __this.form.advertPictureName = '';
                __this.$nextTick(() => {
                    __this.$refs.form.resetFields();
                });

            }).finally(() => {
                __this.loading = false;
            })
        },
        save_edit_advert() {
            const __this = this;
            if (__this.form.advertTitile === '') {
                __this.$message.warning('请输入标题!');
                return false;
            }
            if (__this.form.advertPictureUrl === '') {
                __this.$message.warning('请上传图片!');
                return false;
            }
            __this.loading = true;
            web_common_advert_updateAdvertById(__this.form).then(res => {
                __this.$message.success('保存成功');
                __this.get_advert_list();


                // 保存成功清空数据
                __this.form.advertPictureName = '';
                __this.$nextTick(() => {
                    __this.$refs.form.resetFields();
                });

            }).finally(() => {
                __this.loading = false;
            })
        },
        opa_advert(row, advertState) { // 上架下架
            const __this = this;
            const params = {
                advertState: advertState,
                id: row.id
            };
            __this.loading = true;
            web_common_advert_updateAdvertStateById(params).then(res => {
                __this.$message.success('操作成功');
                __this.get_advert_list();
            }).finally(() => {
                __this.loading = false;
            });
        },
        edit_advert(row) { // 编辑
            const __this = this;
            const form = Object.assign({}, row);
            __this.form = form;
            __this.form.advertWaitSecond = __this.form.advertWaitSecond;
            __this.form.advertForwardType = __this.form.advertForwardType;
            if (__this.$refs.fromCity) {
                __this.$refs.fromCity.value_for_v_model = __this.form.areaId;
            }
        },
        delete_advert(row) { // 根据id删除广告
            const __this = this;
            __this.$confirm('确认删除该图片?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                __this.loading = true;
                web_common_advert_deleteAdvertById({id: row.id}).then(res => {
                    __this.$message.success('删除成功');
                    __this.get_advert_list();
                    // 删除成功清空数据
                    __this.form.advertPictureName = '';
                    __this.$nextTick(() => {
                        __this.$refs.form.resetFields();
                    });
                }).finally(() => {
                    __this.loading = false;
                });
            });
        }
    },
    created() {
        const __this = this;
        __this.get_city_list();
        __this.get_advert_list();

    },
    filters: {
        filterAdvertState(val) {
            if (val === 1) {
                return '已上架'
            } else {
                return '已下架'
            }
        },
    }
};
