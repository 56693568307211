// web_common_user_updateUserPassword 修改用户表密码

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口地址：http://192.168.0.15:8080/doc.html#/所有接口/user-controller/updateUserPasswordVerifyUsingPOST

export default function web_common_user_updateUserPassword(pParameter) {
  if (!pParameter) pParameter = {};
  const id = pParameter.id || ''
  const password = pParameter.password || ''

  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/user/updateUserPassword'
  params.data = {
    id,
    password
  }
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}
