import Q from 'q';
import _ from 'underscore';
import join from 'nllib/src/uri/join';
import LoginCore from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/login-core/1.0.1/index.vue';
import history_tag_api from '@/component/admin-layout/1.0.0/component/history-tag/api';
import consumer_tokenLogin from '@/lib/common-service/consumer_tokenLogin';

export default {
    components: {
        LoginCore,
    },
    data() {
        return {};
    },
    methods: {},
    created() {
        // const __this = this;
    },
    mounted() {
        const __this = this;
        __this.$refs.aLoginCore.init({
            login_successfully_event_handler(data) {
                const environment = global.appConfig.environment;

                const token = data.token;
                const userType = data.userType;

                history_tag_api.remove_all_tag();

                // 供应商
                if (_.contains([1, 2], userType)) {
                    const msg = `供应商用户不应该使用该页面登录！`;
                    alert(msg);
                    return;
                }

                // 分销商
                if (_.contains([3, 4], userType)) {
                    const msg = `分销商用户不应该使用该页面登录！`;
                    alert(msg);
                    return;
                }

                // 平台
                if (_.contains([5], userType)) {
                    consumer_tokenLogin().then(function () {
                        __this.$router.push({
                            name: `admin-home`,
                        });
                    });
                    return;
                }

                // 游客
                if (_.contains([6], userType)) {
                    const msg = `游客用户不应该使用该页面登录！`;
                    alert(msg);
                    return;
                }


            },
        });
    },
    activated() {
        const __this = this;

        __this.form.userName = ``;
        __this.form.userPassword = ``;
    },
    deactivated() {
        // const __this = this;
    },
}
