// web_buyer_audit 采购商审核

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口文档地址： http://192.168.0.15:8080/doc.html#/所有接口/buyer-controller/auditBuyerUsingPOST

export default function web_buyer_audit(pParameter) {
  let params = {}
  if (!pParameter) pParameter = {};
  /*const buyerId = pParameter.buyerId || ''
  const developerId = pParameter.developerId || ''
  const developer = pParameter.developer || ''
  const carryRules = pParameter.carryRules || ''
  const airAccountManagerId = pParameter.airAccountManagerId || ''
  const airAccountManager = pParameter.airAccountManager || ''
  const travelAccountManager = pParameter.travelAccountManager || ''
  const travelAccountManagerId = pParameter.travelAccountManagerId || ''
  const buyerType = pParameter.buyerType || ''
  const buyerStatus = pParameter.buyerStatus || ''
  const failReason = pParameter.failReason || ''
  const isOperationFlow = pParameter.isOperationFlow || 0
  const attachAmountType = pParameter.attachAmountType || ''
  const attachAdultAmount = pParameter.attachAdultAmount || ''
  const attachChildAmount = pParameter.attachChildAmount || ''
  const noattachChildAmount = pParameter.noattachChildAmount || ''
  const attachAgedAmount = pParameter.attachAgedAmount || ''*/
  params.method = 'POST'
  params.urlSuffix = '/web/buyer/audit'
  params.data = pParameter
  /*params.data = {
    buyerId,
    carryRules, // 财付通账号
    travelAccountManager, // 支付宝账号
    travelAccountManagerId, // 易宝账号
    buyerType, // 中间社id
    developerId,
    developer,
    buyerStatus,
    failReason,
    attachAmountType,
    attachAdultAmount,
    airAccountManagerId,
    airAccountManager,
    attachChildAmount,
    noattachChildAmount,
    attachAgedAmount,
    isOperationFlow
  }*/
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
