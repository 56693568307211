import web_managerSupplier_auditDetails from '@/lib/data-service/default/web_managerSupplier_auditDetails'
import web_common_user_getDeveloper from '@/lib/data-service/default/web_common_user_getDeveloper'
import web_clud_list from '@/lib/data-service/default/web_clud_list'
import web_managerSupplier_audit from '@/lib/data-service/default/web_managerSupplier_audit'
import web_circuit_findTouristTour from '@/lib/data-service/default/web_circuit_findTouristTour'
import PreviewImage from '@/page/admin/supplier-management/component/previewImage/index.vue'
import addImgSuffix from '@/lib/common-service/add_img_suffix'
import FullImage from '@/page/admin/supplier-management/component/fullImage/index.vue'
import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client";

export default {
    data() {
        const validateOfExpirationTimeTrue = (rule, value, callback) => {
            if (this.supplier.isOperationFlow === 1) {
                if (value === '') {
                    callback(new Error('流水到期时间不能为空'))
                } else {
                    callback()
                }
            } else {
                callback()
            }
        };
        const validateAmount = (rule, value, callback) => {
            if (this.tourParams.attachType !== 0 && this.tourParams.attachAmountType === 1) {
                if (value === '') {
                    callback(new Error('不能为空'))
                } else {
                    if (value >= 0) {
                        callback()
                    } else {
                        callback(new Error('不能为负数'))
                    }
                }
            } else {
                callback()
            }
        };
        const validatePercent = (rule, value, callback) => {
            if (this.tourParams.attachType !== 0 && this.tourParams.attachAmountType === 2) {
                if (value === '') {
                    callback(new Error('不能为空'))
                } else {
                    if (value >= 0) {
                        callback()
                    } else {
                        callback(new Error('不能为负数'))
                    }
                }
            } else {
                callback()
            }
        };
        const validateServiceFeePercent = (rule, value, callback) => {
            if (this.tourParams.serviceFeeType !== 0) {
                if (value === '') {
                    callback(new Error('不能为空'))
                } else {
                    if (value >= 0) {
                        callback()
                    } else {
                        callback(new Error('不能为负数'))
                    }
                }
            } else {
                callback()
            }
        };
        const validatorInternationalServiceType = (rule, value, callback) => {
            if (this.checkParams.airStatus === 3) {
                if (value === '') {
                    callback(new Error('请选择国际机票收取方式'))
                } else {
                    callback()
                }
            } else {
                callback()
            }
        };
        const validatorInternationalServiceFee = (rule, value, callback) => {
            if (this.checkParams.airStatus === 3) {
                if (value === '') {
                    callback(new Error('请输入服务费'))
                } else {
                    callback()
                }
            } else {
                callback()
            }
        };
        const validateDomesticServiceFee = (rule, value, callback) => {
            if (value >= 0) {
                callback()
            } else {
                callback(new Error('不能为负数'))
            }
        };
        const validateUnWithdrawalRate = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('不能为空'))
            } else {
                if (value >= 0 && value <= 100) {
                    callback()
                } else {
                    callback(new Error('请输入0-100的数'))
                }
            }
            callback()
        }
        const validateDepositAmount = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('不能为空'))
            } else {
                if (value >= 0) {
                    callback()
                } else {
                    callback(new Error('不能为负数'))
                }
            }
            callback()
        }
        const validateAccountName = (rule, value, callback) => {
            if (!value) {
                if (this.accountParams.accountBankNm || this.accountParams.accountNo) {
                    callback(new Error('请输入账户名'))
                }
            }
            callback()
        }
        const validateAccountBankNm = (rule, value, callback) => {
            if (!value) {
                if (this.accountParams.accountName || this.accountParams.accountNo) {
                    callback(new Error('请输入开户行'))
                }
            }
            callback()
        }
        const validateAccountNo = (rule, value, callback) => {
            if (!value) {
                if (this.accountParams.accountName || this.accountParams.accountBankNm) {
                    callback(new Error('请输入银行账号'))
                }
            }
            callback()
        }
        const validateAlipayName = (rule, value, callback) => {
            if (!value) {
                if (this.accountParams.alipayAccount) {
                    callback(new Error('请输入账户名'))
                }
            }
            callback()
        }
        const validateAlipayAccount = (rule, value, callback) => {
            if (!value) {
                if (this.accountParams.alipayName) {
                    callback(new Error('请输入支付宝账号'))
                }
            }
            callback()
        }
        const validateServicePercent = (rule, value, callback) => {
            if (value >= 0) {
                callback()
            } else {
                callback(new Error('不能为负数'))
            }
        };
        const validatePaymentServiceRate = (rule, value, callback) => {
            if (value === '') {
                callback()
            } else {
                if (value >= 0) {
                    callback()
                } else {
                    callback(new Error('不能为负数'))
                }
            }
        };
        const validateTravelPaymentDays = (rule, value, callback) => {
            if (value === '') {
                callback()
            } else {
                if (value >= 0) {
                    callback()
                } else {
                    callback(new Error('不能为负数'))
                }
            }
        };
        const validateSettleDeposit = (rule, value, callback) => {
            if (value >= 0) {
                callback()
            } else {
                callback(new Error('不能输入负数'))
            }
        };
        return {
            loading: false,
            id: '',
            supplier: {
                supCanConfigList:[
                    {
                        // 申请--是否24小时(取位设置) 0：否 1：是
                        allDayForCanPnr: 1,
                        // 退票--是否24小时(退票取位设置) 0：否 1：是
                        allDayForRefPnr: 1,
                        // 申请--自动取位开始时间
                        canPnrTimeBegin: '00:00:00',
                        // 申请--自动取位结束时间
                        canPnrTimeEnd: '23:59:59',
                        // 申请--取位方式，1-自动取位，2-人工取位
                        canPnrType: 1,
                        // 退票--自动取位开始时间
                        refPnrTimeBegin: '00:00:00',
                        // 退票--自动取位结束时间
                        refPnrTimeEnd: '23:59:59',
                        // 退票--取位方式，1-自动取位，2-人工取位
                        refPnrType: 1,
                        // 票证类型：1.BSP 2.B2T 3.B2B 4.OT 5.B2C
                        ticketVoucherType: 1,
                        // 申请--自动取位开始时间
                        canPnrTime: ['00:00:00','23:59:59'],
                        // 退票--自动取位开始时间
                        refPnrTime: ['00:00:00','23:59:59'],
                        // 申请--是否24小时(取位设置)布尔值
                        allDayForCanPnrBoolean: true,
                        // 退票--是否24小时(退票取位设置)布尔值
                        allDayForRefPnrBoolean: true,
                    },
                    {
                        // 申请--是否24小时(取位设置) 0：否 1：是
                        allDayForCanPnr: 1,
                        // 退票--是否24小时(退票取位设置) 0：否 1：是
                        allDayForRefPnr: 1,
                        // 申请--自动取位开始时间
                        canPnrTimeBegin: '00:00:00',
                        // 申请--自动取位结束时间
                        canPnrTimeEnd: '23:59:59',
                        // 申请--取位方式，1-自动取位，2-人工取位
                        canPnrType: 1,
                        // 退票--自动取位开始时间
                        refPnrTimeBegin: '00:00:00',
                        // 退票--自动取位结束时间
                        refPnrTimeEnd: '23:59:59',
                        // 退票--取位方式，1-自动取位，2-人工取位
                        refPnrType: 1,
                        // 票证类型：1.BSP 2.B2T 3.B2B 4.OT 5.B2C
                        ticketVoucherType: 3,
                        // 申请--自动取位开始时间
                        canPnrTime: ['00:00:00','23:59:59'],
                        // 退票--自动取位开始时间
                        refPnrTime: ['00:00:00','23:59:59'],
                        // 申请--是否24小时(取位设置)布尔值
                        allDayForCanPnrBoolean: true,
                        // 退票--是否24小时(退票取位设置)布尔值
                        allDayForRefPnrBoolean: true,
                    },
                    {
                        // 申请--是否24小时(取位设置) 0：否 1：是
                        allDayForCanPnr: 0,
                        // 退票--是否24小时(退票取位设置) 0：否 1：是
                        allDayForRefPnr: 0,
                        // 申请--自动取位开始时间
                        canPnrTimeBegin: '00:00:00',
                        // 申请--自动取位结束时间
                        canPnrTimeEnd: '23:59:59',
                        // 申请--取位方式，1-自动取位，2-人工取位
                        canPnrType: 2,
                        // 退票--自动取位开始时间
                        refPnrTimeBegin: '00:00:00',
                        // 退票--自动取位结束时间
                        refPnrTimeEnd: '23:59:59',
                        // 退票--取位方式，1-自动取位，2-人工取位
                        refPnrType: 2,
                        // 票证类型：1.BSP 2.B2T 3.B2B 4.OT 5.B2C
                        ticketVoucherType: 2,
                        // 申请--自动取位开始时间
                        canPnrTime: ['00:00:00','23:59:59'],
                        // 退票--自动取位开始时间
                        refPnrTime: ['00:00:00','23:59:59'],
                        // 申请--是否24小时(取位设置)布尔值
                        allDayForCanPnrBoolean: false,
                        // 退票--是否24小时(退票取位设置)布尔值
                        allDayForRefPnrBoolean: false,
                    },
                ],
            },
            checkParams: {
                circuitList: '',
                circuitListArr: [],
                airStatus: 2,
                travelStatus: 2,
                airTicketType: 1,
                airFailReason: '',
                travelFailReason: ''
            },
            accountParams: {
                unWithdrawalRate: '', // 不可提现比例
                depositAmount: '', // 压款金额
                accountName: '', // 银行账户名
                accountBankNm: '', // 银行开户行
                accountNo: '', // 银行账号
                alipayName: '', // 支付宝账户名
                alipayAccount: '', // 支付宝账号
            },
            addParams: {
                settleDepositType: 0, // 订金收取方式 0 -不收 2定额 1比例
                settleDeposit: 0, // 订金
                finalPaymentCycle: 0, // 尾款周期，出团前
                finalCycleUnit: 1, // 尾款周期单位 1-天 2-周 3-月 默认天
                finalPaymentType: 1, // 默认出团前 自增
                isHasSettle: 1, // 收订金还是全款 1-订金 0全款 自增
                settleType: 2, // 1比例，2定额 自增

                pressAmountType: 0, // 压款收取方式 0-不压 1 比例 2定额
                pressAmount: 0, // 压款金额
                presslPaymentCycle: 0, // 压款周期，出团后
                presslCycleUnit: 1, // 压款周期单位 1-天 2-周 3-月 默认天
                pressPaymentType: 1, // 默认出团后 自增
                isHasPress: 1, // 是否压款 0-不压 1压 自增
                pressType: 2, // 1比例，2定额 自增
            },
            checkRule: {
                airStatus: [
                    {required: true, message: '机票资质通过类型不能为空', trigger: 'change'}
                ],
                travelStatus: [
                    {required: true, message: '旅游资质通过类型不能为空', trigger: 'change'}
                ],
                airFailReason: [
                    {required: true, message: '请输入失败原因', trigger: 'blur'}
                ],
                travelFailReason: [
                    {required: true, message: '请输入失败原因', trigger: 'blur'}
                ]
            },
            srcList: [],
            developerList: [],
            clubList: [],
            rule: {
                developerId: [
                    {required: true, message: '开发人不能为空', trigger: 'change'}
                ],
                ofExpirationTimeTrue: [
                    {validator: validateOfExpirationTimeTrue, trigger: 'change'}
                ],
                domesticServiceType: [
                    {required: true, message: '请选择收取方式', trigger: 'change'}
                ],
                domesticServiceFee: [
                    {required: true, message: '请输入服务费', trigger: 'blur'},
                    {validator: validateDomesticServiceFee, trigger: 'blur'}
                ],
                airAccountManagerId: [
                    {required: true, message: '机票客户经理不能为空', trigger: 'change'}
                ],
                unWithdrawalRate: [
                    {required: true, message: '请输入不可提现比例', trigger: 'blur'},
                    {validator: validateUnWithdrawalRate, trigger: 'blur'}
                ],
                depositAmount: [
                    {required: true, message: '请输入压款金额', trigger: 'blur'},
                    {validator: validateDepositAmount, trigger: 'blur'}
                ],
                accountName: [
                    {validator: validateAccountName, trigger: 'blur'}
                ],
                accountBankNm: [
                    {validator: validateAccountBankNm, trigger: 'blur'}
                ],
                accountNo: [
                    {validator: validateAccountNo, trigger: 'blur'}
                ],
                alipayName: [
                    {validator: validateAlipayName, trigger: 'blur'}
                ],
                alipayAccount: [
                    {validator: validateAlipayAccount, trigger: 'blur'}
                ],
                serviceFeeType: [
                    {required: true, message: '请选择收取方式', trigger: 'change'}
                ],
                serviceFeePercent: [
                    {required: true, message: '请输入平台服务费', trigger: 'blur'},
                    {validator: validateServicePercent, trigger: 'blur'}
                ],
                paymentServiceRate: [
                    {validator: validatePaymentServiceRate, trigger: 'blur'}
                ],
                travelPaymentDays: [
                    {validator: validateTravelPaymentDays, trigger: 'blur'}
                ],
                travelAccountManagerId: [
                    {required: true, message: '旅游客户经理不能为空', trigger: 'change'}
                ],
                internationalServiceType: [
                    {validator: validatorInternationalServiceType, trigger: 'change'}
                ],
                internationalServiceFee: [
                    {validator: validatorInternationalServiceFee, trigger: 'blur'}
                ],
                specialServiceType: [
                    {required: true, message: '请输入收取方式', trigger: 'change'}
                ],
                specialServiceFee: [
                    {required: true, message: '请输入服务费', trigger: 'blur'},
                    {validator: validateDomesticServiceFee, trigger: 'blur'}
                ],
                settleDeposit: [
                    {required: true, message: '订金金额不能为空', trigger: 'blur'},
                    {validator: validateSettleDeposit, trigger: 'blur'}
                ],
                finalPaymentCycle: [
                    {required: true, message: '尾款周期不能为空', trigger: 'blur'},
                    {validator: validateSettleDeposit, trigger: 'blur'}
                ],
                pressAmount: [
                    {required: true, message: '压款金额不能为空', trigger: 'blur'},
                    {validator: validateSettleDeposit, trigger: 'blur'}
                ],
                presslPaymentCycle: [
                    {required: true, message: '压款周期不能为空', trigger: 'blur'},
                    {validator: validateSettleDeposit, trigger: 'blur'}
                ]
            },
            tourList: [], // 旅游线路
            dialogVisible: false,
            dialogStatus: '',
            textMap: {
                update: '编辑',
                create: '创建'
            },
            tourParams: {
                attachAdultAmount: '',
                attachAdultPercent: '',
                attachAgedAmount: '',
                attachAgedPercent: '',
                attachAmountType: 1,
                attachChildAmount: '',
                attachChildBedAmount: '',
                attachChildBedPercent: '',
                attachChildPercent: '',
                attachType: 1,
                isDelete: 0,
                lineId: '',
                lineName: '',
                lineType: '',
                serviceFeePercent: '',
                serviceFeeType: 0,

                settleDepositType: 0, // 订金收取方式 0 -不收 2定额 1比例
                settleDeposit: 0, // 订金
                finalPaymentCycle: 0, // 尾款周期，出团前
                finalCycleUnit: 1, // 尾款周期单位 1-天 2-周 3-月 默认天
                finalPaymentType: 1, // 默认出团前 自增
                isHasSettle: 1, // 收订金还是全款 1-订金 0全款 自增
                settleType: 2, // 1比例，2定额 自增

                pressAmountType: 0, // 压款收取方式 0-不压 1 比例 2定额
                pressAmount: 0, // 压款金额
                presslPaymentCycle: 0, // 压款周期，出团后
                presslCycleUnit: 1, // 压款周期单位 1-天 2-周 3-月 默认天
                pressPaymentType: 1, // 默认出团后 自增
                isHasPress: 1, // 是否压款 0-不压 1压 自增
                pressType: 2, // 1比例，2定额 自增
            },
            tourRule: {
                lineId: [
                    {required: true, message: '请选择线路', trigger: 'change'},
                ],
                attachAdultAmount: [
                    {validator: validateAmount, trigger: 'blur'}
                ],
                attachChildBedAmount: [
                    {validator: validateAmount, trigger: 'blur'}
                ],
                attachChildAmount: [
                    {validator: validateAmount, trigger: 'blur'}
                ],
                attachAgedAmount: [
                    {validator: validateAmount, trigger: 'blur'}
                ],
                attachAdultPercent: [
                    {validator: validatePercent, trigger: 'blur'}
                ],
                attachChildBedPercent: [
                    {validator: validatePercent, trigger: 'blur'}
                ],
                attachChildPercent: [
                    {validator: validatePercent, trigger: 'blur'}
                ],
                attachAgedPercent: [
                    {validator: validatePercent, trigger: 'blur'}
                ],
                serviceFeePercent: [
                    {validator: validateServiceFeePercent, trigger: 'blur'}
                ],
                settleDeposit: [
                    {required: true, message: '订金金额不能为空', trigger: 'blur'},
                    {validator: validateSettleDeposit, trigger: 'blur'}
                ],
                finalPaymentCycle: [
                    {required: true, message: '尾款周期不能为空', trigger: 'blur'},
                    {validator: validateSettleDeposit, trigger: 'blur'}
                ],
                pressAmount: [
                    {required: true, message: '压款金额不能为空', trigger: 'blur'},
                    {validator: validateSettleDeposit, trigger: 'blur'}
                ],
                presslPaymentCycle: [
                    {required: true, message: '压款周期不能为空', trigger: 'blur'},
                    {validator: validateSettleDeposit, trigger: 'blur'}
                ]
            },
            dialogFullPicVisible: false,
            fullPicSrc: '',

            fileParams: {
                agreementFile: '',
            }
        }
    },
    components: {
        FullImage,
        PreviewImage
    },
    created() {
    },
    mounted() {

    },
    async activated() {
        this.id = this.$route.query.id
        await this.getSupplierDetail(this.id)
        this.getDeveloperList()
        this.getClubList()
        this.findTourListTour()
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {
        accountParams: {
            handler(val){
                if (!val.unWithdrawalRate && !val.depositAmount) {
                    if (this.$refs["withdrawalForm"]) {
                        this.$refs["withdrawalForm"].clearValidate()
                    }
                }
                if (!val.accountName && !val.accountBankNm && !val.accountNo) {
                    if (this.$refs["bankAccountForm"]) {
                        this.$refs["bankAccountForm"].clearValidate()
                    }
                }
                if (!val.alipayName && !val.alipayAccount) {
                    if (this.$refs["alipayAccountForm"]) {
                        this.$refs["alipayAccountForm"].clearValidate()
                    }
                }
            },
            deep: true,
        },
    },
    computed: {},
    filters: {
        filterSupplierStatus(val) {
            if (val === 0) {
                return '待审核'
            } else if (val === 1) {
                return '审核失败'
            } else if (val === 2) {
                return '部分启用'
            } else if (val === 3) {
                return '启用'
            } else if (val === 4) {
                return '平台禁用'
            } else if (val === 5) {
                return '已删除'
            } else if (val === 6) {
                return '未签约'
            } else if (val === 7) {
                return '签约失败'
            } else if (val === 8) {
                return '待设置合同文件'
            } else {
                return ''
            }
        },
        filterAppType(val) {
            if (val === '1') {
                return '机票'
            } else if (val === '2') {
                return '旅游'
            } else {
                return '机票+旅游'
            }
        },
        filterServiceFeeType(val) {
            if (val === 0) {
                return '默认设置'
            } else if (val === 1) {
                return '定额'
            } else {
                return '比例'
            }
        },
        filterAttachType(val) {
            if (val === 1) {
                return '加价'
            } else if (val === 2) {
                return '减免'
            } else {
                return '不附加'
            }
        },
        filterImg(val) {
            let result = addImgSuffix(val)
            return result
        }
    },
    methods: {
        formatterAttachType(val) {
            if (val.attachType === 1) {
                return '加价'
            } else if (val.attachType === 2) {
                return '减免'
            } else {
                return '不附加'
            }
        },
        formatterSettleDepositType(val) {
            if (val.settleDepositType === 0) {
                return '全额'
            } else {
                return '订金'
            }
        },
        formatterSettleDepositFun(val) {
            if (val.settleDepositType === 0) {
                return '--'
            } else if (val.settleDepositType === 1) {
                return '比例'
            } else {
                return '定额'
            }
        },
        formatterSettleDeposit(val) {
            if (val.settleDepositType === 0) {
                return '--'
            } else if (val.settleDepositType === 1) {
                return val.settleDeposit + '%'
            } else {
                return val.settleDeposit
            }
        },
        formatterFinalPaymentCycle(val) {
            if (val.settleDepositType === 0) {
                return '--'
            } else {
                return `出团前${val.finalPaymentCycle}天`
            }
        },
        formatterPressAmountType(val) {
            if (val.pressAmountType === 0) {
                return '不压款'
            } else {
                return '压款'
            }
        },
        formatterPressAmountFun(val) {
            if (val.pressAmountType === 0) {
                return '--'
            } else if (val.pressAmountType === 1) {
                return '百分比'
            } else {
                return '定额'
            }
        },
        formatterPressAmount(val) {
            if (val.pressAmountType === 0) {
                return '--'
            } else if (val.pressAmountType === 1) {
                return val.pressAmount + '%'
            } else {
                return val.pressAmount
            }
        },
        formatterPresslPaymentCycle(val) {
            if (val.pressAmountType === 0) {
                return '--'
            } else {
                return `出团后${val.presslPaymentCycle}天`
            }
        },
        // 获取开发者列表 - （机票经理）
        getDeveloperList() {
            web_common_user_getDeveloper().then((res) => {
                this.developerList = res.getAllDeveloperResult
            })
        },
        // 获取中间社列表
        getClubList() {
            web_clud_list().then(res => {
                this.clubList = res.results
                this.supplier.clubId = res.results[0].id
            })
        },
        // 获取旅行线路
        findTourListTour() {
            web_circuit_findTouristTour().then(res => {
                this.tourList = res.result
            })
        },
        picLoading() {
            let _this = this
            let picSetTimeOut = ''
            picSetTimeOut = setTimeout(() => {
                let formPicArr = document.getElementsByClassName('form-pic')
                let formPicStatus = []
                for (let i in formPicArr.length) {
                    formPicStatus.push(-1)
                }
                formPicArr.forEach((value, index) => {
                    let complete = value.complete
                    formPicStatus[index] = complete ? 1 : -1
                })
                if (formPicStatus.indexOf(-1) >= 0) {
                    this.picLoading()
                } else {
                    _this.loading = false
                }
            }, 500)
        },
        async getSupplierDetail(supplierId) {
            this.loading = true;
            let res = await web_managerSupplier_auditDetails({supplierId});
            let results = res.result;
            results.airAccountManagerId = results.airAccountManagerId === '' ? '' : parseInt(results.airAccountManagerId);
            results.developerId = results.developerId === '' ? '' : parseInt(results.developerId);
            results.travelAccountManagerId = results.travelAccountManagerId === '' ? '' : parseInt(results.travelAccountManagerId);
            results.serviceFeeType = results.serviceFeeType === null ? 2 : results.serviceFeeType;
            results.serviceFeePercent = results.serviceFeePercent === null ? 0 : results.serviceFeePercent;
            results.specialServiceType = results.specialServiceType === null ? 2 : results.specialServiceType;
            results.specialServiceFee = results.specialServiceFee === null ? 0 : results.specialServiceFee;

            if (results.settleDepositType === 0) {
                results.isHasSettle = 0;
                results.settleType = 1;
                results.finalPaymentType = 1;
            } else if (results.settleDepositType === 1) {
                results.isHasSettle = 1;
                results.settleType = 1;
                results.finalPaymentType = 1;
            } else {
                results.isHasSettle = 1;
                results.settleType = 2;
                results.finalPaymentType = 1;
            }
            if (results.pressAmountType === 0) {
                results.isHasPress = 0;
                results.pressType = 1;
                results.pressPaymentType = 1;
            } else if (results.pressAmountType === 1) {
                results.isHasPress = 1;
                results.pressType = 1;
                results.pressPaymentType = 1;
            } else {
                results.isHasPress = 1;
                results.pressType = 2;
                results.pressPaymentType = 1;
            }
            results.ofExpirationTimeTrue = results.ofExpirationTime;
            if (results.isOperationFlow === null) {
                results.isOperationFlow = 0;
            }
            let accountParams = {
                unWithdrawalRate: results.unWithdrawalRate,
                depositAmount: results.depositAmount
            }
            let addParams = {
                settleDepositType: results.settleDepositType, // 订金收取方式 0 -不收 2定额 1比例
                settleDeposit: results.settleDeposit, // 订金
                finalPaymentCycle: results.finalPaymentCycle, // 尾款周期，出团前
                finalCycleUnit: (results.finalCycleUnit === 0 || results.finalCycleUnit === null) ? 1 : results.finalCycleUnit, // 尾款周期单位 1-天 2-周 3-月 默认天
                finalPaymentType: results.finalPaymentType, // 默认出团前 自增
                isHasSettle: results.isHasSettle, // 收订金还是全款 1-订金 0全款 自增
                settleType: results.settleType, // 1比例，2定额 自增

                pressAmountType: results.pressAmountType, // 压款收取方式 0-不压 1 比例 2定额
                pressAmount: results.pressAmount, // 压款金额
                presslPaymentCycle: results.presslPaymentCycle, // 压款周期，出团后
                presslCycleUnit: (results.presslCycleUnit === 0 || results.presslCycleUnit === null) ? 1 : results.presslCycleUnit, // 压款周期单位 1-天 2-周 3-月 默认天
                pressPaymentType: results.pressPaymentType, // 默认出团后 自增
                isHasPress: results.isHasPress, // 是否压款 0-不压 1压 自增
                pressType: results.pressType, // 1比例，2定额 自增
            }
            let fileParams = {
                agreementFile: results.agreementFile
            }

            // 取位部分
            if (results.supCanConfigList.length == 0) {
                results.supCanConfigList = this.supplier.supCanConfigList;
            }
            results.supCanConfigList.forEach((item,index) => {
                item.canPnrTime = [item.canPnrTimeBegin,item.canPnrTimeEnd];
                item.refPnrTime = [item.refPnrTimeBegin,item.refPnrTimeEnd];
                item.allDayForCanPnrBoolean = item.allDayForCanPnr == 1 ? true : false;
                item.allDayForRefPnrBoolean = item.allDayForRefPnr == 1 ? true : false;
            });

            results.allDayForCanPnrType = results.allDayForCanPnr === 1?true:false;
            results.allDayForRefPnrType = results.allDayForRefPnr === 1?true:false;
            // // 时间
            results.cancelTime = [results.canPnrTimeBegin, results.canPnrTimeEnd];
            results.refCancelTime = [results.refPnrTimeBegin, results.refPnrTimeEnd]

            this.supplier = results;

            this.accountParams = accountParams
            this.addParams = addParams
            this.fileParams = fileParams

            if (this.supplier.applicationType === '1') {
                this.checkParams = {
                    circuitList: '',
                    circuitListArr: [],
                    airStatus: 2,
                    travelStatus: 1,
                    airTicketType: this.supplier.airTicketType,
                    airFailReason: '',
                    travelFailReason: ''
                }
            } else if (this.supplier.applicationType === '2') {
                this.checkParams = {
                    circuitList: '',
                    circuitListArr: [],
                    airStatus: 1,
                    travelStatus: 2,
                    airTicketType: this.supplier.airTicketType,
                    airFailReason: '',
                    travelFailReason: ''
                }
            } else {
                this.checkParams = {
                    circuitList: '',
                    circuitListArr: [],
                    airStatus: 2,
                    travelStatus: 2,
                    airTicketType: this.supplier.airTicketType,
                    airFailReason: '',
                    travelFailReason: ''
                }
            }
            this.$nextTick(() => {
                this.picLoading()
                this.$refs['form7'].clearValidate()
                if (this.supplier.applicationType === '1' || this.supplier.applicationType === '1+2') {
                    this.$refs['form8-1'].clearValidate()
                    this.$refs['form8-2'].clearValidate()
                    this.$refs['form8-3'].clearValidate()
                    this.$refs['form8-4'].clearValidate()
                }
                if (this.supplier.applicationType === '2' || this.supplier.applicationType === '1+2') {
                    this.$refs['form9-1'].clearValidate()
                    this.$refs['form9-2'].clearValidate()
                    this.$refs['form9-3'].clearValidate()
                    this.$refs['form9-4'].clearValidate()
                }
            })
        },
        showFullPic(val) {
            this.dialogFullPicVisible = true
            this.fullPicSrc = addImgSuffix(val)
        },
        back() {
            this.$router.go(-1)
        },
        // 选择开发人
        changeDeveloper(value) {
            for (let i = 0, l = this.developerList.length; i < l; i++) {
                if (this.developerList[i].id === value) {
                    this.supplier.developer = this.developerList[i].realName
                    break
                }
            }
        },
        // 选择经理时
        changeAirAccountManager(value) {
            for (let i = 0, l = this.developerList.length; i < l; i++) {
                if (this.developerList[i].id === value) {
                    this.supplier.airAccountManager = this.developerList[i].realName
                    break
                }
            }
        },
        changeAccountManager(value) {
            for (let i = 0, l = this.developerList.length; i < l; i++) {
                if (this.developerList[i].id === value) {
                    this.supplier.travelAccountManager = this.developerList[i].realName
                    break
                }
            }
        },
        submit() {
            let status
            let formDataList
            if (this.supplier.applicationType === '1' && (this.checkParams.airStatus === 2 || this.checkParams.airStatus === 3)) { // 机票类型，且通过
                status = [-1, -1, -1, -1, -1, -1, -1, -1]
                formDataList = ['form5', 'form7', 'form8-1', 'form8-2', 'form8-3', 'form8-4', 'bankAccountForm', 'alipayAccountForm']
            } else if (this.supplier.applicationType === '1' && this.checkParams.airStatus === 1) { // 机票类型，且不通过
                status = [-1, -1, -1]
                formDataList = ['form5', 'bankAccountForm', 'alipayAccountForm']
            } else if (this.supplier.applicationType === '2' && this.checkParams.travelStatus === 2) { // 旅游类型，且通过
                status = [-1, -1, -1, -1, -1, -1, -1, -1]
                formDataList = ['form6', 'form7', 'bankAccountForm', 'alipayAccountForm', 'form9-1', 'form9-2', 'form9-3', 'form9-4']
            } else if (this.supplier.applicationType === '2' && this.checkParams.travelStatus === 1) { // 旅游类型，且不通过
                status = [-1, -1, -1]
                formDataList = ['form6', 'bankAccountForm', 'alipayAccountForm']
            } else if (this.supplier.applicationType === '1+2' && (this.checkParams.airStatus === 2 || this.checkParams.airStatus === 3) && this.checkParams.travelStatus === 2) { // 1+2 两者都通过
                status = [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1]
                formDataList = ['form5', 'form6', 'form7', 'form8-1', 'form8-2', 'form8-3', 'form8-4', 'bankAccountForm', 'alipayAccountForm', 'form9-1', 'form9-2', 'form9-3', 'form9-4']
            } else if (this.supplier.applicationType === '1+2' && (this.checkParams.airStatus === 2 || this.checkParams.airStatus === 3) && this.checkParams.travelStatus === 1) { // 1+2 机票通过，旅游不通过
                status = [-1, -1, -1, -1, -1, -1, -1, -1, -1]
                formDataList = ['form5', 'form6', 'form7', 'form8-1', 'form8-2', 'form8-3', 'form8-4', 'bankAccountForm', 'alipayAccountForm']
            } else if (this.supplier.applicationType === '1+2' && this.checkParams.airStatus === 1 && this.checkParams.travelStatus === 2) { // 1+2 机票不通过， 旅游通过
                status = [-1, -1, -1, -1, -1, -1, -1, -1, -1]
                formDataList = ['form5', 'form6', 'form7', 'bankAccountForm', 'alipayAccountForm', 'form9-1', 'form9-2', 'form9-3', 'form9-4']
            } else {
                status = [-1, -1, -1, -1]
                formDataList = ['form5', 'form6', 'bankAccountForm', 'alipayAccountForm']
            }
            formDataList.map((value, index) => {
                this.$refs[value].validate((valid) => {
                    valid ? status[index] = 1 : status[index] = -1
                    if (status.indexOf(-1) === -1) {
                        // 开始提交
                        this.loading = true
                        if (this.addParams.isHasSettle === 0) {
                            this.addParams.settleDepositType = 0
                        } else {
                            this.addParams.settleDepositType = this.addParams.settleType === 1 ? 1 : 2
                        }
                        if (this.addParams.isHasPress === 0) {
                            this.addParams.pressAmountType = 0
                        } else {
                            this.addParams.pressAmountType = this.addParams.pressType === 1 ? 1 : 2
                        }
                        let params = Object.assign({}, this.supplier, this.checkParams, this.accountParams, this.addParams, this.fileParams)

                        params.supCanConfigList.forEach((item,index) => {
                          delete item.allDayForRefPnrBoolean;
                          delete item.allDayForCanPnrBoolean;
                          delete item.refPnrTime;
                          delete item.canPnrTime;
                        });


                        web_managerSupplier_audit(params).then(res => {
                            this.loading = false
                            this.$alert('审核完毕', '温馨提示', {
                                confirmButtonText: '确定',
                                callback: action => {
                                    if ((params.applicationType === '1' && params.airStatus === 2) || (params.applicationType === '2' && params.travelStatus === 2) || (params.applicationType === '1+2' && params.airStatus === 2 && params.travelStatus === 2)) {
                                        // 跳转
                                        this.$router.push({
                                            name: 'admin-supplier-check-list',
                                        })
                                    } else {
                                        this.$router.push({
                                            name: 'admin-supplier-list'
                                        })
                                    }
                                }
                            })
                            this.accountParams = {
                                unWithdrawalRate: '', // 不可提现比例
                                depositAmount: '', // 压款金额
                                accountName: '', // 银行账户名
                                accountBankNm: '', // 银行开户行
                                accountNo: '', // 银行账号
                                alipayName: '', // 支付宝账户名
                                alipayAccount: '', // 支付宝账号
                            }
                        }).catch(() => {
                            this.loading = false
                        })
                    }
                })
            })
        },
        changeAirStatus(val) {
            if (val === 1) {
                this.checkParams.airTicketType = this.supplier.airTicketType
            } else if (val === 2) {
                this.checkParams.airTicketType = 1
            } else {
                this.checkParams.airTicketType = 2
            }
        },
        editTour(index, val) {
            this.reactTourParams()
            this.tourParams = Object.assign({}, this.tourParams, val)
            this.dialogVisible = true
            this.dialogStatus = 'update'
        },
        delTourList(index, val) {
            this.checkParams.circuitListArr.splice(index, 1)
            let circuitList = this.checkParams.circuitList.concat()
            this.checkParams.circuitList = JSON.stringify(circuitList)
            for (let i = 0, l = this.tourList.length; i < l; i++) {
                if (this.tourList[i].id === val.lineId) {
                    this.tourList[i].disabled = false // 解除禁用
                    break
                }
            }
        },
        handleClose() {
            this.dialogVisible = false
        },
        changeTourLine(val) {
            for (let i = 0, l = this.tourList.length; i < l; i++) {
                if (this.tourList[i].id === val) {
                    this.tourParams.lineName = this.tourList[i].lineName
                    this.tourParams.lineType = this.tourList[i].lineType
                    // this.tourList[i].disabled = true // 禁用
                    break
                }
            }
        },
        submitTour() {
            let formDataList = ['form10-1', 'form10-2', 'form10-3', 'form10-4', 'form10-5']
            let status = [-1, -1, -1, -1, -1]
            formDataList.map((value, index) => {
                this.$refs[value].validate((valid) => {
                    valid ? status[index] = 1 : status[index] = -1
                    if (status.indexOf(-1) === -1) {
                        if (this.tourParams.isHasSettle === 0) {
                            this.tourParams.settleDepositType = 0
                        } else {
                            this.tourParams.settleDepositType = this.tourParams.settleType === 1 ? 1 : 2
                        }
                        if (this.tourParams.isHasPress === 0) {
                            this.tourParams.pressAmountType = 0
                        } else {
                            this.tourParams.pressAmountType = this.tourParams.pressType === 1 ? 1 : 2
                        }
                        let params = Object.assign({}, this.tourParams)
                        this.checkParams.circuitListArr.push(params)
                        let circuitList = this.checkParams.circuitListArr.concat()
                        this.checkParams.circuitList = JSON.stringify(circuitList)
                        this.dialogVisible = false
                        for (let i = 0, l = this.tourList.length; i < l; i++) {
                            if (this.tourList[i].id === params.lineId) {
                                this.tourList[i].disabled = true // 禁用
                                break
                            }
                        }
                    }
                })
            })
            /*this.$refs['form10'].validate((valid) => {
              if (valid) {
                let params = Object.assign({}, this.tourParams)
                this.checkParams.circuitListArr.push(params)
                let circuitList = this.checkParams.circuitList.concat()
                this.checkParams.circuitList = JSON.stringify(circuitList)
                this.dialogVisible = false
                for (let i = 0, l = this.tourList.length; i < l; i++) {
                  if (this.tourList[i].id === params.lineId) {
                    this.tourList[i].disabled = true // 禁用
                    break
                  }
                }
              }
            })*/
        },
        submitEditTour() {
            const formDataList = ['form10-1', 'form10-2', 'form10-3', 'form10-4', 'form10-5']
            let status = [-1, -1, -1, -1, -1]
            formDataList.map((value, index) => {
                this.$refs[value].validate((valid) => {
                    valid ? status[index] = 1 : status[index] = -1
                    if (status.indexOf(-1) === -1) {
                        if (this.tourParams.isHasSettle === 0) {
                            this.tourParams.settleDepositType = 0
                        } else {
                            this.tourParams.settleDepositType = this.tourParams.settleType === 1 ? 1 : 2
                        }
                        if (this.tourParams.isHasPress === 0) {
                            this.tourParams.pressAmountType = 0
                        } else {
                            this.tourParams.pressAmountType = this.tourParams.pressType === 1 ? 1 : 2
                        }
                        let params = Object.assign({}, this.tourParams)
                        for (let i = 0, l = this.checkParams.circuitListArr.length; i < l; i++) {
                            if (this.checkParams.circuitListArr[i].lineId === params.lineId) {
                                this.checkParams.circuitListArr.splice(i, 1, params)
                                break
                            }
                        }
                        let circuitList = this.checkParams.circuitListArr.concat()
                        this.checkParams.circuitList = JSON.stringify(circuitList)
                        this.dialogVisible = false
                        for (let i = 0, l = this.tourList.length; i < l; i++) {
                            if (this.tourList[i].id === params.lineId) {
                                this.tourList[i].disabled = true // 禁用
                                break
                            }
                        }
                    }
                })
            })
            /*this.$refs['form10'].validate((valid) => {
              if (valid) {
                let params = Object.assign({}, this.tourParams)
                for (let i = 0, l = this.checkParams.circuitListArr.length; i < l; i++) {
                  if (this.checkParams.circuitListArr[i].lineId === params.lineId) {
                    this.checkParams.circuitListArr[i] = params
                  }
                }
                let circuitList = this.checkParams.circuitList.concat()
                this.checkParams.circuitList = JSON.stringify(circuitList)
                this.dialogVisible = false
                for (let i = 0, l = this.tourList.length; i < l; i++) {
                  if (this.tourList[i].id === params.lineId) {
                    this.tourList[i].disabled = true // 禁用
                    break
                  }
                }
              }
            })*/
        },
        setLine() {
            this.reactTourParams()
            this.tourParams = Object.assign({}, this.tourParams, this.addParams)
            this.dialogVisible = true
            this.dialogStatus = 'create'
            this.$nextTick(() => {
                this.$refs['form10-1'].clearValidate()
                this.$refs['form10-2'].clearValidate()
                this.$refs['form10-3'].clearValidate()
                this.$refs['form10-4'].clearValidate()
                this.$refs['form10-5'].clearValidate()
            })
        },
        reactTourParams() {
            this.tourParams = {
                attachAdultAmount: '',
                attachAdultPercent: '',
                attachAgedAmount: '',
                attachAgedPercent: '',
                attachAmountType: 1,
                attachChildAmount: '',
                attachChildBedAmount: '',
                attachChildBedPercent: '',
                attachChildPercent: '',
                attachType: 1,
                isDelete: 0,
                lineId: '',
                lineName: '',
                lineType: '',
                serviceFeePercent: '',
                serviceFeeType: 0,

                settleDepositType: 0, // 订金收取方式 0 -不收 2定额 1比例
                settleDeposit: 0, // 订金
                finalPaymentCycle: 0, // 尾款周期，出团前
                finalCycleUnit: 1, // 尾款周期单位 1-天 2-周 3-月 默认天
                finalPaymentType: 1, // 默认出团前 自增
                isHasSettle: 1, // 收订金还是全款 1-订金 0全款 自增
                settleType: 2, // 1比例，2定额 自增

                pressAmountType: 0, // 压款收取方式 0-不压 1 比例 2定额
                pressAmount: 0, // 压款金额
                presslPaymentCycle: 0, // 压款周期，出团后
                presslCycleUnit: 1, // 压款周期单位 1-天 2-周 3-月 默认天
                pressPaymentType: 1, // 默认出团后 自增
                isHasPress: 1, // 是否压款 0-不压 1压 自增
                pressType: 2, // 1比例，2定额 自增
            }
        },
        handleSuccesspictureB(file, fileList) {
            const size = file.size / 1024 / 1024;
            if (size > 10) {
                return this.$message.error("文件大小不能超过10M");
            }
            const _this = this;
            const aOssClient = new OssClient();
            aOssClient.multipartUpload({
                file: file.raw,
                progress: function* (p) {

                }
            })
                .then(function (data) {
                    _this.$message.success("文件上传成功");
                    _this.fileParams.agreementFile = data.url;
                    _this.$refs.uploadB.clearValidate();
                })
        },
        removePDFB() {
            this.fileParams.agreementFile = "";
        },
        openPDFB(file) {
            window.open(this.fileParams.agreementFile);
        },
        cancelChangeClick(val){
            const __this = this;
            if(val===1){
                // 取位
                if(__this.supplier.allDayForCanPnrType){
                    __this.supplier.cancelTime=['00:00:00','23:59:59']
                }
                return;
            }
            if(val===2){
                // 退票取位
                if(__this.supplier.allDayForRefPnrType){
                    __this.supplier.refCancelTime=['00:00:00','23:59:59']
                }
                return;
            }
        },
        cancelChangeClickCanPnr(index) {
          let allDayForCanPnrBoolean = this.supplier.supCanConfigList[index].allDayForCanPnrBoolean;
          this.supplier.supCanConfigList[index].allDayForCanPnr = allDayForCanPnrBoolean ? 1 : 0;
          if (this.supplier.supCanConfigList[index].allDayForCanPnr == 1) {
            this.supplier.supCanConfigList[index].canPnrTime = ['00:00:00','23:59:59'];
            this.supplier.supCanConfigList[index].canPnrTimeBegin = '00:00:00';
            this.supplier.supCanConfigList[index].canPnrTimeEnd = '23:59:59';
          }
        },
        cancelBlurPickerCanPnrTime(index){
          let canPnrTime = this.params.supCanConfigList[index].canPnrTime;
          if (canPnrTime[0] == '00:00:00' && canPnrTime[1] == '23:59:59') {
            this.supplier.supCanConfigList[index].allDayForCanPnr = 1;
            this.supplier.supCanConfigList[index].allDayForCanPnrBoolean = true;
          }
        },
        cancelChangePickerCanPnrTime(index){
          let canPnrTime = this.supplier.supCanConfigList[index].canPnrTime;
          if (!canPnrTime) {
            this.supplier.supCanConfigList[index].allDayForCanPnr = 0;
            this.supplier.supCanConfigList[index].allDayForCanPnrBoolean = false;
            return
          }
          this.supplier.supCanConfigList[index].canPnrTimeBegin = canPnrTime[0];
          this.supplier.supCanConfigList[index].canPnrTimeEnd = canPnrTime[1];
          if (canPnrTime[0] == '00:00:00' && canPnrTime[1] == '23:59:59') {
            this.supplier.supCanConfigList[index].allDayForCanPnr = 1;
            this.supplier.supCanConfigList[index].allDayForCanPnrBoolean = true;
          }
        },
        cancelChangeClickRefPnr(index) {
          let allDayForRefPnrBoolean = this.supplier.supCanConfigList[index].allDayForRefPnrBoolean;
          this.supplier.supCanConfigList[index].allDayForRefPnr = allDayForRefPnrBoolean ? 1 : 0;
          if (this.supplier.supCanConfigList[index].allDayForRefPnr == 1) {
            this.supplier.supCanConfigList[index].refPnrTime = ['00:00:00','23:59:59'];
            this.supplier.supCanConfigList[index].refPnrTimeBegin = '00:00:00';
            this.supplier.supCanConfigList[index].refPnrTimeEnd = '23:59:59';
          }
        },
        cancelBlurPickerRefPnrTime(index){
          let canPnrTime = this.supplier.supCanConfigList[index].canPnrTime;
          if (canPnrTime[0] == '00:00:00' && canPnrTime[1] == '23:59:59') {
            this.supplier.supCanConfigList[index].allDayForCanPnr = 1;
            this.supplier.supCanConfigList[index].allDayForCanPnrBoolean = true;
          }
        },
        cancelChangePickerRefPnrTime(index){
          let refPnrTime = this.supplier.supCanConfigList[index].refPnrTime;
          if (!refPnrTime) {
            this.supplier.supCanConfigList[index].allDayForRefPnr = 0;
            this.supplier.supCanConfigList[index].allDayForRefPnrBoolean = false;
            return
          }
          this.supplier.supCanConfigList[index].refPnrTimeBegin = refPnrTime[0];
          this.supplier.supCanConfigList[index].refPnrTimeEnd = refPnrTime[1];
          if (refPnrTime[0] == '00:00:00' && refPnrTime[1] == '23:59:59') {
            this.supplier.supCanConfigList[index].allDayForRefPnr = 1;
            this.supplier.supCanConfigList[index].allDayForRefPnrBoolean = true;
          }
        },
        cancelChangePicker(val){
            const __this = this;
            let supplier = JSON.parse(JSON.stringify(__this.supplier))
            if(val === 1){
                // 取位
                const t = __this.supplier.cancelTime;
                if(t[0]==='00:00:00' && t[1] === '23:59:59'){
                    supplier.allDayForCanPnrType=true;
                    supplier.allDayForCanPnr=1;
                } else {
                    supplier.allDayForCanPnrType=false;
                    supplier.allDayForCanPnr=0;
                }
                __this.supplier = supplier;
                return;
            }
            if(val === 2){
                // 退票取位
                let t2 =  __this.supplier.refCancelTime;
                if(t2[0]==='00:00:00' && t2[1] === '23:59:59'){
                    supplier.allDayForRefPnrType=true;
                    supplier.allDayForRefPnr = 1;
                } else {
                    supplier.allDayForRefPnrType=false;
                    supplier.allDayForRefPnr = 0;
                }
                __this.supplier = supplier;
                return;
            }
        },
    }
}
