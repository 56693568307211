import Q from 'q';
import moment from 'moment';
// 订单详情
import trains_platformOrder_getPlatformDetail from '@/lib/data-service/train/trains_platformOrder_getPlatformDetail';
// 更新保险状态
import trains_order_updateInsuranceStatus from '@/lib/data-service/train/trains_order_updateInsuranceStatus';
// 日志
import trains_OrderLog_getPlatformOrderLogList from '@/lib/data-service/train/trains_OrderLog_getPlatformOrderLogList';


export default {
    data () {
        return {
            loading: true,
            details: {},
            form: {},
            allocation: {
                orderType: [
                    { label: '全部', value: '' },
                    { label: '预订', value: '1' },
                    { label: '抢票', value: '2' },
                ],
                orderStatus: [],
            },
        }
    },
    methods: {
    },
    mounted () {

    },
    activated () {
    },
    deactivated () {
    },
    watch: {
    }
}
