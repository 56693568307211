import moment from 'moment';

// 下载模板
// import hotel_offlineOrder_getExcelTemplate from "@/lib/data-service/hotel/hotel_offlineOrder_getExcelTemplate.js";
// 上传文件组件
import hotel_offlineOrder_inprotFromExcel from "@/lib/data-service/hotel/hotel_offlineOrder_inprotFromExcel.js";
// 批量添加线下订单
import hotel_offlineOrder_addList from "@/lib/data-service/hotel/hotel_offlineOrder_addList.js";

import get_url_prefix from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/get_url_prefix";
export default {
  components: {},
  data() {
    return {
      moment: moment,
      // form: {
      //   currentPage: 1,
      //   pageSize: 10,
      // },
      // pager: {
      //   pageSizes: [10, 15, 20, 25, 30],
      //   total: 0,
      // },
      list: [],
      loading: false,
      fileLoading: false,
      templateUrl: "",
    };
  },
  filters: {
    format_payType(val) {
      switch (val) {
        case 0:
          return "未知";
          break;
        case 1:
          return "预付款";
          break;
        case 2:
          return "现结";
          break;
        case 3:
          return "周结";
          break;
        case 4:
          return "月结";
          break;
      }
    },
    format_purchaseInvoiceType(val) {
      switch (val) {
        case 0:
          return "未知";
          break;
        case 1:
          return "胤之旅";
          break;
        case 2:
          return "供应商";
          break;
        case 3:
          return "该酒店";
          break;
      }
    },
  },
  methods: {
    init() {
      const prefix = get_url_prefix({ appKey: `hotel` });
      this.templateUrl = prefix + "/hotel/offlineOrder/getExcelTemplate";
    },
    change_file(file) {
      const aFormData = new FormData();
      aFormData.append("file", file.raw);
      // aFormData.append('mchId', __this.payForm.mchId);
      // aFormData.append('mchId', __this.payForm.apiV3Key);
      // aFormData.append('appSecret', __this.payForm.appSecret);
      // aFormData.append('mchSerialNo', __this.payForm.mchSerialNo);

      hotel_offlineOrder_inprotFromExcel(aFormData)
        .then((result) => {
          console.log("result", result);
          this.fileLoading = false;
          this.list = result.data;
        })
        .catch((err) => {
          this.fileLoading = false;
        });
    },
    affirm_import() {
      hotel_offlineOrder_addList(this.list)
        .then((res) => {
          this.$message.success("批量添加成功");
          this.list = [];
        })
        .catch(() => {});
    },
    delet(orderNo) {
      this.$confirm("是否确认取消此订单？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const list = this.list;
          const index = list.findIndex((item) => item.orderNo === orderNo);
          list.splice(index, 1);
        })
        .catch(() => {});
    },
    // change_currentPage(currentPage) {
    //   this.form.currentPage = currentPage;
    //   this.req_list();
    // },
    // change_pageSize(pageSize) {
    //   this.form.pageSize = pageSize;
    //   this.change_currentPage(1);
    // },
  },
  mounted() {},
  activated() {
    this.init();
  },
  deactivated() {},
  watch: {},
};
