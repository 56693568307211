// web_tourismFullReduction_statisticsTotal 统计已开通优惠分销商，累计使用总额和当前生效总额

const __request = require(`@/lib/data-service/default/__request/__request_contentType_json`);

// 接口文档地址： http://192.168.0.15:8080/doc.html#/yinzhilv_travel/tour-order-controller/cutDownOrderUsingPOST
export default function web_tourismFullReduction_list(pParameter) {
    if (!pParameter) pParameter = {};
    let params = {}
    params.method = 'POST'
    params.urlSuffix = '/web/tourismFullReduction/list'
    params.data = {}
    params.process_response_data = false
    return new Promise(function (resolve, reject) {
        resolve(__request(params))
    })
}
