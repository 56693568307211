import Pagination from '@/component/base-pagination/index.vue'
import web_common_credit_theCreditBillList from '@/lib/data-service/default/web_common_credit_theCreditBillList'
import web_common_user_getDeveloper from '@/lib/data-service/default/web_common_user_getDeveloper'
import web_common_credit_postponeCredit from '@/lib/data-service/default/web_common_credit_postponeCredit'



import moment from 'moment'
import web_buyer_findAll from '@/lib/data-service/default/web_buyer_findAll'

export default {
    data () {
        return {
            moment: moment,
            loading: false,
            showMore: false,
            params: {
                creditStatus: 0,
                userName: '',
                companyName: '',
                buyerType: '',

                currentPage: 1,
                pageSize: 10,
                total: 0
            },
            list: [],
            userTypeList: [{value: '', label: '全部'}, {value: 1, label: '机票'}, {value: 2, label: '旅游'}, {value: '1+2', label: '机票+旅游'}],
        }
    },
    components: {
        Pagination
    },
    methods: {
        getBuyerList() {
            this.loading = true;
            web_buyer_findAll(this.params).then((res) => {
                this.list = res.pageResult.pageData;
                this.params.total = res.pageResult.totalCount;
                this.loading = false
            }).catch(() => {
                this.loading = false
            }, new Error(() => {
                this.loading = false
            }))
        },

        getDeveloperList() {
            web_common_user_getDeveloper().then((res) => {
                this.accountManagerList = res.getAllDeveloperResult
            })
        },
        onSearch() {
            this.params.currentPage = 1;
            this.getBuyerList()
        },
        onReset() {
            this.params = {
                creditStatus: 0,
                userName: '',
                companyName: '',
                buyerType: '',
                currentPage: 1,
                pageSize: 10,
                total: 0
            };
            this.onSearch()
        },
        to_detail(row) {
            this.$router.push({
                name: 'admin-credit-open-detail',
                query: {
                    buyerId: row.buyerId,
                    creditId: row.creditId
                }
            })
        }
    },
    activated () {
        this.getBuyerList();
        this.getDeveloperList()
    },
}
