// web_common_user_vueCheckUserName  查询用户表用户名是否存在

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口文档地址： http://tourtest.yinzhilv.com/doc.html#/yinzhilv_travel/user-controller/checkUserNameVerifyUsingPOST
export default function web_common_user_vueCheckUserName(pParameter) {
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/common/user/vueCheckUserName';
  params.data = pParameter;

  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
