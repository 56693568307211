import Pagination from "@/component/base-pagination/index.vue";
import ifInvoiceEnums from "@/lib/data-service/hotel/hotel_platFormOrder_ifInvoiceEnums";
import settlementEnums from "@/lib/data-service/hotel/hotel_platFormOrder_settlementEnums";
import list from "@/lib/data-service/hotel/hotel_manager_order_queryHotelPlatformCancelOrderList";
import _ from 'underscore'
import moment from 'moment'
import to_boolean from 'nllib/src/convert/to_boolean'
export default {
    name: "hotelList",
    data() {
        return {
            params: {
                ifInvoice: 0,
                settlement: 0,
                total: 0,
                currentPage: 1,
                pageSize: 10,
                cancelStatus: ""
            },
            ifInvoiceList:[],
            settlementList:[],
            navList: [
                { label: "全部", value: "" },
                { label: "申请取消", value: "0" },
                { label: "取消成功", value: "1" },
                { label: "待平台确认", value: "2" },
                { label: "取消失败", value: "3" }
            ],
            orderList: []
        };
    },
    components: { Pagination },
    created() {
        this.getIfInvoiceList();
        this.getSettlementList();
        this.onSearch();
    },
    methods: {
        pushHotelOrder(orderNo,supplierId,type){
            const __this = this;
            let name = `hotel-order-detail`;

            const data_app_for_npm_yinzhilv_frontend_platform_flight = __this.$route.meta.data_app_for_npm_yinzhilv_frontend_platform_flight;
            if (data_app_for_npm_yinzhilv_frontend_platform_flight) {
                const get_route_detail_page = data_app_for_npm_yinzhilv_frontend_platform_flight.get_route_detail_page;
                if (_.isFunction(get_route_detail_page)) {
                    const route_detail_page = get_route_detail_page();
                    if (route_detail_page) {
                        const route_name = route_detail_page.route_name;
                        if (route_name) {
                            name = route_name;
                        }
                    }
                }
            }
            __this.$router.push({
                name,
                query: {
                    orderNo,
                    supplierId,
                    type
                }
            });
        },
        getIfInvoiceList(){
            ifInvoiceEnums().then(res => {
                this.ifInvoiceList=[{msg: "全部", code: 0}, ...res.result]
            })
        },
        getSettlementList(){
            settlementEnums().then(res => {
                this.settlementList=[{msg: "全部", type: 0}, ...res.result]
            })
        },
        onSearch(type) {
            let data = this.params;
            type ? "" : (data.currentPage = 1);
            this.loading = true;
            list(data).then(res => {
                this.loading = false;
                this.orderList = res.pageResult.pageData;
                this.params.total = res.pageResult.totalCount;
            });
        },
        onReset() {
            let data = {
                ifInvoice: 0,
                settlement: 0,
                total: 0,
                currentPage: 1,
                pageSize: 10
            };
            this.params = data;
            this.onSearch();
        },
        getOrderList(val) {
            this.params.currentPage = val.page;
            this.onSearch("page");
        },
        handleClick(val) {
            this.params.cancelStatus = val;
            this.onSearch();
        }
    }
};
