// web_buyer_flightBuyer_findPaymentChannel 获取支付方式类型枚举

const __request = require(`@/lib/data-service/default/__request/__request_contentType_json`);

// 接口地址：http://192.168.0.19:9999/doc.html#/yinzhilv_travel/buyer-controller/findPaymentChannelUsingPOST

export default function web_buyer_flightBuyer_findPaymentChannel(pParameter) {
  if (!pParameter) pParameter = {};
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/buyer/flightBuyer/findPaymentChannel'
  params.data = pParameter
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}
