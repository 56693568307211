import moment from "moment";
export default {
    data() {
        return {
            polar: {}
        }
    },
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        /*
        * 入参params obj
        *   title: 标题
        *   type： 图标类型
        *   data： 数组，内含对象
        *   valueYType： 区分y轴的分类名字
        *   valueForY： y轴的取值名字
        *   valueForX： x轴的取值名字
        *   showLegend: 是否显示分组的名称
        *   valueForTypeName： 用作分组的名称
        *   xLabelShowWeek: boolean 是否在x轴相关的label上显示星期几，默认flase
        * */
        init(params) {
            const _this = this
            let title = params.title || ''
            let type = params.type
            let data = params.data
            const valueYType = params.valueYType || 'type'
            const valueForY = params.valueForY || 'value'
            const valueForX = params.valueForX || 'label'
            const showLegend = params.showLegend || false
            const valueForTypeName = params.valueForTypeName || 'typeText'
            const xLabelShowWeek = params.xLabelShowWeek || false
            let typeTitList = []
            let typeNameList = []
            let dataList = []
            let dataDetailList = []
            data.forEach(value => {
                const index = typeTitList.indexOf(value[valueYType])
                if (index === -1) {
                    typeTitList.push(value[valueYType])
                    let newDataListItem = []
                    newDataListItem.push(value[valueForY])
                    dataList.push(newDataListItem)
                    let newDataDetailListItem = []
                    newDataDetailListItem.push(value)
                    dataDetailList.push(newDataDetailListItem)
                    if (showLegend) {
                        typeNameList.push(value[valueForTypeName])
                    }
                } else {
                    dataList[index].push(value[valueForY])
                    dataDetailList[index].push(value)
                }
            })
            let x = []
            if (dataDetailList.length > 0) {
                dataDetailList[0].forEach(value => {
                    x.push(value[valueForX])
                })
            }
            let series = []
            dataList.forEach((value, index) => {
                const item = {
                    data: value,
                    type: type,
                    label: {
                        show: true,
                        position: 'top'
                    },
                    // stack: 'Total',
                }
                if (showLegend) {
                    item.name = typeNameList[index]
                }
                series.push(item)
            })
            let polar = {
                title: {
                    text: title
                },
                tooltip: {
                    trigger: 'axis',
                },
                grid: {
                    left: '1%',
                    right: '2%',
                    bottom: '1%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    axisTick: {
                        alignWithLabel: true
                    },
                    data: x,
                    axisPointer: {
                        label: {
                            formatter:(params) => {
                                let week = ''
                                if (xLabelShowWeek) {
                                    week = _this._getWeek(params.value)
                                }
                                return (
                                    params.value + ' ' + week
                                );
                            }
                        }
                    }
                },
                yAxis: {
                    type: 'value',
                    axisTick: {
                        alignWithLabel: true
                    },
                    axisLine: {
                        show: true,
                    },
                },
                series: series
            }
            if (showLegend) {
                polar.legend = {
                    data: typeNameList
                }
            }
            this.polar = polar
            this.$nextTick(() => {
                this.$refs.chart.resize();
            })
        },
        _getWeek (date) { // 参数时间戳
            let week = moment(date).day()
            switch (week) {
                case 1:
                    return '周一'
                case 2:
                    return '周二'
                case 3:
                    return '周三'
                case 4:
                    return '周四'
                case 5:
                    return '周五'
                case 6:
                    return '周六'
                case 0:
                    return '周日'
            }
        },
    }
}