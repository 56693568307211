import Q from 'q';
import moment from 'moment';
// 订单确认
import web_salesOrder_platformOrderAuditList from '@/lib/data-service/default/web_salesOrder_platformOrderAuditList';
// 出团通知单
import web_common_salesOrder_getAuditdetails from '@/lib/data-service/default/web_common_salesOrder_getAuditdetails';

import Citys from '@/component/citys/index.vue';

export default {
  components: {
    Citys,
  },
  data() {
    return {
      form: {
        currentPage: 1,
        pageSize: 10,
        productNo: '',
        productName: '',
        buyerName: '',
        orderNo: '',
        departtureDateStart: '',
        departtureDateEnd: '',
        orderDateStart: '',
        orderDateEnd: '',
        collectGuestType: '',
        setOutCountry: '',
        setoutProvince: '',
        setoutCity: '',
        productType: '',
        productAreasType: '',
        destinationCountry: '',
        destinationProvince: '',
        destinationCity: '',
        orderState: 2,
        finalPayment: '',
        orderCancel: '',
        setOutCountry: '',
        setOutProvince: '',
        setOutCity: '',
        destinationCountry: '',
        destinationProvince: '',
        destinationCity: '',
      },
      allocation: {
        collectGuestType: [
          { label: '全部', value: '' },
          { label: '散拼团', value: 1 },
          { label: '小包团', value: 2 },
        ],
        productType: [
          { label: '全部', value: '' },
          { label: '跟团游', value: 1 },
          { label: '自由行', value: 2 },
          { label: '当地游', value: 3 }
        ],
        productAreasType: [
          { label: '全部', value: '' },
          { label: '国内', value: 1 },
          { label: '出境', value: 2 }
        ],
        state: [
          { label: '待确认', value: 2 },
          { label: '确认失败', value: 0 },
        ]
      },
      loading: false,
      pager: {
        pageSizes: [10, 20, 30, 40, 50],
        totalCount: 0
      },
      orderList: [],
      show: false,
      state: 2,
      dialogVisible: false,
      platformAuditContents: '',
      supplierAuditContents: '',
    };
  },
  methods: {
    req_order_list(params, type) {
      this.loading = true;
      const _this = this;
      Q.when()
        .then(function() {
          return web_salesOrder_platformOrderAuditList(params);
        })
        .then(function(res) {
          const orderList = res.pageResult.pageData;
          orderList.forEach(item => {
            if (item.departureDate) item.departureDate = moment(item.departureDate).format('YYYY-MM-DD');
          })
          _this.orderList = orderList;
          _this.pager.totalCount = res.pageResult.totalCount;
          if (type) document.documentElement.scrollTop = 0;
          _this.state = _this.form.orderState;
          _this.loading = false;
        });
    },
    req_fall (params) {
      return Q.when()
        .then(function () {
          return web_common_salesOrder_getAuditdetails(params);
        })
    },
    getFromCountry (value) {
      this.form.setOutCountry = value;
    },
    getFromProvince (value) {
      this.form.setOutProvince = value;
    },
    getFromCity (value) {
      this.form.setOutCity = value;
    },
    getToCountry (value) {
      this.form.destinationCountry = value;
    },
    getToProvince (value) {
      this.form.destinationProvince = value;
    },
    getToCity (value) {
      this.form.destinationCity = value;
    },
    fold_change() {
      this.show = !this.show;
    },
    search() {
      this.req_order_list(this.form);
    },
    reset() {
      const pageSize = this.form.pageSize;
      this.form = {
        currentPage: 1,
        pageSize,
        productNo: '',
        orderNo: '',
        productName: '',
        buyerName: '',
        departtureDateStart: '',
        departtureDateEnd: '',
        orderDateStart: '',
        orderDateEnd: '',
        collectGuestType: '',
        setOutCountry: '',
        setoutProvince: '',
        setoutCity: '',
        productType: '',
        productAreasType: '',
        destinationCountry: '',
        destinationProvince: '',
        destinationCity: '',
        orderState: 2,
        setOutCountry: '',
        setOutProvince: '',
        setOutCity: '',
        destinationCountry: '',
        destinationProvince: '',
        destinationCity: '',
      };
      this.$refs.fromCity.reset_toArea();
      this.$refs.toCity.reset_toArea();
      this.req_order_list(this.form);
    },
    change_state () {
      this.form.currentPage = 1;
      this.req_order_list(this.form);
    },
    to_audit(id) {
      this.$router.push({
        name: 'admin-plain-order-detail',
        query: { id ,type: 'plain_order_audit' },
      });
    },
    look_fall (id) {
      const _this = this;
      Q.when()
        .then(function () {
          return _this.req_fall({id});
        })
        .then(function (res) {
          _this.platformAuditContents = res.platformAuditContents;
          _this.supplierAuditContents = res.supplierAuditContents;
          _this.dialogVisible = true;
        })
    },
    handleClose () {
      this.dialogVisible = false;
      this.platformAuditContents = '';
      this.supplierAuditContents = '';
    },
    handleSizeChange(pageSize) {
      this.form.pageSize = pageSize;
      this.req_order_list(this.form, 'pageSize');
    },
    handleCurrentChange(currentPage) {
      this.form.currentPage = currentPage;
      this.req_order_list(this.form, 'currentPage');
    }
  },
  created() {},
  mounted() {},
  activated() {
    this.req_order_list(this.form);
  },
  deactivated() {},
  destroyed() {},
  watch: {
    state: function (newVal) {
      this.form.orderState = newVal;
      this.req_order_list(this.form);
    }
  },
  computed: {}
};
