export default {
  name: 'PreviewImage',
  data () {
    return {}
  },
  props: {
    srcList: {
      type: Array,
      default: () => []
    },
    src: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '140px'
    },
    height: {
      type: String,
      default: '140px'
    }
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
    showFullPic(val) {
      this.$emit('showFull', val)
    }
  }
}
