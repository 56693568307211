import import_latest_version_module from '@/page/login/import_latest_version_module';

const get_route_root = function () {
    const r = {
        name: `root`,
        path: '/',
        component: () => import_latest_version_module(),
        meta: {
            enable_check_token: false,
        },
    };

    return r;
};

export default get_route_root;
