import Q from 'q';
// 设置
import web_common_esIgn_esIgnPlatformController_platformSetting from '@/lib/data-service/default/web_common_esIgn_esIgnPlatformController_platformSetting'
// 回显
import web_esIgn_esIgnPlatformController_platformSettingEcho from '@/lib/data-service/default/web_esIgn_esIgnPlatformController_platformSettingEcho'
// 返回pdf
import web_esIgn_esIgnPlatformController_supplierFileUpload from '@/lib/data-service/default/web_esIgn_esIgnPlatformController_supplierFileUpload'
// 回显pdf
import web_esIgn_esIgnPlatformController_supplierFilePreview from '@/lib/data-service/default/web_esIgn_esIgnPlatformController_supplierFilePreview'



// 确认无误
import web_esIgn_esIgnPlatformController_supplierAffirmFile from '@/lib/data-service/default/web_esIgn_esIgnPlatformController_supplierAffirmFile'



// 修改历史标签
import history_tag_api from '@/component/admin-layout/1.0.0/component/history-tag/api'

export default {
  data(){

    const percentageCharges = (rule, value, callback) => {
      const financialSettlement = this.form.financialSettlement;
      if (financialSettlement.tollCollectionManner === '2') {
        if (value === '' || value === undefined) {
          callback(new Error('结算金额百分比'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };

    return{
      form: {
        supplierId: '',
        agreementNo: '',
        companyName: '',
        legalRepresentative: '',
        address: '',
        socialCreditCode: '',
        openBank: '',
        openBankName: '',
        accountNumber: '',
        emergencyContac: '',
        emergencyContacPhone: '',
        businessContact: {
          businessContact: '',
          phone: '',
        },
        productType: '',
        cooperationFee: {
          flowingWater: '',
          promotionBonus: '',
          guaranteeDeposit: '',
        },
        financialSettlement: {
          tollCollectionManner: '',
          percentageCharges: '',
          ssueAnInvoice: '',
          payTypeText: '',
          settlementInterval: '',
        },
        contractLife: '',
        mailbox: {
          firstPartyEmail: '',
          secondPartyEmaill: '',
        },
        rebatePolicy: '',
      },
      rules: {
        agreementNo: [
          { required: true, message: '请输入协议编号', trigger: 'blur' },
        ],
        companyName: [
          { required: true, message: '请输入公司全称', trigger: 'blur' },
        ],
        legalRepresentative: [
          { required: true, message: '请输入法人姓名', trigger: 'blur' },
        ],
        address: [
          { required: true, message: '请输入公司地址', trigger: 'blur' },
        ],
        socialCreditCode: [
          { required: true, message: '请输入统一社会信用代码', trigger: 'blur' },
        ],
        openBank: [
          { required: true, message: '请输入开户银行', trigger: 'blur' },
        ],
        openBankName: [
          { required: true, message: '请输入开户名称', trigger: 'blur' },
        ],
        accountNumber: [
          { required: true, message: '请输入开户账号', trigger: 'blur' },
        ],
        emergencyContac: [
          { required: true, message: '请输入紧急联系人', trigger: 'blur' },
        ],
        emergencyContacPhone: [
          { required: true, message: '请输入紧急联系电话', trigger: 'blur' },
        ],
        businessContact: {
          businessContact: [
            { required: true, message: '请输入业务对接人', trigger: 'blur' },
          ],
          phone: [
            { required: true, message: '请输入业务对接人联系电话', trigger: 'blur' },
          ],
        },
        productType: [
          { required: true, message: '请输入产品类型', trigger: 'blur' },
        ],
        cooperationFee: {
          flowingWater: [
            { required: true, message: '请输入流水额度', trigger: 'blur' },
          ],
          promotionBonus: [
            { required: true, message: '请输入推广奖金', trigger: 'blur' },
          ],
          guaranteeDeposit: [
            { required: true, message: '请输入质保金', trigger: 'blur' },
          ]
        },
        financialSettlement: {
          tollCollectionManner: [
            { required: true, message: '请选择收费方式',trigger: 'blur' }
          ],
          percentageCharges: [
            { validator: percentageCharges, trigger: 'blur' }
          ],
          ssueAnInvoice: [
            { required: true, message: '请输入发票开具约定', trigger: 'blur' }
          ],
          payTypeText: [
            { required: true, message: '请输入结算支付方式', trigger: 'blur' }
          ],
          settlementInterval: [
            { required: true, message: '请输入结算周期', trigger: 'blur' }
          ]
        },
        contractLife: [
          { required: true, message: '请输入合同有效期', trigger: 'blur' }
        ],
        mailbox: {
          firstPartyEmail: [
            { required: true, message: '请输入甲方邮箱', trigger: 'blur' },
            { pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/, message: '请输入正确的邮箱账号', trigger: 'blur' }
          ],
          secondPartyEmaill: [
            { required: true, message: '请输入乙方邮箱', trigger: 'blur' },
            { pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/, message: '请输入正确的邮箱账号', trigger: 'blur' }
          ],
        },
      },
      allocation: {
        tollType: [
          { label: '1.4.1', value: 1 },
          { label: '1.4.2', value: 2 },
        ],
      },
      dialogVisible: false,
      pdf: '',
      pdfName: '',
      supplierStatus: '',
    }
  },
  methods: {
    req_detail (params) {
      const _this = this;
      Q.when()
        .then(function () {
          return web_esIgn_esIgnPlatformController_platformSettingEcho(params);
        })
        .then(function (res) {
          _this.supplierStatus = res.supplierStatus;
          const form = _this.form;
          form.supplierId = _this.$route.query.supplierId;
          form.agreementNo = res.agreementNo ? res.agreementNo : '';
          form.companyName = res.companyName ? res.companyName : '';
          form.legalRepresentative = res.legalRepresentative ?  res.legalRepresentative : '';
          form.address = res.address ?  res.address : '';
          form.socialCreditCode = res.socialCreditCode ?  res.socialCreditCode : '';
          form.openBank = res.openBank ? res.openBank : '';
          form.openBankName = res.openBankName ? res.openBankName : '';
          form.accountNumber = res.accountNumber ? parseInt(res.accountNumber) : '';
          form.emergencyContac = res.emergencyContac ? res.emergencyContac : '';
          form.emergencyContacPhone = res.emergencyContacPhone ? parseInt(res.emergencyContacPhone) : '';
          form.businessContact.businessContact = res.businessContact.businessContact ? res.businessContact.businessContact : '';
          form.businessContact.phone = res.businessContact.phone ? parseInt(res.businessContact.phone) : '';
          form.productType = res.productType ? res.productType : '';

          form.cooperationFee.flowingWater = res.cooperationFee.flowingWater ? parseInt(res.cooperationFee.flowingWater) : '';
          form.cooperationFee.guaranteeDeposit = res.cooperationFee.guaranteeDeposit ? parseInt(res.cooperationFee.guaranteeDeposit) : '';
          form.cooperationFee.promotionBonus = res.cooperationFee.promotionBonus ? parseInt(res.cooperationFee.promotionBonus) : '';

          form.financialSettlement = res.financialSettlement ? res.financialSettlement : '';
          form.contractLife = res.contractLife ? parseInt(res.contractLife) : '';
          form.mailbox = res.mailbox ? res.mailbox : '';
          form.rebatePolicy = res.rebatePolicy ? res.rebatePolicy : '';

          if (_this.supplierStatus === 3 ||_this.supplierStatus === 6 || _this.supplierStatus === 7) _this.req_echo_pdf({supplierId: _this.$route.query.supplierId});

        })
    },
    req_commit (params) {
      return Q.when()
        .then(function () {
          return web_common_esIgn_esIgnPlatformController_platformSetting(params);
        })
    },
    req_pdf (params) {
      return Q.when()
        .then(function () {
          return web_esIgn_esIgnPlatformController_supplierFileUpload(params);
        })
    },
    req_change_state (params) {
      const _this = this;
      Q.when()
        .then(function () {
          return web_esIgn_esIgnPlatformController_supplierAffirmFile(params);
        })
        .then(function () {
          _this.dialogVisible = false;
          _this.$message.success('合同设置成功');
          _this.$router.push({
            name: 'admin-supplier-list',
          })
        })
    },
    req_echo_pdf (params) {
      const _this = this;
      Q.when()
        .then(function () {
          return web_esIgn_esIgnPlatformController_supplierFilePreview(params);
        })
        .then(function (res) {
          const url = res.fileResults[0].downloadUrl;
          const name = res.fileResults[0].fileName;
          if (!url) _this.$message.error('返回url为空，请联系后台管理员');
          _this.pdf = url;
          _this.pdfName = name;
        })
    },
    commit (type) {
      const _this = this;
      this.$refs.form.validate((valid) => {
        const form = _this.form;
        if (valid) {
          const params = {
            supplierId: form.supplierId,
            companyName: form.companyName,
            agreementNo: form.agreementNo,
            legalRepresentative: form.legalRepresentative,
            address: form.address,
            socialCreditCode: form.socialCreditCode,
            openBank: form.openBank,
            openBankName: form.openBankName,
            accountNumber: form.accountNumber,
            emergencyContac: form.emergencyContac,
            emergencyContacPhone: form.emergencyContacPhone,
            businessContact: form.businessContact,
            productType: form.productType,
            cooperationFee: form.cooperationFee,
            financialSettlement: form.financialSettlement,
            contractLife: form.contractLife,
            mailbox: form.mailbox,
            rebatePolicy: form.rebatePolicy,
          }
          Q.when()
              .then(function () {
                return _this.req_commit(params);
              })
              .then(function (res) {
                if (type === 'create') {
                  alert('生成成功')
                  return _this.req_pdf({supplierId: _this.form.supplierId});
                } else if (type === 'draft') {
                  _this.$message.success('暂存草稿成功');
                  _this.$router.push({
                    name: 'admin-supplier-list'
                  })
                  reject();
                }
              })
              .then(function (res) {
                _this.pdf = res.downloadUrl;
                _this.pdfName = res.fileName;
                _this.dialogVisible = true;
              })
        } else {
          return false;
        }
      });
    },
    handleClose () {
      this.dialogVisible = false;
      this.pdf = '';
    },
    goBack () {
      history_tag_api.remove_tag_by_route({route: this.$route});
      this.$router.back(-1);
    },
    change_state () {
      this.req_change_state({supplierId: this.form.supplierId});
    },
    input_trim (key) {
      const form = this.form;
      if (key.indexOf('.') === -1) {
        form[key] = form[key].replace(' ','');
      } else {
        const keyArr = key.split('.');
        form[keyArr[0]][keyArr[1]] = form[keyArr[0]][keyArr[1]].replace(' ','');
      }
    },
    input_positive (key) {
      const form = this.form;
      if (key.indexOf('.') === -1) {
        if (form[key] < 0) form[key] = 0;
      } else {
        const keyArr = key.split('.');
        if (form[keyArr[0]][keyArr[1]] < 0) form[keyArr[0]][keyArr[1]] = 0;
      }
    },
    input_number (key) {
      const form = this.form;
      const reg = /\D/g;
      if (key.indexOf('.') === -1) {
        form[key] = form[key].replace(reg, '');
      } else {
        const keyArr = key.split('.');
        form[keyArr[0]][keyArr[1]] = form[keyArr[0]][keyArr[1]].replace(reg, '');
      }
    },
    input_nan (key) {

      const form = this.form;
      const reg = /\D/;
      if (key.indexOf('.') === -1) {
        if (typeof form[key] !== 'string')  return;
        form[key] = form[key].replace(reg, '');
      } else {
        const keyArr = key.split('.');
        if (typeof form[keyArr[0]][keyArr[1]] !== 'string')  return;
        form[keyArr[0]][keyArr[1]] = form[keyArr[0]][keyArr[1]].replace(reg, '');
      }
    },
    reset () {
      this.form = {
        supplierId: '',
        agreementNo: '',
        companyName: '',
        legalRepresentative: '',
        address: '',
        socialCreditCode: '',
        openBank: '',
        openBankName: '',
        accountNumber: '',
        emergencyContac: '',
        emergencyContacPhone: '',
        businessContact: {
          businessContact: '',
          phone: '',
        },
        productType: '',
        cooperationFee: {
          flowingWater: '',
          promotionBonus: '',
          guaranteeDeposit: '',
        },
        financialSettlement: {
          tollCollectionManner: '',
          percentageCharges: '',
          ssueAnInvoice: '',
          payTypeText: '',
          settlementInterval: '',
        },
        contractLife: '',
        mailbox: {
          firstPartyEmail: '',
          secondPartyEmaill: '',
        },
        rebatePolicy: '',
      };
    }
  },
  mounted(){

  },
  activated () {
    this.reset();
    document.documentElement.scrollTop = 0;
    this.req_detail({supplierId: this.$route.query.supplierId});
  }
}
