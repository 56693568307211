import web_circuit_findTouristTour from '@/lib/data-service/default/web_circuit_findTouristTour'
import web_circuit_line_list from '@/lib/data-service/default/web_circuit_line_list'
import web_circuit_line_editor from '@/lib/data-service/default/web_circuit_line_editor'
import web_circuit_line_add from '@/lib/data-service/default/web_circuit_line_add'
import web_circuit_line_delete from '@/lib/data-service/default/web_circuit_line_delete'
import web_managerSupplier_details from '@/lib/data-service/default/web_managerSupplier_details'
import { awaitWrap } from '../utils/common'

export default {
  data () {
    const validateAmount = (rule, value, callback) => {
      if (this.tourParams.attachType !== 0 && this.tourParams.attachAmountType === 1) {
        if (value === '') {
          callback(new Error('不能为空'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    };
    const validatePercent = (rule, value, callback) => {
      if (this.tourParams.attachType !== 0 && this.tourParams.attachAmountType === 2) {
        if (value === '') {
          callback(new Error('不能为空'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    };
    const validateServiceFeePercent = (rule, value, callback) => {
      if (this.tourParams.serviceFeeType !== 0) {
        if (value === '') {
          callback(new Error('不能为空'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    };
    const validateSettleDeposit = (rule, value, callback) => {
      if (value >= 0) {
        callback()
      } else {
        callback(new Error('不能输入负数'))
      }
    };
    return {
      loading: false,
      detail: {},
      supplier: {
        settleDepositType: 0, // 订金收取方式 0 -不收 2定额 1比例
        settleDeposit: 0, // 订金
        finalPaymentCycle: 0, // 尾款周期，出团前
        finalCycleUnit: 1, // 尾款周期单位 1-天 2-周 3-月 默认天
        finalPaymentType: 1, // 默认出团前 自增
        isHasSettle: 1, // 收订金还是全款 1-订金 0全款 自增
        settleType: 2, // 1比例，2定额 自增

        pressAmountType: 0, // 压款收取方式 0-不压 1 比例 2定额
        pressAmount: 0, // 压款金额
        presslPaymentCycle: 0, // 压款周期，出团后
        presslCycleUnit: 1, // 压款周期单位 1-天 2-周 3-月 默认天
        pressPaymentType: 1, // 默认出团后 自增
        isHasPress: 1, // 是否压款 0-不压 1压 自增
        pressType: 2, // 1比例，2定额 自增
      },
      tourLineList: [],
      tourList: [],
      id: '',
      tourParams: {
        supplierId: '',
        attachAdultAmount: '',
        attachAdultPercent: '',
        attachAgedAmount: '',
        attachAgedPercent: '',
        attachAmountType: 1,
        attachChildAmount: '',
        attachChildBedAmount: '',
        attachChildBedPercent: '',
        attachChildPercent: '',
        attachType: 1,
        isDelete: 0,
        lineId: '',
        lineName: '',
        lineType: '',
        serviceFeePercent: '',
        serviceFeeType: 0,

        settleDepositType: 0, // 订金收取方式 0 -不收 2定额 1比例
        settleDeposit: 0, // 订金
        finalPaymentCycle: 0, // 尾款周期，出团前
        finalCycleUnit: 1, // 尾款周期单位 1-天 2-周 3-月 默认天
        finalPaymentType: 1, // 默认出团前 自增
        isHasSettle: 1, // 收订金还是全款 1-订金 0全款 自增
        settleType: 2, // 1比例，2定额 自增

        pressAmountType: 0, // 压款收取方式 0-不压 1 比例 2定额
        pressAmount: 0, // 压款金额
        presslPaymentCycle: 0, // 压款周期，出团后
        presslCycleUnit: 1, // 压款周期单位 1-天 2-周 3-月 默认天
        pressPaymentType: 1, // 默认出团后 自增
        isHasPress: 1, // 是否压款 0-不压 1压 自增
        pressType: 2, // 1比例，2定额 自增
      },
      canSubmitTour: true,
      dialogVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '创建'
      },
      tourRule: {
        lineId: [
          {required: true, message: '请选择线路', trigger: 'change'},
        ],
        attachAdultAmount: [
          {validator: validateAmount, trigger: 'blur'}
        ],
        attachChildBedAmount: [
          {validator: validateAmount, trigger: 'blur'}
        ],
        attachChildAmount: [
          {validator: validateAmount, trigger: 'blur'}
        ],
        attachAgedAmount: [
          {validator: validateAmount, trigger: 'blur'}
        ],
        attachAdultPercent: [
          {validator: validatePercent, trigger: 'blur'}
        ],
        attachChildBedPercent: [
          {validator: validatePercent, trigger: 'blur'}
        ],
        attachChildPercent: [
          {validator: validatePercent, trigger: 'blur'}
        ],
        attachAgedPercent: [
          {validator: validatePercent, trigger: 'blur'}
        ],
        serviceFeePercent: [
          {validator: validateServiceFeePercent, trigger: 'blur'}
        ],
        settleDeposit: [
          {required: true, message: '订金金额不能为空', trigger: 'blur'},
          {validator: validateSettleDeposit, trigger: 'blur'}
        ],
        finalPaymentCycle: [
          {required: true, message: '尾款周期不能为空', trigger: 'blur'},
          {validator: validateSettleDeposit, trigger: 'blur'}
        ],
        pressAmount: [
          {required: true, message: '压款金额不能为空', trigger: 'blur'},
          {validator: validateSettleDeposit, trigger: 'blur'}
        ],
        presslPaymentCycle: [
          {required: true, message: '压款周期不能为空', trigger: 'blur'},
          {validator: validateSettleDeposit, trigger: 'blur'}
        ]
      },
      supplierServiceFeePercent: '',
      supplierServiceFeeType: ''
    }
  },
  components: {},
  created () {
  },
  mounted () {

  },
  async activated () {
    this.id = this.$route.query.id
    await this.getSupplierDetail(this.id)
    await this.findTourListTour()
    await this.getTourLineList(this.id)
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {
    filterAttachType(val) {
      if (val === 1) {
        return '加价'
      } else if (val === 2) {
        return '减免'
      } else {
        return '不附加'
      }
    },
    filterServiceFeeType(val) {
      if (val === 0) {
        return '默认设置'
      } else if (val === 1) {
        return '定额'
      } else {
        return '比例'
      }
    }
  },
  methods: {
    formatterAttachType(val) {
      if (val.attachType === 1) {
        return '加价'
      } else if (val.attachType === 2) {
        return '减免'
      } else {
        return '不附加'
      }
    },
    formatterSettleDepositType(val) {
      if (val.settleDepositType === 0) {
        return '全额'
      } else {
        return '订金'
      }
    },
    formatterSettleDepositFun(val) {
      if (val.settleDepositType === 0) {
        return '--'
      } else if (val.settleDepositType === 1) {
        return '比例'
      } else {
        return '定额'
      }
    },
    formatterSettleDeposit(val) {
      if (val.settleDepositType === 0) {
        return '--'
      } else if (val.settleDepositType === 1) {
        return val.settleDeposit + '%'
      } else {
        return val.settleDeposit
      }
    },
    formatterFinalPaymentCycle(val) {
      if (val.settleDepositType === 0) {
        return '--'
      } else {
        return `出团前${val.finalPaymentCycle}天`
      }
    },
    formatterPressAmountType(val) {
      if (val.pressAmountType === 0) {
        return '不压款'
      } else {
        return '压款'
      }
    },
    formatterPressAmountFun(val) {
      if (val.pressAmountType === 0) {
        return '--'
      } else if (val.pressAmountType === 1) {
        return '百分比'
      } else {
        return '定额'
      }
    },
    formatterPressAmount(val) {
      if (val.pressAmountType === 0) {
        return '--'
      } else if (val.pressAmountType === 1) {
        return val.pressAmount + '%'
      } else {
        return val.pressAmount
      }
    },
    formatterPresslPaymentCycle(val) {
      if (val.pressAmountType === 0) {
        return '--'
      } else {
        return `出团后${val.presslPaymentCycle}天`
      }
    },
    async getSupplierDetail(supplierId) {
      let res = await web_managerSupplier_details({supplierId})
      this.detail = res.result
      let result = res.result
      let supplier = {
        settleDepositType: result.settleDepositType, // 订金收取方式 0 -不收 2定额 1比例
        settleDeposit: result.settleDeposit, // 订金
        finalPaymentCycle: result.finalPaymentCycle, // 尾款周期，出团前
        finalCycleUnit: (result.finalCycleUnit === 0 || result.finalCycleUnit === null) ? 1 : result.finalCycleUnit, // 尾款周期单位 1-天 2-周 3-月 默认天
        finalPaymentType: 1, // 默认出团前 自增
        isHasSettle: 1, // 收订金还是全款 1-订金 0全款 自增
        settleType: 2, // 1比例，2定额 自增

        pressAmountType: result.pressAmountType, // 压款收取方式 0-不压 1 比例 2定额
        pressAmount: result.pressAmount, // 压款金额
        presslPaymentCycle: result.presslPaymentCycle, // 压款周期，出团后
        presslCycleUnit: (result.presslCycleUnit === 0 || result.presslCycleUnit === null) ? 1 : result.presslCycleUnit, // 压款周期单位 1-天 2-周 3-月 默认天
        pressPaymentType: 1, // 默认出团后 自增
        isHasPress: 1, // 是否压款 0-不压 1压 自增
        pressType: 2, // 1比例，2定额 自增
      }
      if (supplier.settleDepositType === 0) {
        supplier.isHasSettle = 0
        supplier.settleType = 1
      } else if (supplier.settleDepositType === 1) {
        supplier.isHasSettle = 1
        supplier.settleType = 1
      } else {
        supplier.isHasSettle = 1
        supplier.settleType = 2
      }
      if (supplier.pressAmountType === 0) {
        supplier.isHasPress = 0
        supplier.pressType = 1
      } else if (supplier.pressAmountType === 1) {
        supplier.isHasPress = 1
        supplier.pressType = 1
      } else {
        supplier.isHasPress = 1
        supplier.pressType = 2
      }
      this.supplier = supplier
      console.log(this.supplier)
    },
    // 获取旅行线路
    async findTourListTour() {
      let res = await web_circuit_findTouristTour()
      this.tourList = res.result
    },
    async getTourLineList(supplierId) {
      this.loading = true
      let [err, res] = await awaitWrap(web_circuit_line_list({supplierId}))
      if (err) {
        this.loading = false
        return false
      }
      this.tourLineList = res.lineListResult
      this.supplierServiceFeePercent = res.supplierServiceFeePercent
      this.supplierServiceFeeType = res.supplierServiceFeeType
      this.loading = false

      for (let i = 0, l = this.tourLineList.length; i < l; i++) {
        this.tourLineList[i].finalCycleUnit = (this.tourLineList[i].finalCycleUnit === 0 || this.tourLineList[i].finalCycleUnit === null) ? 1 : this.tourLineList[i].finalCycleUnit
        this.tourLineList[i].presslCycleUnit = (this.tourLineList[i].presslCycleUnit === 0 || this.tourLineList[i].presslCycleUnit === null) ? 1 : this.tourLineList[i].presslCycleUnit
        let lineId = this.tourLineList[i].lineId
        for (let x = 0, y = this.tourList.length; x < y; x++) {
          if (this.tourList[x].id === lineId) {
            this.tourList[x].disabled = true
            break
          }
        }
      }
    },
    reactTourParams() {
      this.tourParams = {
        supplierId: '',
        attachAdultAmount: '',
        attachAdultPercent: '',
        attachAgedAmount: '',
        attachAgedPercent: '',
        attachAmountType: 1,
        attachChildAmount: '',
        attachChildBedAmount: '',
        attachChildBedPercent: '',
        attachChildPercent: '',
        attachType: 1,
        isDelete: 0,
        lineId: '',
        lineName: '',
        lineType: '',
        serviceFeePercent: '',
        serviceFeeType: 0,

        settleDepositType: 0, // 订金收取方式 0 -不收 2定额 1比例
        settleDeposit: 0, // 订金
        finalPaymentCycle: 0, // 尾款周期，出团前
        finalCycleUnit: 1, // 尾款周期单位 1-天 2-周 3-月 默认天
        finalPaymentType: 1, // 默认出团前 自增
        isHasSettle: 1, // 收订金还是全款 1-订金 0全款 自增
        settleType: 2, // 1比例，2定额 自增

        pressAmountType: 0, // 压款收取方式 0-不压 1 比例 2定额
        pressAmount: 0, // 压款金额
        presslPaymentCycle: 0, // 压款周期，出团后
        presslCycleUnit: 1, // 压款周期单位 1-天 2-周 3-月 默认天
        pressPaymentType: 1, // 默认出团后 自增
        isHasPress: 1, // 是否压款 0-不压 1压 自增
        pressType: 2, // 1比例，2定额 自增
      }
    },
    editTour(index, val) {
      console.log('v', val)
      this.reactTourParams()
      let tourParams = Object.assign({}, this.tourParams, val)
      if (tourParams.settleDepositType === 0) {
        tourParams.isHasSettle = 0
        tourParams.settleType = 1
      } else if (tourParams.settleDepositType === 1) {
        tourParams.isHasSettle = 1
        tourParams.settleType = 1
      } else {
        tourParams.isHasSettle = 1
        tourParams.settleType = 2
      }
      if (tourParams.pressAmountType === 0) {
        tourParams.isHasPress = 0
        tourParams.pressType = 1
      } else if (tourParams.pressAmountType === 1) {
        tourParams.isHasPress = 1
        tourParams.pressType = 1
      } else {
        tourParams.isHasPress = 1
        tourParams.pressType = 2
      }
      this.tourParams = tourParams
      console.log('t', this.tourParams)
      this.dialogVisible = true
      this.dialogStatus = 'update'
    },
    delTourList(index, val) {
      this.$confirm('确定要删除该线路吗?', '提示', {
        type: 'warning'
      }).then(() => {
        web_circuit_line_delete({supplierId: this.id, lineId: val.lineId}).then(res => {
          if (res.flag) {
            // 删除成功
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            for (let i = 0, l = this.tourList.length; i < l; i++) {
              if (this.tourList[i].id === val.lineId) {
                this.tourList[i].disabled = false
                break
              }
            }
            this.tourLineList.splice(index, 1)
          } else {}
        }).catch(() => {})
      }).catch(() => {
      })
    },
    setLine() {
      this.reactTourParams()
      this.tourParams = Object.assign({}, this.tourParams, this.supplier)
      this.dialogVisible = true
      this.dialogStatus = 'create'
      this.$nextTick(() => {
        this.$refs['form10-1'].clearValidate()
        this.$refs['form10-2'].clearValidate()
        this.$refs['form10-3'].clearValidate()
        this.$refs['form10-4'].clearValidate()
        this.$refs['form10-5'].clearValidate()
      })
    },
    submitTour() {

      let formDataList = ['form10-1', 'form10-2', 'form10-3', 'form10-4', 'form10-5']
      let status = [-1, -1, -1, -1, -1]
      formDataList.map((value, index) => {
        this.$refs[value].validate((valid) => {
          valid ? status[index] = 1 : status[index] = -1
          if (status.indexOf(-1) === -1) {
            if (!this.canSubmitTour) {
              return
            }
            this.canSubmitTour = false
            this.loading = true
            if (this.tourParams.isHasSettle === 0) {
              this.tourParams.settleDepositType = 0
            } else {
              this.tourParams.settleDepositType = this.tourParams.settleType === 1 ? 1 : 2
            }
            if (this.tourParams.isHasPress === 0) {
              this.tourParams.pressAmountType = 0
            } else {
              this.tourParams.pressAmountType = this.tourParams.pressType === 1 ? 1 : 2
            }
            this.tourParams.supplierId = this.id
            let params = Object.assign({}, this.tourParams)
            // 提交
            web_circuit_line_add(params).then(res => {
              this.canSubmitTour = true
              this.loading = false
              this.getTourLineList(this.id)
              this.$message({
                message: '创建成功',
                type: 'success'
              });
              this.tourLineList.push(params)
              this.dialogVisible = false
              for (let i = 0, l = this.tourList.length; i < l; i++) {
                if (this.tourList[i].id === params.lineId) {
                  this.tourList[i].disabled = true // 禁用
                  break
                }
              }
            }).catch(() => {
              this.canSubmitTour = true
              this.loading = false
            })
          }
        })
      })
      /*this.$refs['form10'].validate((valid) => {
        if (valid) {
          this.tourParams.supplierId = this.id
          let params = Object.assign({}, this.tourParams)
          // 提交
          web_circuit_line_add(params).then(res => {
            this.$message({
              message: '创建成功',
              type: 'success'
            });
            this.tourLineList.push(params)
            this.dialogVisible = false
            for (let i = 0, l = this.tourList.length; i < l; i++) {
              if (this.tourList[i].id === params.lineId) {
                this.tourList[i].disabled = true // 禁用
                break
              }
            }
          })
        }
      })*/
    },
    submitEditTour() {
      let formDataList = ['form10-1', 'form10-2', 'form10-3', 'form10-4', 'form10-5']
      let status = [-1, -1, -1, -1, -1]
      formDataList.map((value, index) => {
        this.$refs[value].validate((valid) => {
          valid ? status[index] = 1 : status[index] = -1
          if (status.indexOf(-1) === -1) {
            if (!this.canSubmitTour) {
              return
            }
            this.canSubmitTour = false
            this.loading = true
            if (this.tourParams.isHasSettle === 0) {
              this.tourParams.settleDepositType = 0
            } else {
              this.tourParams.settleDepositType = this.tourParams.settleType === 1 ? 1 : 2
            }
            if (this.tourParams.isHasPress === 0) {
              this.tourParams.pressAmountType = 0
            } else {
              this.tourParams.pressAmountType = this.tourParams.pressType === 1 ? 1 : 2
            }
            this.tourParams.supplierId = this.id
            let params = Object.assign({}, this.tourParams)
            web_circuit_line_editor(params).then(res => {
              this.canSubmitTour = true
              this.loading = false
              this.getTourLineList(this.id)
              this.$message({
                message: '修改成功',
                type: 'success'
              });
              for (let i = 0, l = this.tourLineList.length; i < l; i++) {
                if (this.tourLineList[i].lineId === params.lineId) {
                  this.tourLineList[i] = params
                }
              }
              this.dialogVisible = false
              for (let i = 0, l = this.tourList.length; i < l; i++) {
                if (this.tourList[i].id === params.lineId) {
                  this.tourList[i].disabled = true // 禁用
                  break
                }
              }
            }).catch(() => {
              this.canSubmitTour = true
              this.loading = false
            })
          }
        })
      })
      /*this.$refs['form10'].validate((valid) => {
        if (valid) {
          this.tourParams.supplierId = this.id
          let params = Object.assign({}, this.tourParams)
          web_circuit_line_editor(params).then(res => {
            this.$message({
              message: '修改成功',
              type: 'success'
            });
            for (let i = 0, l = this.tourLineList.length; i < l; i++) {
              if (this.tourLineList[i].lineId === params.lineId) {
                this.tourLineList[i] = params
              }
            }
            this.dialogVisible = false
            for (let i = 0, l = this.tourList.length; i < l; i++) {
              if (this.tourList[i].id === params.lineId) {
                this.tourList[i].disabled = true // 禁用
                break
              }
            }
          })
        }
      })*/
    },
    changeTourLine(val) {
      for (let i = 0, l = this.tourList.length; i < l; i++) {
        if (this.tourList[i].id === val) {
          this.tourParams.lineName = this.tourList[i].lineName
          this.tourParams.lineType = this.tourList[i].lineType
          break
        }
      }
    },
    handleClose() {
      this.dialogVisible = false
    },
    back() {
      this.$router.go(-1)
    }
  }
}
