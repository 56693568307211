import web_managerSupplier_flightSupplier_list from '@/lib/data-service/default/web_managerSupplier_flightSupplier_list'
import web_common_user_getDeveloper from '@/lib/data-service/default/web_common_user_getDeveloper'
import web_common_area_getAreaDataByAreaType from '@/lib/data-service/default/web_common_area_getAreaDataByAreaType'
import web_common_area_getInlandAreaChildListByParentId from '@/lib/data-service/default/web_common_area_getInlandAreaChildListByParentId'
import web_managerSupplier_flightSupplier_exportExcel from '@/lib/data-service/default/web_managerSupplier_flightSupplier_exportExcel'
import web_managerSupplier_updateStatus from '@/lib/data-service/default/web_managerSupplier_updateStatus'
import web_managerSupplier_supplierDelete from '@/lib/data-service/default/web_managerSupplier_supplierDelete'
import web_common_kickOutUser from '@/lib/data-service/default/web_common_kickOutUser'
//获取机票供应商默认技术服务费
import getServiceFee from '@/lib/data-service/default/web_managerSupplier_flightSupplier_getServiceFee'
//设置获取机票供应商技术服务费
import setServiceFee from '@/lib/data-service/default/web_managerSupplier_flightSupplier_setServiceFee'
//获取机票供应商默认评分
import getDefaultGrade from '@/lib/data-service/default/web_managerSupplier_flightSupplier_getDefaultGrade'
//设置获取机票供应商评分
import web_managerSupplier_flightSupplier_setGrade from '@/lib/data-service/default/web_managerSupplier_flightSupplier_setGrade'
import Pagination from '@/component/base-pagination/index.vue'
import moment from 'moment'
export default {
  data () {
    const validateDomesticServiceFee = (rule, value, callback) => {
      if (value >= 0) {
        callback()
      } else {
        callback(new Error('不能为负数'))
      }
    };
    const validateInternationalServiceFee = (rule, value, callback) => {
      if (value >= 0) {
        callback()
      } else {
        callback(new Error('不能为负数'))
      }
    };
    const validateGrade = (rule, value, callback) => {
      if (value >= 0 && value <= 100) {
        callback()
      } else {
        callback(new Error('请输入0至100之间的分数'))
      }
    };
    return {
      loading: false,
      params: {
        period: [],

        currentPage: 1,
        pageSize: 10,
        userName: '',
        companyName: '',
        cityId: '',
        regionId: '',
        provinceId: '',
        applicationType: '',
        startTime: '',
        endTime: '',
        supplierStatus: '',
        telephone: '',
        accountManagerId: '',
        developerId: '',
        supplierLevel: '',
        address: '',
        officeId: '',
        orderByLastLoginTime: '', // 最后登录时间排序填值: asc:升序,desc:降序,不需要为空即可
        orderByGmtCreate: '', // 创建时间排序填值: asc:升序,desc:降序,不需要为空即可
        total: 0
      },
      userTypeList: [{value: '', label: '全部'}, {value: 1, label: '机票'}, {value: '1+2', label: '机票+旅游'}],
      developerList: [],
      provinceList: [],
      cityList: [],
      supplierStatusList: [{value: '', label: '全部'}, {value: 2, label: '部分启用'}, {value: 3, label: '启用'}, {value: 4, label: '平台禁用'}],
      showMore: false,
      userList: [],
      fileUrl: '',
      moment: moment,
      onlyCheckDetail: false,
      defServFeeObj: {},//默认技术服务费
      servFeeObj: {},//新设置的技术服务费
      defGrade: 0,//默认评分
      gradeObj: {
        grade: 0,//新设置的评分
      },
      isShowTechServFeeDialog: false,
      isShowGradeDialog: false,
      isBatchSetFee: false,//是否批量设置技术服务费
      isBatchSetGrade: false,//是否批量设置评分
      selectedSupplierList: [],
      currCompanyId: '',
      setGradeParam: {//设置评分的请求参数
        gradeList: []
      },
      setServiceFeeParam: {//设置技术服务费的请求参数
        serviceFeeList: []
      },
      supplier: {
        domesticServiceType: 2,
        domesticServiceFee: ''
      },
      rule: {
        domesticServiceType: [
          {required: true, message: '请选择收取方式', trigger: 'change'}
        ],
        domesticServiceFee: [
          {required: true, message: '请输入服务费', trigger: 'blur'},
          {validator: validateDomesticServiceFee, trigger: 'blur'}
        ],
        internationalServiceType: [
          {required: true, message: '请选择收取方式', trigger: 'change'}
        ],
        internationalServiceFee: [
          {required: true, message: '请输入服务费', trigger: 'blur'},
          {validator: validateInternationalServiceFee, trigger: 'blur'}
        ],
        specialServiceType: [
          {required: true, message: '请输入收取方式', trigger: 'change'}
        ],
        specialServiceFee: [
          {required: true, message: '请输入服务费', trigger: 'blur'},
          {validator: validateDomesticServiceFee, trigger: 'blur'}
        ],
        grade: [
          {required: true, message: '请输入评分', trigger: 'blur'},
          {validator: validateGrade, trigger: 'blur'}
        ]
      }
    }
  },
  components: {
    Pagination
  },
  created () {
  },
  mounted () {
    //this.getDeveloperList()
    //this.getAreaDataByAreaType()
    //this.getSupplierList()
  },
  activated () {
    let onlyCheckDetail = this.$route.meta.onlyCheckDetail
    if (onlyCheckDetail) {
      this.onlyCheckDetail = onlyCheckDetail
    }
    this.getDeveloperList()
    this.getAreaDataByAreaType()
    this.getDefaultData()
    this.getSupplierList()
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {
    filterTravelPaymentType(val) {
      if (val === 1) {
        return 'T'
      } else if (val === 2) {
        return '回团后'
      } else if (val === 3) {
        return '出团前'
      } else {
        return '--'
      }
    },
    filterSettleDepositType(val) {
      if (val === 0) {
        return '全额'
      } else {
        return '订金'
      }
    },
    filterSettleDepositTypeFun(val) {
      if (val === 0) {
        return '--'
      } else if (val === 1) {
        return '比例'
      } else {
        return '定额'
      }
    },
    filterPressAmountType(val) {
      if (val === 0) {
        return '不压款'
      } else {
        return '压款'
      }
    },
    filterPressAmountTypeFun(val) {
      if (val === 0) {
        return '--'
      } else if (val === 1) {
        return '比例'
      } else {
        return '定额'
      }
    },
  },
  methods: {
    getDefaultData(){
      this.getDefaultGrade()
      this.getServiceFee()
    },
    onSearch() {
      this.params.currentPage = 1
      this.getSupplierList()
    },
    handleFilter() {
      this.onSearch()
    },
    showOrHideForm() {
      const showMore = this.showMore
      this.showMore = !showMore
    },
    onReset() {
      this.$refs['userTable'].clearSort()
      this.params = {
        period: [],
        currentPage: 1,
        pageSize: 10,
        userName: '',
        companyName: '',
        cityId: '',
        regionId: '',
        provinceId: '',
        applicationType: '',
        startTime: '',
        endTime: '',
        supplierStatus: '',
        telephone: '',
        accountManagerId: '',
        developerId: '',
        supplierLevel: '',
        address: '',
        officeId: '',
        orderByLastLoginTime: '', // 最后登录时间排序填值: asc:升序,desc:降序,不需要为空即可
        orderByGmtCreate: '', // 创建时间排序填值: asc:升序,desc:降序,不需要为空即可
        total: 0
      }
      this.onSearch()
    },
    changeParamsTime(val) {
      if (val === '' || val === null) {
        this.params.startTime = ''
        this.params.endTime = ''
      } else {
        this.params.startTime = val[0]
        this.params.endTime = val[1]
      }
    },
    changeProvince(val) {
      this.params.cityId = ''
      this.cityList = []
      if (val !== '') {
        this.getCity(val)
      }
    },

    handleCreate() {
      this.$router.push({
        name: 'admin-supplier-add'
      })
    },
    uploadExcel(val) {
      let params
      if (val === 'current') {
        params = this.params
      } else {
        params = ''
      }
      web_managerSupplier_flightSupplier_exportExcel(params).then(res => {
        if (res.url) {
          // window.open(res.url)
          this.fileUrl = res.url
          this.$nextTick(() => {
            console.log(res.url)
            this.$refs.downloadBtn.click()
          })
        }
      })
    },

    // 获取开发者列表 - （机票经理）
    getDeveloperList() {
      web_common_user_getDeveloper().then((res) => {
        this.developerList = res.getAllDeveloperResult
      })
    },
    // 获取省份
    getAreaDataByAreaType(areaType = 1) {
      web_common_area_getAreaDataByAreaType({areaType}).then(res => {
        this.provinceList = res
      })
    },
    // 获取城市
    getCity(id) {
      web_common_area_getInlandAreaChildListByParentId({id}).then(res => {
        this.cityList = res
      })
    },
    //获取供应商默认评分
    async getDefaultGrade() {
      let res = await getDefaultGrade()
      this.defGrade = res.grade
    },
    //获取供应商默认技术服务费
    async getServiceFee(){
      this.defServFeeObj = await getServiceFee()
    },
    getSupplierList() {
      this.loading = true
      web_managerSupplier_flightSupplier_list(this.params).then((res) => {
        let userList = res.pageResult.pageData
        userList.forEach(value => {
          if (value.lastLoginTime === null) {
            value.lastLoginTime = ''
          }
          if(value.grade === null){
            value.grade = this.defGrade
          }
          if(value.domesticServiceType === null){
            value.domesticServiceType = this.defServFeeObj.domesticServiceType
          }
          if(value.domesticServiceFee === null){
            value.domesticServiceFee = this.defServFeeObj.domesticServiceFee
          }
          if(value.internationalServiceType === null){
            value.internationalServiceType = this.defServFeeObj.internationalServiceType
          }
          if(value.internationalServiceFee === null){
            value.internationalServiceFee = this.defServFeeObj.internationalServiceFee
          }
          if(value.specialServiceType === null){
            value.specialServiceType = this.defServFeeObj.specialServiceType
          }
          if(value.specialServiceFee === null){
            value.specialServiceFee = this.defServFeeObj.specialServiceFee
          }
        })
        this.userList = userList
        this.params.total = res.pageResult.totalCount
        this.loading = false
      }).catch(() => {
        this.loading = false
      }, new Error(() => {
        this.loading = false
      }))
    },
    statusFormatter(val) {
      let status = ''
      switch (val.supplierStatus) {
        case 0:
          status = '待审核'
          break
        case 1:
          status = '审核失败'
          break
        case 3:
          status = '启用'
          break
        case 2:
          status = '部分启用'
          break
        case 4:
          status = '平台禁用'
          break
        case 5:
          status = '已删除'
          break
        case 6:
          status = '未签约'
          break
        case 7:
          status = '签约失败'
          break
        case 8:
          status = '待设置合同文件'
          break
        default:
          status = '平台禁用'
      }
      return status
    },
    handleCheck(val) {
      this.$router.push({
        name: 'admin-supplier-detail',
        query: {
          id: val.supplierId
        }
      })
    },
    handleSetting(val) {
      this.$router.push({
        name: 'admin-supplier-set',
        query: {
          id: val.supplierId
        }
      })
    },
    handleStopUserStatus(index, val) {
      this.$confirm('供应商将会被停用，是否继续？', '提示', {
        type: 'warning'
      }).then(() => {
        this.updateStatus(val.supplierId, 4).then((res) => {
          // this.stuList.splice(index, 1)
          console.log(res)
          this.userList[index].supplierStatus = 4
        }).catch(() => {

        })
      }).catch(() => {
      })
    },
    handleStartUserStatus(index, val) {
      this.$confirm('供应商将会被启用，是否继续？', '提示', {
        type: 'warning'
      }).then(() => {
        this.updateStatus(val.supplierId, 3).then((res) => {
          console.log(res)
          this.userList[index].supplierStatus = 3
        }).catch(() => {

        })
      }).catch(() => {
      })
    },
    async updateStatus(supplierId, supplierStatus) {
      return await web_managerSupplier_updateStatus({supplierId, supplierStatus})
    },
    handleDel(index, val) {
      this.$confirm('供应商将会被删除，是否继续？', '提示', {
        type: 'warning'
      }).then(() => {
        web_managerSupplier_supplierDelete({supplierId: val.supplierId}).then((res) => {
          // this.stuList.splice(index, 1)
          console.log(res)
          this.getSupplierList()
        }).catch(() => {

        })
      }).catch(() => {
      })
    },
    handleSetTourLine(val) {
      this.$router.push({
        name: 'admin-supplier-tour-line-set',
        query: {
          id: val.supplierId
        }
      })
    },
    handleLogOut(index, val) {
      this.$confirm('确定强制退出吗?', '提示', {
        type: 'warning'
      }).then(() => {
        web_common_kickOutUser({userId: val.userId}).then(res => {
          if (res.code === '000000') {
            this.$message({
              message: res.msg,
              type: 'success'
            })
          } else {
            this.$message({
              message: res.msg,
              type: 'warning'
            })
          }
        })
      }).catch(() => {
      })
    },
    handleContract(val) {
      this.$router.push({
        name: 'admin-contract-set',
        query: {
          supplierId: val.supplierId,
          type: (val.supplierStatus === 6 || val.supplierStatus === 7 || val.supplierStatus === 8) ? 'audit' : 'look'
        }
      })
    },
    changeSort({ column, prop, order }) {
      // orderByLastLoginTime: '', // 最后登录时间排序填值: asc:升序,desc:降序,不需要为空即可
      // orderByGmtCreate: '', // 创建时间排序填值: asc:升序,desc:降序,不需要为空即可
      if (order === null) {
        this.params.orderByLastLoginTime = ''
        this.params.orderByGmtCreate = ''
      } else if (prop === 'registerTime') {
        this.params.orderByLastLoginTime = ''
        this.params.orderByGmtCreate = order === 'ascending' ? 'asc' : 'desc'
      } else {
        this.params.orderByGmtCreate = ''
        this.params.orderByLastLoginTime = order === 'ascending' ? 'asc' : 'desc'
      }
      this.onSearch()
      // this.getSupplierList()
    },
    //显示技术服务费弹框
    showTechServFeeDialog(supplierObj){
      if (!supplierObj) {
        if(this.selectedSupplierList.length === 0){
          this.$message.warning('请选择供应商');
          return
        } else if (this.selectedSupplierList.length === 1) {
          this.servFeeObj = this.setServiceFeeParam.serviceFeeList[0]
        }else {
          console.log('this.de',this.defServFeeObj)
          this.servFeeObj = {
            domesticServiceType: 2,
            internationalServiceType: 2,
            specialServiceType: 2
          }
        }
        this.isBatchSetFee = true
      } else {
        this.servFeeObj = {
          companyId: supplierObj.supplierId,
          domesticServiceType: supplierObj.domesticServiceType,
          domesticServiceFee: supplierObj.domesticServiceFee,
          internationalServiceType: supplierObj.internationalServiceType,
          internationalServiceFee: supplierObj.internationalServiceFee,
          specialServiceType: supplierObj.specialServiceType,
          specialServiceFee: supplierObj.specialServiceFee
        }
        this.currCompanyId = supplierObj.supplierId
        this.isBatchSetFee = false
      }
      this.isShowTechServFeeDialog = true
    },
    //显示设置评分弹框
    showGradeDialog(supplierObj){
      if(!supplierObj){
        if(this.selectedSupplierList.length === 0){
          this.$message.warning('请选择供应商');
          return
        } else if (this.selectedSupplierList.length === 1) {
          this.gradeObj.grade = this.setGradeParam.gradeList[0].grade
        }else {
          this.gradeObj.grade = ''
        }
        this.isBatchSetGrade = true
      }else {
        this.gradeObj.grade = supplierObj.grade
        this.currCompanyId = supplierObj.supplierId
        this.isBatchSetGrade = false
      }
      this.isShowGradeDialog = true
    },
    //设置技术服务费
    async setTechServFee() {
      if(this.isBatchSetFee){
        this.setServiceFeeParam.serviceFeeList.map(item => {
          this.$set(item, 'domesticServiceType', this.servFeeObj.domesticServiceType)
          this.$set(item, 'domesticServiceFee', this.servFeeObj.domesticServiceFee)
          this.$set(item, 'internationalServiceType', this.servFeeObj.internationalServiceType)
          this.$set(item, 'internationalServiceFee', this.servFeeObj.internationalServiceFee)
          this.$set(item, 'specialServiceType', this.servFeeObj.specialServiceType)
          this.$set(item, 'specialServiceFee', this.servFeeObj.specialServiceFee)
        })
      }else {
        this.setServiceFeeParam.serviceFeeList = [{
          companyId: this.currCompanyId,
          domesticServiceType: this.servFeeObj.domesticServiceType,
          domesticServiceFee: this.servFeeObj.domesticServiceFee,
          internationalServiceType: this.servFeeObj.internationalServiceType,
          internationalServiceFee: this.servFeeObj.internationalServiceFee,
          specialServiceType: this.servFeeObj.specialServiceType,
          specialServiceFee: this.servFeeObj.specialServiceFee
        }]
      }
      await setServiceFee(this.setServiceFeeParam)
      await this.getSupplierList()
      this.isShowTechServFeeDialog = false
    },
    //判断是否符合设置供应商评分规定
    checkGrade() {
      this.$refs['gradeForm'].validate((valid) => {
        if(valid){
          this.submitGrade()
        } else {
          return false
        }
      })
    },
    //提交供应商评分
    async submitGrade() {
      if (this.isBatchSetGrade) {
        this.setGradeParam.gradeList.map(item => {
          return this.$set(item, 'grade', this.gradeObj.grade)
        })
      } else {
        this.setGradeParam.gradeList = [{
          companyId: this.currCompanyId,
          grade: this.gradeObj.grade
        }]
      }
      await web_managerSupplier_flightSupplier_setGrade(this.setGradeParam)
      await this.getSupplierList()
      this.isShowGradeDialog = false
    },
    //选中供应商时
    handleSelectionChange(val) {
      this.selectedSupplierList = val
      this.setGradeParam.gradeList = val.map(item => {
        let list = {
          companyId: item.supplierId,
          grade: item.grade
        }
        return list
      })
      this.setServiceFeeParam.serviceFeeList = val.map(item => {
        let list = {
          companyId: item.supplierId,
          domesticServiceType: item.domesticServiceType,
          domesticServiceFee: item.domesticServiceFee,
          internationalServiceType: item.internationalServiceType,
          internationalServiceFee: item.internationalServiceFee,
          specialServiceType: item.specialServiceType,
          specialServiceFee: item.specialServiceFee
        }
        return list
      })
    }
  }
}




/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
