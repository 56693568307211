// web_buyer_getBuyerRelevanceList 条件分页查询供应商关联关系列表

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口文档地址： http://192.168.0.15:8080/doc.html#/所有接口/buyer-controller/getBuyerRelevanceListUsingPOST
export default function web_buyer_getBuyerRelevanceList(pParameter) {
  let params = {}
  if (!pParameter) pParameter = {};
  const userName = pParameter.userName || ''
  const companyName = pParameter.companyName || ''
  const buyerType = pParameter.buyerType || ''
  const startTime = pParameter.startTime || ''
  const endTime = pParameter.endTime || ''
  const supplierStatus = pParameter.supplierStatus || ''
  const telephone = pParameter.telephone || ''
  const accountManagerId = pParameter.accountManagerId || ''
  const developerId = pParameter.officeId || ''
  const currentPage = pParameter.currentPage || ''
  const pageSize = pParameter.pageSize || ''
  params.method = 'POST'
  params.urlSuffix = '/web/buyer/getBuyerRelevanceList'
  params.data = {
    userName,
    companyName,
    supplierStatus,
    buyerType,
    telephone,
    accountManagerId,
    developerId,
    startTime,
    endTime,
    currentPage,
    pageSize
  }
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
