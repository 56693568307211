import moment from "moment";
import history_tag_api from '@/component/admin-layout/1.0.0/component/history-tag/api';
import web_credit_getCreditProduct from '@/lib/data-service/default/web_credit_getCreditProduct' // 获取产品列表
import web_common_getPhone from '@/lib/data-service/default/web_common_getPhone' // 获取发送验证码所需的手机号
import web_common_kaptcha from '@/lib/data-service/default/web_common_kaptcha' // 获取图形码
import web_common_register from '@/lib/data-service/default/web_common_register' // 验证图形码并发送短信code
import web_common_phoneCodeCheck from '@/lib/data-service/default/web_common_phoneCodeCheck' // 检验短信验证码
import web_credit_dredgeCredit from '@/lib/data-service/default/web_credit_dredgeCredit'
import web_credit_dredgeCreditForJson from '@/lib/data-service/default/web_credit_dredgeCreditForJson'
import web_buyer_findOne from '@/lib/data-service/default/web_buyer_findOne';
import web_credit_dealStatistic from '@/lib/data-service/default/web_credit_dealStatistic'; // 查询交易情况

export default {
    data () {
        const checkCreditAmount = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入授信金额'))
            } else {
                if ((/(^[1-9]\d*$)/.test(value))) {
                    callback()
                } else {
                    callback(new Error('请输入正确的金额'))
                }
            }
        };
        const checkMsgCode = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('不能为空'))
            } else {
                callback()
            }
        };
        return {
            loading: false,
            details: {},
            form: {
                companyId: '',
                creditMode: 1, // 1.全款，2.轻松付
                businessAccountType: [2], // 务账户类型：1.旅游，2.机票
                creditBusinesses: [
                    {businessAccountType: 2, creditAmount: ''},
                ],
                creditAmount: '',
                productId: '',
                productName: '',
                creditPaymentDays: '',
                creditPaymentRate: '',
                code: '',
                isCashDeposit: 0, // 是否缴纳保证金
                cashDepositAmount: '' // 保证金金额
            },
            isCashDeposit: false, // 是否缴纳保证金
            formRule: {
                creditMode: [
                    {required: true, message: '', trigger: 'change'}
                ],
                businessAccountType: [
                    {required: true, message: '', trigger: 'change'}
                ],
                creditAmount: [
                    { required: true, validator: checkCreditAmount, trigger: 'blur' }
                ],
                productId: [
                    { required: true, message: '请选择授信产品', trigger: 'change' }
                ],
                code: [
                    { required: true, validator: checkMsgCode, trigger: 'blur' }
                ],
            },
            picParams: {
                phoneNumber: '',
                verificationCode: '',
                roleType: 2, // 角色类型:1-供应商 2-采购商 3-平台
                uuid: ''
            },
            picParamsRule: {
                verificationCode: [
                    { required: true, message: '请输入图形码', trigger: 'blur' },
                ]
            },
            getCodeText: '获取短信验证码',
            innerVisible: false,
            canGetCode: true,
            inerval: '', // 倒计时
            picCodeUrl: '',
            fullCreditProductList: [], // 全款授信产品
            instalmentCreditProductList: [], // 分期授信产品
            dealStatistic: {}, // 交易情况
        }
    },
    methods: {
        checkCreditAmount(rule, value, callback) {
            if (value === '') {
                callback(new Error('请输入授信金额'))
            } else {
                if ((/(^[1-9]\d*$)/.test(value))) {
                    callback()
                } else {
                    callback(new Error('请输入正确的金额'))
                }
            }
        },


        getCreditProduct() {
            const __this = this;
            web_credit_getCreditProduct().then(res => {
                let creditProducts = res.creditProducts;
                let fullPay = [];
                let instalmentPay = [];
                creditProducts.forEach((value, index) => {
                    if (value.productType === 1) {
                        fullPay.push(value);
                    } else {
                        instalmentPay.push(value);
                    }
                });
                __this.fullCreditProductList = fullPay;
                __this.instalmentCreditProductList = instalmentPay;
            })
        },
        changeFullProductId(val) {
            const __this = this;
            for (let i = 0, l = __this.fullCreditProductList.length; i < l; i++) {
                if (__this.fullCreditProductList[i].id === val) {
                    __this.form.creditPaymentDays = __this.fullCreditProductList[i].creditPaymentDays;
                    __this.form.creditPaymentRate = __this.fullCreditProductList[i].creditPaymentRate;
                    __this.form.productName = __this.fullCreditProductList[i].productName;
                    break
                }
            }
        },
        changeinstalmentProductId(val) {
            const __this = this;
            for (let i = 0, l = __this.instalmentCreditProductList.length; i < l; i++) {
                if (__this.instalmentCreditProductList[i].id === val) {
                    __this.form.creditPaymentDays = __this.instalmentCreditProductList[i].creditPaymentDays;
                    __this.form.creditPaymentRate = __this.instalmentCreditProductList[i].creditPaymentRate;
                    __this.form.productName = __this.instalmentCreditProductList[i].productName;
                    break
                }
            }
        },
        getFindOne() { // 客户资料
            const __this = this;
            __this.loading = true;
            web_buyer_findOne({buyerId: __this.$route.query.buyerId}).then(res => {
                __this.loading = false;
                __this.details = res.result;
            }).catch(err => {
                __this.loading = false;
            }).finally(() => {
                __this.loading = false;
            })
        },
        creditModeChange(val) {
            const __this = this;
            __this.$refs['creditForm'].resetFields();
            __this.form.productId = '';
            __this.form.creditMode = val;
        },
        businessChange() {
            const __this = this;
            if (__this.form.businessAccountType.length >= 2) {
                __this.form.creditBusinesses = [
                    {businessAccountType: 2, creditAmount: ''},
                    {businessAccountType: 1, creditAmount: ''}
                ];
            } else {
                __this.form.creditBusinesses = [
                    {businessAccountType: 2, creditAmount: ''}
                ]
            }
        },
        getMsgCode() {
            const __this = this;
            if (!__this.canGetCode) {
                return
            }
            web_common_getPhone().then(res => {
                __this.picParams.phoneNumber = res.phone;
                web_common_kaptcha().then(result => {
                    if (result.code === '000000') {
                        __this.picCodeUrl = result.img;
                        __this.picParams.uuid = result.uuid;
                        __this.innerVisible = true
                    }
                })
            })
        },
        submitPicCode() {
            const __this = this;
            __this.$refs['picForm'].validate((valid) => {
                if (valid) {
                    web_common_register(this.picParams).then(res => {
                        if (res.code === '000000') {
                            __this.$message({
                                message: '验证码已发送，请查看您的手机',
                                type: 'success'
                            });
                            __this.innerVisible = false;
                            clearInterval(this.inerval);
                            __this.canGetCode = false;
                            __this.inervalNum = 59;
                            __this.getCodeText = `${this.inervalNum}秒重新发送`;
                            __this.inerval = setInterval(() => {
                                if (__this.inervalNum > 1) {
                                    __this.inervalNum--;
                                    __this.getCodeText = `${this.inervalNum}秒重新发送`
                                } else {
                                    __this.getCodeText = '重新发送';
                                    __this.canGetCode = true;
                                    clearInterval(this.inerval)
                                }
                            }, 1000)

                        } else {
                            __this.$message.error(res.msg);
                            web_common_kaptcha().then(result => {
                                if (result.code === '000000') {
                                    __this.picCodeUrl = result.img;
                                    __this.picParams.uuid = result.uuid
                                }
                            })
                        }
                    })
                }
            })
        },
        submit() {
            const __this = this;
            __this.form.companyId = __this.details.buyerId;
            __this.form.companyName = __this.details.companyName;
            __this.form.accountName = __this.details.userName;
            __this.form.creditBusinesses.forEach(item => {
                item.isCashDeposit = __this.form.isCashDeposit;
                item.cashDepositAmount = __this.form.cashDepositAmount;
            });
            __this.$refs['creditForm'].validate((valid) => {
                if (valid) {
                    let params = {
                        smsCode: __this.form.code,
                        phone: __this.picParams.phoneNumber
                    };
                    __this.loading = true;
                    web_common_phoneCodeCheck(params).then(res => {
                        if (res.code === '000000') {
                            web_credit_dredgeCreditForJson(__this.form).then(result => {
                                __this.loading = false;
                                __this.$message.success('开通成功！');
                                __this.$router.push({
                                    name: 'admin-credit-customer-list',
                                });
                                __this.onReturn();
                            }).finally(() => {
                                __this.loading = false;
                            })
                        }
                    }).finally(() => {
                        __this.loading = false;
                    })
                }
            })
        },
        onReturn() {
            const __this = this;
            history_tag_api.remove_tag_by_route({
                route: __this.$route,
            });
            __this.$router.push({
                name: `admin-credit-open-list`
            });
        },
        getDealStatistic() { // 交易情况
            const __this = this;
            web_credit_dealStatistic({companyId: Number(__this.$route.query.buyerId)}).then(res => {
                const dealStatisticObj = res;
                dealStatisticObj.dealStatisticResultList.forEach(item => {
                    if (item.businessType === 1) { // 机票
                        dealStatisticObj.filghtStatistics = item;
                    }
                    if (item.businessType === 2) { // 旅游
                        dealStatisticObj.travelStatistics = item;
                    }
                    if (item.businessType === 3) { // 酒店
                        dealStatisticObj.hotelStatistics = item;
                    }
                    if (item.businessType === 4) { // 火车票
                        dealStatisticObj.trainStatistics = item;
                    }
                    if (item.businessType === 5) { // 门票
                        dealStatisticObj.ticketStatistics = item;
                    }
                });
                __this.dealStatistic = dealStatisticObj;
            })
        }
    },
    activated () {
        const __this = this;
        __this.getCreditProduct();
        __this.getFindOne();
        __this.getDealStatistic();
    },
    filters: {
        filterDate(val) {
            return val ? moment(val).format("YYYY-MM-DD") : val;
        }
    },
    watch: {
        isCashDeposit: {
            handler(newVal, oldVal) {
                const __this = this;
                if (newVal) {
                    __this.form.isCashDeposit = 1
                } else {
                    __this.form.isCashDeposit = 0
                }
            },
            immediate: true
        }
    }
}
