import web_common_credit_cutCreditBillDetails from '@/lib/data-service/default/web_common_credit_cutCreditBillDetails'
import web_common_credit_cutTravelOrdList from '@/lib/data-service/default/web_common_credit_cutTravelOrdList'
import flight_travel_refund_order_list from '@/lib/data-service/flight/flight_travel_refund_order_list'
import moment from 'moment'
import Pagination from '@/component/base-pagination/index.vue'
export default {
  data () {
    return {
      loading: false,
      companyId: '',
      detail: {},
      detailList: [],
      params: {
        companyId: '',
        businessType: 2,
        currentPage: 1,
        pageSize: 20,
        total: 0
      },
      airParams: {
        buyerIds: '',
        currentPage: 1,
        pageSize: 20,
        total: 0
      },
      travelList: [],
      airList: [],
      activeName: 'travel',
      moment: moment,
      canRefresh: true,
      chooseList: []
    }
  },
  components: {Pagination},
  created () {},
  mounted () {

  },
  activated () {
    this.companyId = this.$route.query.companyId
    this.params.companyId = this.$route.query.companyId
    this.airParams.buyerIds = this.$route.query.companyId
    this.getDetail()
    this.getTravelList()
    this.getAirList()
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
    formatterOrderState(val) {
      // 0-审核失败，1-待供应商审核，2-待平台审核，3-待支付，4-已支付，5确认出团，6-已出团，7-已回团，8-已分账，9-新增订单,10-已结算，11-切位收客失败
      if (val.orderState === 0) {
        return '审核失败'
      } else if (val.orderState === 1) {
        return '待供应商审核'
      } else if (val.orderState === 2) {
        return '待平台审核'
      } else if (val.orderState === 3) {
        return '待支付'
      } else if (val.orderState === 4) {
        return '已支付'
      } else if (val.orderState === 5) {
        return '确认出团'
      } else if (val.orderState === 6) {
        return '已出团'
      } else if (val.orderState === 7) {
        return '已回团'
      } else if (val.orderState === 8) {
        return '已分账'
      } else if (val.orderState === 9) {
        return '新增订单'
      } else if (val.orderState === 10) {
        return '已结算'
      } else {
        return '切位收客失败'
      }
    },
    formatterReturnAmountStatus(val) {
      // 回款状态 0.未回款，1.已回款
      if (val.returnAmountStatus === 0) {
        return '未回款'
      } else {
        return '已回款'
      }
    },
    handleClick() {},
    getDetail() {
      this.loading = true
      web_common_credit_cutCreditBillDetails({companyId: this.companyId}).then(res => {
        this.loading = false
        this.detail = res.cutCreditBillResult
        this.detailList.push(res.cutCreditBillResult)
      }).catch(() => {
        this.loading = false
      })
    },
    refresh() {
      if (!this.canRefresh) {
        return
      }
      this.canRefresh = false
      this.getDetail()
      let countDownNum = 0
      let setRefresh = setInterval(() => {
        countDownNum++
        if (countDownNum >= 60) {
          this.canRefresh = true
          clearInterval(setRefresh)
        }
      }, 1000)
    },
    getTravelList() {
      web_common_credit_cutTravelOrdList(this.params).then(res => {
        if (res.pageResult !== null) {
          this.travelList = res.pageResult.pageData
          this.params.total = res.pageResult.totalCount
        } else {
          this.travelList = []
          this.params.total = 0
        }

      })
    },
    getAirList() {
      flight_travel_refund_order_list(this.airParams).then(res => {
        if (res.pageResult !== null) {
          let airList = res.pageResult.pageData
          airList.forEach(value => {
            value.choose = false
            value.appType = 1
            value.supplierSettleAmount = value.supTotalPay // 供应商结算金额
            value.buyerPaidAmount = value.totalPriceAll // 采购支付金额
          })
          for (let i = 0, l = airList.length; i < l; i++) {
            for (let m = 0, n = this.chooseList.length; m < n; m++) {
              if (airList[i].orderNo === this.chooseList[m].orderNo) {
                airList[i].choose = true
                break
              }
            }
          }
          this.airList = airList
          this.airParams.total = res.pageResult.totalCount
        } else {
          this.airList = []
          this.airParams.total = 0
        }
      })
    }
  }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
