import Q from 'q';

import moment from 'moment';

// 产品列表
import web_product_specialTouristList from '@/lib/data-service/default/web_product_specialTouristList';
// 取消特价
import web_common_product_setSpecialState from '@/lib/data-service/default/web_common_product_setSpecialState';
// 设置首页展示
import web_common_product_setSpecialShowStatus from '@/lib/data-service/default/web_common_product_setSpecialShowStatus';
// 设置平台服务费
import web_common_product_setSpecialPlatformServiceAmount from '@/lib/data-service/default/web_common_product_setSpecialPlatformServiceAmount';
//

// 设置附加金额
import web_product_updateSpecialAddition from '@/lib/data-service/default/web_product_updateSpecialAddition';
// 设置下线
import web_product_updateOffline from '@/lib/data-service/default/web_product_updateOffline';
// 设置产品等级
import web_common_product_updateProductLevel from '@/lib/data-service/default/web_common_product_updateProductLevel';
// 附加金额详情
import web_product_getAdditionDetail from '@/lib/data-service/default/web_common_product_getAdditionMoney';

// 获取团期
import web_common_userSupplier_GroupStage from '@/lib/data-service/default/web_common_userSupplier_GroupStage';



// 跳转预览
import get_preview_url from '@/lib/common-service/get_preview_url';

import FullCalendar from '../component/FullCalendar/team/index.vue';

import Citys from '@/component/citys/index.vue';

export default {
  components: {
    Citys,
    FullCalendar
  },
  data() {
    return {
      form: {
        currentPage: 1,
        pageSize: 10,
        productNo: '',
        productName: '',
        productType: '',
        tripDay: '',
        specialExpirationDateStart: '',
        specialExpirationDateEnd: '',
        productAreasType: '',
        productStatus: -1,
        toProvince: '',
        toCity: '',
        fromProvince: '',
        fromCity: '',
        specialSource: ''
      },
      allocation: {
        productTypeList: [
          { label: '全部', value: '' },
          { label: '跟团游', value: 1 },
          { label: '自由行', value: 2 },
          { label: '当地游', value: 3 }
        ],
        tripDayList: [
          { label: '全部', value: '' },
          { label: '一天', value: 1 },
          { label: '两天', value: 2 },
          { label: '三天', value: 3 },
          { label: '四天', value: 4 },
          { label: '五天', value: 5 },
          { label: '六天', value: 6 },
          { label: '七天', value: 7 },
          { label: '八天', value: 8 },
          { label: '八天以上', value: 9 }
        ],
        areasTypeList: [
          { label: '全部', value: '' },
          { label: '国内', value: 1 },
          { label: '出境', value: 2 }
        ],
        productStatusList: [
          { label: '全部', value: -1 },
          { label: '上线', value: 3 },
          { label: '下线', value: 4 },
          { label: '平台下线', value: 5 }
        ],
        specialSourceList: [
          { label: '全部', value: '' },
          { label: '无', value: 0 },
          { label: '供应商设置', value: 1 },
          { label: '平台设置', value: 2 }
        ]
      },
      pager: {
        pageSizes: [10, 20, 30, 40, 50],
        totalCount: 0
      },
      productData: [],
      isCheckAll: false,
      emptyDialog: false,
      specialDialog: false,
      showDialog: false,
      serviceDialog: false,
      show: false,
      loading: false,
      // 平台服务费
      platformServiceAmount: '',
      additionDialog: false,
      additionInfoDialog: false,
      productIds: '',
      productNos: '',
      additionForm: {
        attachType: 1,
        attachAmountType: 2,
        attachAdult: '',
        attachBedChild: '',
        attachChild: '',
        attachAged: ''
      },
      additionRules: {
        attachType: [
          { required: true, message: '请选择附加类型', trigger: 'blur' }
        ],
        attachAmountType: [
          { required: true, message: '请选择附加方式', trigger: 'blur' }
        ],
        attachAdult: [
          { required: true, message: '请输入成人附加', trigger: 'blur' },
          { pattern: /\d/g, message: '请输入数字值' }
        ],
        attachBedChild: [
          { required: true, message: '请输入儿童占床附加', trigger: 'blur' },
          { pattern: /\d/g, message: '请输入数字值' }
        ],
        attachChild: [
          { required: true, message: '请输入儿童不占床附加', trigger: 'blur' },
          { pattern: /\d/g, message: '请输入数字值' }
        ],
        attachAged: [
          { required: true, message: '请输入老人附加', trigger: 'blur' },
          { pattern: /\d/g, message: '请输入数字值' }
        ]
      },
      additionAllocation: {
        attachTypeList: [
          { label: 1, value: '加价' },
          { label: 2, value: '减免' },
          { label: 0, value: '无附加' }
        ],
        attachAmountTypeList: [
          { label: 2, value: '百分比' },
          { label: 1, value: '固定金额' }
        ]
      },
      levelList: [],
      additionInfoForm: {
        attachType: '',
        attachAmountType: '',
        attachAdultPercent: '',
        attachChildBedPercent: '',
        attachChildPercent: '',
        attachAgedPercent: '',
        attachTypeText: '',
        attachAmountTypeText: ''
      },
      calendarData: {
        visible: false,
        full: false,
        width: '50%',
        status: 'half',
        events: [],
        locale: '',
        selectStatus: 2,
        firstDay: 0,
        initialDayTime: 0,
        deleteGroupDate: []
      }
    };
  },
  methods: {
    // 请求列表
    req_procut_list(params, type) {
      this.loading = true;
      const _this = this;
      Q.when()
        .then(function() {
          return web_product_specialTouristList(params);
        })
        .then(function(res) {
          const productData = res.pageResult.pageData;
          productData.forEach(function(item) {
            if (item.lastTeamDate) item.lastTeamDate = moment(item.lastTeamDate).format('YYYY-MM-DD');
            item.checked = false;
            switch (item.productAreasType) {
              case 1:
                item.areasTypeText = '国内';
                break;
              case 2:
                item.areasTypeText = '出境';
                break;
            }
            switch (item.isHaveTeam) {
              case 0:
                item.isHaveTeamText = '无团期';
                break;
              case 1:
                item.isHaveTeamText = '有团期';
                break;
            }
            switch (item.havaShopping) {
              case 1:
                item.havaShoppingText = '有';
                break;
              case 2:
                item.havaShoppingText = '无';
                break;
            }
            switch (item.havaSelfItem) {
              case 1:
                item.havaSelfItemText = '有';
                break;
              case 2:
                item.havaSelfItemText = '无';
                break;
            }
            switch (item.productLevel) {
              case 0:
                item.productLevelText = '无';
                break;
              case 1:
                item.productLevelText = '经典';
                break;
              case 2:
                item.productLevelText = '经济';
                break;
              case 3:
                item.productLevelText = '促销';
                break;
              case 4:
                item.productLevelText = '尊享';
                break;
              case 5:
                item.productLevelText = '转谈';
                break;
            }
            switch (item.productStatus) {
              case 3:
                item.productStatusText = '上线';
                break;
              case 4:
                item.productStatusText = '下线';
                break;
              case 5:
                item.productStatusText = '平台下线';
                break;
            }
          });
          _this.isCheckAll = false;
          _this.pager.totalCount = res.pageResult.totalCount;
          const reg = /\$\$/g;
          productData.forEach(function (item) {
            item.fromCityText = item.fromCityName.replace(reg,',');
          })
          _this.productData = productData;
          if (type) document.documentElement.scrollTop = 0;
          _this.loading = false;
        });
    },
    // 取消特价
    req_setSpecial(params) {
      return Q.when().then(function() {
        return web_common_product_setSpecialState(params);
      });
    },
    // 首页展示
    req_set_show_home(params) {
      return Q.when().then(function() {
        return web_common_product_setSpecialShowStatus(params);
      });
    },
    req_set_platform_service(params) {
      return Q.when().then(function() {
        return web_common_product_setSpecialPlatformServiceAmount(params);
      });
    },

    req_addition(params) {
      return Q.when().then(function() {
        return web_product_updateSpecialAddition(params);
      });
    },
    req_team_details(params) {
      return Q.when().then(function() {
        return web_common_userSupplier_GroupStage(params);
      });
    },
    req_additionIfo(params) {
      return Q.when().then(function() {
        return web_product_getAdditionDetail(params);
      });
    },
    initialize() {
      this.req_procut_list(this.form);
    },
    getToProvince(value) {
      this.form.toProvince = value;
    },
    getToCity(value) {
      this.form.toCity = value;
    },
    getFromProvince(value) {
      this.form.fromProvince = value;
    },
    getFromCity(value) {
      this.form.fromCity = value;
    },
    search() {
      this.form.currentPage = 1;
      this.req_procut_list(this.form);
    },
    reset() {
      this.$refs.toCity.reset_toArea();
      this.$refs.fromCity.reset_fromArea();
      const pageSize = this.form.pageSize;
      this.form = {
        currentPage: 1,
        pageSize,
        productNo: '',
        productName: '',
        productType: '',
        tripDay: '',
        specialExpirationDateStart: '',
        specialExpirationDateEnd: '',
        productAreasType: '',
        productStatus: -1,
        toProvince: '',
        toCity: '',
        fromProvince: '',
        fromCity: '',
        specialSource: ''
      };
      this.search();
    },
    handleCloseEmpty() {
      this.emptyDialog = false;
    },
    setSpecial() {
      if (!this.productIds) {
        this.emptyDialog = true;
      } else {
        this.specialDialog = true;
      }
    },
    handleCloseSpecial() {
      this.specialDialog = false;
    },
    commitSetSpecial() {
      const _this = this;
      Q.when()
        .then(function() {
          _this.specialDialog = false;
          return _this.req_setSpecial({ productIds: _this.productIds });
        })
        .then(function(res) {
          _this.$message.success('取消特价成功');
          _this.handleCloseSpecial();
          _this.req_procut_list(_this.form);
        });
    },
    set_show_home() {
      if (!this.productIds) {
        this.emptyDialog = true;
        return;
      } else {
        this.showDialog = true;
      }
    },
    handle_show_home() {
      this.showDialog = false;
    },
    commit_show_home() {
      const _this = this;
      Q.when()
        .then(function() {
          return _this.req_set_show_home({ productIds: _this.productIds });
        })
        .then(function(res) {
          _this.$message.success('设置首页展示成功');

          _this.handle_show_home();
          _this.req_procut_list(_this.form);
        });
    },
    set_platform_service() {
      if (!this.productIds) {
        this.emptyDialog = true;
        return;
      } else {
        this.serviceDialog = true;
      }
    },
    handle_platform_service() {
      this.serviceDialog = false;
      this.platformServiceAmount = '';
    },
    commit_platform_service() {
      const _this = this;
      const params = {
        productIds: _this.productIds,
        platformServiceAmount: _this.platformServiceAmount
      };
      Q.when()
        .then(function() {
          return _this.req_set_platform_service(params);
        })
        .then(function(res) {
          _this.$message.success('设置平台服务费成功');
          _this.handle_platform_service();
          _this.req_procut_list(_this.form);
        });
    },

    setAddition() {
      if (!this.productIds) {
        this.emptyDialog = true;
        return;
      } else {
        this.additionDialog = true;
      }
    },
    handleCloseAddition() {
      this.additionDialog = false;
      this.additionForm = {
        attachType: 1,
        attachAmountType: 2,
        attachAdult: '',
        attachBedChild: '',
        attachChild: '',
        attachAged: ''
      };
    },
    additionCommit() {
      const _this = this;
      let params = {};
      if (_this.additionForm.attachType === 0) {
        params = {
          attachType: _this.additionForm.attachType,
          attachAmountType: _this.additionForm.attachAmountType,
          attachAdult: 0,
          attachBedChild: 0,
          attachChild: 0,
          attachAged: 0
        };
        params.productIds = _this.productIds;
        params.productNos = _this.productNos;
        Q.when()
          .then(function() {
            return _this.req_addition(params);
          })
          .then(function(res) {
            _this.handleCloseAddition();
            _this.$message.success('设置附加金额成功');
            _this.req_procut_list(_this.form);
          });
      } else {
        _this.$refs.additionForm.validate(valid => {
          if (valid) {
            params = _this.additionForm;
            params.productIds = _this.productIds;
            params.productNos = _this.productNos;
            Q.when()
              .then(function() {
                return _this.req_addition(params);
              })
              .then(function(res) {
                _this.handleCloseAddition();
                _this.$message.success('设置附加金额成功');
                _this.req_procut_list(_this.form);
              });
          }
        });
      }
    },
    to_product_details(productNo) {
      get_preview_url(productNo)
    },
    to_supplier_detail(id) {
      this.$router.push({
        name: 'admin-supplier-detail',
        query: { id }
      });
    },
    look_team_offer(productNo) {
      const _this = this;
      Q.when()
        .then(function() {
          return _this.req_team_details({ productNo });
        })
        .then(function(res) {
          let data = res.tPdTourismTeams;
          data.forEach(function(item) {
            item.detailVisible = false;
          });
          _this.calendarData.events = data;
          _this.calendarData.visible = true;
        });
    },
    handleCloseCalendar() {
      this.calendarData.visible = false;
      setTimeout(() => {
        this.calendarData.full = false;
        this.calendarData.width = '50%';
        this.calendarData.status = 'half';
      }, 100);
    },
    full_screen() {
      this.calendarData.full = true;
      this.calendarData.status = 'full';
    },
    cancel_full() {
      this.calendarData.full = false;
      this.calendarData.width = '50%';
      this.calendarData.status = 'half';
    },
    calendar_shrink() {
      this.calendarData.width = '12%';
      this.calendarData.status = 'shink';
    },
    lookDdditionInfo(productNo) {
      const _this = this;
      Q.when()
        .then(function() {
          return _this.req_additionIfo({ productNo });
        })
        .then(function(res) {
          const additionInfoForm = _this.additionInfoForm;
          switch (res.specialAttachType) {
            case 0:
              additionInfoForm.attachTypeText = '无附加';
              break;
            case 1:
              additionInfoForm.attachTypeText = '加价';
              break;
            case 2:
              additionInfoForm.attachTypeText = '减免';
              break;
          }
          switch (res.specialAttachAmountType) {
            case 1:
              additionInfoForm.attachAmountTypeText = '固定金额';
              break;
            case 2:
              additionInfoForm.attachAmountTypeText = '百分比';
              break;
          }
          
          additionInfoForm.attachType = res.specialAttachType;
          additionInfoForm.attachAmountType = res.specialAttachAmountType;

          additionInfoForm.attachAdultAmount = res.specialAttachAdultAmount;
          additionInfoForm.attachChildBedAmount = res.specialAttachChildBedAmount;
          additionInfoForm.attachChildAmount = res.specialAttachChildAmount;
          additionInfoForm.attachAgedAmount = res.specialAttachAgedAmount;

          additionInfoForm.attachAdultPercent = res.specialAttachAdultPercent;
          additionInfoForm.attachChildBedPercent = res.specialAttachChildBedPercent;
          additionInfoForm.attachChildPercent = res.specialAttachChildPercent;
          additionInfoForm.attachAgedPercent = res.specialAttachAgedPercent;
          
          _this.additionInfoDialog = true;
        });
    },
    handleCloseadditionInfo() {
      this.additionInfoDialog = false;
      this.additionInfoForm = {
        attachType: '',
        attachAmountType: '',
        attachAdultPercent: '',
        attachChildBedPercent: '',
        attachChildPercent: '',
        attachAgedPercent: '',
        attachTypeText: '',
        attachAmountTypeText: ''
      };
    },
    handleSizeChange(pages) {
      this.form.pageSize = pages;
      this.form.currentPage = 1;
      this.req_procut_list(this.form, 'pager');
    },
    handleCurrentChange(page) {
      this.form.currentPage = page;
      this.req_procut_list(this.form, 'pager');
    },
    fold_change () {
      this.show = !this.show;
    }
  },
  created() {},
  mounted() {},
  activated() {
    this.initialize();
  },
  deactivated() {},
  destroyed() {},
  watch: {
    isCheckAll: function(newVal) {
      this.productData.forEach(function(item) {
        item.checked = newVal;
      });
    },
    productData: {
      handler: function(newVal) {
        let productIds = '';
        let productNos = '';
        newVal.forEach(function(item) {
          if (item.checked) {
            if (productIds) {
              productIds += `,${item.id}`;
            } else {
              productIds = item.id;
            }

            
            if (productNos) {
              productNos += `,${item.productNo}`;
            } else {
              productNos = item.productNo;
            }
          }
        });
        this.productIds = productIds;
        this.productNos = productNos;
      },
      deep: true
    }
  },
  computed: {}
};
