// web_managerSupplier_list

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口地址：

export default function web_managerSupplier_list(pParameter) {
  if (!pParameter) pParameter = {};
  /*const currentPage = pParameter.currentPage || 1
  const pageSize = pParameter.pageSize || 10
  const userName = pParameter.userName || ''
  const applicationType = pParameter.applicationType || ''
  const telephone = pParameter.telephone || ''
  const companyName = pParameter.companyName || ''
  const startTime = pParameter.startTime || ''
  const endTime = pParameter.endTime || ''
  const accountManagerId = pParameter.accountManagerId || ''
  const address = pParameter.address || ''
  const provinceId = pParameter.provinceId || ''
  const cityId = pParameter.cityId || ''
  const supplierStatus = pParameter.supplierStatus || ''
  const developerId = pParameter.developerId || ''
  const officeId = pParameter.officeId || ''*/
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/managerSupplier/list'
  params.data = pParameter
  /*params.data = {
    currentPage,
    pageSize,
    userName,
    applicationType,
    telephone,
    companyName,
    startTime,
    endTime,
    accountManagerId,
    address,
    provinceId,
    cityId,
    supplierStatus,
    developerId,
    officeId
  }*/
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
