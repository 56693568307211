// web_common_upload_aptitudeSingle

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口地址：

export default function web_common_upload_aptitudeSingle(pParameter) {
  if (!pParameter) pParameter = {};
  const formData = new FormData();
  formData.append('fileUpload', pParameter);
  return __request({
    urlSuffix: '/web/common/upload/aptitudeSingle',
    data: formData,
    // process_response_data: false,
    transformRequest: [function (data, headers) {
      return data;
    }],
  });
}
