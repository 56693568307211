import web_chopList from '@/lib/data-service/default/web_chopList'
import web_tourOrder_cutDownOrder from '@/lib/data-service/default/web_tourOrder_cutDownOrder'
import flight_travel_refund_order_list from '@/lib/data-service/flight/flight_travel_refund_order_list'
import Pagination from '@/component/base-pagination/index.vue'
import moment from 'moment'
export default {
  data () {
    return {
      loading: false,
      noPayedAmount: 0, // 未还款金额
      overdueFineLimitAmount: 0, // 总滞纳金
      companyId: '',
      params: {
        buyerId: '',
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      airParams: {
        buyerIds: '',
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      travelList: [],
      airList: [],
      chooseList: [],
      activeName: 'travel',
      travelNum: 0,
      airNum: 0,
      allCoverAmount: 0,
      moment: moment,
      showList: false,
      canSubmit: true
    }
  },
  components: {Pagination},
  created () {

  },
  mounted () {

  },
  activated () {
    this.companyId = parseInt(this.$route.query.buyerId)
    this.params.buyerId = this.$route.query.buyerId
    this.airParams.buyerIds = this.$route.query.buyerId
    this.noPayedAmount = parseFloat(this.$route.query.noPayedAmount)
    this.overdueFineLimitAmount = parseFloat(this.$route.query.overdueFineLimitAmount)
    this.getChopList()
    this.getAirList()
  },
  deactivated() {},
  destroyed () {
  },
  watch: {
    '$route.query.cancelOrderListBuyerId' (newVal, oldVal) { // 因为keep-alive的存在，页面中的待砍单列表不会因为切换页面而清空
      console.log(newVal, oldVal)
      if (newVal !== undefined) {
        this.chooseList = []
      }
    },
    chooseList(newVal) {
      let travelNum = 0
      let airNum = 0
      let allCoverAmount = 0 // 累计翻盖金额
      newVal.forEach(value => {
        if (value.appType === 2) {
          travelNum++
          allCoverAmount = allCoverAmount + value.coverAmount
        } else {
          airNum++
          allCoverAmount = allCoverAmount + value.coverAmount
        }
      })
      this.travelNum = travelNum
      this.airNum = airNum
      this.allCoverAmount = allCoverAmount
    }
  },
  computed: {},
  filters: {
    filterOrderState(val) {
      // 0-审核失败，1-待供应商审核，2-待平台审核，3-待支付，4-已支付，5确认出团，6-已出团，7-已回团，8-已分账，9-新增订单,10-已结算，11-切位收客失败
      if (val === 0) {
        return '审核失败'
      } else if (val === 1) {
        return '待供应商审核'
      } else if (val === 2) {
        return '待平台审核'
      } else if (val === 3) {
        return '待支付'
      } else if (val === 4) {
        return '已支付'
      } else if (val === 5) {
        return '确认出团'
      } else if (val === 6) {
        return '已出团'
      } else if (val === 7) {
        return '已回团'
      } else if (val === 8) {
        return '已分账'
      } else if (val === 9) {
        return '新增订单'
      } else if (val === 10) {
        return '已结算'
      } else {
        return '切位收客失败'
      }
    },
  },
  methods: {
    handleClick() {},
    formatterFinalPayment(val) {
      if (val.finalPayment === 0) {
        return '待缴尾款'
      } else if (val.finalPayment === 1) {
        return '已缴尾款'
      } else if (val.finalPayment === 2) {
        return '逾期未交尾款'
      } else {
        return '逾期缴纳尾款'
      }
    },
    formatterOrderState(val) {
      // 0-审核失败，1-待供应商审核，2-待平台审核，3-待支付，4-已支付，5确认出团，6-已出团，7-已回团，8-已分账，9-新增订单,10-已结算，11-切位收客失败
      if (val.orderState === 0) {
        return '审核失败'
      } else if (val.orderState === 1) {
        return '待供应商审核'
      } else if (val.orderState === 2) {
        return '待平台审核'
      } else if (val.orderState === 3) {
        return '待支付'
      } else if (val.orderState === 4) {
        return '已支付'
      } else if (val.orderState === 5) {
        return '确认出团'
      } else if (val.orderState === 6) {
        return '已出团'
      } else if (val.orderState === 7) {
        return '已回团'
      } else if (val.orderState === 8) {
        return '已分账'
      } else if (val.orderState === 9) {
        return '新增订单'
      } else if (val.orderState === 10) {
        return '已结算'
      } else {
        return '切位收客失败'
      }
    },
    formatterOrderType(val) {
      // 0.全部,1.普通订单，2.切位订单，3.特价订单
      if (val.orderType === 0) {
        return '全部'
      } else if (val.orderType === 1) {
        return '普通订单'
      } else if (val.orderType === 2) {
        return '切位订单'
      } else {
        return '特价订单'
      }
    },
    formatterAppType(val) {
      if (val.appType === 1) {
        return '机票'
      } else {
        return '旅游'
      }
    },
    getChopList() {
      web_chopList(this.params).then(res => {
        let travelList = res.pageResult.pageData
        travelList.forEach(value => {
          value.choose = false
          value.appType = 2
        })
        for (let i = 0, l = travelList.length; i < l; i++) {
          for (let m = 0, n = this.chooseList.length; m < n; m++) {
            if (travelList[i].id === this.chooseList[m].id) {
              travelList[i].choose = true
              break
            }
          }
        }
        this.travelList = travelList
        this.params.total = res.pageResult.totalCount
      })
    },
    getAirList() {
      flight_travel_refund_order_list(this.airParams).then(res => {
        let airList = res.pageResult.pageData
        airList.forEach(value => {
          value.choose = false
          value.appType = 1
          value.supplierSettleAmount = value.supTotalPay // 供应商结算金额
          value.buyerPaidAmount = value.totalPriceAll // 采购支付金额
        })
        for (let i = 0, l = airList.length; i < l; i++) {
          for (let m = 0, n = this.chooseList.length; m < n; m++) {
            if (airList[i].orderNo === this.chooseList[m].orderNo) {
              airList[i].choose = true
              break
            }
          }
        }
        this.airList = airList
        this.airParams.total = res.pageResult.totalCount
      })
    },
    chooseItem(val, index, appType) {
      if (val.choose) {
        for (let i = 0, l = this.chooseList.length; i < l; i++) {
          if (this.chooseList[i].id === val.id) {
            this.chooseList.splice(i, 1)
            break
          }
        }
        let item = this.travelList[index]
        item.choose = false
        this.travelList.splice(index, 1, item)
      } else {
        this.chooseList.push(val)
        let item = this.travelList[index]
        item.choose = true
        this.travelList.splice(index, 1, item)
      }
    },
    airChooseItem(val, index, appType) {
      if (val.choose) {
        for (let i = 0, l = this.chooseList.length; i < l; i++) {
          if (this.chooseList[i].orderNo === val.orderNo) {
            this.chooseList.splice(i, 1)
            break
          }
        }
        let item = this.airList[index]
        item.choose = false
        this.airList.splice(index, 1, item)
      } else {
        // 先判断添加之后累计覆盖金额会不会超过未还款总金额
        /* if (this.allCoverAmount + val.coverAmount > this.noPayedAmount + this.overdueFineLimitAmount) {
          this.$message({
            message: '累计覆盖金额不能超过未还款总金额',
            type: 'warning'
          })
          return
        }*/
        this.chooseList.push(val)
        let item = this.airList[index]
        item.choose = true
        this.airList.splice(index, 1, item)
      }
    },
    cancelChoose(val, index) {
      this.chooseList.splice(index, 1)
      if (val.appType === 1) {
        for (let i = 0, l = this.airList.length; i < l; i++) {
          if (this.airList[i].orderNo === val.orderNo) {
            let item = this.airList[i]
            item.choose = false
            this.airList.splice(i, 1, item)
            break
          }
        }
      } else {
        for (let i = 0, l = this.travelList.length; i < l; i++) {
          if (this.travelList[i].id === val.id) {
            let item = this.travelList[i]
            item.choose = false
            this.travelList.splice(i, 1, item)
            break
          }
        }
      }
    },
    showDetail() {
      this.showList = !this.showList
    },
    submit() {
      if (this.chooseList.length === 0) {
        this.$message({
          type: 'warning',
          message: '请先选择需要砍单的订单'
        })
        return
      }
      if (!this.canSubmit) {
        return
      }
      this.canSubmit = false
      let airOrderIds = []
      let travelOrderIds = []
      this.chooseList.forEach(value => {
        console.log(value)
        if (value.appType === 2) {
          travelOrderIds.push(value.id)
        } else {
          airOrderIds.push(value.orderNo)
        }
      })
      let params = {
        companyId: this.companyId,
        airOrderIds: airOrderIds.join(','),
        travelOrderIds: travelOrderIds.join(','),
        // orderIds: orderIds.join(',')
      }
      web_tourOrder_cutDownOrder(params).then(res => {
        this.canSubmit = true
        this.$message({
          type: 'success',
          message: '砍单成功'
        })
        // 清空待砍的单
        this.chooseList = []
        this.getChopList()
        this.getAirList()
      }).catch(() => {
        this.canSubmit = true
      })
    }
  }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
