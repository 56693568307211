import Q from 'q'
import router from '@/router'
import get_url_prefix from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/get_url_prefix";
import join from "nllib/src/uri/join";

const redirect_to_login_page = function (pParameter) {
    const environment = global.appConfig.environment;
    if (environment === `local`) {
        router.replace({
            name: `login`,
        });
        return;
    }

    const url_prefix = get_url_prefix({
        appKey: `frontend-distributor`,
    });
    const url = join(url_prefix, `login`);
    global.location.replace(url);
};

export default redirect_to_login_page;
