const Q = require('q');
const __request = require(`./__request/__request_contentType_json`);

const web_btoc_apply_statusEnum = function (pParameter) {
    if (!pParameter) pParameter = {};

    // 接口文档地址： http://tour-alpha.yinzhilv.com/doc.html#/yinzhilv_travel/btoc-controller/listEnumUsingPOST

    return Q.when().then(function () {
        return __request({
            urlSuffix: '/web/btoc/apply/statusEnum',
            data: pParameter,
        });
    }).catch(function (ex) {
        console.error(ex);
        if (ex) {
            if (ex.stack) {
                console.error(ex.stack);
            }
        }
        return Q.reject(ex);
    });
};

module.exports = web_btoc_apply_statusEnum;