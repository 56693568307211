// 票务配置枚举
import biz_platform_bdconfig_ticketProductConfig from "@/lib/data-service/b2c/biz_platform_bdconfig_ticketProductConfig";
// 配置列表
import biz_platform_bdconfig_configList from "@/lib/data-service/b2c/biz_platform_bdconfig_configList";
// 配置设置
import biz_platform_bdconfig_configSetting from "@/lib/data-service/b2c/biz_platform_bdconfig_configSetting";

export default {
    data() {
        return {
            loading: false,
            trainsFeeConfig: [],
        }
    },
    methods: {
        get_config_Type() { // 火车票费用配置枚举
            const __this = this;
            biz_platform_bdconfig_ticketProductConfig().then((res) => {
                res.datas.forEach((item) => {
                    const obj = {
                        code: item.code,
                        id: "",
                        text: item.msg,
                        type: "TICKET_PRODUCT_CONFIG",
                        value: ""
                    };
                    __this.trainsFeeConfig.push(obj);
                });
            }).finally(() => {
                __this.get_config_list();
            });
        },
        get_config_list() {
            const __this = this;
            biz_platform_bdconfig_configList({type: 'TICKET_PRODUCT_CONFIG'}).then((res) => {
                if (res.datas.length !== 0) {
                    res.datas.forEach((item) => {
                        __this.trainsFeeConfig.forEach(config => {
                            if (item.code === config.code) {
                                config.id = item.id;
                                config.value = item.value;
                            }
                        })
                    })
                }
                console.log(__this.trainsFeeConfig)
            });
        },
        update_setting() {
            const __this = this;
            const params = {
                configVos: __this.trainsFeeConfig
            };
            __this.loading = true;
            biz_platform_bdconfig_configSetting(params).then((res) => {
                __this.$message.success('设置成功');
                __this.get_config_list();
            }).finally(() => {
                __this.loading = false;
            });
        }
    },
    created() {
        const __this = this;
        __this.get_config_Type();
    },
    filters: {}
}