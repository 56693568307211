import import_latest_version_module from '@/component/admin-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/buyer-total-data',
            name: 'admin-buyer-total-data',
            component: () => import(/* webpackChunkName: "page-admin-statistical-report-buyer-total-data" */ `@/page/admin/statistical-report/buyer-total-data/index.vue`),
            meta: {
          data_for_admin_layout: {
                    data_for_history_tag: {
                              entity: {
                                        name: "分销商统计详情"
                              }
                    },
                    data_for_breadcrumb: {
                              entity_list: [
                                        {
                                                  name: "数据统计"
                                        },
                                        {
                                                  name: "分销商统计"
                                        },
                                        {
                                                  name: "分销商统计详情"
                                        }
                              ]
                    }
          }
},
        },
    ],
};
