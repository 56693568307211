

const __request = require(`./__request/__request_contentType_json`);

// 火车票平台订单操作日志列表接口地址：http://219.128.52.2:9901/doc.html#/trains/order-log-controller/getPlatformOrderLogListUsingPOST_1

export default function trains_OrderLog_getPlatformOrderLogList(pParameter) {
  if (!pParameter) pParameter = {};
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/trains/OrderLog/getPlatformOrderLogList'
  params.data = pParameter;
  params.process_response_data = false
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}
