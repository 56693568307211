import web_common_credit_cutCreditBillList from '@/lib/data-service/default/web_common_credit_cutCreditBillList'
import Pagination from '@/component/base-pagination/index.vue'
import web_common_user_getDeveloper from '@/lib/data-service/default/web_common_user_getDeveloper'
import web_common_credit_cutOrdRelieve from '@/lib/data-service/default/web_common_credit_cutOrdRelieve'
import moment from 'moment'
export default {
  data () {
    return {
      loading: false,
      params: {
        currentPage: 1,
        pageSize: 10,
        accountName: '',
        companyName: '',
        accountManager: '',
        total: 0
      },
      accountManagerList: [],
      list: [],
      moment: moment
    }
  },
  components: {Pagination},
  created () {
  },
  mounted () {

  },
  activated () {
    this.getDeveloperList()
    this.getCutCreditBillList()
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
    getDeveloperList() {
      web_common_user_getDeveloper().then((res) => {
        this.accountManagerList = res.getAllDeveloperResult
      })
    },
    getCutCreditBillList() {
      this.loading = true
      web_common_credit_cutCreditBillList(this.params).then(res => {
        let list = res.pageResult.pageData
        list.forEach(value => {
          value.show = false
          let overdueFineLimitAmount = 0
          value.billResultList.forEach(val => {
            if (val.overdueFineLimitAmount !== null && val.overdueFineLimitAmount !== '') {
              overdueFineLimitAmount = overdueFineLimitAmount + val.overdueFineLimitAmount
            }
          })
          value.overdueFineLimitAmount = overdueFineLimitAmount // 总的滞纳金
        })
        this.list = list
        this.params.total = res.pageResult.totalCount
        this.loading = false
      }).catch(() => {
        this.loading = false
      }, new Error(() => {
        this.loading = false
      }))
    },
    onSearch() {
      this.params.currentPage = 1
      this.getCutCreditBillList()
    },
    onReset() {
      this.params = {
        currentPage: 1,
        pageSize: 10,
        accountName: '',
        companyName: '',
        accountManager: '',
        total: 0
      }
      this.onSearch()
    },
    setOrder(val) {
      this.$router.push({
        name: 'admin-cancel-order-list',
        query: {
          cancelOrderListBuyerId: val.companyId,
          buyerId: val.companyId,
          noPayedAmount: val.noPayedAmount, // 未还款总额
          overdueFineLimitAmount: val.overdueFineLimitAmount // 总滞纳金
        }
      })
    },
    cancelSetOrder(val) {
      let params = {
        companyId: val.companyId
      }
      web_common_credit_cutOrdRelieve(params).then(res => {
        this.$message({
          message: '解除砍单成功',
          type: 'success'
        })
        this.getCutCreditBillList()
      })
    },
    setShow(val, index) {
      this.$refs['orderTable'].toggleRowExpansion(val, !val.show)
      // this.list[index].show = !val.show
    },
    expandChangeTable(row, expandedRows) {
      for (let i = 0, l = this.list.length; i < l; i++) {
        if (this.list[i].companyId === row.companyId) {
          this.list[i].show = !row.show
          break
        }
      }
    },
  }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
