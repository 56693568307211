// web_circuit_findTouristTour


const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口地址：

export default function web_circuit_findTouristTour(pParameter) {
  if (!pParameter) pParameter = {};
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/circuit/findTouristTour'
  params.data = {}
  params.process_response_data = false
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}
