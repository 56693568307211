import moment from "moment";
import web_salesOrder_tourOrderDetails from '@/lib/data-service/default/web_salesOrder_tourOrderDetails'; // 产品订单详情
import web_credit_orderBill from '@/lib/data-service/default/web_credit_orderBill';  // 授信订单详情
import web_credit_travelCreditLogList from '@/lib/data-service/default/web_credit_travelCreditLogList';  // 授信日志列表


export default {
    data () {
        return {
            loading: false,
            orderDetails: {},
            creditDetails: {},
            logList: []
        }
    },
    methods: {
        getOrderDetails() {
            const __this = this;
            const id = __this.$route.query.id;
            web_salesOrder_tourOrderDetails({id: id}).then(res => {
                __this.orderDetails = res.result;
                __this.loading = false
            }).catch(() => {
                __this.loading = false
            }, new Error(() => {
                __this.loading = false
            }))
        },
        getCreditDetails() {
            const __this = this;
            const orderNo = __this.$route.query.orderNo;
            __this.loading = true;
            web_credit_orderBill({orderNo: orderNo}).then(res => {
                __this.creditDetails = res;
                __this.loading = false
            }).catch(() => {
                __this.loading = false
            }, new Error(() => {
                __this.loading = false
            }))
        },
        getCreditLogList() {
            const __this = this;
            const orderNo = __this.$route.query.orderNo;
            web_credit_travelCreditLogList({orderNo: orderNo}).then(res => {
                __this.logList = res.pageResult.pageData;
            });
        }
    },
    activated () {
        const __this = this;
        __this.getOrderDetails();
        __this.getCreditDetails();
        __this.getCreditLogList();
    },
    filters: {
        filterDate(val) {
            return val ? moment(val).format("YYYY-MM-DD") : '--';
        }
    }
}
