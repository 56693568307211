import import_latest_version_module from '@/component/admin-layout/import_latest_version_module';

export default {
  path: '',
  component: () => import_latest_version_module(),
  children: [
    {
      path: '/admin/buyer/opening-mall-no',
      name: 'admin-buyer-opening-mall-no',
      component: () => import(/* webpackChunkName: "page-admin-buyer-management-opening-mall-no" */ `./1.0.1/index.vue`),
      meta: {
        entity_for_history_tag: {
          name: `开通微商城`,
        },
        entity_list_for_breadcrumb: [
          {name: `分销商管理`},
          {name: `分销商列表`},
          {name: `开通微商城`},
        ],
        data_for_left_menu: {
          get_active_route_name(pParameter) {
              // if (!pParameter) pParameter = {};
              // const all_route_list = pParameter.all_route_list;
              return `admin-buyer-list`;
          },
      },
      },
    },
  ],
};
