// web_settleAccounts_getSettlementConfirmation 获取结算确认信息

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口地址：http://192.168.0.15:8080/doc.html#/所有接口/settle-accounts-controller/getSettlementProcessingListUsingPOST

export default function web_settleAccounts_getSettlementConfirmation(pParameter) {
  if (!pParameter) pParameter = {};
  const settleAccountId = pParameter.settleAccountId || ''

  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/settleAccounts/getSettlementConfirmation'
  params.data = {
    settleAccountId
  }
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}

