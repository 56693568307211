// web_managerSupplier_flightSupplier_getDefaultGrade 获取机票供应商默认评分

const __request = require(`@/lib/data-service/default/__request/__request_contentType_json`);

// 接口地址：http://192.168.0.19:9999/doc.html#/yinzhilv_travel/manager-supplier-controller/getDefaultGradeUsingPOST

export default function web_managerSupplier_flightSupplier_getDefaultGrade() {
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/managerSupplier/flightSupplier/getDefaultGrade'
  params.data = {}
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}
