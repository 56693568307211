import web_common_user_getDeveloper from '@/lib/data-service/default/web_common_user_getDeveloper'
import web_managerSupplier_getSupplierRelevanceList from '@/lib/data-service/default/web_managerSupplier_getSupplierRelevanceList'
import web_managerSupplier_updateOfExpirationTime from '@/lib/data-service/default/web_managerSupplier_updateOfExpirationTime'
// 查询供应商当前绑定关系对应的分销商  isOperationFlow
import web_managerSupplier_getSupplierBoundBuyerList from '@/lib/data-service/default/web_managerSupplier_getSupplierBoundBuyerList'

// 更改分销商与供应商绑定关系 boundStatus 1绑定 ，0解绑
import web_managerSupplier_updateSupplierBoundBuyer from '@/lib/data-service/default/web_managerSupplier_updateSupplierBoundBuyer'
import Pagination from '@/component/base-pagination/index.vue'
export default {
  data () {
    return {
      loading: false,
      params: {
        currentPage: 1,
        pageSize: 10,
        userName: '',
        companyName: '',
        applicationType: '',
        startTime: '',
        endTime: '',
        supplierStatus: '',
        telephone: '',
        accountManagerId: '',
        developerId: '',
        startRelevanceTime: '',
        endRelevanceTime: '',
        total: 0,

        period: [],
        arriveTime: []
      },
      showMore: false,
      userTypeList: [{value: '', label: '全部'}, {value: 1, label: '机票'}, {value: 2, label: '旅游'}, {value: '1+2', label: '机票+旅游'}],
      developerList: [],
      userList: [],
      changeList: [],
      dialogTimeVisible: false,
      timeParams: {
        ofExpirationTime: ''
      },
      timeRule: {
        ofExpirationTime: [
          {required: true, message: '请填写流水到期时间', trigger: 'change'}
        ]
      },
      dialogDetailVisible: false,
      boundBuyerList: [],

      boundBuyerLoading: false,

      boundBuyerForm: {
        id: '',
        isOperationFlow: '',
        patternType: 1,
        currentPage: 1,
        pageSize: 10,
        userName: '',
        companyName: '',
      },
      boundBuyerPager: {
        total: 0,
        pageSizes: [10, 15, 20, 25, 30],
      },


      dialogStatus: '',
      textMap: {
        check: '查看明细',
        set: '绑定分销商'
      },
      boundBuyerChangeList: [], // 选中的分销商的合集
      dialogListVisible: false,
    }
  },
  components: {
    Pagination
  },
  created () {
  },
  mounted () {
    
  },
  activated () {
    this.getDeveloperList()
    this.getSupplierRelevanceList()
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
    reqBoundBuyerList () {
      if (this.boundBuyerLoading) return;
      this.boundBuyerLoading = true;
      this.boundBuyerList = [];
      this.boundBuyerPager.total = 0;
      web_managerSupplier_getSupplierBoundBuyerList(this.boundBuyerForm).then((res) => {
        const datas = res.pageResult;
        this.boundBuyerList = datas.pageData;
        this.boundBuyerPager.total = datas.totalCount;
        this.boundBuyerLoading = false;
      }).catch((err) => {
        this.boundBuyerLoading = false;
      })
    },

    searchBoundBuyer () {
      this.boundBuyerChangeCurrent(1);
    },
    resetBoundBuyer () {
      const form = this.boundBuyerForm;
      form.userName = '';
      form.companyName = '';
      this.searchBoundBuyer();
    },

    boundBuyerChangeCurrent (currentPage) {
      this.boundBuyerForm.currentPage = currentPage;
      this.reqBoundBuyerList();
    },
    boundBuyerChangeSize (pageSize) {
      this.boundBuyerForm.pageSize = pageSize;
      this.boundBuyerChangeCurrent(1);
    },



    handleFilter() {
      this.onSearch()
    },
    showOrHideForm() {
      const showMore = this.showMore
      this.showMore = !showMore
    },
    changeParamsTime(val) {
      if (val === '' || val === null) {
        this.params.startTime = ''
        this.params.endTime = ''
      } else {
        this.params.startTime = val[0]
        this.params.endTime = val[1]
      }
    },
    changeParamsArriveTime(val) {
      if (val === '' || val === null) {
        this.params.startRelevanceTime = ''
        this.params.endRelevanceTime = ''
      } else {
        this.params.startRelevanceTime = val[0]
        this.params.endRelevanceTime = val[1]
      }
    },
    onSearch() {
      this.params.currentPage = 1
      this.getSupplierRelevanceList()
    },
    onReset() {
      this.params = {
        currentPage: 1,
          pageSize: 10,
          userName: '',
          companyName: '',
          applicationType: '',
          startTime: '',
          endTime: '',
          supplierStatus: '',
          telephone: '',
          accountManagerId: '',
          developerId: '',
          startRelevanceTime: '',
          endRelevanceTime: '',
          total: 0,
          period: [],
          arriveTime: []
      }
      this.onSearch()
    },

    // 获取开发者列表 - （机票经理）
    getDeveloperList() {
      web_common_user_getDeveloper().then((res) => {
        this.developerList = res.getAllDeveloperResult
      })
    },
    getSupplierRelevanceList() {
      this.loading = true
      web_managerSupplier_getSupplierRelevanceList(this.params).then(res => {
        this.userList = res.pageResult.pageData
        this.params.total = res.pageResult.totalCount
        this.loading = false
      }, new Error(() => {
        this.loading = false
      })).catch(() => {
        this.loading = false
      })
    },

    handleSetEndTime() {
      if (this.changeList.length === 0) {
        this.$message('请选择要设置的供应商')
        return false
      }
      this.dialogTimeVisible = true
      this.resetTime()
      this.$nextTick(() => {
        this.$refs['timeForm'].clearValidate()
      })
    },
    // 查看已绑定明细
    handleCheck(val) {
      this.dialogStatus = 'check'
      this.dialogDetailVisible = true;
      this.boundBuyerForm.id = val.supplierId
      this.boundBuyerForm.isOperationFlow = 1;
      this.reqBoundBuyerList();
    },
    // 未绑定
    handleBind(val) {
      this.dialogStatus = 'set'
      this.dialogListVisible = true
      this.boundBuyerForm.id = val.supplierId
      this.boundBuyerForm.isOperationFlow = 2
      this.reqBoundBuyerList();
    },
    handleSelectionChange(val) {
      this.changeList = val
    },
    handleTimeDialogClose() {
      this.dialogTimeVisible = false
    },
    submitTime() {
      this.$refs['timeForm'].validate((valid) => {
        if (valid) {
          let idArr = []
          this.changeList.forEach(value => {
            idArr.push(value.supplierId)
          })
          let ids = idArr.join(',')
          let params = {
            ids,
            ofExpirationTime: this.timeParams.ofExpirationTime
          }
          
          web_managerSupplier_updateOfExpirationTime(params).then(res => {
            if (res.code === '000000') {
              this.$message({
                message: '设置成功',
                type: 'success'
              })
              this.dialogTimeVisible = false
              this.changeList = []
              this.getSupplierRelevanceList()
            }
          })
        }
      })
    },
    resetTime() {
      this.timeParams = {
        ofExpirationTime: ''
      }
    },
    handleDetailDialogClose(val) {
      this.dialogDetailVisible = false
      this.boundBuyerChangeList = []


      
      this.boundBuyerList = [];
      this.boundBuyerForm = {
        id: '',
        isOperationFlow: '',
        patternType: 1,
        currentPage: 1,
        pageSize: 10,
        userName: '',
        companyName: '',
      };
    },
    handleCancelBind(val) {
      this.$confirm('确定取消绑定吗?', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = {
          supplierIds: this.boundBuyerForm.id,
          buyerIds: val.buyerId,
          boundStatus: 0
        }
        web_managerSupplier_updateSupplierBoundBuyer(params).then(res => {
          if (res.code === '000000') {
            this.$message({
              message: '成功解除绑定',
              type: 'success'
            })
            this.reqBoundBuyerList()
          } else {
            this.$message({
              message: res.msg,
              type: 'warning'
            })
          }
        })
      }).catch(() => {});
    },
    handleSelectionUserChange (val) {
      this.boundBuyerChangeList = val
    },
    submitBind() {
      if (this.boundBuyerChangeList.length === 0) {
        this.$message('请选择要绑定的分销商')
        return false
      }
      let buyerIds = []
      this.boundBuyerChangeList.forEach(value => {
        buyerIds.push(value.buyerId)
      })
      let params = {
        supplierIds: this.boundBuyerForm.id,
        buyerIds: buyerIds.join(','),
        boundStatus: 1
      }
      web_managerSupplier_updateSupplierBoundBuyer(params).then(res => {
        if (res.code === '000000') {
          this.$message({
            message: '绑定成功',
            type: 'success'
          })
          this.handleListDialogClose()
        } else {
          this.$message({
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    handleListDialogClose() {
      this.dialogListVisible = false
      this.boundBuyerChangeList = []

      
      this.boundBuyerList = [];
      this.boundBuyerForm = {
        id: '',
        isOperationFlow: '',
        patternType: 1,
        currentPage: 1,
        pageSize: 10,
        userName: '',
        companyName: '',
      };
    }
  }
}
