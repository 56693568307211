const __request = require(`./__request/__request_contentType_json`)
const consumer_tokenLogin = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/tokenLogin',
        data: data,
        check_token: false
    }
    return __request(pParameter)
}
export default consumer_tokenLogin
