import Q from 'q';

import moment from 'moment';

// 产品列表
import web_product_touristRouteList from '@/lib/data-service/default/web_product_touristRouteList';
// 设置附加金额
import web_product_updateAdditionMoney from '@/lib/data-service/default/web_product_updateAdditionMoney';
// 设置下线
import web_product_updateOffline from '@/lib/data-service/default/web_product_updateOffline';
// 设置产品等级
import web_common_product_updateProductLevel from '@/lib/data-service/default/web_common_product_updateProductLevel';
// 设置特价
import web_common_product_setSpecial from '@/lib/data-service/default/web_common_product_setSpecial';
// 附加金额详情
import web_product_getAdditionDetail from '@/lib/data-service/default/web_product_getAdditionDetail';

// 获取团期
import web_common_userSupplier_GroupStage from '@/lib/data-service/default/web_common_userSupplier_GroupStage';
// 产品级别枚举
import web_common_product_getProductLevel from '@/lib/data-service/default/web_common_product_getProductLevel';



// 跳转预览
import get_preview_url from '@/lib/common-service/get_preview_url';



import FullCalendar from '../component/FullCalendar/team/index.vue';


import Citys from '@/component/citys/index.vue';

export default {
  components: {
    Citys,
    FullCalendar
  },
  data() {
    return {
      form: {
        currentPage: 1,
        pageSize: 10,
        productNo: '',
        productName: '',
        productAreasType: '',
        userName: '',
        havaShopping: '',
        havaSelfItem: '',
        containVisa: '',
        attachType: -1,
        productStatus: -1,
        attachAmountType: -1,
        supplierName: '',
        toProvince: '',
        toCity: '',
        fromProvince: '',
        fromCity: '',
        tripDay: '',
        isHaveTeam: '',
        productLevel: '',
        specialSource: '',
        isRestrict: '',
      },
      allocation: {
        areasTypeList: [
          { label: '全部', value: '' },
          { label: '国内', value: 1 },
          { label: '出境', value: 2 }
        ],
        havaShoppingList: [
          { label: '全部', value: '' },
          { label: '有', value: 1 },
          { label: '无', value: 2 }
        ],
        havaSelfItemList: [
          { label: '全部', value: '' },
          { label: '有', value: 1 },
          { label: '无', value: 2 }
        ],
        containVisaList: [
          { label: '全部', value: '' },
          { label: '包含', value: 1 },
          { label: '不包含', value: 2 }
        ],
        attachTypeList: [
          { label: '全部', value: -1 },
          { label: '加价', value: 1 },
          { label: '减免', value: 2 },
          { label: '无附加', value: 0 }
        ],
        productStatusList: [
          { label: '全部', value: -1 },
          { label: '上线', value: 3 },
          { label: '下线', value: 4 },
          { label: '平台下线', value: 5 }
        ],
        attachAmountTypeList: [
          { label: '全部', value: -1 },
          { label: '固定金额', value: 1 },
          { label: '百分比', value: 2 }
        ],
        tripDayList: [
          { label: '全部', value: '' },
          { label: '一天', value: 1 },
          { label: '两天', value: 2 },
          { label: '三天', value: 3 },
          { label: '四天', value: 4 },
          { label: '五天', value: 5 },
          { label: '六天', value: 6 },
          { label: '七天', value: 7 },
          { label: '八天', value: 8 },
          { label: '八天以上', value: 9 }
        ],
        isHaveTeamList: [
          { label: '全部', value: '' },
          { label: '无团期', value: 0 },
          { label: '有团期', value: 1 }
        ],
        productLevelList: [],
        specialSourceList: [
          { label: '全部', value: '' },
          { label: '无', value: 0 },
          { label: '供应商设置', value: 1 },
          { label: '平台设置', value: 2 }
        ],
        isRestrict: [
          { label: '全部', value: '' },
          { label: '不限', value: 0 },
          { label: '限定', value: 1 },
        ]
      },
      pager: {
        pageSizes: [10, 20, 30, 40, 50],
        totalCount: 0
      },
      productData: [],
      isCheckAll: false,
      emptyDialog: false,
      offlineDialog: false,
      additionDialog: false,
      levelDialog: false,
      specialDialog: false,
      additionInfoDialog: false,
      productIds: '',
      productNos: '',
      offlineCause: '',
      show: false,
      loading: false,
      additionForm: {
        attachType: 1,
        attachAmountType: 2,
        attachAdult: '',
        attachBedChild: '',
        attachChild: '',
        attachAged: ''
      },
      additionRules: {
        attachType: [
          { required: true, message: '请选择附加类型', trigger: 'blur' }
        ],
        attachAmountType: [
          { required: true, message: '请选择附加方式', trigger: 'blur' }
        ],
        attachAdult: [
          { required: true, message: '请输入成人附加', trigger: 'blur' },
          { pattern: /\d/g, message: '请输入数字值', },
        ],
        attachBedChild: [
          { required: true, message: '请输入儿童占床附加', trigger: 'blur' },
          { pattern: /\d/g, message: '请输入数字值', },
        ],
        attachChild: [
          { required: true, message: '请输入儿童不占床附加', trigger: 'blur' },
          { pattern: /\d/g, message: '请输入数字值', },
        ],
        attachAged: [
          { required: true, message: '请输入老人附加', trigger: 'blur' },
          { pattern: /\d/g, message: '请输入数字值', },
        ]
      },
      additionAllocation: {
        attachTypeList: [
          { label: 1, value: '加价' },
          { label: 2, value: '减免' },
          { label: 0, value: '无附加' }
        ],
        attachAmountTypeList: [
          { label: 2, value: '百分比' },
          { label: 1, value: '固定金额' }
        ]
      },
      productLevel: '0',
      productLevelList: [],
      additionInfoForm: {
        attachType: '',
        attachAmountType: '',
        attachAdultPercent: '',
        attachChildBedPercent: '',
        attachChildPercent: '',
        attachAgedPercent: '',
        attachTypeText: '',
        attachAmountTypeText: '',
      },
      calendarData: {
        visible: false,
        full: false,
        width: '50%',
        status: 'half',
				events: [],
        locale: '',
				selectStatus: 2,
				firstDay: 0,
				initialDayTime: 0,
				deleteGroupDate: [],
      },
    };
  },
  methods: {
    req_procut_list(params, type) {
      this.loading = true;
      const _this = this;
      Q.when()
        .then(function() {
          return web_product_touristRouteList(params);
        })
        .then(function(res) {
          const productData = res.pageResult.pageData;
          productData.forEach(function(item) {
            if (item.lastTeamDate) item.lastTeamDate = moment(item.lastTeamDate).format('YYYY-MM-DD');
            item.checked = false;
            switch (item.productAreasType) {
              case 1:
                item.areasTypeText = '国内';
                break;
              case 2:
                item.areasTypeText = '出境';
                break;
            }
            switch (item.isHaveTeam) {
              case 0:
                item.isHaveTeamText = '无团期';
                break;
              case 1:
                item.isHaveTeamText = '有团期';
                break;
            }
            switch (item.havaShopping) {
              case 1:
                item.havaShoppingText = '有';
                break;
              case 2:
                item.havaShoppingText = '无';
                break;
            }
            switch (item.havaSelfItem) {
              case 1:
                item.havaSelfItemText = '有';
                break;
              case 2:
                item.havaSelfItemText = '无';
                break;
            }
            switch (Number(item.productLevel)) {
              case 0:
                item.productLevelText = '无';
                break;
              case 1:
                item.productLevelText = '经典';
                break;
              case 2:
                item.productLevelText = '经济';
                break;
              case 3:
                item.productLevelText = '促销';
                break;
              case 4:
                item.productLevelText = '尊享';
                break;
              case 5:
                item.productLevelText = '转谈';
                break;
              case 6:
                item.productLevelText = '臻品';
                break;
            }
            switch (item.productStatus) {
              case 3:
                item.productStatusText = '上线';
                break;
              case 4:
                item.productStatusText = '下线';
                break;
              case 5:
                item.productStatusText = '平台下线';
                break;
            }
          });
          _this.isCheckAll = false;
          _this.pager.totalCount = res.pageResult.totalCount;
          _this.productData = productData;
          if (type) document.documentElement.scrollTop = 0;

          _this.loading = false;
        });
    },
    req_addition(params) {
      return Q.when().then(function() {
        return web_product_updateAdditionMoney(params);
      });
    },
    req_offLine(params) {
      return Q.when().then(function() {
        return web_product_updateOffline(params);
      });
    },
    req_level (params) {
      return Q.when()
        .then(function () {
          return web_common_product_updateProductLevel(params);
        })
    },
    req_setSpecial (params) {
      return Q.when()
        .then(function () {
          return web_common_product_setSpecial(params);
        })
    },
    req_team_details (params) {
      return Q.when()
        .then(function () {
          return web_common_userSupplier_GroupStage(params);
        })
    },
    req_additionIfo (params) {
      return Q.when()
        .then(function () {
          return web_product_getAdditionDetail(params);
        })
    },
    initialize() {
      web_common_product_getProductLevel().then((res) => {
        this.productLevelList = [...res.list];
        res.list.unshift({label: "全部", value: ""});
        this.allocation.productLevelList = res.list;
      })
      this.req_procut_list(this.form);
    },
    getToProvince(value) {
      this.form.toProvince = value;
    },
    getToCity(value) {
      this.form.toCity = value;
    },
    getFromProvince(value) {
      this.form.fromProvince = value;
    },
    getFromCity(value) {
      this.form.fromCity = value;
    },
    search() {
      this.form.currentPage = 1;
      this.req_procut_list(this.form);
    },
    reset() {
      this.$refs.toCity.reset_toArea();
      this.$refs.fromCity.reset_fromArea();
      const pageSize = this.form.pageSize;
      this.form = {
        currentPage: 1,
        pageSize,
        productNo: '',
        productName: '',
        productAreasType: '',
        userName: '',
        havaShopping: '',
        havaSelfItem: '',
        containVisa: '',
        attachType: -1,
        productStatus: -1,
        attachAmountType: -1,
        supplierName: '',
        toProvince: '',
        toCity: '',
        fromProvince: '',
        fromCity: '',
        tripDay: '',
        isHaveTeam: '',
        productLevel: '',
        specialSource: '',
        isRestrict: '',
      };
      this.search();
    },
    handleCloseEmpty() {
      this.emptyDialog = false;
    },
    setAddition() {
      if (!this.productIds) {
        this.emptyDialog = true;
        return;
      } else {
        this.additionDialog = true;
      }
    },
    handleCloseAddition() {
      this.additionDialog = false;
      this.additionForm = {
        attachType: 1,
        attachAmountType: 2,
        attachAdult: '',
        attachBedChild: '',
        attachChild: '',
        attachAged: ''
      };
    },
    additionCommit() {
      const _this = this;
      let params = {};
      if (_this.additionForm.attachType === 0) {
        params = {
          attachType: _this.additionForm.attachType,
          attachAmountType: _this.additionForm.attachAmountType,
          attachAdult: 0,
          attachBedChild: 0,
          attachChild: 0,
          attachAged: 0
        };
        params.productIds = _this.productIds;
        params.productNos = _this.productNos;
        Q.when()
        .then(function() {
          return _this.req_addition(params);
        })
        .then(function(res) {
          _this.handleCloseAddition();
          _this.$message.success('设置附加金额成功');
          _this.req_procut_list(_this.form);
        });

      } else {
        _this.$refs.additionForm.validate((valid) => {
          if (valid) {
            params = _this.additionForm;
            params.productIds = _this.productIds;
            params.productNos = _this.productNos;
            Q.when()
            .then(function() {
              return _this.req_addition(params);
            })
            .then(function(res) {
              _this.handleCloseAddition();
              _this.$message.success('设置附加金额成功');
              _this.req_procut_list(_this.form);
            });
          };
        });
      }
    },
    offline() {
      if (!this.productIds) {
        this.emptyDialog = true;
        return;
      } else {
        this.offlineDialog = true;
      }
    },
    handleCloseOffline() {
      this.offlineDialog = false;
      this.offlineCause = '';
    },
    commitOffline(params) {
      const _this = this;
      Q.when()
        .then(function() {
          return _this.req_offLine(params);
        })
        .then(function(res) {
          _this.$message.success('设置下线成功');
          _this.handleCloseOffline();
          _this.req_procut_list(_this.form);
        });
    },
    setLevel () {
      if (!this.productIds) {
        this.emptyDialog = true;
      } else {
        this.levelDialog = true;
      }
    },
    handleCloseLevel () {
      this.levelDialog = false;
      this.productLevel = '0';
    },
    commitSetLevel () {
      const _this = this;
      let params = {productIds: _this.productIds, productLevel: _this.productLevel};
      Q.when()
        .then(function () {
          return _this.req_level(params);
        })
        .then(function (res) {
          _this.$message.success('设置产品级别成功')
          _this.handleCloseLevel();
          _this.req_procut_list(_this.form);
        })
    },
    setSpecial () {
      if (!this.productIds) {
        this.emptyDialog = true;
      } else {
        this.specialDialog = true;
      }
    },
    handleCloseSpecial () {
      this.specialDialog = false;
    },
    commitSetSpecial () {
      const _this = this;
      Q.when()
        .then(function () {
          _this.specialDialog = false;
          return _this.req_setSpecial({productIds: _this.productIds});
        })
        .then(function (res) {
          _this.$message.success('设置特价成功');
          _this.handleCloseSpecial();
          _this.req_procut_list(_this.form);
        });
    },
    to_product_details(productNo) {
      get_preview_url(productNo)
    },
    to_supplier_detail (id) {
      this.$router.push({
        name: 'admin-supplier-detail',
        query: {id},
      })
    },
    look_team_offer (productNo) {
      const _this = this;
      Q.when()
        .then(function () {
          return _this.req_team_details({productNo});
        })
        .then(function (res) {
          let data = res.tPdTourismTeams;
          data.forEach(function (item) {
            item.detailVisible = false;
          })
          _this.calendarData.events = data;
          _this.calendarData.visible = true;
        })
    },
    handleCloseCalendar () {
      this.calendarData.visible = false;
      setTimeout(() => {
        this.calendarData.full = false;
        this.calendarData.width = '50%';
        this.calendarData.status = 'half';
      }, 100);
    },
    full_screen () {
      this.calendarData.full = true;
      this.calendarData.status = 'full';
    },
    cancel_full () {
      this.calendarData.full = false;
      this.calendarData.width = '50%';
      this.calendarData.status = 'half';
    },
    calendar_shrink () {
      this.calendarData.width = '12%';
      this.calendarData.status = 'shink';
    },
    lookDdditionInfo (productId) {
      const _this = this;
      Q.when()
        .then(function () {
          return _this.req_additionIfo({productId});
        })
        .then(function (res) {
          switch (res.attachType) {
            case 0:
              res.attachTypeText = '无附加';
              break;
            case 1:
              res.attachTypeText = '加价';
              break;
            case 2:
              res.attachTypeText = '减免';
              break;
          };
          switch (res.attachAmountType) {
            case 1:
              res.attachAmountTypeText = '固定金额';
              break;
            case 2:
              res.attachAmountTypeText = '百分比';
              break;
          };
          _this.additionInfoForm = res;
          _this.additionInfoDialog = true;
        })
    },
    handleCloseadditionInfo () {
      this.additionInfoDialog = false;
      this.additionInfoForm = {
        attachType: '',
        attachAmountType: '',
        attachAdultPercent: '',
        attachChildBedPercent: '',
        attachChildPercent: '',
        attachAgedPercent: '',
        attachTypeText: '',
        attachAmountTypeText: '',
      };
    },
    handleSizeChange(pages) {
      this.form.pageSize = pages;
      this.form.currentPage = 1;
      this.req_procut_list(this.form, 'pager');
    },
    handleCurrentChange(page) {
      this.form.currentPage = page;
      this.req_procut_list(this.form, 'pager');
    },
    fold_change () {
      this.show = !this.show;
    }
  },
  created() {},
  mounted() {},
  activated() {
    this.initialize();
  },
  deactivated() {},
  destroyed() {},
  watch: {
    isCheckAll: function(newVal) {
      this.productData.forEach(function(item) {
        item.checked = newVal;
      });
    },
    productData: {
      handler: function(newVal) {
        let productIds = '';
        let productNos = '';
        newVal.forEach(function(item) {
          if (item.checked) {
            if (productIds) {
              productIds += `,${item.id}`;
            } else {
              productIds = item.id;
            }

            
            if (productNos) {
              productNos += `,${item.productNo}`;
            } else {
              productNos = item.productNo;
            }
          }
        });
        this.productIds = productIds;
        this.productNos = productNos;
      },
      deep: true
    }
  },
  computed: {}
};
