import Q from 'q';

// 订单确认
import web_common_tourCutOrder_orderList from '@/lib/data-service/default/web_common_tourCutOrder_orderList';
// 出团通知单
import web_common_salesOrder_getAuditdetails from '@/lib/data-service/default/web_common_salesOrder_getAuditdetails';

import Citys from '@/component/citys/index.vue';

export default {
  components: {
    Citys,
  },
  data() {
    return {
      form: {
        currentPage: 1,
        pageSize: 10,
        cutOrderNo: '',
        productNo: '',
        productName: '',
        buyerName: '',
        teamStart: '',
        teamEnd: '',
        orderDateStart: '',
        orderDateEnd: '',
        tripDay: '',
        productType: '',
        productAreasType: '',
        orderState: 2,
        fromCity: '',
        toCity: '',
      },
      allocation: {
        tripDay: [
          { label: '全部', value: '' },
          { label: '一天', value: 1 },
          { label: '两天', value: 2 },
          { label: '三天', value: 3 },
          { label: '四天', value: 4 },
          { label: '五天', value: 5 },
          { label: '六天', value: 6 },
          { label: '七天', value: 7 },
          { label: '八天及八天以上', value: 8 },
        ],
        productType: [
          { label: '全部', value: '' },
          { label: '跟团游', value: 1 },
          { label: '自由行', value: 2 },
          { label: '当地游', value: 3 }
        ],
        productAreasType: [
          { label: '全部', value: '' },
          { label: '国内', value: 1 },
          { label: '出境', value: 2 }
        ],
        state: [
          { label: '待确认', value: 2 },
          { label: '确认失败', value: 0 },
        ]
      },
      loading: false,
      pager: {
        pageSizes: [10, 20, 30, 40, 50],
        totalCount: 0
      },
      orderList: [],
      show: false,
      state: 2,
      dialogVisible: false,
      platformAuditContents: '',
      supplierAuditContents: '',
    };
  },
  methods: {
    req_order_list(params, type) {
      this.loading = true;
      const _this = this;
      Q.when()
        .then(function() {
          return web_common_tourCutOrder_orderList(params);
        })
        .then(function(res) {
          const orderList = res.pageResult.pageData;
          orderList.forEach(function(item) {
            item.teamTimes = JSON.parse(item.teamTimes);
          });
          _this.orderList = orderList;
          _this.pager.totalCount = res.pageResult.totalCount;
          if (type) document.documentElement.scrollTop = 0;
          _this.state = _this.form.orderState;
          _this.loading = false;
        });
    },
    req_fall (params) {
      return Q.when()
        .then(function () {
          return web_common_salesOrder_getAuditdetails(params);
        })
    },
    getFromCity (value) {
      this.form.fromCity = value;
    },
    getToCity (value) {
      this.form.toCity = value;
    },
    fold_change() {
      this.show = !this.show;
    },
    search() {
      this.req_order_list(this.form);
    },
    reset() {
      const pageSize = this.form.pageSize;
      this.form = {
        currentPage: 1,
        pageSize,
        cutOrderNo: '',
        productNo: '',
        productName: '',
        buyerName: '',
        teamStart: '',
        teamEnd: '',
        orderDateStart: '',
        orderDateEnd: '',
        tripDay: '',
        productType: '',
        productAreasType: '',
        orderState: 2,
        fromCity: '',
        toCity: '',
      };
      this.$refs.fromCity.reset();
      this.$refs.toCity.reset();
      this.req_order_list(this.form);
    },
    change_state () {
      this.form.currentPage = 1;
      this.req_order_list(this.form);
    },
    to_audit(cutOrderNo) {
      this.$router.push({
        name: 'admin-cut-order-detail',
        query: { cutOrderNo ,type: 'cut_order_audit' },
      });
    },
    look_fall (supplierAuditContents, platformAuditContents) {
      this.supplierAuditContents = supplierAuditContents;
      this.platformAuditContents = platformAuditContents;
      this.dialogVisible = true;
    },
    handleClose () {
      this.dialogVisible = false;
      this.platformAuditContents = '';
      this.supplierAuditContents = '';
    },
    handleSizeChange(pageSize) {
      this.form.pageSize = pageSize;
      this.req_order_list(this.form, 'pageSize');
    },
    handleCurrentChange(currentPage) {
      this.form.currentPage = currentPage;
      this.req_order_list(this.form, 'currentPage');
    }
  },
  created() {},
  mounted() {},
  activated() {
    this.req_order_list(this.form);
  },
  deactivated() {},
  destroyed() {},
  watch: {
    state: function (newVal) {
      this.form.orderState = newVal;
      this.req_order_list(this.form);
    }
  },
  computed: {}
};
