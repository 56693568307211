import Q from 'q';
import _ from 'underscore';
import get_url_prefix from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/get_url_prefix';
import logout from 'yinzhilv-js-sdk/frontend/common-project/lib/data-service/default/logout';
import join from 'nllib/src/uri/join';
import web_common_source_getPermissions from '@/lib/data-service/default/web_common_source_getPermissions';
import redirect_to_login_page from '@/lib/common-service/redirect_to_login_page';

export default {
    data() {
        return {
            user_name: `admin`,
            menu_entity_list: [
                {text: `修改密码`, code: `changePassword`, enabled: true,},
                {text: `注销`, code: `logout`, enabled: true,},
                // {text: `其他`, code: `other`, enabled: false,},
            ],
            data_web_common_source_getPermissions: {
                userName: ``,
            },
            dMenu: [
                {checked: false, orderIndex: null, parentId: 1, permission: "platform:buyer", resourceId: 12, resourceLevel: "2", resourceName: "分销商优惠管理", routeName: ""},
                {checked: false, orderIndex: null, parentId: 12, permission: "platform:open", resourceId: 5656, resourceLevel: "3", resourceName: "开通优惠", routeName: ""},
                {checked: false, orderIndex: null, parentId: 12, permission: "platform:open:list", resourceId: 423423, resourceLevel: "3", resourceName: "分销商优惠列表", routeName: ""}
            ]
        };
    },
    methods: {
        __btn_menu_entity_click(pEntity) {
            const __this = this;

            const code = pEntity.code;
            switch (code) {
                case `logout`:
                    Q.when()
                        .then(function () {
                            return logout();
                        })
                        .then(function () {
                            return redirect_to_login_page();
                        })
                        .catch(function (ex) {
                            console.error(ex);
                            if (ex) {
                                if (ex.stack) {
                                    console.error(ex.stack);
                                }
                            }
                            return Q.reject(ex);
                        })
                    ;
                    break;

                case `changePassword`:
                    this.$router.push({name: 'admin-change-password'});
                    break;

                case `other`:
                default:
                    console.log(pEntity);
                    break
            }
        },
    },
    watch: {},
    created() {
        const __this = this;
        web_common_source_getPermissions().then(function (data) {
            for(var i = 0; i < __this.dMenu.length; i++) {
                data.permissionList.push(__this.dMenu[i]);
            }
            __this.data_web_common_source_getPermissions = data;
            console.log(__this.data_web_common_source_getPermissions);

        })
    },
}
