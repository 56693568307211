const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request/__request_contentType_json`);

// 接口文档地址：
// http://tour-beta.yinzhilv.com/doc.html#/yinzhilv_travel/area-controller/getStoreAreaTreeUsingPOST
const web_common_area_getStoreAreaTree = (pParameter) => {
    if (!pParameter) pParameter = {};

    const p = {
        method: 'post',
        urlSuffix: '/web/common/area/getStoreAreaTree',
        data: {},
    };
    return Q.when()
        .then(function () {
            return __request(p);
        })
        .then(function (data) {
            return Q.resolve(data);
        })
        .catch(function (ex) {
            console.error(ex);
            if (ex) {
                if (ex.stack) {
                    console.error(ex.stack);
                }
            }
            return Q.reject(ex);
        })
        ;
};
module.exports = web_common_area_getStoreAreaTree;
