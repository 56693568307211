// web_buyer_findAll 采购商分页查询

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口文档地址： http://192.168.0.15:8080/doc.html#/所有接口/buyer-controller/platformSupplierUsingPOST
export default function web_buyer_findAll(pParameter) {
  let params = {}
  if (!pParameter) pParameter = {};
  /*const userName = pParameter.userName || ''
  const companyName = pParameter.companyName || ''
  const regionId = pParameter.regionId || ''
  const provinceId = pParameter.provinceId || ''
  const areaCityId = pParameter.areaCityId || ''
  const creditId = pParameter.creditId || ''
  const buyerType = pParameter.buyerType || ''
  const buyerStatus = pParameter.buyerStatus || ''
  const buyerLevel = pParameter.buyerLevel || ''
  const phone = pParameter.phone || ''
  const accountManagerId = pParameter.accountManagerId || ''
  const developerId = pParameter.developerId || ''
  const officeId = pParameter.officeId || ''
  const startTime = pParameter.startTime || ''
  const endTime = pParameter.endTime || ''
  const userStatus = pParameter.userStatus || ''
  const isCreditList = pParameter.isCreditList || ''
  const realStatus = pParameter.realStatus || ''
  const creditStatus = pParameter.creditStatus || ''
  const currentPage = pParameter.currentPage || ''
  const pageSize = pParameter.pageSize || ''*/
  params.method = 'POST'
  params.urlSuffix = '/web/buyer/findAll'
  params.data = pParameter
  /*params.data = {
    userName,
    companyName,
    regionId,
    provinceId,
    areaCityId,
    creditId,
    buyerType,
    buyerStatus,
    buyerLevel,
    phone,
    accountManagerId,
    developerId,
    officeId,
    startTime,
    endTime,
    userStatus,
    isCreditList,
    creditStatus,
    realStatus,
    currentPage,
    pageSize
  }*/
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}

