// 取消酒店线下订单  http://hoteltest.yinzhilv.com/doc.html#/%E9%85%92%E5%BA%97%E6%A8%A1%E5%9D%97/order-offline-controller/cancelOneUsingPOST

const __request = require(`./__request/__request_contentType_json`);

export default function (pParameter) {
    let params = {}
    params.method = 'POST'
    params.urlSuffix = '/hotel/offlineOrder/cancelOne';

    params.data = pParameter;

    return new Promise(function (resolve, reject) {
        resolve(__request(params))
    })
}
