import web_common_source_GetResourceByResourceType from '@/lib/data-service/default/web_common_source_GetResourceByResourceType' // 获取权限列表
import web_common_department_list from '@/lib/data-service/default/web_common_department_list' // 获取部门列表
// import web_common_user_checkUserName from '@/lib/data-service/default/web_common_user_checkUserName' // 检查名字是否可以使用
import web_common_user_getUserInfo from '@/lib/data-service/default/web_common_user_getUserInfo'
import web_staffManage_update from '@/lib/data-service/default/web_staffManage_update'
import web_data_selectType from '@/lib/data-service/default/web_data_selectType' // 获取维度
import web_data_dataOperation from '@/lib/data-service/default/web_data_dataOperation' // 新增或修改维度
import web_common_source_getPlatformAuthority from '@/lib/data-service/default/web_common_source_getPlatformAuthority'
import web_staffManage_platformUpdateStaff from '@/lib/data-service/default/web_staffManage_platformUpdateStaff'
import limits from './../../component/limits'
import { awaitWrap } from "../../../supplier-management/utils/common";

export default {
  data () {
    /*const validateUserName = (rule, value, callback) => {
      web_common_user_checkUserName({userName: value}).then(res => {
        if (res) {
          callback()
        } else {
          callback(new Error('该用户名已经存在'))
        }
      })
    };*/
    const validateRePassword = (rule, value, callback) => {
      if (value === this.params.password) {
        callback()
      } else {
        callback(new Error('两次输入的密码不一致'))
      }
    };
    const validatePhone = (rule, value, callback) => {
      const regExp = /^(?:13\d|14[5-9]|15\d|166|17\d|18\d|19[0|8|9]|106)-?\d{3,8}$/
      if (regExp.test(value)) {
        callback()
      } else {
        callback(new Error('不符合手机号码格式'))
      }
    };
    const validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback()
        return
      }
      const regExp = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
      if (regExp.test(value)) {
        callback()
      } else {
        callback(new Error('邮件格式不正确'))
      }
    };
    const validateQQ = (rule, value, callback) => {
      if (value === '') {
        callback()
        return
      }
      const regExp = /^[1-9][0-9]{4,9}$/gim
      if (regExp.test(value)) {
        callback()
      } else {
        callback(new Error('QQ格式不正确'))
      }
    };
    return {
      loading: false,
      id: '',
      params: {
        id: '',
        resourceIds: '',
        userName: '', // 账号
        password: '', // 密码
        realName: '', // 真实名字
        phone: '', // 手机号
        qq: '',
        eMail: '',
        deptId: '',
        companyId: '',
        roleId: '',
        userStatus: '',
        rePassword: '' // 重复密码
      },
      rule: {
        userName: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { min: 4, max: 16, message: '长度在 4 到 16 个字符', trigger: 'blur' },
        ],
        password: [
          { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
        ],
        rePassword: [
          { validator: validateRePassword, trigger: 'blur' }
        ],
        realName: [
          { required: true, message: '请输入真实姓名', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: validatePhone, trigger: 'blur' }
        ],
        qq: [
          // { validator: validateQQ, trigger: 'blur' }
        ],
        eMail: [
          { validator: validateEmail, trigger: 'blur' }
        ]
      },
      departmentList: [], // 部门列表
      resourceList: [],
      resourceIds: [],
      limit: {
        '旅游产品管理': 'platform:tourProduct', // 13
        '旅游订单管理': 'platform:order', // 8
        '笛风云订单管理': 'platform:dfyOrder', // 201
        '酒店订单管理': 'platform:hotelOrder', // 206
        '酒店产品管理': 'platform:hotel', // 210
        '火车票订单管理': 'platform:trainsOrder', // 280
        '火车票产品设置': 'platform:productSet', // 281
        '国内机票订单管理': 'platform:flightOrder', // 220
        '授信管理': 'platform:creditManager', // 282
        '退款管理': 'platform:refundManager', // 283
        '结算管理': 'platform:settle', // 173
        '供应商管理': 'platform:supplier', // 10
        '分销商管理': 'platform:buyer', // 12
        '商务代理管理': 'platform:personManager', // 临时渠道管理
        '客户管理': 'platform:clientManager', // 310
        '客户订单管理': 'platform:clientOrder', // 311
        '系统管理': 'platform:system', // 14
      },
      selectParams: {
        id: '',
        dataType: '1',
        resourceId: ''
      },

      treeLoading: false,
      expandedKey: [],
      checkResourceIds: [],
      defaultProps: {
        children: 'children',
        label: 'resourceName'
      }
    }
  },
  components: {},
  created () {

  },
  mounted () {},
  async activated () {
    this.id = this.$route.query.id
    // this.reactSelectParams()
    this.selectParams.resourceId = this.id
    await this.getUserInfo()
    await this.getResource()
    this.getDepartmentList()
    this.getDataSelectType()
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
    handleCheckChange(data, checked, indeterminate) {
      console.log(data, checked, indeterminate);
    },
    reactSelectParams() {
      this.selectParams = {
        id: '',
        dataType: '1',
        resourceId: ''
      }
    },
    getDataSelectType() {
      web_data_selectType({resourceId: this.id}).then(res => {
        let selectParams = {
          id: res.id,
          dataType: res.type,
          resourceId: this.id
        }
        this.selectParams = selectParams
      })
    },
    async getUserInfo() {
      let res = await web_common_user_getUserInfo({id: this.id})
      let params = {
        id: this.id,
        resourceIds: '',
        userName: res.userName, // 账号
        password: '', // 密码
        realName: res.realName, // 真实名字
        phone: res.phone, // 手机号
        qq: res.qq,
        eMail: res.eMail,
        deptId: res.deptId,
        companyId: res.companyId,
        roleId: res.roleId,
        userStatus: '',
        rePassword: '' // 重复密码
      }
      this.params = params
      this.checkResourceIds = res.childResourceIds
    },
    getDepartmentList() {
      web_common_department_list().then(res => {
        let departmentList = res.treeList
        departmentList.forEach(value => {
          value.value = value.id
          value.label = value.deptName
          if (value.childDeptList.length > 0) {
            value.children = value.childDeptList
            value.children = this.setDepartment(value.children)
            /*value.children.forEach(val => {
              val.value = val.id
              val.label = val.deptName
              if (val.childDeptList.length > 0) {
                val.children = val.childDeptList
                val.children = this.setDepartment(val.children)
              }
            })*/
          }
        })
        this.departmentList = departmentList
      })
    },
    setDepartment(val) {
      if (val.length > 0) {
        val.forEach(value => {
          value.value = value.id
          value.label = value.deptName
          if (value.childDeptList.length > 0) {
            value.children = value.childDeptList
            value.children = this.setDepartment(value.children)
          }
        })
      }
      return val
    },
    async getResource() {
      this.treeLoading = true
      let [err, res] = await awaitWrap(web_common_source_getPlatformAuthority({isAll: true}))
      this.treeLoading = false
      if (err) {
        return
      }
      this.treeLoading = false
      let result = res.resourceResultList
      let expandedKey = []
      result.forEach(value => {
        if (value.resourceLevel === '1' || value.resourceLevel === '2' || value.resourceLevel === '3') {
          expandedKey.push(value.resourceId)
        }
      })
      this.expandedKey = expandedKey
      let resourceList = this.translateDataToTree(result)
      console.log(resourceList)
      this.resourceList = resourceList
    },
    getAuthoritySort(authorityList) {
      let arr = []
      Object.values(limits).map((key, index) => {
        authorityList.map(item => {
          if (item.permission === key) {
            arr.push(item)
          }
        })
      })
      return arr
    },
    /**
     * 该方法用于将有父子关系的数组转换成树形结构的数组
     * 接收一个具有父子关系的数组作为参数
     * 返回一个树形结构的数组
     */
    translateDataToTree (data) {
      // 没有父节点的数据
      const parents = data.filter(value => value.parentId === 0 || value.parentId === undefined || value.parentId === null)
      console.log(parents)
      // 有父节点的数据
      const children = data.filter(value => value.parentId !== 0)
      // 定义转换方法的具体实现
      const translator = (parents, children) => {
        // 遍历父节点数据
        parents.forEach((parent) => {
            // 遍历子节点数据
            children.forEach((current, index) => {
                // 此时找到父节点对应的一个子节点
                if (current.parentId === parent.resourceId) {
                  // 对子节点数据进行深复制，这里只支持部分类型的数据深复制，对深复制不了解的童靴可以先去了解下深复制
                  const temp = JSON.parse(JSON.stringify(children))
                  // 让当前子节点从temp中移除，temp作为新的子节点数据，这里是为了让递归时，子节点的遍历次数更少，如果父子关系的层级越多，越有利
                  temp.splice(index, 1)
                  // 让当前子节点作为唯一的父节点，去递归查找其对应的子节点
                  translator([current], temp)
                  // 把找到子节点放入父节点的children属性中
                  typeof parent.children !== 'undefined' ? parent.children.push(current) : parent.children = [current]
                }
              }
            )
          }
        )
      }
      // 调用转换方法
      translator(parents, children)
      // 返回最终的结果
      return parents
    },
    handleCheckAllChange(index, id, val) {
      let resourceList = this.resourceList
      if (val) {
        let checkArr = []
        resourceList[index].children.forEach(value => {
          checkArr.push(value.id)
        })
        resourceList[index].checkedArr = checkArr
      } else {
        resourceList[index].checkedArr = []
      }
      resourceList[index].isIndeterminate = false
      this.resourceList = resourceList
    },
    handleCheckedCitiesChange(index, id, val) {
      let checkedCount = val.length
      this.resourceList[index].checkAll = checkedCount === this.resourceList[index].children.length
      this.resourceList[index].isIndeterminate = checkedCount > 0 && checkedCount < this.resourceList[index].children.length
    },
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true
          let checkedKey = []
          let checkedKeyNodes = this.$refs.tree.getCheckedNodes(true, false)
          checkedKeyNodes.forEach(value => {
            checkedKey.push(value.resourceId)
          })
          this.params.resourceIds = checkedKey  // .join(',')
          web_staffManage_platformUpdateStaff(this.params).then(res => {
            web_data_dataOperation(this.selectParams).then(result => {
              this.loading = false
              this.$message({
                message: '修改成功',
                type: 'success'
              });
              this.back()
            }).catch(() => {
              this.loading = false
            })
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },
    back() {
      this.$router.go(-1)
    }
  }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
