const __request = require(`./__request/__request_contentType_json`);

// 微商城详情
// http://tour-alpha.yinzhilv.com/doc.html#/yinzhilv_travel/btoc-controller/btocCheckUsingPOST_1
const request = (pParameter) => {
    if (!pParameter) pParameter = {};

    const id = pParameter.id
    const buyerId = pParameter.buyerId

    const p = {
        method: 'post',
        urlSuffix: '/web/btoc/check',
        data: {
            id,
            buyerId,
        }
    };
    return new Promise(function (resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = request;