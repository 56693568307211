import import_latest_version_module from '@/component/admin-layout/import_latest_version_module';

export default {
  path: '',
  component: () => import_latest_version_module(),
  children: [
    {
      path: '/admin/buyer/mall-examine',
      name: 'admin-buyer-mall-examine',
      component: () => import(/* webpackChunkName: "page-admin-buyer-management-mall-examine" */ `./1.0.1/index.vue`),
      meta: {
        entity_for_history_tag: {
          name: `申请审批`,
        },
        entity_list_for_breadcrumb: [
          {name: `分销商管理`},
          {name: `分销商列表`},
          {name: `申请审批`},
        ],
        data_for_left_menu: {
          get_active_route_name(pParameter) {
              return `admin-buyer-list`;
          },
      },
      },
    },
  ],
};
