// web_managerSupplier_updateStatus 设置供应商状态

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口地址：http://192.168.0.15:8080/doc.html#/所有接口/manager-supplier-controller/updateSupplierStatusUsingPOST

export default function web_managerSupplier_updateStatus(pParameter) {
  if (!pParameter) pParameter = {};
  const supplierId = pParameter.supplierId || ''
  const supplierStatus = pParameter.supplierStatus || ''
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/managerSupplier/updateStatus'
  params.data = {
    supplierId,
    supplierStatus
  }
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}
