import getBuyerTotalDataPage from '@/lib/data-service/statistics/get_buyer_total_data_page'
import getTotalAmount from '@/lib/data-service/statistics/get_total_amount'
import Pagination from '@/component/base-pagination/index.vue'
import moment from 'moment'

export default {
    components: {},
    data() {
        return {
            loading: false,
            params: {
                currPage: 1,
                pageSize: 10,
                totalCount: 0,
                userName: '',
                companyName: '',
                buyerType: '',
                buyerStatus: '',
                orderBy: '',
                orderType: ''
            },
            buyerTypeMap: { '': '全部', '1': '机票', '2': '旅游', '1+2': '机票+旅游' },
            buyerStatusMap: { '': '全部', 0: '', 2: '部分启用', 3: '启用', 4: '平台禁用' },
            buyerList: [],
            totalAmount: 0,
            dailyDate: '',
            moment: moment
        }
    },
    filters: {
        emptyFilter(str) {
            if (str == null || str == "") {
                return "--";
            }
            return str;
        },
        toThousandFilter(num) {
            return (+num || 0).toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')
        }
    },
    components: {
        Pagination
    },
    methods: {
        getBuyerList() {
            this.loading = true
            getBuyerTotalDataPage(this.params).then((res) => {
                let buyerList = res.page.list
                this.buyerList = buyerList
                this.params.totalCount = res.page.totalCount
                this.loading = false
            }).catch(() => {
                this.loading = false
            }, new Error(() => {
                this.loading = false
            }))
        },
        getBuyerTotalAmount() {
            getTotalAmount().then((res) => {
                this.totalAmount = res.totalAmount
                this.dailyDate = res.gmtLastUpdate
            }).catch(() => {

            }, new Error(() => {

            }))
        },
        changeSort({ column, prop, order }) {
            if (order === null) {
                this.params.orderBy = ''
                this.params.orderType = ''
            } else {
                this.params.orderBy = prop
                this.params.orderType = order
            }
            this.getBuyerList();
        },
        doSearch(){
            this.params.currPage = 1
            this.params.pageSize = 10
            this.getBuyerList();
        },
        getBuyerTotalData(val) {
            this.$router.push({
                name: 'admin-buyer-total-data',
                query: {
                    id: val.companyId
                }
            })
        }
    },
    beforeCreate: function () {
        const __this = this;
    },
    created() {

        // const __this = this;
    },
    activated() {
        // const __this = this;
        this.getBuyerList();
        this.getBuyerTotalAmount();
    },
    deactivated() {
        // const __this = this;
    },
}
