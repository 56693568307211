

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 平台订单详情地址： http://192.168.0.13:8080/doc.html#/%E6%89%80%E6%9C%89%E6%8E%A5%E5%8F%A3/platform-order-controller/tourOrderDetailsUsingPOST_1
export default function web_salesOrder_tourOrderDetails(pParameter) {
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/salesOrder/tourOrderDetails';
  params.data = pParameter;

  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
