// 添加接口
import hotel_offlineOrder_add from "@/lib/data-service/hotel/hotel_offlineOrder_add.js";

export default {
  components: {},
  data() {
    return {
      form: {
        generateDepartment: "", //出单部门
        generatePersonName: "", //出单人员
        hotelName: "", //酒店名称
        goodName: "", //产品名称
        checkIn: "", //入住时间
        checkOut: "", //离店时间
        roomNum: "", //房间数量
        linkmanName: "", //联系人姓名
        linkmanPhone: "", //联系人电话
        purchaseSupplierName: "", //供应商名称
        purchaseSupplierOrderNo: "", //供应商订单号
        purchasePayPrice: "", //付款金额
        purchasePayType: "", // 付款方式
        purchasePayTime: "", // 付款时间
        purchaseInvoiceType: "", //开票方式
        purchasePaySerialNumber: "", // 付款流水号
        purchaseTotalPrice: "", //订单总金额
        purchaseBrokerage: "", //佣金
        purchaseClosePrice: "", //订单结算价
        sellCustomerName: "", //客户名称
        sellOrderTime: "", //下单时间
        sellReceivablePrice: "", //应收款
        sellIssueDate: "", // 出单时间
        sellServicePrice: "", //服务费
        sellRaisePrice: "", //加价金额
        sellPaymentStatus: "", //收款状态
        sellPaymentDate: "", // 收款时间
        sellPaymentType: "", //收款方式
        sellPaymentPrice: "", //收款金额
        sellPaymentSerialNum: "", //收款金额
      },
      rules: {
        orderNo: [{ required: true, message: "", trigger: "blur" }],
        generateDepartment: [
          { required: true, message: "请输入出单部门", trigger: "blur" },
        ],
        generatePersonName: [
          { required: true, message: "请输入出单人员", trigger: "blur" },
        ],
        hotelName: [
          { required: true, message: "请输入酒店名称", trigger: "blur" },
        ],
        goodName: [
          { required: true, message: "请输入产品名称", trigger: "blur" },
        ],
        checkIn: [{ required: true, message: "请输入离日期", trigger: "blur" }],
        checkOut: [
          { required: true, message: "请输入离店日期", trigger: "blur" },
        ],
        roomNum: [{ required: true, message: "请输入房间数", trigger: "blur" }],
        linkmanName: [
          { required: true, message: "请输入离店日期", trigger: "blur" },
        ],
        linkmanPhone: [
          { required: true, message: "请输入房间数", trigger: "blur" },
        ],
        purchaseSupplierName: [
          { required: true, message: "请输入供应商名称", trigger: "blur" },
        ],
        purchaseSupplierOrderNo: [
          { required: true, message: "请输入供应商订单号", trigger: "blur" },
        ],
        purchasePayPrice: [
          { required: true, message: "请输入付款金额", trigger: "blur" },
        ],
        purchasePayType: [
          { required: true, message: "请选择付款方式", trigger: "blur" },
        ],
        purchasePayTime: [
          { required: true, message: "请选择付款时间", trigger: "blur" },
        ],
        purchaseInvoiceType: [
          { required: true, message: "请选择开发票方", trigger: "blur" },
        ],
        purchasePaySerialNumber: [
          { required: true, message: "请输入付款流水号", trigger: "blur" },
        ],
        purchaseTotalPrice: [
          { required: true, message: "请输入订单总金额", trigger: "blur" },
        ],
        purchaseBrokerage: [
          { required: true, message: "请输入佣金", trigger: "blur" },
        ],
        purchaseClosePrice: [
          { required: true, message: "请输入订单结算价", trigger: "blur" },
        ],
        sellCustomerName: [
          { required: true, message: "请输入客户名称", trigger: "blur" },
        ],
        sellOrderTime: [
          { required: true, message: "请选择下单时间", trigger: "blur" },
        ],
        sellReceivablePrice: [
          { required: true, message: "请输入销售应收金额", trigger: "blur" },
        ],
        sellIssueDate: [
          { required: true, message: "请选择出单时间", trigger: "blur" },
        ],
        sellServicePrice: [
          { required: true, message: "请输入销售服务费", trigger: "blur" },
        ],
        sellRaisePrice: [
          { required: true, message: "请输入加价/让利", trigger: "blur" },
        ],
        sellPaymentStatus: [
          { required: true, message: "请选择收款状态", trigger: "blur" },
        ],
        sellPaymentDate: [
          { required: true, message: "请选择收款时间", trigger: "blur" },
        ],
        sellPaymentType: [
          { required: true, message: "请选择收款方式", trigger: "blur" },
        ],
        sellPaymentPrice: [
          { required: true, message: "请输入收款金额", trigger: "blur" },
        ],
        sellPaymentSerialNum: [
          { required: true, message: "请输入收款流水号", trigger: "blur" },
        ],
      },

      allocation: {
        purchasePayType: [
          { label: "未知", value: "0" },
          { label: "预付款", value: "1" },
          { label: "现结", value: "2" },
          { label: "周结", value: "3" },
          { label: "月结", value: "4" },
        ],
        purchaseInvoiceType: [
          { label: "未知", value: "0" },
          { label: "胤之旅开具", value: "1" },
          { label: "供应商开具", value: "2" },
          { label: "该酒店开具", value: "3" },
        ],
        sellPaymentStatus: [
          { label: "未收", value: "0" },
          { label: "已收", value: "1" },
        ],
        sellPaymentType: [
          { label: "龙存管", value: "1" },
          { label: "二维码", value: "2" },
        ],
      },
      // 入离日期
      checkDate: null,
      // 是否锁定
      isLock: false,
    };
  },
  methods: {
    change_checkDate(val) {
      const form = this.form;
      if (val) {
        form.checkIn = val[0];
        form.checkOut = val[1];
      } else {
        form.checkIn = "";
        form.checkOut = "";
      }
    },

    commit() {
      const form = this.form;
      this.$refs.form.validate((valid) => {
        if (valid) {
          hotel_offlineOrder_add(this.form)
            .then((result) => {
              this.$message.success("添加成功");
              if (this.isLock) {
                for (const key in form) {
                  if (key !== "generatePersonName") form[key] = "";
                }
              } else {
                for (const key in form) {
                  form[key] = "";
                }
              }
              this.checkDate = null;
            })
            .catch((err) => {});
        } else {
          return;
        }
      });
    },
    // back() {},
  },
  mounted() {},
  activated() {},
  deactivated() {},
  watch: {},
};
