

const __request = require(`@/lib/data-service/default/__request/__request_contentType_json`);

// 个人用户详情接口地址： http://192.168.0.11:8080/doc.html#/%E6%89%80%E6%9C%89%E6%8E%A5%E5%8F%A3/personage-controller/personageDetailByBuyerIdUsingPOST
export default function web_personage_personageDetailByBuyerId(pParameter) {
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/personage/personageDetailByBuyerId';
  params.data = pParameter;
  
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
