import web_common_area_getStairAreaDataExclusiveHongKongAndMacaoAndTaiwan from '@/lib/data-service/default/web_common_area_getStairAreaDataExclusiveHongKongAndMacaoAndTaiwan' // 国内
import web_common_area_getStairAreaDataOnlyHaveHongKongAndMacaoAndTaiwan from '@/lib/data-service/default/web_common_area_getStairAreaDataOnlyHaveHongKongAndMacaoAndTaiwan' // 港澳台
import web_common_area_getInternationalAreaChildListByParentId from '@/lib/data-service/default/web_common_area_getInternationalAreaChildListByParentId' // 国际
import web_common_area_getInlandAreaChildListByParentId from '@/lib/data-service/default/web_common_area_getInlandAreaChildListByParentId'
import web_circuit_findAll from '@/lib/data-service/default/web_circuit_findAll'
import web_circuit_addOrUpdate from '@/lib/data-service/default/web_circuit_addOrUpdate'
import Pagination from '@/component/base-pagination/index.vue'
export default {
  data () {
    const checkLineAreaArr = (rule, value, callback) => {
      let pass = true
      for (let i = 0, l = this.lineParams.lineAreaArr.length; i < l; i++) {
        for (let x = 0, y = this.lineParams.lineAreaArr[i].areaThree.length; x <y; x++) {
          if (this.lineParams.lineAreaArr[i].areaThree[x].id === '') {
            pass = false
            break
          }
        }
        if (!pass) {
          break
        }
      }
      if (pass) {
        callback()
      } else {
        callback(new Error('请选择所属城市'))
      }
    };
    return {
      loading: false,
      params: {
        currentPage: 1,
        pageSize: 10,
        id: '',
        lineName: '',
        lineType: '',
        areaOne: '',
        areaTwo: '',
        areaThree: '',
        total: 0
      },
      lineTypeList: [{value: '', label: '全部'}, {value: 1, label: '国内'}, {value: 3, label: '港澳台'}, {value: 2, label: '出境'}],
      provinceList: [],
      cityList: [],
      countyList: [],
      lineList: [],
      lineParams: {
        id: '',
        lineName: '',
        lineType: 1,
        lineAreaArr: [],
        areaResult: '',
        areaInfoArr: []
      },
      rule: {
        lineName: [
          { required: true, message: '请输入线路类型名称', trigger: 'blur' },
        ],
      },
      lineRule: {
        lineType: [
          { required: true, message: '请输入所属类型', trigger: 'change' },
        ],
        lineAreaArr: [
          { required: true, message: '请输入所属城市', trigger: 'change' },
        ],
      },
      formList: ['form', 'lineForm'],
      dialogVisible: false,
      provinceAddList: [],
      cityAddList: [],
      countyAddList: [],
      dialogStatus: '',
      textMap: {
        create: '线路新增',
        edit: '线路编辑'
      },

      areaList: [
        {label: 1, text: '国内'},
        {label: 3, text: '港澳台'},
        {label: 2, text: '出境'},
      ]
    }
  },
  components: {
    Pagination
  },
  created () {},
  mounted () {
    this.getCircuitList()
  },
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
    changeLineType(val) {
      // 先清空后面的选中项
      this.clearZone(1)
      if (val === '') { // 全部

      } else if (val === 1) { // 国内
        web_common_area_getStairAreaDataExclusiveHongKongAndMacaoAndTaiwan().then(res => {
          this.provinceList = res
        })
      } else if (val === 2) { // 国际
        web_common_area_getInternationalAreaChildListByParentId({id: 0}).then(res => {
          this.provinceList = res
        })
      } else { // 港澳台
        web_common_area_getStairAreaDataOnlyHaveHongKongAndMacaoAndTaiwan().then(res => {
          this.provinceList = res
        })
      }
    },
    changeProvince(val) {
      this.clearZone(2)
      if (this.params.lineType === 1 || this.params.lineType === 3) {
        web_common_area_getInlandAreaChildListByParentId({id: val}).then(res => {
          this.cityList = res
        })
      } else {
        web_common_area_getInternationalAreaChildListByParentId({id: val}).then(res => {
          this.cityList = res
        })
      }
    },
    changeCity(val) {
      this.clearZone(3)
      if (this.params.lineType === 1 || this.params.lineType === 3) {
        web_common_area_getInlandAreaChildListByParentId({id: val}).then(res => {
          this.countyList = res
        })
      } else {
        web_common_area_getInternationalAreaChildListByParentId({id: val}).then(res => {
          this.countyList = res
        })
      }
    },
    changeCounty() {},
    clearZone(val) {
      if (val < 2) {
        this.params.areaOne = ''
        this.provinceList = []
      }
      if (val < 3) {
        this.params.areaTwo = ''
        this.cityList = []
      }
      if (val < 4) {
        this.params.areaThree = ''
        this.countyList = []
      }
    },
    onSearch() {
      this.params.currentPage = 1
      this.getCircuitList()
    },
    onReset() {
      this.params = {
        currentPage: 1,
        pageSize: 10,
        id: '',
        lineName: '',
        lineType: '',
        areaOne: '',
        areaTwo: '',
        areaThree: '',
        total: 0
      }
      this.onSearch()
    },
    getCircuitList() {
      web_circuit_findAll(this.params).then(res => {
        let lineList = res.pageResult.pageData
        lineList.forEach(value => {
          let areaInfoArr = JSON.parse(value.areaInfo)
          areaInfoArr.forEach(val => {
            val.lineType = value.lineType
          })
          value.areaInfoArr = areaInfoArr
        })
        this.lineList = lineList
        this.params.total = res.pageResult.totalCount
      })
    },
    lineTypeFormatter(val) {
      let typeName = ''
      if (val.lineType === 1) {
        typeName = '国内'
      } else if (val.lineType === 2) {
        typeName = '境外'
      } else {
        typeName = '港澳台'
      }
      return typeName
    },
    handleEdit(row, index) {
      this.dialogStatus = 'edit'
      this.dialogVisible = true
      this.reactForm()
      let lineParams = row
      let areaInfoArr = JSON.parse(row.areaInfo)
      let endAreaInfoArr = []
      for (let i = 0, l = areaInfoArr.length; i < l; i++) {
        let isHave = false
        for (let m = 0, n = endAreaInfoArr.length; m < n; m++) {
          if (endAreaInfoArr[m].areaOne === parseInt(areaInfoArr[i].areaOne) && endAreaInfoArr[m].areaTwo === parseInt(areaInfoArr[i].areaTwo)) {
            endAreaInfoArr[m].areaThree.push({
              id: parseInt(areaInfoArr[i].areaThree),
              name: areaInfoArr[i].areaThreeName
            })
            isHave = true
            break
          }
        }
        if (!isHave) {
          let params = {
            areaOne: parseInt(areaInfoArr[i].areaOne),
            areaOneName: areaInfoArr[i].areaOneName,
            areaTwo: parseInt(areaInfoArr[i].areaTwo),
            areaTwoName: areaInfoArr[i].areaTwoName,
            areaThree: [
              {
                id: parseInt(areaInfoArr[i].areaThree),
                name: areaInfoArr[i].areaThreeName
              }
            ],
            provinceAddList: [], // 地区省份列表
            cityAddList: [], // 省份城市列表
            countyAddList: [] // 城市列表
          }
          endAreaInfoArr.push(params)
        }
      }
      this.lineParams = Object.assign({}, this.lineParams, lineParams)
      this.lineParams.lineAreaArr = endAreaInfoArr
      console.log(this.lineParams)
      if (this.lineParams.lineType === 1) {
        // 获取大地区
        web_common_area_getStairAreaDataExclusiveHongKongAndMacaoAndTaiwan().then(res => {
          this.lineParams.lineAreaArr.forEach(value => {
            value.provinceAddList = res
          })
        })
      } else if (this.lineParams.lineType === 3) {
        // 获取大地区
        web_common_area_getStairAreaDataOnlyHaveHongKongAndMacaoAndTaiwan().then(res => {
          this.lineParams.lineAreaArr.forEach(value => {
            value.provinceAddList = res
          })
        })
      } else {
        // 获取大地区
        web_common_area_getInternationalAreaChildListByParentId({id: 0}).then(res => {
          this.lineParams.lineAreaArr.forEach(value => {
            value.provinceAddList = res
          })
        })
      }
      for (let i = 0, l = this.lineParams.lineAreaArr.length; i < l; i++) {
        if (this.lineParams.lineType === 1 || this.lineParams.lineType === 3) {
          this.getChinaEditAreaList(i, this.lineParams.lineAreaArr[i])
        } else {
          this.getAbroadEditAreaList(i, this.lineParams.lineAreaArr[i])
        }
      }
    },
    getChinaEditAreaList(index, val) {
      // 获取城市列表
      web_common_area_getInlandAreaChildListByParentId({id: val.areaOne}).then(res => {
        this.lineParams.lineAreaArr[index].cityAddList = res
      })
      // 获取镇区列表
      web_common_area_getInlandAreaChildListByParentId({id: val.areaTwo}).then(res => {
        this.lineParams.lineAreaArr[index].countyAddList = res
      })
    },
    getAbroadEditAreaList(index, val) {
      // 获取城市列表
      web_common_area_getInternationalAreaChildListByParentId({id: val.areaOne}).then(res => {
        this.lineParams.lineAreaArr[index].cityAddList = res
      })
      // 获取镇区列表
      web_common_area_getInternationalAreaChildListByParentId({id: val.areaTwo}).then(res => {
        this.lineParams.lineAreaArr[index].countyAddList = res
      })
    },
    handleCreate() {
      this.dialogStatus = 'create'
      this.dialogVisible = true
      this.reactForm()
      this.changeLineAddType(1)
    },
    reactForm() {
      this.lineParams = {
        id: '',
        lineName: '',
        lineType: 1,
        lineAreaArr: [],
        areaResult: '',
        areaInfoArr: []
      }
      this.lineParams.lineAreaArr.push({
        areaOne: '',
        areaOneName: '',
        areaTwo: '',
        areaTwoName: '',
        areaThree: [{id: '', name: ''}],
        provinceAddList: [], // 地区省份列表
        cityAddList: [], // 省份城市列表
        countyAddList: [] // 城市列表
      })
    },
    changeLineAddType(val) {
      // 先清空后面的选中项
      // this.clearFormZone(1)
      this.lineParams.lineAreaArr = []
      this.lineParams.lineAreaArr.push({
        areaOne: '',
        areaOneName: '',
        areaTwo: '',
        areaTwoName: '',
        areaThree: [{id: '', name: ''}],
        provinceAddList: [],
        cityAddList: [],
        countyAddList: []
      })
      if (val === 1) { // 国内
        web_common_area_getStairAreaDataExclusiveHongKongAndMacaoAndTaiwan().then(res => {
          this.lineParams.lineAreaArr[0].provinceAddList = res
        })
      } else if (val === 2) { // 国际
        web_common_area_getInternationalAreaChildListByParentId({id: 0}).then(res => {
          this.lineParams.lineAreaArr[0].provinceAddList = res
        })
      } else { // 港澳台
        web_common_area_getStairAreaDataOnlyHaveHongKongAndMacaoAndTaiwan().then(res => {
          this.lineParams.lineAreaArr[0].provinceAddList = res
        })
      }
    },
    addAdr() {
      if (this.dialogStatus === 'edit') {
        return
      }
      this.lineParams.lineAreaArr.push({
        areaOne: '',
        areaTwo: '',
        areaThree: [{id: '', name: ''}],
        provinceAddList: this.lineParams.lineAreaArr[0].provinceAddList,
        cityAddList: [],
        countyAddList: []
      })
    },
    removeAdr(index) {
      if (this.lineParams.lineAreaArr.length === 1 || this.dialogStatus === 'edit') {
        return
      }
      let cityId = this.lineParams.lineAreaArr[index].areaTwo
      for (let i = 0, l = this.lineParams.lineAreaArr.length; i < l; i++) {
        let cityList = this.lineParams.lineAreaArr[i].cityAddList
        for (let m = 0, n = cityList.length; m < n; m++) {
          if (cityList[m].id === cityId) {
            this.lineParams.lineAreaArr[i].cityAddList[m].disabled = false
            break
          }
        }
      }
      this.lineParams.lineAreaArr.splice(index,1)
    },
    addTown(index) {
      if (this.dialogStatus === 'edit') {
        return
      }
      this.lineParams.lineAreaArr[index].areaThree.push({
        id: '', name: ''
      })
    },
    removeTown(index, tIndex) {
      if (this.lineParams.lineAreaArr[index].areaThree.length === 1 || this.dialogStatus === 'edit') {
        return
      }
      let params = this.lineParams.lineAreaArr[index].areaThree[tIndex]
      for (let i = 0, l = this.lineParams.lineAreaArr[index].countyAddList.length; i < l; i++) {
        if (this.lineParams.lineAreaArr[index].countyAddList[i].id === params.id) {
          this.lineParams.lineAreaArr[index].countyAddList[i].disabled = false
          break
        }
      }
      this.lineParams.lineAreaArr[index].areaThree.splice(tIndex, 1)
    },
    changeAddProvince(index, val) {
      this.clearFormZone(index, 2)
      if (val === '') {
        return
      }
      for (let i = 0, l = this.lineParams.lineAreaArr[index].provinceAddList.length; i < l; i++) {
        if (this.lineParams.lineAreaArr[index].provinceAddList[i].id === val) {
          this.lineParams.lineAreaArr[index].areaOneName = this.lineParams.lineAreaArr[index].provinceAddList[i].areaName
          break
        }
      }
      if (this.lineParams.lineType === 1 || this.lineParams.lineType === 3) {
        web_common_area_getInlandAreaChildListByParentId({id: val}).then(res => {
          this.lineParams.lineAreaArr[index].cityAddList = res
          this.disabledCityList()
        })
      } else {
        web_common_area_getInternationalAreaChildListByParentId({id: val}).then(res => {
          this.lineParams.lineAreaArr[index].cityAddList = res
          this.disabledCityList()
        })
      }
    },
    disabledCityList() {
      if (this.lineParams.lineAreaArr.length === 1) {
        return
      }
      let chooseParamsList = []
      for (let i = 0, l = this.lineParams.lineAreaArr.length; i < l; i++) {
        if (this.lineParams.lineAreaArr[i].areaTwo !== '') {
          chooseParamsList.push(this.lineParams.lineAreaArr[i].areaTwo)
        }
      }
      if (chooseParamsList.length === 0) {
        return
      }
      for (let i = 0, l = this.lineParams.lineAreaArr.length; i < l; i++) {
        let cityAddList = this.lineParams.lineAreaArr[i].cityAddList
        for (let m = 0, n = cityAddList.length; m < n; m++) {
          for (let x = 0, y = chooseParamsList.length; x < y; x++) {
            if (chooseParamsList[x] === cityAddList[m].id) {
              this.lineParams.lineAreaArr[i].cityAddList[m].disabled = true
            }
          }
        }
      }
    },
    changeAddCity(index, val) {
      this.clearFormZone(index, 3)
      if (val === '') {
        return
      }
      for (let i = 0, l = this.lineParams.lineAreaArr[index].cityAddList.length; i < l; i++) {
        if (this.lineParams.lineAreaArr[index].cityAddList[i].id === val) {
          this.lineParams.lineAreaArr[index].areaTwoName = this.lineParams.lineAreaArr[index].cityAddList[i].areaName
          break
        }
      }
      let areaTwoList = []
      this.lineParams.lineAreaArr.forEach(value => {
        if (value.areaTwo !== '') {
          areaTwoList.push(value.areaTwo)
        }
      })
      this.lineParams.lineAreaArr.forEach(value => {
        value.cityAddList.forEach(val => {
          val.disabled = false
        })
      })
      for (let i = 0, l = this.lineParams.lineAreaArr.length; i < l; i++) {
        let cityAddList = this.lineParams.lineAreaArr[i].cityAddList
        for (let m = 0, n = cityAddList.length; m < n; m++) {
          for (let x = 0, y = areaTwoList.length; x < y; x++) {
            if (areaTwoList[x] === cityAddList[m].id) {
              this.lineParams.lineAreaArr[i].cityAddList[m].disabled = true
            }
          }
        }
      }


      if (this.lineParams.lineType === 1 || this.lineParams.lineType === 3) {
        web_common_area_getInlandAreaChildListByParentId({id: val}).then(res => {
          this.lineParams.lineAreaArr[index].countyAddList = res
        })
      } else {
        web_common_area_getInternationalAreaChildListByParentId({id: val}).then(res => {
          this.lineParams.lineAreaArr[index].countyAddList = res
        })
      }
    },
    changeAddCounty(index, tIndex, val) {
      if (val === '') {
        return
      }
      this.lineParams.lineAreaArr[index].countyAddList.forEach(value => {
        value.disabled = false
        if (value.id === val) {
          this.lineParams.lineAreaArr[index].areaThree[tIndex].name = value.areaName
        }
      })
      let areaThreeList = []
      for (let i = 0, l = this.lineParams.lineAreaArr[index].areaThree.length; i < l; i++) {
        if (this.lineParams.lineAreaArr[index].areaThree[i].id !== '') {
          areaThreeList.push(this.lineParams.lineAreaArr[index].areaThree[i].id)
        }
      }

      if (areaThreeList.length === 0) {
        return
      }
      for (let i = 0, l = areaThreeList.length; i < l; i++) {
        for (let m = 0, n = this.lineParams.lineAreaArr[index].countyAddList.length; m < n; m++) {
          let countyAddList = this.lineParams.lineAreaArr[index].countyAddList
          if (areaThreeList[i] === countyAddList[m].id) {
            this.lineParams.lineAreaArr[index].countyAddList[m].disabled = true
            break
          }
        }
      }
    },
    clearFormZone(index, val) {
      if (val < 2) {
        this.lineParams.lineAreaArr[index].areaOne = ''
        this.provinceAddList = []
      }
      if (val < 3) {
        this.lineParams.lineAreaArr[index].areaTwo = ''
        this.cityAddList = []
      }
      if (val < 4) {
        this.lineParams.lineAreaArr[index].areaThree = [{id: '', name: ''}]
        this.countyAddList = []
      }
    },
    handleClose() {
      this.dialogVisible = false
    },
    handleCancelChange(row, index) {
      this.lineParams.areaInfoArr.splice(index, 1)
    },
    handelChange() {
      this.$refs['lineForm'].validate((valid) => {
        if (valid) {
          let areaThree = this.lineParams.areaThree
          let isHaveArea = false
          for (let i = 0, l = this.lineParams.areaInfoArr.length; i < l; i++) {
            if (areaThree === this.lineParams.areaInfoArr[i].areaThree) {
              isHaveArea = true
              break
            }
          }
          if (isHaveArea) {
            this.$message({
              message: '所选区域已存在',
              type: 'warning'
            });
            return
          }
          let params = {
            lineType: this.lineParams.lineType,
            areaOne: this.lineParams.areaOne,
            areaOneName: this.lineParams.areaOneName,
            areaTwo: this.lineParams.areaTwo,
            areaTwoName: this.lineParams.areaTwoName,
            areaThree: this.lineParams.areaThree,
            areaThreeName: this.lineParams.areaThreeName,
          }
          this.lineParams.areaInfoArr.push(params)
        }
      })
    },
    handelSave() {
      /*if (this.lineParams.lineType === '') {
        this.$message({
          message: '请选择所属类型',
          type: 'warning'
        });
        return
      }*/
      if (this.lineParams.lineAreaArr.length === 1 && this.lineParams.lineAreaArr[0].areaThree[0].id === '') {
        this.$message({
          message: '请选择路线',
          type: 'warning'
        });
        return
      }

      let pass = true
      for (let i = 0, l = this.lineParams.lineAreaArr.length; i < l; i++) {
        for (let x = 0, y = this.lineParams.lineAreaArr[i].areaThree.length; x < y; x++) {
          if (this.lineParams.lineAreaArr[i].areaThree[x].id === '') {
            pass = false
            break
          }
        }
        if (!pass) {
          break
        }
      }
      if (!pass) {
        this.$message({
          message: '请选择所属城市',
          type: 'warning'
        });
        return;
      }
      let formStatusList = []
      this.formList.forEach(value => {
        formStatusList.push(-1)
      })
      this.formList.map((value, index) => {
        this.$refs[value].validate((valid) => {
          valid ? formStatusList[index] = 1 : formStatusList[index] = -1
          if (formStatusList.indexOf(-1) === -1) {
            this.loading = true
            let areaInfoArr = []
            this.lineParams.lineAreaArr.forEach(value => {
              let params = {
                lineType: this.lineParams.lineType,
                areaOne: value.areaOne,
                areaOneName: value.areaOneName,
                areaTwo: value.areaTwo,
                areaTwoName: value.areaTwoName,
                areaThree: '',
                areaThreeName: '',
              }
              value.areaThree.forEach(val => {
                params.areaThree = val.id
                params.areaThreeName = val.name
                let resultParams = Object.assign({}, params)
                areaInfoArr.push(resultParams)
              })
            })
            this.lineParams.areaInfoArr = areaInfoArr
            this.lineParams.areaResult = JSON.stringify(this.lineParams.areaInfoArr)
            web_circuit_addOrUpdate(this.lineParams).then(res => {
              this.loading = false
              this.$message({
                message: '新增成功',
                type: 'success'
              });
              this.dialogVisible = false
              this.getCircuitList()
            }).catch(() => {
              this.loading = false
            })
          }
        })
      })

    },
    handelEditSave() {
      /*if (this.lineParams.lineType === '') {
        this.$message({
          message: '请选择所属类型',
          type: 'warning'
        });
        return
      }
      if (this.lineParams.areaInfoArr.length === 0) {
        this.$message({
          message: '请选择路线',
          type: 'warning'
        });
        return
      }*/
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true
          let areaInfoArr = []
          this.lineParams.lineAreaArr.forEach(value => {
            let params = {
              lineType: this.lineParams.lineType,
              areaOne: value.areaOne,
              areaOneName: value.areaOneName,
              areaTwo: value.areaTwo,
              areaTwoName: value.areaTwoName,
              areaThree: '',
              areaThreeName: '',
            }
            value.areaThree.forEach(val => {
              params.areaThree = val.id
              params.areaThreeName = val.name
              let resultParams = Object.assign({}, params)
              areaInfoArr.push(resultParams)
            })
          })
          this.lineParams.areaInfoArr = areaInfoArr
          this.lineParams.areaResult = JSON.stringify(this.lineParams.areaInfoArr)
          web_circuit_addOrUpdate(this.lineParams).then(res => {
            this.loading = false
            this.$message({
              message: '修改成功',
              type: 'success'
            });
            this.dialogVisible = false
            this.getCircuitList()
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },
  }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
