const Q = require('q');
const __request = require(`./__request/__request_contentType_json`);

const web_credit_travelCreditLogList = function (pParameter) {
    if (!pParameter) pParameter = {};
    // 授信日志列表 ：http://219.128.52.2:9999/doc.html#/yinzhilv_travel/credit-account-controller/travelCreditLogListUsingPOST

    const orderNo = pParameter.orderNo;

    return Q.when().then(function () {
        return __request({
            method: 'POST',
            urlSuffix: '/web/credit/travelCreditLogList',
            data: {
                orderNo: orderNo,
            },
        });
    }).catch(function (ex) {
        console.error(ex);
        if (ex) {
            if (ex.stack) {
                console.error(ex.stack);
            }
        }
        return Q.reject(ex);
    });
};

module.exports = web_credit_travelCreditLogList;