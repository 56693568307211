import moment from 'moment'
import _ from 'underscore';
import Pagination from '@/component/base-pagination/index.vue';
import web_credit_creditConstantList from '@/lib/data-service/default/web_credit_creditConstantList'; // 旅游授信订单分页列表
import web_credit_orderBillList from '@/lib/data-service/default/web_credit_orderBillList'; // 旅游授信订单分页列表

export default {
    data () {
        return {
            loading: false,
            params: {
                orderNo: '',
                accountName: '',
                companyName: '',
                refundStatus: '',
                refundDateBegin: '',
                refundDateEnd: '',
                isOverdue: '',

                currentPage: 1,
                pageSize: 10,
                total: 0
            },
            allocation: {
                refundStatusList: [
                    {label: '全部', value: ''},
                    {label: '待还款', value: 1},
                    {label: '已还款', value: 2},
                    {label: '已逾期', value: 3},
                ],
                isOverdueList: [
                    {label: '全部', value: ''},
                    {label: '是', value: 1},
                    {label: '否', value: 2},
                ]
            },
            datas: {},
            list: [],
            returnDate: []
        }
    },
    components: {
        Pagination
    },
    methods: {
        getCreditConstantList() {
            const __this = this;
            web_credit_creditConstantList().then(res => {
                __this.allocation.refundStatusList = res.refundStatusList;
                __this.allocation.isOverdueList = res.isOverdueList;
            })
        },
        getOrderBillList() {
            const __this = this;
            __this.loading = true;
            web_credit_orderBillList(__this.params).then(res => {
                const list = res.pageResult.pageData;
                __this.datas = res;
                __this.list = list;
                __this.params.total = res.pageResult.totalCount;
                __this.loading = false
            }).catch(() => {
                __this.loading = false
            }, new Error(() => {
                __this.loading = false
            }))
        },
        onSearch() {
            const __this = this;
            __this.params.currentPage = 1;
            __this.getOrderBillList()
        },
        onReset() {
            const __this = this;
            __this.returnDate = [];
            __this.params = {
                orderNo: '',
                accountName: '',
                companyName: '',
                refundStatus: '',
                refundDateBegin: '',
                refundDateEnd: '',
                isOverdue: '',
                currentPage: 1,
                pageSize: 10,
                total: 0
            };
            __this.onSearch()
        },
        to_detail(row) {
            const __this = this;

            const get_route_name = function () {
                let r = `admin-credit-bill-deposit-detail`;

                const meta = __this.$route.meta;
                if (!meta) return r;

                const data_app_for_npm_yinzhilv_frontend_platform_v2 = meta.data_app_for_npm_yinzhilv_frontend_platform_v2;
                if (!data_app_for_npm_yinzhilv_frontend_platform_v2) return r;

                const get_route_detail_page = data_app_for_npm_yinzhilv_frontend_platform_v2.get_route_detail_page;
                if (!_.isFunction(get_route_detail_page)) return r;

                const route = get_route_detail_page();
                if (!route) return r;

                const route_name = route.route_name;
                if (!route_name) return r;

                r = route_name;
                return r;
            };

            const route_name = get_route_name();

            __this.$router.push({
                name: route_name,
                query: {
                    id: row.gorderListResult.id,
                    orderNo: row.orderNo
                }
            })
        }
    },
    activated () {
        const __this = this;
        __this.getCreditConstantList();
        __this.getOrderBillList();
    },
    watch: {
        returnDate(newVal) {
            const __this = this;
            __this.params.refundDateBegin = newVal[0];
            __this.params.refundDateEnd = newVal[1];
        }
    }
}
