const __request = require(`./__request/__request_contentType_json`);

// 配置设置：http://b2c-api-alpha.yinzhilv.com/doc.html#/btoc-biz-service/%E5%B9%B3%E5%8F%B0%20-%20%E5%BE%AE%E5%95%86%E5%9F%8E%E7%AE%A1%E7%90%86/configSettingUsingPOST_1
const biz_platform_bdconfig_configSetting = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/biz/platform/bdconfig/configSetting',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = biz_platform_bdconfig_configSetting;