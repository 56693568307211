
// 当前用户修改密码接口文档地址：http://192.168.0.12:8080/doc.html#/yinzhilv_travel/user-controller/updatePasswordUsingPOST
const Q = require(`q`);
const _ = require(`underscore`);
const moment = require(`moment`);
const __request = require(`./__request/__request_contentType_json`);

const web_user_resetPassword = (pParams) => {
    pParams = pParams || {};
    const p = {
        method: 'post',
        urlSuffix: '/web/user/resetPassword',
        data: pParams,
        enable_error_alert: false
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    });

};
module.exports = web_user_resetPassword;
