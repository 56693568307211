export default (vm) => {
  return {
    lineId: [
      { required: true, message: '请选择路线', trigger: 'change' },
    ],
    settleDeposit: [
      {
        required: true,
        validator(rule, value, callback) {
          if (vm.whetherToCollect === 0) callback()
          if (value === null || value === undefined || value === '') {
            callback('请填写金额')
          }
          callback()
        },
        trigger: 'blur'
      },
    ],
    pressAmount: [
      {
        required: true,
        validator(rule, value, callback) {
          if (vm.whetherToCollect === 0) callback()
          if (value === null || value === undefined || value === '') {
            callback('请填写金额')
          }
          callback()
        },
        trigger: 'blur'
      },
    ],
  }
}