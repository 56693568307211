import web_managerSupplier_details from '@/lib/data-service/default/web_managerSupplier_details'
import web_common_user_getDeveloper from '@/lib/data-service/default/web_common_user_getDeveloper'
import web_clud_list from '@/lib/data-service/default/web_clud_list'
import web_managerSupplier_set from '@/lib/data-service/default/web_managerSupplier_set'
import PreviewImage from '@/page/admin/supplier-management/component/previewImage/index.vue'
import addImgSuffix from '@/lib/common-service/add_img_suffix'
import FullImage from '@/page/admin/supplier-management/component/fullImage/index.vue'
import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client";
export default {
  data () {
    const validateOfExpirationTimeTrue = (rule, value, callback) => {
      if (this.supplier.isOperationFlow === 1) {
        if (value === '') {
          callback(new Error('流水到期时间不能为空'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    };
    const validateDomesticServiceFee = (rule, value, callback) => {
      if (value >= 0) {
        callback()
      } else {
        callback(new Error('不能为负数'))
      }
    };
    const validateInternationalServiceFee = (rule, value, callback) => {
      if (value >= 0) {
        callback()
      } else {
        callback(new Error('不能为负数'))
      }
    };
    const validateUnWithdrawalRate = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('不能为空'))
      } else {
        if (value >= 0 && value <= 100) {
          callback()
        } else {
          callback(new Error('请输入0-100的数'))
        }
      }
      callback()
    }
    const validateDepositAmount = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('不能为空'))
      } else {
        if (value >= 0) {
          callback()
        } else {
          callback(new Error('不能为负数'))
        }
      }
      callback()
    }
    const validateAccountName = (rule, value, callback) => {
      if (!value) {
        if (this.supplier.accountBankNm || this.supplier.accountNo) {
          callback(new Error('请输入账户名'))
        }
      }
      callback()
    }
    const validateAccountBankNm = (rule, value, callback) => {
      if (!value) {
        if (this.supplier.accountName || this.supplier.accountNo) {
          callback(new Error('请输入开户行'))
        }
      }
      callback()
    }
    const validateAccountNo = (rule, value, callback) => {
      if (!value) {
        if (this.supplier.accountName || this.supplier.accountBankNm) {
          callback(new Error('请输入银行账号'))
        }
      }
      callback()
    }
    const validateAlipayName = (rule, value, callback) => {
      if (!value) {
        if (this.supplier.alipayAccount) {
          callback(new Error('请输入账户名'))
        }
      }
      callback()
    }
    const validateAlipayAccount = (rule, value, callback) => {
      if (!value) {
        if (this.supplier.alipayName) {
          callback(new Error('请输入支付宝账号'))
        }
      }
      callback()
    }
    const validateServiceFeePercent = (rule, value, callback) => {
      if (value >= 0) {
        callback()
      } else {
        callback(new Error('不能为负数'))
      }
    };
    const validatePaymentServiceRate = (rule, value, callback) => {
      if (value === '') {
        callback()
      } else {
        if (value >= 0) {
          callback()
        } else {
          callback(new Error('不能为负数'))
        }
      }
    };
    const validateTravelPaymentDays = (rule, value, callback) => {
      if (value === '') {
        callback()
      } else {
        if (value >= 0) {
          callback()
        } else {
          callback(new Error('不能为负数'))
        }
      }
    };
    const validateSettleDeposit = (rule, value, callback) => {
      if (value >= 0) {
        callback()
      } else {
        callback(new Error('不能输入负数'))
      }
    };
    const validatePlatAssignThreshold = (rule, value, callback) => {
      const re = /^[0-9]+(\.[0-9]{0,2})?$/;
      if (1 === this.supplier.platAssignStatus && null == value) {
        callback(new Error('请输入拒单转自营自动出票阈值'))
      } else if (value <= 0) {
        callback(new Error('请输入非零正数'))
      } else if (!re.test(value)){
        callback(new Error('最多两位小数'))
      }
      callback()
    };
    return {
      loading: false,
      id: '',
      supplier: {},
      srcList: [],
      developerList: [],
      clubList: [],
      rule: {
        developerId: [
          {required: true, message: '开发人不能为空', trigger: 'change'}
        ],
        ofExpirationTimeTrue: [
          {validator: validateOfExpirationTimeTrue, trigger: 'change'}
        ],
        domesticServiceType: [
          {required: true, message: '请选择收取方式', trigger: 'change'}
        ],
        domesticServiceFee: [
          {required: true, message: '请输入服务费', trigger: 'blur'},
          {validator: validateDomesticServiceFee, trigger: 'blur'}
        ],
        internationalServiceFee: [
          {required: true, message: '请输入服务费', trigger: 'blur'},
          {validator: validateInternationalServiceFee, trigger: 'blur'}
        ],
        specialServiceType: [
          {required: true, message: '请输入收取方式', trigger: 'change'}
        ],
        specialServiceFee: [
          {required: true, message: '请输入服务费', trigger: 'blur'},
          {validator: validateDomesticServiceFee, trigger: 'blur'}
        ],
        airAccountManagerId: [
          {required: true, message: '机票客户经理不能为空', trigger: 'change'}
        ],
        unWithdrawalRate: [
          {required: true, message: '请输入不可提现比例', trigger: 'blur'},
          {validator: validateUnWithdrawalRate, trigger: 'blur'}
        ],
        depositAmount: [
          {required: true, message: '请输入压款金额', trigger: 'blur'},
          {validator: validateDepositAmount, trigger: 'blur'}
        ],
        accountName: [
          {validator: validateAccountName, trigger: 'blur'}
        ],
        accountBankNm: [
          {validator: validateAccountBankNm, trigger: 'blur'}
        ],
        accountNo: [
          {validator: validateAccountNo, trigger: 'blur'}
        ],
        alipayName: [
          {validator: validateAlipayName, trigger: 'blur'}
        ],
        alipayAccount: [
          {validator: validateAlipayAccount, trigger: 'blur'}
        ],
        serviceFeeType: [
          {required: true, message: '请选择收取方式', trigger: 'change'}
        ],
        serviceFeePercent: [
          {required: true, message: '请输入技术服务费', trigger: 'blur'},
          {validator: validateServiceFeePercent, trigger: 'blur'}
        ],
        paymentServiceRate: [
          {validator: validatePaymentServiceRate, trigger: 'blur'}
        ],
        travelPaymentDays: [
          {validator: validateTravelPaymentDays, trigger: 'blur'}
        ],
        travelAccountManagerId: [
          {required: true, message: '旅游客户经理不能为空', trigger: 'change'}
        ],
        internationalServiceType: [
          {required: true, message: '请选择收取方式', trigger: 'change'}
        ],
        settleDeposit: [
          {required: true, message: '订金金额不能为空', trigger: 'blur'},
          {validator: validateSettleDeposit, trigger: 'blur'}
        ],
        finalPaymentCycle: [
          {required: true, message: '尾款周期不能为空', trigger: 'blur'},
          {validator: validateSettleDeposit, trigger: 'blur'}
        ],
        pressAmount: [
          {required: true, message: '压款金额不能为空', trigger: 'blur'},
          {validator: validateSettleDeposit, trigger: 'blur'}
        ],
        presslPaymentCycle: [
          {required: true, message: '压款周期不能为空', trigger: 'blur'},
          {validator: validateSettleDeposit, trigger: 'blur'}
        ],
        platAssignThreshold: [
          {validator: validatePlatAssignThreshold, trigger: 'blur'}
        ],
      },
      dialogFullPicVisible: false,
      fullPicSrc: ''
    }
  },
  components: {
    FullImage,
    PreviewImage
  },
  created () {},
  mounted () {},
  activated () {
    this.id = this.$route.query.id
    this.getSupplierDetail(this.id)
    this.getDeveloperList()
    this.getClubList()
  },
  deactivated() {},
  destroyed () {
  },
  watch: {
    supplier: {
      handler(val){
        if (!val.unWithdrawalRate && !val.depositAmount) {
          if (this.$refs["withdrawalForm"]) {
            this.$refs["withdrawalForm"].clearValidate()
          }
        }
        if (!val.accountName && !val.accountBankNm && !val.accountNo) {
          if (this.$refs["bankAccountForm"]) {
            this.$refs["bankAccountForm"].clearValidate()
          }
        }
        if (!val.alipayName && !val.alipayAccount) {
          if (this.$refs["alipayAccountForm"]) {
            this.$refs["alipayAccountForm"].clearValidate()
          }
        }
      },
      deep: true,
    },
  },
  computed: {},
  filters: {
    filterSupplierStatus(val) {
      if (val === 0) {
        return '待审核'
      } else if (val === 1) {
        return '审核失败'
      } else if (val === 2) {
        return '部分启用'
      } else if (val === 3) {
        return '启用'
      } else if (val === 4) {
        return '平台禁用'
      } else if (val === 5) {
        return '已删除'
      } else if (val === 6) {
        return '未签约'
      } else if (val === 7) {
        return '签约失败'
      } else if (val === 8) {
        return '待设置合同文件'
      } else {
        return ''
      }
    },
    filterAppType(val) {
      if (val === '1') {
        return '机票'
      } else if (val === '2') {
        return '旅游'
      } else {
        return '机票+旅游'
      }
    },
    filterImg(val) {
      let result = addImgSuffix(val)
      return result
    }
  },
  methods: {
    // 获取开发者列表 - （机票经理）
    getDeveloperList() {
      web_common_user_getDeveloper().then((res) => {
        this.developerList = res.getAllDeveloperResult
      })
    },
    // 获取中间社列表
    getClubList() {
      web_clud_list().then(res => {
        this.clubList = res.results
      })
    },
    picLoading() {
      let _this = this
      let picSetTimeOut = ''
      picSetTimeOut = setTimeout(() => {
        let formPicArr = document.getElementsByClassName('form-pic')
        let formPicStatus = []
        for (let i in formPicArr.length) {
          formPicStatus.push(-1)
        }
        formPicArr.forEach((value, index) => {
          let complete = value.complete
          formPicStatus[index] = complete ? 1 : -1
        })
        if (formPicStatus.indexOf(-1) >= 0) {
          this.picLoading()
        } else {
          _this.loading = false
        }
      }, 500)
    },
    cancelChangeClickCanPnr(index) {
      let allDayForCanPnrBoolean = this.supplier.supCanConfigList[index].allDayForCanPnrBoolean;
      this.supplier.supCanConfigList[index].allDayForCanPnr = allDayForCanPnrBoolean ? 1 : 0;
      if (this.supplier.supCanConfigList[index].allDayForCanPnr == 1) {
        this.supplier.supCanConfigList[index].canPnrTime = ['00:00:00','23:59:59'];
        this.supplier.supCanConfigList[index].canPnrTimeBegin = '00:00:00';
        this.supplier.supCanConfigList[index].canPnrTimeEnd = '23:59:59';
      }
    },
    cancelBlurPickerCanPnrTime(index){
      let canPnrTime = this.supplier.supCanConfigList[index].canPnrTime;
      if (canPnrTime[0] == '00:00:00' && canPnrTime[1] == '23:59:59') {
        this.supplier.supCanConfigList[index].allDayForCanPnr = 1;
        this.supplier.supCanConfigList[index].allDayForCanPnrBoolean = true;
      }
    },
    cancelChangePickerCanPnrTime(index){
      let canPnrTime = this.supplier.supCanConfigList[index].canPnrTime;
      if (!canPnrTime) {
        this.supplier.supCanConfigList[index].allDayForCanPnr = 0;
        this.supplier.supCanConfigList[index].allDayForCanPnrBoolean = false;
        return
      }
      this.supplier.supCanConfigList[index].canPnrTimeBegin = canPnrTime[0];
      this.supplier.supCanConfigList[index].canPnrTimeEnd = canPnrTime[1];
      if (canPnrTime[0] == '00:00:00' && canPnrTime[1] == '23:59:59') {
        this.supplier.supCanConfigList[index].allDayForCanPnr = 1;
        this.supplier.supCanConfigList[index].allDayForCanPnrBoolean = true;
      }
    },
    cancelChangeClickRefPnr(index) {
      let allDayForRefPnrBoolean = this.supplier.supCanConfigList[index].allDayForRefPnrBoolean;
      this.supplier.supCanConfigList[index].allDayForRefPnr = allDayForRefPnrBoolean ? 1 : 0;
      if (this.supplier.supCanConfigList[index].allDayForRefPnr == 1) {
        this.supplier.supCanConfigList[index].refPnrTime = ['00:00:00','23:59:59'];
        this.supplier.supCanConfigList[index].refPnrTimeBegin = '00:00:00';
        this.supplier.supCanConfigList[index].refPnrTimeEnd = '23:59:59';
      }
    },
    cancelBlurPickerRefPnrTime(index){
      let canPnrTime = this.supplier.supCanConfigList[index].canPnrTime;
      if (canPnrTime[0] == '00:00:00' && canPnrTime[1] == '23:59:59') {
        this.supplier.supCanConfigList[index].allDayForCanPnr = 1;
        this.supplier.supCanConfigList[index].allDayForCanPnrBoolean = true;
      }
    },
    cancelChangePickerRefPnrTime(index){
      let refPnrTime = this.supplier.supCanConfigList[index].refPnrTime;
      if (!refPnrTime) {
        this.supplier.supCanConfigList[index].allDayForRefPnr = 0;
        this.supplier.supCanConfigList[index].allDayForRefPnrBoolean = false;
        return
      }
      this.supplier.supCanConfigList[index].refPnrTimeBegin = refPnrTime[0];
      this.supplier.supCanConfigList[index].refPnrTimeEnd = refPnrTime[1];
      if (refPnrTime[0] == '00:00:00' && refPnrTime[1] == '23:59:59') {
        this.supplier.supCanConfigList[index].allDayForRefPnr = 1;
        this.supplier.supCanConfigList[index].allDayForRefPnrBoolean = true;
      }
    },
    getSupplierDetail(supplierId) {
      this.loading = true
      web_managerSupplier_details({supplierId}).then(res => {
        let results = res.result
        results.airAccountManagerId = results.airAccountManagerId === '' ? '' : parseInt(results.airAccountManagerId)
        results.developerId = results.developerId === '' ? '' : parseInt(results.developerId)
        results.travelAccountManagerId = results.travelAccountManagerId === '' ? '' : parseInt(results.travelAccountManagerId)
        results.domesticServiceType = results.domesticServiceType === null ? 1 : results.domesticServiceType
        results.internationalServiceType = results.internationalServiceType === null ? 1 : results.internationalServiceType
        results.specialServiceType = results.specialServiceType === null ? 2 : results.specialServiceType
        results.specialServiceFee = results.specialServiceFee === null ? 0 : results.specialServiceFee

        if (results.settleDepositType === 0) {
          results.isHasSettle = 0
          results.settleType = 1
          results.finalPaymentType = 1
        } else if (results.settleDepositType === 1) {
          results.isHasSettle = 1
          results.settleType = 1
          results.finalPaymentType = 1
        } else {
          results.isHasSettle = 1
          results.settleType = 2
          results.finalPaymentType = 1
        }
        if (results.pressAmountType === 0) {
          results.isHasPress = 0
          results.pressType = 1
          results.pressPaymentType = 1
        } else if (results.pressAmountType === 1) {
          results.isHasPress = 1
          results.pressType = 1
          results.pressPaymentType = 1
        } else {
          results.isHasPress = 1
          results.pressType = 2
          results.pressPaymentType = 1
        }
        results.ofExpirationTimeTrue = results.ofExpirationTime
        if (results.agreementFile) {
          let num = results.agreementFile.lastIndexOf('/')+1
          let name = results.agreementFile.substring(num)
          const file = {
            name,
            url: results.agreementFile
          }
          results.fileList = [file]
        }

        // 取位部分
        results.supCanConfigList.forEach((item,index) => {
          item.canPnrTime = [item.canPnrTimeBegin,item.canPnrTimeEnd];
          item.refPnrTime = [item.refPnrTimeBegin,item.refPnrTimeEnd];
          item.allDayForCanPnrBoolean = item.allDayForCanPnr == 1 ? true : false;
          item.allDayForRefPnrBoolean = item.allDayForRefPnr == 1 ? true : false;
          if ('' == item.lockTicket || null == item.lockTicket || 'undefined' == item.lockTicket || item.lockTicket === 0) {
            item.lockTicket = 0
          }
        });

        results.allDayForCanPnrType = results.allDayForCanPnr === 1 ? true : false;
        results.allDayForRefPnrType = results.allDayForRefPnr === 1 ? true : false;
        // 时间
        results.cancelTime = [results.canPnrTimeBegin, results.canPnrTimeEnd];
        results.refCancelTime = [results.refPnrTimeBegin, results.refPnrTimeEnd];

        if ('' == results.copyOrderStatus || null == results.copyOrderStatus || 'undefined' == results.copyOrderStatus || results.copyOrderStatus === 0) {
          results.copyOrderStatus = 0
        }
        if ('' == results.orderAutoRefuse || null == results.orderAutoRefuse || 'undefined' == results.orderAutoRefuse || results.orderAutoRefuse === 0) {
          results.orderAutoRefuse = 0
        }
        if ('' == results.platAssignStatus || null == results.platAssignStatus || 'undefined' == results.platAssignStatus || results.platAssignStatus === 0) {
          results.platAssignStatus = 0
        }
        console.log(results)
        this.supplier = results
        // this.supplier.ofExpirationTimeTrue = this.supplier.ofExpirationTime
        this.$nextTick(() => {
          this.picLoading()
        })
      }).catch(() => {
        this.loading = false
      })
    },
    showFullPic(val) {
      this.dialogFullPicVisible = true
      this.fullPicSrc = addImgSuffix(val)
    },
    back() {
      this.$router.go(-1)
    },
    // 选择开发人
    changeDeveloper(value) {
      console.log(value)
      for (let i = 0, l = this.developerList.length; i < l; i++) {
        if (this.developerList[i].id === value) {
          this.supplier.developer = this.developerList[i].realName
          break
        }
      }
    },
    // 选择经理时
    changeAirAccountManager(value) {
      for (let i = 0, l = this.developerList.length; i < l; i++) {
        if (this.developerList[i].id === value) {
          this.supplier.airAccountManager = this.developerList[i].realName
          break
        }
      }
    },
    changeAccountManager(value) {
      for (let i = 0, l = this.developerList.length; i < l; i++) {
        if (this.developerList[i].id === value) {
          this.supplier.travelAccountManager = this.developerList[i].realName
          break
        }
      }
    },
    submit() {
      let status
      let formDataList
      if (this.supplier.applicationType === '1') {
        status = [-1, -1, -1, -1, -1, -1, -1, -1]
        formDataList = ['form4', 'form5-1', 'form5-2', 'form5-3', 'form5-4', 'form5-5', 'bankAccountForm', 'alipayAccountForm']
      } else if (this.supplier.applicationType === '2') {
        status = [-1, -1, -1, -1, -1, -1, -1]
        formDataList = ['form4', 'bankAccountForm', 'alipayAccountForm', 'form6-1', 'form6-2', 'form6-3', 'form6-4']
      } else {
        status = [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1]
        formDataList = ['form4', 'form5-1', 'form5-2', 'form5-3', 'form5-4', 'form5-5', 'bankAccountForm', 'alipayAccountForm', 'form6-1', 'form6-2', 'form6-3', 'form6-4']
      }
      formDataList.map((value, index) => {
        this.$refs[value].validate((valid) => {
          valid ? status[index] = 1 : status[index] = -1
          if (status.indexOf(-1) === -1) {
            // 开始提交
            this.loading = true
            if (this.supplier.isHasSettle === 0) {
              this.supplier.settleDepositType = 0
            } else {
              this.supplier.settleDepositType = this.supplier.settleType === 1 ? 1 : 2
            }
            if (this.supplier.isHasPress === 0) {
              this.supplier.pressAmountType = 0
            } else {
              this.supplier.pressAmountType = this.supplier.pressType === 1 ? 1 : 2
            }
            let params = this.supplier;

            params.supCanConfigList.forEach((item,index) => {
              delete item.allDayForRefPnrBoolean;
              delete item.allDayForCanPnrBoolean;
              delete item.refPnrTime;
              delete item.canPnrTime;
            });

            web_managerSupplier_set(params).then(res => {
              this.loading = false
              this.$alert('修改成功', '温馨提示', {
                confirmButtonText: '确定',
                callback: action => {
                  // 跳转
                  this.$router.back();
                }
              })
            }).catch(() => {
              this.loading = false
            })
          }
        })
      })
    },
    handleSuccesspictureB(file, fileList) {
      const size = file.size / 1024 / 1024;
      if (size > 10) {
        return this.$message.error("文件大小不能超过10M");
      }
      const _this = this;
      const aOssClient = new OssClient();
      aOssClient.multipartUpload({
        file: file.raw,
        progress: function* (p) {

        }
      })
          .then(function (data) {
            _this.$message.success("文件上传成功");
            _this.supplier.agreementFile = data.url;
            _this.$refs.uploadB.clearValidate();
          })
    },
    removePDFB() {
      this.supplier.agreementFile = "";
    },
    openPDFB(file) {
      window.open(this.supplier.agreementFile);
    },
    onExceed(files, fileList) {
      console.log('duo1', files, fileList)
      this.$message({
        type: "warning",
        message: '请先删除现有文件'
      })
    },
    changeClick(val){
      const __this = this;
      if(val===1){
        // 取位
        if(__this.supplier.allDayForCanPnrType){
          __this.supplier.cancelTime=['00:00:00','23:59:59']
        }
        return;
      }
      if(val===2){
        // 退票取位
        if(__this.supplier.allDayForRefPnrType){
          __this.supplier.refCancelTime=['00:00:00','23:59:59']
        }
        return;
      }
    },
    changePicker(val, event){
      let __this = this;
      let supplier = JSON.parse(JSON.stringify(__this.supplier))
      if(val === 1){
        if(null == event){
          setTimeout(() => {
            supplier.cancelTime=['00:00:00', '23:59:59'];
            supplier.allDayForCanPnrType=true;
            supplier.allDayForCanPnr=1;
            __this.supplier = supplier;
            __this.$forceUpdate();
          }, 300)
          return;
        }
        // 取位
        const t = __this.supplier.cancelTime;
        if(t[0]==='00:00:00' && t[1] === '23:59:59'){
          supplier.allDayForCanPnrType=true;
          supplier.allDayForCanPnr=1;
        } else {
          supplier.allDayForCanPnrType=false;
          supplier.allDayForCanPnr=0;
        }
        __this.supplier = supplier;
        return;
      }
      if(val === 2){
        if(null == event){

          setTimeout(() => {
            supplier.refCancelTime=['00:00:00', '23:59:59'];
            supplier.allDayForRefPnrType=true;
            supplier.allDayForRefPnr = 1;
            __this.supplier = supplier;
            __this.$forceUpdate();
            console.log(__this.supplier.refCancelTime);
          }, 300)
          return;
        }
        // 退票取位
        let t2 =  __this.supplier.refCancelTime;
        if(t2[0]==='00:00:00' && t2[1] === '23:59:59'){
          supplier.allDayForRefPnrType=true;
          supplier.allDayForRefPnr = 1;
        } else {
          supplier.allDayForRefPnrType=false;
          supplier.allDayForRefPnr = 0;
        }
        __this.supplier = supplier;
        return;
      }
    }
  }
}
