import import_latest_version_module from '@/component/admin-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/credit-product-management',
            name: 'admin-credit-product-management',
            component: () => import(/* webpackChunkName: "page-admin-credit-management-credit-product-management" */ `./index.vue`),
            meta: {
          data_for_admin_layout: {
                    data_for_history_tag: {
                              entity: {
                                        name: "产品管理"
                              }
                    },
                    data_for_breadcrumb: {
                              entity_list: [
                                        {
                                                  name: "授信管理"
                                        },
                                        {
                                                  name: "产品管理"
                                        }
                              ]
                    }
          }
},
        },
    ],
};
