

const __request = require(`./__request/__request_contentType_json`);

// 火车票平台设置操作日志接口地址：http://192.168.0.11:9900/doc.html#/trains/system-config-controller/getSystemConfigLogListUsingPOST

export default function trains_systemConfig_getSystemConfigLogList(pParameter) {
  if (!pParameter) pParameter = {};
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/trains/systemConfig/getSystemConfigLogList'
  params.data = pParameter;
  params.process_response_data = false
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}
