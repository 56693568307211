// web_common_source_getPlatformAuthority 获取胤之旅平台菜单权限列表

const __request = require(`@/lib/data-service/default/__request/__request_contentType_json`);

// 接口地址：http://192.168.0.19:9999/doc.html#/yinzhilv_travel/resource-controller/getPlatformAuthorityUsingPOST

export default function web_common_source_getPlatformAuthority(pParameter) {
    if (!pParameter) pParameter = {};

    let params = {}
    params.method = 'POST'
    params.urlSuffix = '/web/common/source/getPlatformAuthority'
    params.data = pParameter
    return new Promise(function (resolve, reject) {
        resolve(__request(params))
    })
}