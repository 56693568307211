import Pagination from '@/component/base-pagination/index.vue'
import web_common_credit_theCreditBillList from '@/lib/data-service/default/web_common_credit_theCreditBillList'
import web_common_user_getDeveloper from '@/lib/data-service/default/web_common_user_getDeveloper'
import web_common_credit_postponeCredit from '@/lib/data-service/default/web_common_credit_postponeCredit'
import moment from 'moment'
export default {
  data () {
    const validatePostponeDay = (rule, value, callback) => {
      if (value > 0) {
        callback()
      } else {
        callback(new Error('只能输入正整数'))
      }
    }
    return {
      moment: moment,
      loading: false,
      showMore: false,
      params: {
        billDate: [],
        billRefundDate: [],

        accountManager: '',
        accountName: '',
        billDateEnd: '',
        billDateStart: '',
        billRefundDateEnd: '',
        billRefundDateStart: '',
        billStatus: '',
        companyName: '',
        expireStatus: '',
        isPostpone: '',
        overdueDays: '',
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      accountManagerList: [],
      billStatusList: [{value: '', label: '全部'}, {value: 1, label: '待还'}, {value: 2, label: '已还清'}, {value: 3, label: '已逾期'}, {value: 6, label: '即将逾期'}, {value: 7, label: '逾期待还'}],
      expireStatusList: [{value: '', label: '全部'}, {value: 1, label: '不到1天即将逾期'}, {value: 2, label: '逾期'}, {value: 3, label: '还有1天逾期'}, {value: 4, label: '正常'}],
      isPostponeList: [{value: '', label: '全部'}, {value: 1, label: '是'}, {value: 2, label: '否'}],
      overdueDaysList: [{value: '', label: '全部'}, {value: 1, label: '1天'}, {value: 2, label: '2天'}, {value: 3, label: '3天及以上'}],
      list: [],
      dialogVisible: false,
      creditParams: {
        billIds: '',
        buyerIds: '',
        isPostpone: 1,
        orderNos: '',
        postponeDay: 0
      },
      rule: {
        postponeDay: [
          {required: true, message: '延期天数', trigger: 'blur'},
          {validator: validatePostponeDay, trigger: 'blur'}
        ]
      }
    }
  },
  components: {Pagination},
  created () {
  },
  mounted () {

  },
  activated () {
    this.getCreditBillList()
    this.getDeveloperList()
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
    formatterLatestRefundDate(val) {
      if (val.latestRefundDate === '' || val.latestRefundDate === null || val.latestRefundDate === undefined) {
        return '--'
      } else {
        return moment(val.latestRefundDate).format('YYYY-MM-DD')
      }
    },
    showOrHideForm() {
      const showMore = this.showMore
      this.showMore = !showMore
    },
    changeBillDate(val) {
      if (val === '' || val === null) {
        this.params.billDateStart = ''
        this.params.billDateEnd = ''
      } else {
        this.params.billDateStart = val[0]
        this.params.billDateEnd = val[1]
      }
    },
    changeBillRefundDate(val) {
      if (val === '' || val === null) {
        this.params.billRefundDateStart = ''
        this.params.billRefundDateEnd = ''
      } else {
        this.params.billRefundDateStart = val[0]
        this.params.billRefundDateEnd = val[1]
      }
    },
    onSearch() {
      this.params.currentPage = 1
      this.getCreditBillList()
    },
    onReset() {
      this.params = {
        billDate: [],
        billRefundDate: [],
        accountManager: '',
        accountName: '',
        billDateEnd: '',
        billDateStart: '',
        billRefundDateEnd: '',
        billRefundDateStart: '',
        billStatus: '',
        companyName: '',
        expireStatus: '',
        isPostpone: '',
        overdueDays: '',
        currentPage: 1,
        pageSize: 10,
        total: 0
      }
      this.onSearch()
    },
    getDeveloperList() {
      web_common_user_getDeveloper().then((res) => {
        this.accountManagerList = res.getAllDeveloperResult
      })
    },
    getCreditBillList() {
      this.loading = true
      web_common_credit_theCreditBillList(this.params).then(res => {
        const list = res.pageResult.pageData;
        list.forEach(item => {
          if (item.billDate) item.billDate = moment(item.billDate).format('YYYY-MM-DD');
          if (item.billRefundDate) item.billRefundDate = moment(item.billRefundDate).format('YYYY-MM-DD');
        })
        this.list = list;
        this.params.total = res.pageResult.totalCount
        this.loading = false
      }).catch(() => {
        this.loading = false
      }, new Error(() => {
        this.loading = false
      }))
    },
    setBillStatus(val) {
      this.reactCreditParams()
      this.creditParams = {
        billIds: val.billId,
        buyerIds: val.companyId,
        isPostpone: 1,
        orderNos: val.orderNo,
        postponeDay: 0
      }
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs['form'].resetFields();
      })
    },
    reactCreditParams() {
      this.creditParams = {
        billIds: '',
        buyerIds: '',
        isPostpone: 1,
        orderNos: '',
        postponeDay: 0
      }
    },
    handleClose() {
      this.dialogVisible = false
    },
    submitCredit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          web_common_credit_postponeCredit(this.creditParams).then(res => {
            this.$message({
              message: '设置成功',
              type: 'success'
            });
            this.dialogVisible = false
            this.getCreditBillList()
          })
        }
      })
    },
  }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
