import web_settleAccounts_getSettlementProcessingList from '@/lib/data-service/default/web_settleAccounts_getSettlementProcessingList'
import web_settleAccounts_getSettlementConfirmation from '@/lib/data-service/default/web_settleAccounts_getSettlementConfirmation'
import web_common_upload_uploadAttachment from '@/lib/data-service/default/web_common_upload_uploadAttachment' // 上传附件
import web_settleAccounts_settleOrder from '@/lib/data-service/default/web_settleAccounts_settleOrder' // 结算确认
import Pagination from '@/component/base-pagination/index.vue'
export default {
  data () {
    return {
      show: false,
      loading: false,
      params: {
        currentPage: 1,
        pageSize: 10,
        orderNo: '',
        userName: '',
        productNo: '',
        companyName: '',
        productName: '',
        startSettleDate: '',
        endSettleDate: '',
        total: 0,
        period: [],
        settleBillsType: '',
      },
      settlementProcessingList: [],
      dialogVisible: false,
      rule: {
        bankNumber: [
          { required: true, message: '请输入流水号', trigger: 'blur' },
        ]
      },
      settleParams: {
        settleIds: '', // 结算ID
        settleWayVal: '', // 结算方式：1.易宝结算，2.银行结算
        settleVoucher: '', // 结算凭证url
        bankNumber: '' // 流水号
      },
      settleBillsType: [
        { label: '全部', value: ''},
        { label: '定金结算单', value: 1},
        { label: '尾款结算单', value: 2},
        { label: '压款结算单', value: 3},
        { label: '全额结算', value: 4},
      ],
      fileList: [],
      multipleSelection: []
    }
  },
  components: {
    Pagination
  },
  created () {
  },
  mounted () {
    this.getSettlementProcessingList()
  },
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
    fold_change () {
      this.show = !this.show;
    },
    changeParamsTime(val) {
      if (val === '' || val === null) {
        this.params.startSettleDate = ''
        this.params.endSettleDate = ''
      } else {
        this.params.startSettleDate = val[0]
        this.params.endSettleDate = val[1]
      }
    },
    onSearch() {
      this.params.currentPage = 1
      this.getSettlementProcessingList()
    },
    onReset() {
      this.params = {
        currentPage: 1,
        pageSize: 10,
        orderNo: '',
        userName: '',
        productNo: '',
        companyName: '',
        productName: '',
        startSettleDate: '',
        endSettleDate: '',
        total: 0,
        period: []
      }
      this.onSearch()
    },
    getSettlementProcessingList() {
      this.loading = true
      web_settleAccounts_getSettlementProcessingList(this.params).then(res => {
        this.loading = false
        this.settlementProcessingList = res.pageResult.pageData
        this.params.total = res.pageResult.totalCount
      }).catch(() => {
        this.loading = false
      })
    },
    handleSettle() {
      if (this.multipleSelection.length === 0) {
        this.$message({
          type: 'warning',
          message: '请先勾选要结算的项'
        });
        return
      }
      let settleIdsArr = []
      console.log(this.multipleSelection)
      this.multipleSelection.forEach(value => {
        settleIdsArr.push(value.id)
      })
      this.reactSettleParams()
      web_settleAccounts_getSettlementConfirmation({settleAccountId: this.multipleSelection[0].id}).then(res => {
        if (this.multipleSelection[0].settleAccountsVal === '1') {
          this.settleParams.settleWayVal = 1
        } else if (this.multipleSelection[0].settleAccountsVal === '2' || this.multipleSelection[0].settleAccountsVal === '1+2') {
          this.settleParams.settleWayVal = 2
        }
        this.settleParams.settleAccountsVal = this.multipleSelection[0].settleAccountsVal
        this.settleParams.settleIds = settleIdsArr.join(',')
        this.settleParams = Object.assign({}, this.settleParams, res)
        console.log(this.settleParams)
        this.dialogVisible = true
      })
    },
    handleCheck(val) {
      this.reactSettleParams()
      web_settleAccounts_getSettlementConfirmation({settleAccountId: val.id}).then(res => {
        if (val.settleAccountsVal === '1') {
          this.settleParams.settleWayVal = 1
        } else if (val.settleAccountsVal === '2' || val.settleAccountsVal === '1+2') {
          this.settleParams.settleWayVal = 2
        }
        this.settleParams.settleAccountsVal = val.settleAccountsVal
        this.settleParams.settleIds = val.id
        this.settleParams = Object.assign({}, this.settleParams, res)
        console.log(this.settleParams)
        this.dialogVisible = true
      })
    },
    reactSettleParams() {
      this.settleParams = {
        settleIds: '', // 结算ID
        settleWayVal: '', // 结算方式：1.易宝结算，2.银行结算
        settleVoucher: '', // 结算凭证url
        bankNumber: '' // 流水号
      }
    },
    handleClose() {
      this.dialogVisible = false
    },
    handleChange(file, fileList) {
      this.settleParams.settleVoucher = ''
    },
    uploadChange(file) {
      web_common_upload_uploadAttachment(file.file).then(data => {
        this.$message({ type: 'success', message: '上传文件成功' })
        this.settleParams.settleVoucher = data.fileList[0]
      }, error => {
        this.$message({ type: 'error', message: '文件上传失败！' })
        this.$refs['upload'].clearFiles()
      })
    },
    handelSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true
          let params = Object.assign({}, this.settleParams)
          web_settleAccounts_settleOrder(params).then(res => {
            this.loading = false
            if (res.code === '000000') {
              this.$message({ type: 'success', message: '操作成功' })
              this.dialogVisible = false
              this.getSettlementProcessingList()
            } else {
              this.dialogVisible = false
            }
          }).catch(() => {
            this.dialogVisible = false
            this.loading = false
          })
        }
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    }
  }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
