// web_managerSupplier_details 获取供应商详情

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口地址：http://192.168.0.15:8080/doc.html#/%E6%89%80%E6%9C%89%E6%8E%A5%E5%8F%A3/manager-supplier-controller/findSupplierDetailsUsingPOST

export default function web_managerSupplier_details(pParameter) {
  if (!pParameter) pParameter = {};
  const supplierId = pParameter.supplierId || ''
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/managerSupplier/details'
  params.data = {
    supplierId
  }
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}
