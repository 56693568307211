import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client/index";

import history_tag_api from "@/component/admin-layout/1.0.0/component/history-tag/api";
// 审批详情
import web_btoc_apply from "@/lib/data-service/default/web_btoc_apply";
// 检测微商城名称
import web_btoc_checkStoreName from "@/lib/data-service/default/web_btoc_checkStoreName";
// 提交审批
import web_btoc_check_submit from "@/lib/data-service/default/web_btoc_check_submit";

export default {
  data() {
    const validateStoreName = (rule, value, callback) => {
      if (value) {
        web_btoc_checkStoreName({ storeName: value })
          .then((result) => {
            if (result.storeNameUsed) callback(new Error("该商城名已被占用"));
            else callback();
          })
          .catch((err) => {
            callback(new Error(err.msg));
          });
      } else {
        callback(new Error("微商城名不能为空"));
      }
    };
    const validateStoreLimit = (rule, value, callback) => {
      const limitType = this.limitType;
      if (limitType == 2 && !value) {
        callback(new Error("请设置门店数"));
      } else {
        callback();
      }
    };
    return {
      OssClient: new OssClient(),
      loading: false,
      detail: {},
      form: {
        storeName: "",
        btocVersion: "1",
        storeLimit: "",
        touristBusinessLicenseNumber: "",
        touristBusinessLicense: "",
        travelLinkedMan: "",
        travelLinkedPhone: "",
      },
      rules: {
        storeName: [
          { required: true, validator: validateStoreName, trigger: "blur" },
        ],
        storeLimit: [{ validator: validateStoreLimit, trigger: "blur" }],
        touristBusinessLicenseNumber: [
          { required: true, message: "请输入旅游经营许可证", trigger: "blur" },
        ],
        touristBusinessLicense: [
          {
            required: true,
            message: "请上传旅游经营许可证照片",
            trigger: "blur",
          },
        ],
        travelLinkedMan: [
          { required: true, message: "请输入旅游联系人", trigger: "blur" },
          {
            pattern: /^([a-zA-Z\u4e00-\u9fa5]+)$/,
            message: "仅可使用中文和字母",
            trigger: "blur",
          },
        ],
        travelLinkedPhone: [
          { required: true, message: "请输入旅游联系号码", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
      },

      limitType: 1,

      versionList: [
        { btocVersion: "1", versionName: "单门店" },
        { btocVersion: "2", versionName: "多门店" },
      ],
    };
  },
  activated() {
    this.req_detail();
  },
  methods: {
    req_detail() {
      this.loading = true;
      const { id: buyerId } = this.$route.query;
      web_btoc_apply({ buyerId })
        .then((result) => {
          this.detail = { ...result, ...result.aptitude };
          history_tag_api.modify_tag_name_by_route({
            route: this.$route,
            tag_name: `${this.detail.companyName}-开通微商城`,
          });
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    req_approve() {
      this.loading = true;
      const { buyerId } = this.detail;
      const {
        storeName,
        btocVersion,
        storeLimit,
        touristBusinessLicenseNumber,
        touristBusinessLicense,
        travelLinkedMan,
        travelLinkedPhone,
      } = this.form;
      let params = {
        platformOpen: true,
        status: 3,
        btocApplySumbitRequest: { buyerId, storeName, btocVersion },
        buyerAptitudeResult: {
          touristBusinessLicenseNumber,
          touristBusinessLicense,
          travelLinkedMan,
          travelLinkedPhone,
        },
      };
      if (btocVersion == 2) params.storeLimit = storeLimit;

      web_btoc_check_submit(params)
        .then((result) => {
          this.loading = false;
          this.$message.success("开通商城成功");
          this.onBack();
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    // 查看公司信息
    onCompanyDetail() {
      this.$router.push({
        path: "/admin/buyer-detail",
        query: {
          id: this.detail.buyerId,
        },
      });
    },

    // 许可证上传
    licenceBeforeUpload(file, name) {
      this.OssClient.multipartUpload({ file })
        .then((data) => {
          this.$set(this.form, name, data.url);
        })
        .catch(() => {
          this.$message.error("上传图片失败");
        })
        .finally(() => {
          this.$refs.form.validateField(name);
        });
    },
    change_btocVersion(val) {
      if (val == 1) this.form.storeLimit = "";
    },

    change_limitType(val) {
      if (val == 1) {
        this.form.storeLimit = "-1";
      } else if (val == 2) {
        this.form.storeLimit = "5";
      }
    },
    input_limit(val) {
      this.form.storeLimit = val.replace(/\D/g, "");
    },

    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.req_approve();
        } else {
          return false;
        }
      });
    },
    // 返回
    onBack() {
      history_tag_api.remove_tag_by_route({
        route: this.$route,
      });
      this.$router.replace({
        path: "/admin/buyer-list",
      });
    },
  },
};
