// 酒店批量导入线下订单-不入库仅展示 http://hoteltest.yinzhilv.com/doc.html#/%E9%85%92%E5%BA%97%E6%A8%A1%E5%9D%97/order-offline-controller/inprotFromExcelUsingPOST

const __request = require(`./__request/__request_contentType_multipart_form_data`);

const hotel_offlineOrder_inprotFromExcel = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/hotel/offlineOrder/inprotFromExcel',
        data: pParameter,
    };
    return __request(p);
};
module.exports = hotel_offlineOrder_inprotFromExcel;
