// web_buyer_updateStatus 设置采购商状态

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口地址：http://192.168.0.15:8080/doc.html#/所有接口/buyer-controller/updateBuyerStatusUsingPOST

export default function web_buyer_updateStatus(pParameter) {
  if (!pParameter) pParameter = {};
  const buyerId = pParameter.buyerId || ''
  const buyerStatus = pParameter.buyerStatus || ''
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/buyer/updateStatus'
  params.data = {
    buyerId,
    buyerStatus
  }
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}
