const __request = require(`@/lib/data-service/default/__request/__request_contentType_json`);

// 旅游订单，回填实际回款金额接口地址：http://192.168.0.9:8080/doc.html#/yinzhilv_travel/credit-account-controller/creditAmountBackSetUsingPOST

export default function web_common_credit_creditAmountBackSet(pParameter) {
  if (!pParameter) pParameter = {};
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/common/credit/creditAmountBackSet'
  params.data = pParameter
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}
