// web_circuit_addOrUpdate 线路添加或更新

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口地址：http://192.168.0.15:8080/doc.html#/所有接口/circuit-controller/addOrUpdateCircuitUsingPOST

export default function web_circuit_addOrUpdate(pParameter) {
  if (!pParameter) pParameter = {};
  const id = pParameter.id || ''
  const lineName = pParameter.lineName || ''
  const lineType = pParameter.lineType || ''
  const areaResult = pParameter.areaResult || ''
  const lineStatus = pParameter.lineStatus || ''
  const UpdateCircuitRequest = pParameter.UpdateCircuitRequest || ''

  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/circuit/addOrUpdate'
  params.data = {
    lineName,
    lineType,
    id,
    areaResult,
    lineStatus,
    UpdateCircuitRequest
  }
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}

