const __request = require(`./__request/__request_contentType_json`);

// 分销商申请
// http://tour-alpha.yinzhilv.com/doc.html#/yinzhilv_travel/btoc-controller/pendingCheckCountUsingPOST
const request = () => {

  const p = {
    method: 'post',
    urlSuffix: '/web/btoc/check/pendingNum',
    data: {}
  };
  return new Promise(function (resolve) {
    resolve(__request(p))
  })
};
module.exports = request;