// web_managerSupplier_exportExcel 导出Excel表

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口地址：http://192.168.0.15:8080/doc.html#/所有接口/manager-supplier-controller/exportSupplierUsingPOST_1

export default function web_managerSupplier_exportExcel(pParameter) {
  if (!pParameter) pParameter = {};
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/managerSupplier/exportExcel'
  params.data = pParameter
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
