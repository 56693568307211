import extend from 'extend';
import Q from 'q';
import _ from 'underscore';
import moment from 'moment';
import Client from "yinzhilv-websocket-server/src/WebSocketServer/Client";
import superheroes from "superheroes";

let aWebSocketServerClient;

const init = function () {

  const aWebSocketUri = global.appConfig[`yinzhilv-websocket-server`].uri;

  aWebSocketServerClient = new Client({
    info: {
      remarks: `${superheroes.random()}`,
    },
    WebSocketUri: aWebSocketUri,
  });
  global.aWebSocketServerClient = aWebSocketServerClient;
};


export default {
  init,
}
