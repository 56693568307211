import web_buyer_findOne from '@/lib/data-service/default/web_buyer_findOne'
import PreviewImage from '@/page/admin/supplier-management/component/previewImage/index.vue'
import addImgSuffix from '@/lib/common-service/add_img_suffix'
import FullImage from '@/page/admin/supplier-management/component/fullImage/index.vue'
export default {
  data () {
    return {
      loading: false,
      id: '',
      buyer: {},
      srcList: [],
      dialogFullPicVisible: false,
      fullPicSrc: ''
    }
  },
  components: {
    FullImage,
    PreviewImage
  },
  created () {
  },
  mounted () {

  },
  activated () {
    this.id = this.$route.query.id
    this.getBuyerDetail(this.id)
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {
    filterSupplierStatus(val) {
      if (val === '0') {
        return '待审核'
      } else if (val === '1') {
        return '审核失败'
      } else if (val === '2') {
        return '部分启用'
      } else if (val === '3') {
        return '启用'
      } else if (val === '4') {
        return '平台禁用'
      } else if (val === '5') {
        return '已删除'
      } else if (val === '6') {
        return '未签约'
      } else if (val === '7') {
        return '签约失败'
      } else if (val === '8') {
        return '待设置合同文件'
      } else {
        return ''
      }
    },
    filterAppType(val) {
      if (val === '1') {
        return '机票'
      } else if (val === '2') {
        return '旅游'
      } else {
        return '机票+旅游'
      }
    },
    filterCarryRules(val) {
      let type = ''
      if (val === 0) {
        type = '进位到元'
      } else {
        type = '进位到分'
      }
      return type
    },
    filterImg(val) {
      let result = addImgSuffix(val)
      return result
    }
  },
  methods: {
    picLoading() {
      let _this = this
      let picSetTimeOut = ''
      picSetTimeOut = setTimeout(() => {
        let formPicArr = document.getElementsByClassName('form-pic')
        let formPicStatus = []
        for (let i in formPicArr.length) {
          formPicStatus.push(-1)
        }
        formPicArr.forEach((value, index) => {
          let complete = value.complete
          formPicStatus[index] = complete ? 1 : -1
        })
        if (formPicStatus.indexOf(-1) >= 0) {
          this.picLoading()
        } else {
          _this.loading = false
        }
      }, 500)
    },
    getBuyerDetail(buyerId) {
      this.loading = true
      web_buyer_findOne({buyerId}).then(res => {
        this.buyer = res.result
        this.$nextTick(() => {
          this.picLoading()
        })
        // this.businessLicensePic = this.buyer.businessLicensePic
      }).catch(() => {
        this.loading = false
      })
    },
    showFullPic(val) {
      this.dialogFullPicVisible = true
      this.fullPicSrc = addImgSuffix(val)
    },
    back() {
      this.$router.go(-1)
    },
    downloadPDF() {
      let url = this.buyer.agreementFile
      window.open(url)
    },
  }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
