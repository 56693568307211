// web_managerSupplier_list

const __request = require(`@/lib/data-service/default/__request/__request_contentType_json`);

// 接口地址：

export default function web_managerSupplier_flightSupplier_list(pParameter) {
  if (!pParameter) pParameter = {};

  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/managerSupplier/flightSupplier/list'
  params.data = pParameter
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
