import import_latest_version_module from '@/component/admin-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/customer-difengyun-order-list',
            name: 'admin-customer-difengyun-order-list',
            component: () => import(/* webpackChunkName: "page-admin-customer-order-managment-difengyun-order-list" */ `@/page/admin/difengyun-order-management/difengyun-oder-management/index.vue`),
            meta: {
          data_for_admin_layout: {
                    data_for_history_tag: {
                              entity: {
                                        name: "笛风云订单列表"
                              }
                    },
                    data_for_breadcrumb: {
                              entity_list: [
                                        {
                                                  name: "客户订单管理"
                                        },
                                        {
                                                  name: "笛风云订单列表"
                                        }
                              ]
                    }
          }
},
        },
    ],
};