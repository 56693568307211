import biz_line_select from '@/lib/data-service/b2c/biz_line_select.js'
import biz_line_delete from '@/lib/data-service/b2c/biz_line_delete.js'

export default {
  data() {
    return {
      tableData: [],
      form: {
        buyerId: this.$route.query.id,
        currentPage: 1,
        pageSize: 10,
      },
      totalCount: 0,
    }
  },
  methods: {
    getTableData() {
      biz_line_select(this.form).then(data => {
        this.tableData = data.list
        this.totalCount = data.totalCount
      })
    },
    handleCurrentChange(page) {
      this.form.currentPage = page
      this.getTableData()
    },
    handleSizeChange(size) {
      this.form.currentPage = 1
      this.form.pageSize = size
      this.getTableData()
    },
    // 绑定路线
    onBindRoute() {
      this.$router.push({
        path: '/admin/buyer/mall-route-setting',
        query: this.$route.query,
      })
    },
    // table 平台服务费字段格式化
    serviceFeePercent(row) {
      let msg = '--'
      if (row.serviceFeeType === 1) {
        msg = `${row.serviceFeePercent}元`
      }
      if (row.serviceFeeType === 2) {
        msg = `${row.serviceFeePercent}%`
      }
      return msg
    },
    // table 订金收取方式字段格式化
    settleDepositTypeMsg(row) {
      let msg = '--'
      if (row.settleDepositType === 0) {
        msg = '全额'
      } else {
        msg = '订金'
      }
      return msg
    },
    // table 订金定额或比例字段格式化
    settleDeposit(row) {
      let msg = '--'
      if (row.settleDepositType === 1) {
        msg = `${row.settleDeposit}元`
      } else if (row.settleDepositType == 2) {
        msg = `${row.settleDeposit}%`
      }
      return msg
    },
    // table 压款定额或比例字段格式化
    pressAmount(row) {
      let msg = '--'
      if (row.pressAmountType === 1) {
        msg = `${row.pressAmount}元`
      } else if (row.pressAmountType === 2) {
        msg = `${row.pressAmount}%`
      }
      return msg
    },
    // 删除
    onDelete(row) {
      this.$confirm('是否删除此路线?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        biz_line_delete({ id: row.id })
          .then(() => {
            this.getTableData()
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          })
      })
    },
    // 编辑
    onEdit(row) {
      this.$router.push({
        path: '/admin/buyer/mall-route-setting/edit',
        query: {
          buyerId: this.$route.query.id,
          id: row.id,
        },
      })
    },
  },
  activated() {
    this.getTableData()
  },
}