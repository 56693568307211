import web_common_user_getDeveloper from '@/lib/data-service/default/web_common_user_getDeveloper';
import web_buyer_findAll from '@/lib/data-service/default/web_buyer_findAll';
import web_tourismFullReduction_statisticsTotal from '@/lib/data-service/default/web_tourismFullReduction_statisticsTotal';
import web_tourismFullReduction_close from '@/lib/data-service/default/web_tourismFullReduction_close';

import Pagination from '@/component/base-pagination/index.vue';
import moment from 'moment'

export default {
    components: {
        Pagination
    },
    data() {
        return {
            loading: false,
            moment: moment,
            params: {
                userName: '',
                buyerType: '',
                companyName: '',
                phone: '',
                accountManagerId: '',
                activeName: '',
                offerStatus: '',

                currentPage: 1,
                pageSize: 10,
                total: 0,
                userStatus: '',
                realStatus: '',
                orderByLastLoginTime: '', // 最后登录时间排序填值: asc:升序,desc:降序,不需要为空即可
                orderByGmtCreate: '', // 创建时间排序填值: asc:升序,desc:降序,不需要为空即可
            },
            pager: {
                pageSizes: [10, 20, 30, 40, 50],
                totalCount: 0
            },
            userTypeList: [{value: '', label: '全部'}, {value: 1, label: '机票'}, {value: 2, label: '旅游'}, {value: '1+2', label: '机票+旅游'}, {value: 3, label: '商务代理'}],
            offerStatusList: [{value: '', label: '全部'}, {value: 1, label: '有效'}, {value: 0, label: '无效'}],
            developerList: [],
            tableList: [],
            statisticsTotal: {
                openReductionBuyerNum: 0,
                usedLimitAmountTotal: 0,
                isEffectiveLimitAmountTotal: 0
            }
        }
    },
    methods: {
        // 格式化数据
        carryRulesFormatter(val) {
            let type = '';
            if (val.carryRules === 0) {
                type = '进位到元'
            } else if (val.carryRules === 1) {
                type = '进位到分'
            } else {
                type = '--'
            }
            return type
        },
        buyerStatusFormatter(val) {
            let type = '';
            if (val.buyerStatus === 0) {
                type = '待审核'
            } else if (val.buyerStatus === 1) {
                type = '审核失败'
            } else if (val.buyerStatus === 2) {
                type = '部分启用'
            } else if (val.buyerStatus === 3) {
                type = '启用'
            } else if (val.buyerStatus === 4) {
                type = '平台禁用'
            } else if (val.buyerStatus === 5) {
                type = '已删除'
            } else if (val.buyerStatus === 6) {
                type = '未签约'
            } else if (val.buyerStatus === 7) {
                type = '签约失败'
            } else if (val.buyerStatus === 8) {
                type = '待设置合同文件'
            } else if (val.buyerStatus === 9) {
                type = '平台禁用'
            } else {
                type = '--'
            }
            return type
        },
        realStatusFormatter(val) {
            let type = '';
            if (val.realStatus === 0) {
                type = '未实名'
            } else if (val.realStatus === 1) {
                type = '已实名'
            } else {
                type = ''
            }
            return type
        },
        offerStatusFormatter(val) {
            let type = '';
            if (val.offerStatus === 0 || val.offerStatus === '0') {
                type = '无效'
            } else if (val.offerStatus === 1 || val.offerStatus === '1') {
                type = '有效'
            } else {
                type = '--'
            }
            return type
        },
        // 格式化数据 end

        // 获取table数据
        getTableList() {
            this.loading = true;
            web_buyer_findAll(this.params).then((res) => {
                let userList = res.pageResult.pageData;
                userList.forEach((value) => {
                    if (value.lastLoginTime === null) {
                        value.lastLoginTime = ''
                    }
                });
                this.tableList = userList;
                this.params.total = res.pageResult.totalCount;
                this.loading = false
            }).catch(() => {
                this.loading = false
            }, new Error(() => {
                this.loading = false
            }));
        },
        // 获取table数据 end
        getStatisticsTotal() { // 获取已开通优惠分销商数量，累计使用总额和当前生效总额
            web_tourismFullReduction_statisticsTotal().then((res) => {
                if(res.code === '000000') {
                    this.statisticsTotal.openReductionBuyerNum = res.openReductionBuyerNum;
                    this.statisticsTotal.usedLimitAmountTotal = res.usedLimitAmountTotal;
                    this.statisticsTotal.isEffectiveLimitAmountTotal = res.isEffectiveLimitAmountTotal;
                }
            });
        },

        onSearch() {
            this.params.currentPage = 1;
            this.getTableList()
        },
        onReset() {
            this.$refs['buyerTable'].clearSort();
            this.params = {
                userName: '',
                buyerType: '',
                companyName: '',
                phone: '',
                accountManagerId: '',
                activeName: '',
                offerStatus: '',

                currentPage: 1,
                pageSize: 10,
                total: 0,
                userStatus: '',
                realStatus: '',
                orderByLastLoginTime: '', // 最后登录时间排序填值: asc:升序,desc:降序,不需要为空即可
                orderByGmtCreate: '', // 创建时间排序填值: asc:升序,desc:降序,不需要为空即可
            };
            this.onSearch()
        },

        // 获取开发者列表 - （机票经理）
        getDeveloperList() {
            web_common_user_getDeveloper().then((res) => {
                this.developerList = res.getAllDeveloperResult
            });
        },
        // 分页
        handleSizeChange(pageSize) {
            this.params.pageSize = pageSize;
            this.req_order_list(this.params, 'pageSize');
        },
        handleCurrentChange(currentPage) {
            this.params.currentPage = currentPage;
            this.req_order_list(this.params, 'currentPage');
        },
        to_discounts_detail (type, row) { // 优惠详情
            const __this = this;
            __this.$router.push({
                name: 'admin-discounts-detail',
                query: {
                    type: type,
                    buyerId: row.buyerId
                }
            })
        },
        close_discount(row) { // 关闭优惠
            const __this = this;
            const query = {
                companyId: row.buyerId,
            };
            __this.$confirm('确认关闭优惠吗？', '确认关闭', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                web_tourismFullReduction_close(query).then((res) => {
                    if (res.code === '000000') {
                        __this.$message({showClose: true, message: '关闭优惠成功', type: 'success',
                            onClose:()=>{
                                __this.onSearch();
                            }
                        });
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            }).catch(() => {

            });
        }
    },
    mounted() {
        const __this = this;
    },
    activated() {
        this.getDeveloperList();
        this.getStatisticsTotal();
        this.getTableList();
    },
    filters: {
        filterTravelDepositType(val) {
            if (val === 0 || val === null) {
                return '全额'
            } else {
                return '订金'
            }
        },
        filterTravelDepositTypeFun(val) {
            if (val === 0) {
                return ''
            } else if (val === 1) {
                return '比例'
            } else {
                return '定额'
            }
        }
    }
}