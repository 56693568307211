// wallet_account_initAccount 初始化分销商钱包账户

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口地址：http://192.168.0.15:8080/doc.html#/所有接口/wallet-account-controller/initAccountUsingPOST

export default function wallet_account_initAccount(pParameter) {
  if (!pParameter) pParameter = {};
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/wallet/account/initAccount'
  params.data = pParameter
  // params.process_response_data = false;
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}

