const __request = require(`@/lib/data-service/statistics/__request/__request_contentType_json`);

// 禁用用接口地址：http://192.168.0.11:8080/swagger-ui.html#/personage-controller/deletePersonageUsingPOST

export default function get_total_amount(pParameter) {
  if (!pParameter) pParameter = {};
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/getTotalAmount'
  params.data = pParameter
  params.process_response_data = false
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}
