import detail from "@/lib/data-service/hotel/hotel_manager_base_queryProductsByHotelId";
import info from "@/lib/data-service/hotel/hotel_manager_base_queryHotelInfo";

export default {
    name: "orderAmount",
    data() {
        return {
            tableData: [],
            detail: {}
        };
    },
    methods: {
        getDetail(id) {
            info({ hotelId: id }).then(res => {
                this.detail = res.hotelInfoResult;
            });
        }
    },
    created() {
        let hotelId = this.$route.query.hotelId;
        this.getDetail(hotelId);
        detail({ hotelId }).then(res => {
            console.log(res)
        })
    }
};