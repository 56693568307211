// web_buyer_register 平台添加采购商

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口文档地址： http://192.168.0.15:8080/doc.html#/所有接口/buyer-controller/platformSupplierUsingPOST
export default function web_buyer_register(pParameter) {
  let params = {}
  if (!pParameter) pParameter = {};
  /*const userName = pParameter.userName || ``
  const password = pParameter.password || ``
  const phone = pParameter.phone || ''
  const companyName = pParameter.companyName || ''
  const areaRegion = pParameter.areaRegion || ''
  const areaProvince = pParameter.areaProvince || ''
  const areaCity = pParameter.areaCity || ''
  const areaRegionName = pParameter.areaRegionName || '' // 公司所在大区名字
  const areaProvinceName = pParameter.areaProvinceName || '' // 公司所在省份名字
  const areaCityName = pParameter.areaCityName || '' // 公司所在城市名字
  const companyAddress = pParameter.companyAddress || ''
  const businessLicenseNum = pParameter.businessLicenseNum || ''
  const businessLicensePic = pParameter.businessLicensePic || ''
  const idCard = pParameter.idCard || ''
  const idCardFront = pParameter.idCardFront || ''
  const idCardReverse = pParameter.idCardReverse || ''
  const financeFax = pParameter.financeFax || ''
  const travelLinkman = pParameter.travelLinkman || ''
  const travelPhone = pParameter.travelPhone || ''
  const travelQq = pParameter.travelQq || ''
  const travelEmail = pParameter.travelEmail || ''
  const airLinkman = pParameter.airLinkman || ''
  const airPhone = pParameter.airPhone || ''
  const airEmail = pParameter.airEmail || ''
  const airQq = pParameter.airQq || ''
  const developerId = pParameter.developerId || ''
  const developer = pParameter.developer || ''
  const officeId = pParameter.officeId || ''
  const accountManagerId = pParameter.accountManagerId || ''
  const accountManager = pParameter.accountManager || ''
  const isOperationFlow = pParameter.isOperationFlow || 0

  const buyerType = pParameter.buyerType || '1'
  const touristBusinessLicenseNumber = pParameter.touristBusinessLicenseNumber || ''
  const touristBusinessLicense = pParameter.touristBusinessLicense || ''
  const commonSeal = pParameter.commonSeal || ''
  const travelAccountManagerId = pParameter.travelAccountManagerId || ''
  const travelAccountManager = pParameter.travelAccountManager || ''
  const carryRules = pParameter.carryRules || 0
  const attachAmountType = pParameter.attachAmountType || 0
  const attachAdultAmount = pParameter.attachAdultAmount || ''
  const attachChildBedAmount = pParameter.attachChildBedAmount || ''
  const attachChildAmount = pParameter.attachChildAmount || ''
  const attachAgedAmount = pParameter.attachAgedAmount || ''*/
  params.method = 'POST'
  params.urlSuffix = '/web/buyer/register'
  params.data = pParameter
  /*params.data = {
    userName,
    password,
    phone,
    idCard,
    companyName,
    areaRegion,
    areaProvince,
    areaCity,
    areaRegionName, // 公司所在大区名字
    areaProvinceName, // 公司所在省份名字
    areaCityName, // 公司所在城市名字
    companyAddress,
    financeFax,
    businessLicenseNum,
    businessLicensePic,
    idCardFront,
    idCardReverse,

    buyerType,

    officeId,
    airLinkman,
    airPhone,
    airEmail,
    airQq,
    accountManagerId, // 机票经理
    accountManager,

    touristBusinessLicenseNumber,
    touristBusinessLicense,
    commonSeal,
    travelLinkman,
    travelPhone,
    travelEmail,
    travelQq,
    travelAccountManagerId, // 旅游经理
    travelAccountManager,

    developerId,
    developer,
    carryRules,
    isOperationFlow,

    attachAmountType,
    attachAdultAmount,
    attachChildBedAmount,
    attachChildAmount,
    attachAgedAmount
  }*/
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
