

const __request = require(`@/lib/data-service/default/__request/__request_contentType_json`);

// 禁用用接口地址：http://192.168.0.11:8080/swagger-ui.html#/personage-controller/disablePersonageUsingPOST

export default function web_personage_disablePersonage(pParameter) {
  if (!pParameter) pParameter = {};
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/personage/disablePersonage'
  params.data = pParameter
  params.process_response_data = false
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}
