import moment from "moment";
import Pagination from '@/component/base-pagination/index.vue'
import web_buyer_findOne from '@/lib/data-service/default/web_buyer_findOne'; // 客户资料
import web_credit_creditAccountSurvey from '@/lib/data-service/default/web_credit_creditAccountSurvey'; // 授信概况
import web_credit_billAndDetailList from '@/lib/data-service/default/web_credit_billAndDetailList'; // 授信订单
import web_credit_creditFlow from '@/lib/data-service/default/web_credit_creditFlow'; // 授信流水
import web_credit_dealStatistic from '@/lib/data-service/default/web_credit_dealStatistic'; // 查询交易情况


export default {
    data() {
        return {
            loading: false,
            details: {},
            creditSurvey: {},

            tableLoading: false,
            creditParams: {
                companyId: '',
                isGiveOut: '',
                isOverdue: '',
                repayStatus: '',
                tradingHourEnd: '',
                tradingHourStart: '',
                businessType: '',

                currentPage: 1,
                pageSize: 10,
                totalCount: 0
            },
            orderList: [],
            tradingHour: [],

            flowLoading: false,
            flowParams: {
                tradingHourEnd: '',
                tradingHourStart: '',
                flowType: 0, // 交易类型:1.购物，2.还款，3.退款
                businessType: 0, // 业务类型：1.机票，2.旅游，3.酒店，99.其他

                currentPage: 1,
                pageSize: 10,
                totalCount: 0
            },
            flowList: [],
            flowTradingHour: [],

            allocation: {
                flowTypeList: [
                    { label: '全部', value: 0 },
                    { label: '购买', value: 1 },
                    { label: '退款', value: 3 },
                    { label: '还款', value: 2 },
                ],
                businessTypeList: [
                    { label: '全部', value: 0 },
                    { label: '机票', value: 1 },
                    { label: '旅游', value: 2 },
                    { label: '其他', value: 99 },
                ],
            },

            dealStatistic: {}, // 交易情况

            timer: null,
        }
    },
    components: {
        Pagination
    },
    methods: {
        getFindOne() { // 客户资料
            const __this = this;
            __this.loading = true;
            web_buyer_findOne({ buyerId: __this.$route.query.companyId }).then(res => {
                __this.loading = false;
                __this.details = res.result;
            }).catch(err => {
                __this.loading = false;
            }).finally(() => {
                __this.loading = false;
            })
        },
        getCreditDetail() {
            const __this = this;
            __this.loading = true;
            web_credit_creditAccountSurvey({ query: Number(__this.$route.query.companyId) }).then(res => {
                __this.loading = false;
                const creditSurveyObj = res;
                creditSurveyObj.bilSurveyType.forEach(item => {
                    if (item.type === 1 || item.type === '1') { // 综合
                        creditSurveyObj.allBill = item;
                    }
                    if (item.type === 2 || item.type === '2') { // 机票
                        creditSurveyObj.filghtBill = item;
                    }
                });
                __this.creditSurvey = creditSurveyObj;
            }).catch(err => {
                __this.loading = false;
            }).finally(() => {
                __this.loading = false;
            })
        },
        billAndDetailList() { // 授信订单
            const __this = this;
            __this.creditParams.companyId = Number(__this.$route.query.companyId);
            __this.tableLoading = true;
            web_credit_billAndDetailList(__this.creditParams).then(res => {
                __this.tableLoading = false;
                __this.orderList = res.result;
                this.countDown();
                __this.creditParams.totalCount = res.total;
            }).catch(err => {
                __this.tableLoading = false;
            }).finally(() => {
                __this.tableLoading = false;
            })
        },
        countDown() {
            if (this.timer) {
                clearInterval(this.timer);
                this.timer = null;
            }
            this.timer = setInterval(() => {
                this.orderList = this.orderList.map(item => {
                    if (item.refundDate > 0) {
                        item.refundDate -= 1000;
                    } else {
                        item.refundDate = 0;
                    }
                    return item;
                });
            }, 1000);
        },
        onSearch() {
            const __this = this;
            __this.creditParams.currentPage = 1;
            __this.billAndDetailList();
        },
        onReset() {
            const __this = this;
            __this.creditParams = {
                companyId: Number(__this.$route.query.companyId),
                isGiveOut: '',
                isOverdue: '',
                repayStatus: '',
                tradingHourEnd: "",
                tradingHourStart: "",
                businessType: '',

                currentPage: 1,
                pageSize: 10,
                totalCount: 0
            };
            __this.tradingHour = [];
            __this.onSearch();
        },
        handleSizeChange(pages) {
            const __this = this;
            __this.creditParams.pageSize = pages;
            __this.creditParams.currentPage = 1;
            __this.billAndDetailList();
        },
        handleCurrentChange(page) {
            const __this = this;
            __this.creditParams.currentPage = page;
            __this.billAndDetailList();
        },

        getCreditFlowList() { // 授信流水
            const __this = this;
            __this.flowParams.companyId = Number(__this.$route.query.companyId);
            __this.flowLoading = true;
            web_credit_creditFlow(__this.flowParams).then(res => {
                __this.flowLoading = false;
                __this.flowList = res.pageResult.pageData;
                __this.flowParams.totalCount = res.pageResult.totalCount
            }).catch(err => {
                __this.flowLoading = false;
            }).finally(() => {
                __this.flowLoading = false;
            })
        },
        flowSearch() {
            const __this = this;
            __this.flowParams.currentPage = 1;
            __this.getCreditFlowList();
        },
        flowReset() {
            const __this = this;
            __this.flowParams = {
                tradingHourEnd: '',
                tradingHourStart: '',
                flowType: '', // 交易类型:1.购物，2.还款，3.退款
                businessType: '', // 业务类型：1.机票，2.旅游，3.酒店，99.其他

                currentPage: 1,
                pageSize: 10,
                totalCount: 0
            };
            __this.flowTradingHour = [];
            __this.flowSearch();
        },
        flowHandleSizeChange(pages) {
            const __this = this;
            __this.flowParams.pageSize = pages;
            __this.flowParams.currentPage = 1;
            __this.getCreditFlowList();
        },
        flowHandleCurrentChange(page) {
            const __this = this;
            __this.flowParams.currentPage = page;
            __this.getCreditFlowList();
        },

        getDealStatistic() { // 交易情况
            const __this = this;
            web_credit_dealStatistic({ companyId: Number(__this.$route.query.companyId) }).then(res => {
                const dealStatisticObj = res;
                dealStatisticObj.dealStatisticResultList.forEach(item => {
                    if (item.businessType === 1) { // 机票
                        dealStatisticObj.filghtStatistics = item;
                    }
                    if (item.businessType === 2) { // 旅游
                        dealStatisticObj.travelStatistics = item;
                    }
                    if (item.businessType === 3) { // 酒店
                        dealStatisticObj.hotelStatistics = item;
                    }
                    if (item.businessType === 4) { // 火车票
                        dealStatisticObj.trainStatistics = item;
                    }
                    if (item.businessType === 5) { // 门票
                        dealStatisticObj.ticketStatistics = item;
                    }
                });
                __this.dealStatistic = dealStatisticObj;
            })
        }
    },
    activated() {
        const __this = this;
        __this.getFindOne();
        __this.getCreditDetail();
        __this.billAndDetailList();
        __this.getCreditFlowList();
        __this.getDealStatistic();
    },
    watch: {
        tradingHour(newVal) {
            const __this = this;
            __this.creditParams.tradingHourStart = newVal[0];
            __this.creditParams.tradingHourEnd = newVal[1];
        },
        flowTradingHour(newVal) {
            const __this = this;
            __this.flowParams.tradingHourStart = newVal[0];
            __this.flowParams.tradingHourEnd = newVal[1];
        },
    },
    filters: {
        filterDate(val) {
            return val ? moment(val).format("YYYY-MM-DD") : val;
        },
        formatCountDown(val) {
            val = val - Date.now();
            if (val > 0) {
                let hours = Math.floor(val / 1000 / 60 / 60 % 24); // 获取小时
                let minutes = Math.floor(val / 1000 / 60 % 60); //获取分钟数
                let seconds = Math.floor(val / 1000 % 60); //获取秒数

                hours < 10 ? hours = '0' + hours : hours;
                minutes < 10 ? minutes = '0' + minutes : minutes;
                seconds < 10 ? seconds = '0' + seconds : seconds;

                let rels = `${hours}:${minutes}:${seconds}`;

                let mis = val > 0 ? rels : '已超时';

                return mis;
            } else {
                return '已超时'
            }
        },
    },
    beforeDestroy() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    },
}
