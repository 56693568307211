// web_managerSupplier_notAllPass 查询审核失败和部分启用供应商

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口地址：http://192.168.0.15:8080/doc.html#/所有接口/manager-supplier-controller/getUncheckedSupplierUsingPOST

export default function web_managerSupplier_notAllPass(pParameter) {
  if (!pParameter) pParameter = {};
  const currentPage = pParameter.currentPage || 1
  const pageSize = pParameter.pageSize || 10
  const userName = pParameter.userName || ''
  const companyName = pParameter.companyName || ''
  const cityId = pParameter.cityId || ''
  const regionId = pParameter.regionId || ''
  const provinceId = pParameter.provinceId || ''
  const applicationType = pParameter.applicationType || ''
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/managerSupplier/notAllPass'
  params.data = {
    currentPage,
    pageSize,
    userName,
    companyName,
    cityId,
    regionId,
    provinceId,
    applicationType
  }
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}
