import UploadImage from '@/page/admin/supplier-management/component/uploadImage/index.vue'
import web_common_area_getInlandAreaChildListByParentId from '@/lib/data-service/default/web_common_area_getInlandAreaChildListByParentId'
import web_common_user_getDeveloper from '@/lib/data-service/default/web_common_user_getDeveloper'
import web_buyer_register from '@/lib/data-service/default/web_buyer_register'
import web_common_user_vueCheckUserName from '@/lib/data-service/default/web_common_user_vueCheckUserName'
import getDefaultRebate from '@/lib/data-service/default/web_buyer_flightBuyer_getDefaultRebate'
import findPaymentChannel from '@/lib/data-service/default/web_buyer_flightBuyer_findPaymentChannel'
import web_buyer_flightBuyer_findWithHoldType from '@/lib/data-service/default/web_buyer_flightBuyer_findWithHoldType'
import web_buyer_flightBuyer_findCarryRule from '@/lib/data-service/default/web_buyer_flightBuyer_findCarryRule'

import is_mobile from 'yinzhilv-js-sdk/src/common-service/other/is_mobile';
import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client";
export default {
  data () {
    const validateUserName = (rule, value, callback) => {
      const regExp = /^\w+$/
      if (regExp.test(value)) {
        web_common_user_vueCheckUserName({userName: value}).then(res => {
          if (res.code === '000000' && res.flag === true) {
            callback()
          } else if (res.code === '000000' && res.flag === false) {
            callback(new Error('用户已存在不可使用'))
          } else {
            callback(new Error(res.msg))
          }
        }).catch((err) => {
          callback(new Error(err.data.msg))
        })
      } else {
        callback(new Error('只能输入字母/数字/下划线'))
      }
    };
    const validateLicenseNumber = (rule, value, callback) => {
          const regExp = /^\w+$/
          if (regExp.test(value)) {
            callback()
          } else {
            callback(new Error('只能输入字母/数字/下划线'))
          }
    }
    const validatePassword = (rule, value, callback) => {
      const regExp = /^[A-Za-z0-9]+$/
      if (regExp.test(value)) {
        callback()
      } else {
        callback(new Error('只能输入字母/数字'))
      }
    };
    const validateSubmitPassword = (rule, value, callback) => {
      if (value === this.params.password) {
        callback()
      } else {
        callback(new Error('与密码不一致'))
      }
    };
    const validatePhone = (rule, value, callback) => {
      if (is_mobile(value)) {
        callback()
      } else {
        callback(new Error('不符合手机号码格式'))
      }
    };
    const validateCompanyName = (rule, value, callback) => {
      const regExp = /^(?!_)(?!.*?_$)[a-zA-Z0-9_\u4e00-\u9fa5]+$/
      if (regExp.test(value)) {
        callback()
      } else {
        callback(new Error('只能输入字母/中文/数字/下划线'))
      }
    };
    const validateIdBusinessLicensePic = (rule, value, callback) => {
      if (this.params.businessLicensePic === '') {
        callback(new Error('请传入营业执照照片'))
      } else {
        callback()
      }
    };
    const validateIdCardFront = (rule, value, callback) => {
      if (this.params.idCardFront === '') {
        callback(new Error('请传入法人代表身份证(正面)'))
      } else {
        callback()
      }
    };
    const validateIdCardReverse = (rule, value, callback) => {
      if (this.params.idCardReverse === '') {
        callback(new Error('请传入法人代表身份证(反面)'))
      } else {
        callback()
      }
    };
    const validateTravelBusinessLicense = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请传入旅游经营许可证照片'))
      } else {
        callback()
      }
    };
    const validateOfficeID = (rule, value, callback) => {
      if (!value) {
        callback()
        return;
      }
      const regExp = /^([a-z_A-Z-0-9]+)$/
      if (regExp.test(value)) {
        callback()
        return;
      }
      callback(new Error('OfficeID格式不正确'))
    };
    const validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback()
        return
      }
      const regExp = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
      if (regExp.test(value)) {
        callback()
      } else {
        callback(new Error('邮件格式不正确'))
      }
    };
    const validateQQ = (rule, value, callback) => {
      if (value === '') {
        callback()
        return
      }
      const regExp = /^[1-9][0-9]{4,9}$/gim
      if (regExp.test(value)) {
        callback()
      } else {
        callback(new Error('QQ格式不正确'))
      }
    };
    const validateAmount = (rule, value, callback) => {
      if (this.params.attachAmountType !== 0) {
        if (value === '') {
          callback(new Error('不能为空'))
        } else {
          if (value >= 0) {
            callback()
          } else  {
            callback(new Error('不能为负数'))
          }
        }
      } else {
        callback()
      }
    };
    const validateIDCard = (rule, value, callback) => {
      const regIdCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (!regIdCard.test(value)) {
        callback(new Error('身份证号填写有误'))
      } else {
        callback()
      }
    };
    const validateIncreaseDepositRate = (rule, value, callback) => {
      if (value >= 0) {
        callback()
      } else {
        callback(new Error('不能为负数'))
      }
    };
    const validateBusinessLicenseNum = (rule, value, callback) => {
      if (value.indexOf(' ') >= 0) {
        callback(new Error('不能输入空格'))
      } else {
        const reg = /^([159Y]{1})([1239]{1})([0-9ABCDEFGHJKLMNPQRTUWXY]{6})([0-9ABCDEFGHJKLMNPQRTUWXY]{9})([0-9ABCDEFGHJKLMNPQRTUWXY])$/;
        if (!value) {
          return callback(new Error("请输入营业执照"));
        } else if (!reg.test(value)) {
          callback(new Error("请输入正确的营业执照"));
        } else {
          callback();
        }
      }
    };
    return {
      loading: false,
      params: {
        userName: '',
        password: '',
        submitPassword: '',
        phone: '',

        companyName: '',
        areaRegion: '',
        areaProvince: '',
        areaCity: '',
        areaRegionName: '', // 公司所在大区名字
        areaProvinceName: '', // 公司所在省份名字
        areaCityName: '', // 公司所在城市名字
        companyAddress: '',
        financeFax: '',
        financeFaxTit: '',
        financeFaxNum: '',
        businessLicenseNum: '',
        businessLicensePic: '',
        idCard: '',
        idCardFront: '',
        idCardReverse: '',
        businessLicenseArr: [],
        idCardFrontArr: [],
        idCardReverseArr: [],

        buyerType: '1',

        officeId: '',
        airLinkman: '',
        airPhone: '',
        airEmail: '',
        airQq: '',
        accountManagerId: '', // 机票经理
        accountManager: '',

        touristBusinessLicenseNumber: '',
        touristBusinessLicense: '',
        commonSeal: '',
        touristBusinessLicenseArr: [],
        commonSealArr: [],
        travelLinkman: '',
        travelPhone: '',
        travelEmail: '',
        travelQq: '',
        travelAccountManagerId: '', // 旅游经理
        travelAccountManager: '',

        developerId: '',
        developer: '',
        carryRules: 0,
        isOperationFlow: 0,

        rebate: 0,//返点
        specialRebate: 0,//机票特殊政策返点
        paymentChannelTypeList: [1,2],//支付方式类型，1.余额支付，2.在线收单

        attachAmountType: 0,
        attachAdultAmount: '',
        attachChildBedAmount: '',
        attachChildAmount: '',
        attachAgedAmount: '',

        // travelDepositType: 0, // 订金 0-不收取 1比例 2定额
        finalCycle: '', // 尾款周期，天数
        increaseDepositRate: '', // 定金增加比
        isHasDeposit: 0, // 是否收订金 0-不收 1收 自增
        depositType: 1, // 收取方式 1-比例 2-定额 自增
        finalCycleType: 1, // 尾期类型 1-出团前

        agreementFile: '',

        carryRule: 1, // 说明：进位规则，机票专用(1.进位到元 2.进位到分)
        yeePayAccount: '',
        withHoldType: 1, // 说明：代扣关系 1：未配置 2：已配置

      },
      rule: {
        userName: [
          {required: true, message: '请输入账户名称', trigger: 'blur'},
          {min: 4, max: 16, message: '长度在4~16个字符', trigger: 'blur'},
          {validator: validateUserName, trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 6, max: 20, message: '长度在6~20个字符', trigger: 'blur'},
          {validator: validatePassword, trigger: 'blur'}
        ],
        submitPassword: [
          {required: true, message: '请再次输入密码', trigger: 'blur'},
          {validator: validateSubmitPassword, trigger: 'blur'}
        ],
        phone: [
          {required: true, message: '请输入手机号码', trigger: 'blur'},
          {max: 11, message: '不符合手机号码长度', trigger: 'blur'},
          {validator: validatePhone, trigger: 'blur'}
        ],
        companyName: [
          {required: true, message: '请输入公司全称', trigger: 'blur'},
          {max: 30, message: '不符合公司名称长度', trigger: 'blur'},
          {validator: validateCompanyName, trigger: 'blur'}
        ],
        areaCity: [
          {required: true, message: '请填入所属大区', trigger: 'blur'},
        ],
        companyAddress: [
          {required: true, message: '请输入公司地址', trigger: 'blur'},
          {max: 30, message: '不符合公司地址长度', trigger: 'blur'},
          // {validator: validateCompanyName, trigger: 'blur'}
        ],
        businessLicenseNum: [
          {required: true, message: '请输入统一社会信用代码', trigger: 'blur'},
          {validator: validateBusinessLicenseNum, trigger: 'blur'}
        ],
        /*idCardFrontArr: [
          {required: true, validator: validateIdCardFrontArr, trigger: 'change'}
        ],*/
        businessLicensePic: [
          {required: true, validator: validateIdBusinessLicensePic}
        ],
        idCard: [
          {required: true, message: '请输入法人身份证号码', trigger: 'blur'},
          {validator: validateIDCard, trigger: 'blur'}
        ],
        idCardFront: [
          {required: true, validator: validateIdCardFront}
        ],
        idCardReverse: [
          {required: true, validator: validateIdCardReverse}
        ],
        buyerType: [
          {required: true, message: '请选择意向采购产品', trigger: 'change'},
        ],
        officeId: [
          {message: '请输入OfficeID', trigger: 'blur'},
          {max: 30, message: '不符合OfficeID长度', trigger: 'blur'},
          {validator: validateOfficeID, trigger: 'blur'}
        ],
        airLinkman: [
          {required: true, message: '请输入联系人', trigger: 'blur'},
          {max: 30, message: '不符合联系人长度', trigger: 'blur'}
        ],
        airPhone: [
          {required: true, message: '请输入手机号码', trigger: 'blur'},
          {max: 11, message: '不符合手机号码长度', trigger: 'blur'},
          {validator: validatePhone, trigger: 'blur'}
        ],
        airEmail: [
          {validator: validateEmail, trigger: 'blur'}
        ],
        airQq: [
          {validator: validateQQ, trigger: 'blur'}
        ],
        accountManagerId: [
          {required: true, message: '机票客户经理不能为空', trigger: 'change'}
        ],
        touristBusinessLicenseNumber: [
          {required: true, message: '请输入旅游经营许可证', trigger: 'blur'},
          {max: 30, message: '不符合旅游经营许可证长度', trigger: 'blur'},
          {validator: validateLicenseNumber, trigger: 'blur'}
        ],
        touristBusinessLicense: [
          {required: true, validator: validateTravelBusinessLicense}
        ],
        travelLinkman: [
          {required: true, message: '请输入联系人', trigger: 'blur'},
          {max: 30, message: '不符合联系人长度', trigger: 'blur'}
        ],
        travelPhone: [
          {required: true, message: '请输入手机号码', trigger: 'blur'},
          {max: 11, message: '不符合手机号码长度', trigger: 'blur'},
          {validator: validatePhone, trigger: 'blur'}
        ],
        travelEmail: [
          {validator: validateEmail, trigger: 'blur'}
        ],
        travelQq: [
          {validator: validateQQ, trigger: 'blur'}
        ],
        travelAccountManagerId: [
          {required: true, message: '旅游客户经理不能为空', trigger: 'change'}
        ],
        carryRules: [
          {required: true, message: '请选择进位规则', trigger: 'change'}
        ],
        isOperationFlow: [
          {required: true, message: '请选择是否操作流水', trigger: 'change'}
        ],
        paymentChannelTypeList: [
          {required: true, message: '请选择机票支付方式', trigger: 'change'}
        ],
        attachAdultAmount: [
          {validator: validateAmount, trigger: 'blur'}
        ],
        attachChildBedAmount: [
          {validator: validateAmount, trigger: 'blur'}
        ],
        attachChildAmount: [
          {validator: validateAmount, trigger: 'blur'}
        ],
        attachAgedAmount: [
          {validator: validateAmount, trigger: 'blur'}
        ],
        increaseDepositRate: [
          {required: true, message: '增幅不能为空', trigger: 'blur'},
          {validator: validateIncreaseDepositRate, trigger: 'blur'}
        ],
        finalCycle: [
          {required: true, message: '尾款周期天数不能为空', trigger: 'blur'},
          {validator: validateIncreaseDepositRate, trigger: 'blur'}
        ]
      },
      inLandList: [],
      provinceList: [],
      paymentChannelTypeList: [],
      cityList: [],
      developerList: [],
      fileImg: { show: false, url: '' },

      withHoldTypeList: [],
      findCarryRuleList: [],
    }
  },
  components: {
    UploadImage
  },
  created () {
  },
  mounted () {
    this.initAreaList()
    this.findWithHoldType()
    this.findCarryRule()
    this.getDeveloperList()
    this.getDefaultRebate()
    this.findPaymentChannel()
  },
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
    // 获取代扣关系枚举
    findWithHoldType() {
      web_buyer_flightBuyer_findWithHoldType().then(res => {
        this.withHoldTypeList = res.resultList
      })
    },
    // 获取进位规则枚举
    findCarryRule() {
      web_buyer_flightBuyer_findCarryRule().then(res => {
        this.findCarryRuleList = res.enumList
      })
    },
    // 获取开发者列表 - （机票经理）
    getDeveloperList() {
      web_common_user_getDeveloper().then((res) => {
        this.developerList = res.getAllDeveloperResult
      })
    },
    async initAreaList() {
      const params = {
        id: 0
      }
      let res = await web_common_area_getInlandAreaChildListByParentId(params)
      let id = res[0].id
      this.getInlandAreaChildList({id})
    },
    //获取机票分销默认返点
    async getDefaultRebate() {
      const res = await getDefaultRebate()
      this.params.rebate = res.rebate
      this.params.specialRebate = res.specialRebate
    },
    // 改变机票返点
    handleRebateChange(num) {
      const value = Number(num.replace(/[^\d|^\.|^\-]/gi, ""))
          ? Number(num.replace(/[^\d|^\.|^\-]/gi, ""))
          : 0
      if (value >= 100) {
        this.params.rebate = Number(100).toFixed(2)
      } else if (value <= -100) {
        this.params.rebate = Number(-100).toFixed(2)
      } else {
        this.params.rebate = value.toFixed(2)
      }
    },
    // 改变机票特殊政策返点
    handleSpecialRebateChange(num) {
      const value = Number(num.replace(/[^\d|^\.|^\-]/gi, ""))
          ? Number(num.replace(/[^\d|^\.|^\-]/gi, ""))
          : 0
      if (value >= 100) {
        this.params.specialRebate = Number(100).toFixed(2)
      } else if (value <= -100) {
        this.params.specialRebate = Number(-100).toFixed(2)
      } else {
        this.params.specialRebate = value.toFixed(2)
      }
    },
    //获取支付方式类型枚举
    async findPaymentChannel() {
      const res = await findPaymentChannel()
      this.paymentChannelTypeList = res.paymentChannelTypeList
    },
    // 改变机票支付方式
    handlePaymentChannelTypeListChange(value) {
      this.params.paymentChannelTypeList = value
    },
    // 获取地区
    async getInlandAreaChildList (params, index = 0) {
      let res = await web_common_area_getInlandAreaChildListByParentId(params)
      let inLandList = res
      inLandList.forEach(value => {
        value.value = value.id
        value.label = value.areaName
      })
      if (index === 0) {
        this.inLandList = res
      } else if (index === 1) {
        this.provinceList = res
      } else {
        this.cityList = res
      }
    },
    // 下拉大区改变时
    changeInLand(value) {
      if (value === '') {
        this.clearAreaList(2)
        this.params.areaRegionName = ''
      } else {
        for (let i = 0, l = this.inLandList.length; i < l; i++) {
          if (this.inLandList[i].id === value) {
            this.params.areaRegionName = this.inLandList[i].areaName
            break
          }
        }
        this.clearAreaList(2)
        this.getInlandAreaChildList({id: value}, 1)
      }
    },
    // 改变省份时
    changeProvince(value) {
      if (value === '') {
        this.clearAreaList(3)
        this.params.areaProvinceName = ''
      } else {
        for (let i = 0, l = this.provinceList.length; i < l; i++) {
          if (this.provinceList[i].id === value) {
            this.params.areaProvinceName = this.provinceList[i].areaName
            break
          }
        }
        this.clearAreaList(3)
        this.getInlandAreaChildList({id: value}, 2)
      }
    },
    // 改变城市时
    changeCity(value) {
      if (value === '') {
        this.params.areaCityName = ''
      } else {
        for (let i = 0, l = this.cityList.length; i < l; i++) {
          if (this.cityList[i].id === value) {
            this.params.areaCityName = this.cityList[i].areaName
            break
          }
        }
      }
    },
    clearAreaList(listNum) {
      for (let i = 1, l = 4; i < l; i++) {
        if (i === 1 && i >= listNum) {
          this.params.areaRegion = ''
        }
        if (i === 2 && i >= listNum) {
          this.params.areaProvince = ''
          this.provinceList = []
        }
        if (i === 3 && i >= listNum) {
          this.params.areaCity = ''
          this.cityList = []
        }
      }
    },
    setFax() {
      this.params.financeFax = `${this.params.financeFaxTit}-${this.params.financeFaxNum}`
    },
    //获取产品图片上传的地址
    uploadChange(file, name, from, arrListName, data) {
      this.params[arrListName] = [data.url]
      this.params[name] = data.url
    },
    handleRemoveImg(name, arrListName, from) {
      this.params[name] = ''
      this.params[arrListName] = []
    },
    handlePictureCardPreview(file) {
      this.fileImg.url = file
      this.fileImg.show = true
    },
    // 选择经理时
    changeAirAccountManager(value) {
      for (let i = 0, l = this.developerList.length; i < l; i++) {
        if (this.developerList[i].id === value) {
          this.params.accountManager = this.developerList[i].realName
          break
        }
      }
    },
    changeAccountManager(value) {
      for (let i = 0, l = this.developerList.length; i < l; i++) {
        if (this.developerList[i].id === value) {
          this.params.travelAccountManager = this.developerList[i].realName
          break
        }
      }
    },
    changeDeveloper(value) {
      for (let i = 0, l = this.developerList.length; i < l; i++) {
        if (this.developerList[i].id === value) {
          this.params.developer = this.developerList[i].realName
          break
        }
      }
    },
    submit() {
      let status
      let formDataList
      if (this.params.buyerType === '1') {
        status = [-1, -1, -1, -1, -1, -1]
        formDataList = ['form1', 'form2', 'form3', 'form4', 'form6','form7']
      } else if (this.params.buyerType === '2') {
        status = [-1, -1, -1, -1, -1]
        formDataList = ['form1', 'form2', 'form3', 'form5', 'form6']
      } else {
        status = [-1, -1, -1, -1, -1, -1, -1]
        formDataList = ['form1', 'form2', 'form3', 'form4', 'form5', 'form6', 'form7']
      }
      formDataList.map((value, index) => {
        this.$refs[value].validate((valid) => {
          valid ? status[index] = 1 : status[index] = -1
          if (status.indexOf(-1) === -1) {
            // 开始提交
            this.loading = true
            if (this.params.isHasDeposit === 0) {
              this.params.attachAmountType = 0
            } else {
              this.params.attachAmountType = this.params.depositType
            }
            web_buyer_register(this.params).then(res => {
              this.loading = false
              this.$alert('分销商创建成功', '温馨提示', {
                confirmButtonText: '确定',
                callback: action => {
                  // 跳转
                  this.$router.push({
                    name: 'admin-buyer-list'
                  })
                }
              })
            }).catch(() => {
              this.loading = false
            })
          }
        })
      })
    },
    filterBlank(label,val) {
      let str = val
      let params = this.params
      params[label] = str.replace(/\s+/g,'')
      this.params = params
    },
    handleSuccesspictureB(file, fileList) {
      const size = file.size / 1024 / 1024;
      if (size > 10) {
        return this.$message.error("文件大小不能超过10M");
      }
      const _this = this;
      const aOssClient = new OssClient();
      aOssClient.multipartUpload({
        file: file.raw,
        progress: function* (p) {

        }
      })
          .then(function (data) {
            _this.$message.success("文件上传成功");
            _this.params.agreementFile = data.url;
            _this.$refs.uploadB.clearValidate();
          })
    },
    removePDFB() {
      this.params.agreementFile = "";
    },
    openPDFB(file) {
      window.open(this.params.agreementFile);
    },
  }
}
