// web_managerSupplier_flightSupplier_auditList 查询待审核机票供应商

const __request = require(`./__request/__request_contentType_json`);

export default function web_managerSupplier_flightSupplier_auditList(pParameter) {
  if (!pParameter) pParameter = {};
  const currentPage = pParameter.currentPage || 1
  const pageSize = pParameter.pageSize || 10
  const userName = pParameter.userName || ''
  const companyName = pParameter.companyName || ''
  const cityId = pParameter.cityId || ''
  const regionId = pParameter.regionId || ''
  const provinceId = pParameter.provinceId || ''
  const applicationType = pParameter.applicationType || ''
  const supplierStatus = pParameter.supplierStatus || ''
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/managerSupplier/flightSupplier/auditList'
  params.data = {
    currentPage,
    pageSize,
    userName,
    companyName,
    cityId,
    regionId,
    provinceId,
    applicationType,
    supplierStatus
  }
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}
