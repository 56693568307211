import history_tag_api from '@/component/admin-layout/1.0.0/component/history-tag/api'
import rules from '../verification/mall-route-setting'
import web_circuit_findTouristTour from '@/lib/data-service/default/web_circuit_findTouristTour.js'
import biz_line_saveOrUpdate from '@/lib/data-service/b2c/biz_line_saveOrUpdate.js'

export default {
  data() {
    return {
      form: {
        lineId: null,
        attachType: 0,
        attachAmountType: 1,
        attachAdultAmount: 0,
        attachChildAmount: 0,
        attachChildBedAmount: 0,
        attachAgedAmount: 0,
        attachAdultPercent: 0,
        attachChildBedPercent: 0,
        attachChildPercent: 0,
        attachAgedPercent: 0,
        serviceFeeType: 0,
        serviceFeePercent: 0,
        whetherToCollect: 0, // 是否收取
        settleDepositType: 2,
        settleDeposit: 0,
        finalPaymentDays: 0,
        depositOrNot: 0, // 是否压款
        pressAmountType: 2,
        pressAmount: 0,
        presslPaymentCycle: 0,
      },
      rules: rules(this),
      selectList: [],
    }
  },
  methods: {
    getSelectList() {
      web_circuit_findTouristTour().then(data => {
        this.selectList = data.result
      })
    },
    onSubmit() {
      const validList = []
      this.$refs.form.validate((valid) => {
        validList.push(valid)
      })
      if (validList.some((item) => item === false)) {
        this.$message.error('检查是否有遗漏表单')
        return false
      }
      const lineItem = this.selectList.find(item => item.id === this.form.lineId)
      let body = {}
      body.buyerId = this.$route.query.id
      body.lineName = lineItem.lineName
      body.lineId = this.form.lineId
      body.attachType = this.form.attachType
      body.attachAmountType = this.form.attachAmountType
      if (body.attachType != 0) {
        if (body.attachAmountType === 1) {
          body.attachAdultAmount = this.form.attachAdultAmount
          body.attachChildAmount = this.form.attachChildAmount
          body.attachChildBedAmount = this.form.attachChildBedAmount
          body.attachAgedAmount = this.form.attachAgedAmount
        } else {
          body.attachAdultPercent = this.form.attachAdultPercent
          body.attachChildBedPercent = this.form.attachChildBedPercent
          body.attachChildPercent = this.form.attachChildPercent
          body.attachAgedPercent = this.form.attachAgedPercent
        }
      }
      body.serviceFeeType = this.form.serviceFeeType
      if (body.serviceFeeType != 0) {
        body.serviceFeePercent = this.form.serviceFeePercent
      }
      if (this.form.whetherToCollect === 0) {
        body.settleDepositType = 0
      } else {
        body.settleDepositType = this.form.settleDepositType
      }
      if (body.settleDepositType != 0) {
        body.settleDeposit = this.form.settleDeposit
        body.finalPaymentDays = this.form.finalPaymentDays
      }
      if (this.form.depositOrNot === 0) {
        body.pressAmountType = 0
      } else {
        body.pressAmountType = this.form.pressAmountType
      }
      if (body.pressAmountType != 0) {
        body.pressAmount = this.form.pressAmount
        body.presslPaymentCycle = this.form.presslPaymentCycle
      }
      biz_line_saveOrUpdate(body).then(() => {
        this.$message.success('添加成功');
        this.onBack();
      }).catch(() => {
        this.$message.error('添加失败');
      })
    },
    onBack() {
      history_tag_api.remove_tag_by_route({
        route: this.$route,
      });
      this.$router.replace({
        path: '/admin/buyer/mall-route-setting-list',
        query: { id: this.$route.query.id },
      })
    },
  },
  activated() {
    this.getSelectList()
  },
}

/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
