// web_managerSupplier_flightSupplier_setServiceFee 设置机票供应商技术服务费

const __request = require(`@/lib/data-service/default/__request/__request_contentType_json`);

// 接口地址：http://192.168.0.19:9999/doc.html#/yinzhilv_travel/manager-supplier-controller/setServiceFeeUsingPOST

export default function web_managerSupplier_flightSupplier_setServiceFee(pParameter) {
    let params = {}
    if (!pParameter) pParameter = {};
    params.method = 'POST'
    params.urlSuffix = '/web/managerSupplier/flightSupplier/setServiceFee'
    params.data = pParameter
    return new Promise(function (resolve, reject) {
        resolve(__request(params))
    })
}
