const __request = require(`./__request/__request_contentType_json`);

// 根据id删除广告：http://tour-beta.yinzhilv.com/doc.html#/yinzhilv_travel/advert-controller/deleteAdvertByIdUsingPOST
const web_common_advert_deleteAdvertById = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/web/common/advert/deleteAdvertById',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = web_common_advert_deleteAdvertById;