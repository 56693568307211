const Q = require('q');
const __request = require(`./__request/__request_contentType_json`);

const web_credit_getCreditAccount = function (pParameter) {
    if (!pParameter) pParameter = {};

    // 授信客户查询：http://tour-alpha.yinzhilv.com/doc.html#/yinzhilv_travel/credit-account-controller/getCreditAccountUsingPOST

    return Q.when()
        .then(function () {
            return __request({
                method: 'POST',
                urlSuffix: '/web/credit/getCreditAccount',
                data: pParameter,
            });
        })
        .catch(function (ex) {
            console.error(ex);
            if (ex) {
                if (ex.stack) {
                    console.error(ex.stack);
                }
            }
            return Q.reject(ex);
        });

};

module.exports = web_credit_getCreditAccount;