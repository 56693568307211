// web_buyer_getSupplierBoundBuyerList 查询分销商绑定关系和非绑定关系的供应商

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口文档地址： http://192.168.0.15:8080/doc.html#/所有接口/buyer-controller/getSupplierBoundBuyerListUsingPOST
export default function web_buyer_getSupplierBoundBuyerList(pParameter) {
  let params = {}
  if (!pParameter) pParameter = {};
  const id = pParameter.id || ''
  const isOperationFlow = pParameter.isOperationFlow || 0
  const currentPage = pParameter.currentPage || ''
  const pageSize = pParameter.pageSize || ''
  params.method = 'POST'
  params.urlSuffix = '/web/buyer/getSupplierBoundBuyerList'
  params.data = {
    id,
    isOperationFlow,
    currentPage,
    pageSize
  }
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
