const __request = require(`./__request/__request_contentType_json`);

// 平台轮播图列表：http://tour-beta.yinzhilv.com/doc.html#/yinzhilv_travel/advert-controller/listUsingPOST
const web_common_advert_list = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/web/common/advert/list',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = web_common_advert_list;