// web_common_user_updateUserStatus 可批量修改用户状态

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口地址：http://192.168.0.15:8080/doc.html#/所有接口/staff-management-controller/updateStaffUsingPOST

export default function web_common_user_updateUserStatus(pParameter) {
  if (!pParameter) pParameter = {};
  const ids = pParameter.ids || []
  const userStatus = pParameter.userStatus || ''

  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/user/updateUserStatus'
  params.data = {
    ids,
    userStatus
  }
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}
