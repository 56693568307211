import Q from "q";
import moment from 'moment';
// 改签详情
import trains_order_platformChangeOrderDetail from "@/lib/data-service/train/trains_order_platformChangeOrderDetail.js";

export default {
  data() {
    return {
      loading: false,
      detail: {},
      timer: null,
    };
  },
  methods: {
    req_detail(changeNo, orderNo) {
      clearInterval(this.timer);
      this.loading = true;
      const _this = this;
      Q.when()
        .then(function() {
          return trains_order_platformChangeOrderDetail({ changeNo, orderNo });
        })
        .then(function(res) {
          const detail = res;

          detail.inChangePassengerList.forEach(function(item) {
            item.gmtStart = moment(item.gmtStart).format('YYYY-MM-DD HH:mm');
            item.originalChangeStartDate = moment(item.originalChangeStartDate).format('YYYY-MM-DD HH:mm');

            if (item.passengerType) {
              switch (item.passengerType) {
                case 1:
                  item.passengerTypeText = "成人";
                  break;
                case 2:
                  item.passengerTypeText = "儿童";
                  break;
                case 3:
                  item.passengerTypeText = "学生票";
                  break;
                case 4:
                  item.passengerTypeText = "残军票";
                  break;
              }
            }

            if (item.passengerCardType) {
              switch (item.passengerCardType) {
                case "1":
                  item.passengerCardTypeText = "身份证";
                  break;
                case "2":
                  item.passengerCardTypeText = "儿童无证件";
                  break;
                case "3":
                  item.passengerCardTypeText = "港澳通行证";
                  break;
                case "4":
                  item.passengerCardTypeText = "台湾通行证";
                  break;
                case "5":
                  item.passengerCardTypeText = "护照";
                  break;
              }
            }

            if (item.passengerCardNumber) {
              item.passengerCardNumberText =
                item.passengerCardNumber.substr(0, 3) +
                "***********" +
                item.passengerCardNumber.substr(-3, 3);
            }

            if (item.ticketStatus) {
              switch (item.ticketStatus) {
                case 1:
                  item.ticketStatusText = "未出票";
                  break;
                case 2:
                  item.ticketStatusText = "待出票";
                  break;
                case 3:
                  item.ticketStatusText = "出票中";
                  break;
                case 4:
                  item.ticketStatusText = "出票失败";
                  break;
                case 5:
                  item.ticketStatusText = "退票已申请";
                  break;
                case 6:
                  item.ticketStatusText = "退票处理中";
                  break;
                case 7:
                  item.ticketStatusText = "退票完成";
                  break;
                case 8:
                  item.ticketStatusText = "退票失败";
                  break;
                case 9:
                  item.ticketStatusText = "改签已申请";
                  break;
                case 10:
                  item.ticketStatusText = "改签中";
                  break;
                case 11:
                  item.ticketStatusText = "改签完成";
                  break;
                case 12:
                  item.ticketStatusText = "改签失败";
                  break;
                case 13:
                  item.ticketStatusText = "改签已取消";
                  break;
              }
            }
          });
          _this.count_down(detail);
          _this.detail = detail;
          _this.loading = false;
          _this.timer = setInterval(function() {
            _this.count_down(_this.detail);
          }, 1000);
        });
    },
    count_down(detail) {
      if (detail.changeOderStatus !== 1) return clearInterval(this.timer);
      detail.second -= 1;
      if (detail.second >= 0) {
        let minute = Math.floor(detail.second / 60);
        minute = minute < 10 ? "0" + minute : minute;

        let second = detail.second % 60;
        second = second < 10 ? "0" + second : second;
        detail.secondText = minute + "分" + second + "秒";
        if (detail.second === 0) {
          this.req_detail(detail.changeNo, detail.orderNo);
          clearInterval(this.count_down.timer);
        }
      } else {
        detail.secondText = "00分00秒";
        clearInterval(this.count_down.timer);
      }
    }
  },
  mounted() {},
  activated() {
    this.req_detail(this.$route.query.changeNo, this.$route.query.orderNo);
  },
  deactivated() {
    clearInterval(this.timer);
  },
  watch: {}
};
