// flight_travel_refund_order_list 根据分销商ID查询所有可操作取消的订单列表

const __request = require(`./__request/__request_contentType_json`);

// 接口地址： http://219.128.52.2:8989/swagger-ui.html#/travel-calls-controller/refundOrderUsingPOST

export default function flight_travel_refund_order_list(pParameter) {
  if (!pParameter) pParameter = {};
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/flight/travel/refund/order/list'
  params.data = pParameter
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
