import Q from 'q';
// 详情
import web_personage_personageDetailByBuyerId from '@/lib/data-service/person/web_personage_personageDetailByBuyerId.js';
// 客户经理
import web_common_user_getDeveloper from '@/lib/data-service/person/web_common_user_getDeveloper.js';
// 获取支付方式类型枚举
import findPaymentChannel from '@/lib/data-service/default/web_buyer_flightBuyer_findPaymentChannel'
// 设置
import web_personage_personagePlatformSet from '@/lib/data-service/person/web_personage_personagePlatformSet.js';
// 审核
import web_personage_personageAudit from '@/lib/data-service/person/web_personage_personageAudit.js';
// 修改历史标签
import history_tag_api from '@/component/admin-layout/1.0.0/component/history-tag/api'


export default {
  data() {
    return {
      detail: {},
      developerList: [],
      paymentChannelTypeList: [],
      personParams: {
        developerId: '',   // 开发人id
        developer: '',   // 开发人名字
        accountManagerId: '',   // 客户经理id
        accountManagerName: '',   // 客户经理名字
        rebate: 0,   // 返点
        specialRebate: 0,   // 机票特殊政策返点
        paymentChannelTypeList: [1,2],   // 支付方式类型，1.余额支付，2.在线收单
      },
      dialogVisible: false,
      failReason: '',
      rule: {
        developerId: [
          {required: true, message: '开发者不能为空', trigger: 'change'}
        ],
        accountManagerId: [
          {required: true, message: '机票客户经理不能为空', trigger: 'change'}
        ],
        paymentChannelTypeList: [
          {required: true, message: '请选择机票支付方式', trigger: 'change'}
        ]
      }
    }
  },
  methods: {
    req_detail(params) {
      const _this = this;
      Q.when()
        .then(function () {
          return web_personage_personageDetailByBuyerId(params);
        })
        .then(function (res) {
          _this.detail = res.personageDetailResult;
          if (_this.$route.query.type === 'set' || _this.$route.query.type === 'audit') {
            if (_this.detail.developerId) _this.personParams.developerId = parseInt(_this.detail.developerId);
            if (_this.detail.accountManagerId) _this.personParams.accountManagerId = parseInt(_this.detail.accountManagerId);
            if (_this.detail.rebate) _this.personParams.rebate = _this.detail.rebate;
            if (_this.detail.specialRebate) _this.personParams.specialRebate = _this.detail.specialRebate;
            if (_this.$route.query.type === 'set') {
              if (_this.detail.paymentChannelTypeList) _this.personParams.paymentChannelTypeList = _this.detail.paymentChannelTypeList;
            }
            _this.req_developerList();
          };
        })
    },
    req_developerList() {
      const _this = this;
      Q.when()
        .then(function () {
          return web_common_user_getDeveloper();
        })
        .then(function (res) {
          _this.developerList = res.getAllDeveloperResult;
          if (_this.detail.developerId) _this.handleDeveloperChange(_this.personParams.developerId);
          if (_this.detail.accountManagerId) _this.handleAccountManagerIdChange(_this.personParams.accountManagerId);
        })
    },
    req_save(params) {
      const _this = this;
      Q.when()
        .then(function () {
          return web_personage_personagePlatformSet(params);
        })
        .then(function (res) {
          _this.$message.success('设置成功');
          _this.$router.back(-1);
        })
    },
    req_audit(params, message) {
      const _this = this;
      Q.when()
        .then(function () {
          return web_personage_personageAudit(params);
        })
        .then(function (res) {
          _this.handleClose();
          _this.$message.success(message);
          _this.$router.back(-1);
        })
    },
    // 选择开发人
    handleDeveloperChange(value) {
      this.developerList.forEach(item => {
        if (item.id === value) {
          this.personParams.developer = item.realName;
        }
      })
    },
    // 选择经理
    handleAccountManagerIdChange(value) {
      this.developerList.forEach(item => {
        if (item.id === value) {
          this.personParams.accountManagerName = item.realName;
        }
      })
    },
    // 改变机票返点
    handleRebateChange(num) {
      const value = Number(num.replace(/[^\d|^\.|^\-]/gi, ""))
          ? Number(num.replace(/[^\d|^\.|^\-]/gi, ""))
          : 0
      if (value >= 100) {
        this.personParams.rebate = Number(100).toFixed(2)
      } else if (value <= -100) {
        this.personParams.rebate = Number(-100).toFixed(2)
      } else {
        this.personParams.rebate = value.toFixed(2)
      }
    },
    // 改变机票特殊政策返点
    handleSpecialRebateChange(num) {
      const value = Number(num.replace(/[^\d|^\.|^\-]/gi, ""))
          ? Number(num.replace(/[^\d|^\.|^\-]/gi, ""))
          : 0
      if (value >= 100) {
        this.personParams.specialRebate = Number(100).toFixed(2)
      } else if (value <= -100) {
        this.personParams.specialRebate = Number(-100).toFixed(2)
      } else {
        this.personParams.specialRebate = value.toFixed(2)
      }
    },
    // 获取支付方式类型枚举
    async findPaymentChannel() {
      const res = await findPaymentChannel()
      this.paymentChannelTypeList = res.paymentChannelTypeList
    },
    // 改变机票支付方式
    handlePaymentChannelTypeListChange(value) {
      this.personParams.paymentChannelTypeList = value
    },
    // 保存
    save() {
      let status = [-1, -1]
      let formList = ['developerForm', 'flightForm']
      formList.map((value, index) => {
        this.$refs[value].validate((valid) => {
          valid ? status[index] = 1 : status[index] = -1
          if (status.indexOf(-1) === -1) {
            this.req_save({
              buyerId: this.detail.buyerId,
              ...this.personParams
            });
          }
        })
      })
    },
    // 审核
    audit(buyerAirStatus) {
      if (buyerAirStatus === 2) {
        const params = {
          buyerAirStatus,
          buyerId: this.detail.buyerId,
          phone: this.detail.phone,
          userId: this.detail.userId,
          userName: this.detail.userName,
          ...this.personParams
        };
        let status = [-1, -1]
        let formList = ['developerForm', 'flightForm']
        formList.map((value, index) => {
          this.$refs[value].validate((valid) => {
            valid ? status[index] = 1 : status[index] = -1
            if (status.indexOf(-1) === -1) {
              let message = '设置审核通过成功';
              this.req_audit(params, message);
            }
          })
        })
      } else if (buyerAirStatus === 1) {
        this.dialogVisible = true;
      }
    },
    // 确定
    fall_audit() {
      const params = {
        buyerAirStatus: 1,
        buyerId: this.detail.buyerId,
        userId: this.detail.userId,
        failReason: this.failReason,
        phone: this.detail.phone,
        userName: this.detail.userName,
      };
      let message = '设置审核不通过成功';
      if (!params.failReason) {
        this.$message.warning('请输入不通过原因')
        return;
      }
      this.req_audit(params, message);
    },
    // 取消
    handleClose() {
      this.dialogVisible = false;
      this.failReason = '';
    }
  },
  mounted() {
    this.findPaymentChannel()
  },
  created() {},
  activated() {
    this.personParams = {
      developerId: '',   // 开发人id
      developer: '',   // 开发人名字
      accountManagerId: '',   // 客户经理id
      accountManagerName: '',   // 客户经理名字
      rebate: 0,   // 返点
      specialRebate: 0,   // 机票特殊政策返点
      paymentChannelTypeList: [1,2],   // 支付方式类型，1.余额支付，2.在线收单
     }

    let route = this.$route;
    let tag_name = '';
    const type = this.$route.query.type;
    if (type === 'set') {
      tag_name = '平台设置';
    } else if (type === 'detail') {
      tag_name = '个人用户详情';
    } else if (type === 'audit') {
      tag_name = '个人用户审核详情';
    }
    console.log(this.$route.query.type)
    history_tag_api.modify_tag_name_by_route({
      route,
      tag_name,
    });
    this.req_detail({buyerId: this.$route.query.buyerId, userId: this.$route.query.userId});
  }
}
