// web_managerSupplier_getSupplierRelevanceList 条件分页查询供应商关联关系列表

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口地址： http://192.168.0.15:8080/doc.html#/所有接口/manager-supplier-controller/getSupplierRelevanceListUsingPOST

export default function web_managerSupplier_getSupplierRelevanceList(pParameter) {
  if (!pParameter) pParameter = {};
  const currentPage = pParameter.currentPage || 1
  const pageSize = pParameter.pageSize || 10
  const userName = pParameter.userName || ''
  const applicationType = pParameter.applicationType || ''
  const telephone = pParameter.telephone || ''
  const companyName = pParameter.companyName || ''
  const startTime = pParameter.startTime || ''
  const endTime = pParameter.endTime || ''
  const accountManagerId = pParameter.accountManagerId || ''
  const startRelevanceTime = pParameter.startRelevanceTime || ''
  const endRelevanceTime = pParameter.endRelevanceTime || ''
  const developerId = pParameter.developerId || ''
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/managerSupplier/getSupplierRelevanceList'
  params.data = {
    currentPage,
    pageSize,
    userName,
    applicationType,
    telephone,
    companyName,
    startTime,
    endTime,
    accountManagerId,
    startRelevanceTime,
    endRelevanceTime,
    developerId
  }
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
