import Pagination from "@/component/base-pagination/index.vue";
import ifInvoiceEnums from "@/lib/data-service/hotel/hotel_platFormOrder_ifInvoiceEnums";
import settlementEnums from "@/lib/data-service/hotel/hotel_platFormOrder_settlementEnums";
import list from "@/lib/data-service/hotel/hotel_manager_order_queryHotelPlatformOrderList";
import updateConfirmNo from "@/lib/data-service/hotel/hotel_manager_order_updateConfirmNo";
import manualOrder from "@/lib/data-service/hotel/hotel_manager_order_manualOrder";
import reorderOrder from "@/lib/data-service/hotel/hotel_platFormOrder_reorderOrder";
import cancelOrderSubmit from "@/lib/data-service/hotel/hotel_baseOrder_cancelOrderSubmit";
import _ from 'underscore'
import moment from 'moment'
import to_boolean from 'nllib/src/convert/to_boolean'
export default {
    name: "hotelOrderList",
    data() {
        return {
            params: {
                ifInvoice: 0,
                settlement: 0,
                total: 0,
                currentPage: 1,
                pageSize: 10
            },
            ifInvoiceList: [],
            settlementList: [],
            activeName: "",
            navList: [
                {label: "全部", value: ""},
                {label: "待支付", value: "0"},
                {label: "已支付", value: "2"},
                {label: "待确认", value: "3"},
                {label: "已确认", value: "4"},
                {label: "已拒单", value: "5"}
                // { label: "已取消", value: "6" },
                // { label: "取消中", value: "7" },
                // { label: "取消失败", value: "8" },
                // { label: "下单失败", value: "9" }
            ],
            orderList: [],
            loading: false
        };
    },
    components: {Pagination},
    created() {
        this.init();
    },
    computed :{
        is_show_operation_button() {
            const __this = this;

            const default_value = true;

            const data_app_for_npm_yinzhilv_frontend_platform_flight = __this.$route.meta.data_app_for_npm_yinzhilv_frontend_platform_flight;
            if (!data_app_for_npm_yinzhilv_frontend_platform_flight) return default_value;

            const get_is_show_operation_button = data_app_for_npm_yinzhilv_frontend_platform_flight.get_is_show_operation_button;
            if (!_.isFunction(get_is_show_operation_button)) return default_value;

            const is_show_operation_button_001 = get_is_show_operation_button();
            const is_show_operation_button_002 = to_boolean(is_show_operation_button_001, true);

            return is_show_operation_button_002;
        },
    },
    methods: {
        init() {
            this.getIfInvoiceList();
            this.getSettlementList();
            this.onSearch();
        },
        pushHotelOrder(orderNo,supplierId){
            const __this = this;
            let name = `hotel-order-detail`;

            const data_app_for_npm_yinzhilv_frontend_platform_flight = __this.$route.meta.data_app_for_npm_yinzhilv_frontend_platform_flight;
            if (data_app_for_npm_yinzhilv_frontend_platform_flight) {
                const get_route_detail_page = data_app_for_npm_yinzhilv_frontend_platform_flight.get_route_detail_page;
                if (_.isFunction(get_route_detail_page)) {
                    const route_detail_page = get_route_detail_page();
                    if (route_detail_page) {
                        const route_name = route_detail_page.route_name;
                        if (route_name) {
                            name = route_name;
                        }
                    }
                }
            }
            __this.$router.push({
                name,
                query: {
                    orderNo,
                    supplierId
                }
            });
        },
        getIfInvoiceList() {
            ifInvoiceEnums().then(res => {
                this.ifInvoiceList = [{msg: "全部", code: 0}, ...res.result]
            })
        },
        getSettlementList() {
            settlementEnums().then(res => {
                this.settlementList = [{msg: "全部", type: 0}, ...res.result]
            })
        },
        onSearch(type) {
            let data = this.params;
            type ? "" : (data.currentPage = 1);
            this.loading = true;
            list(data).then(res => {
                this.loading = false;
                this.orderList = res.pageResult.pageData;
                this.params.total = res.pageResult.totalCount;
            });
        },
        upDateConfirm(val) {
            updateConfirmNo({
                customerOrderCode: val.orderNo,
                supplierId: val.supplierId,
                createBegin: val.checkInDate,
                createEnd: val.checkOutDate,
                orderCode: val.supplierOrderNo
            }).then(res => {
                this.$message({type: "success", message: "更新成功！"});
                this.onSearch();
            });
        },
        foundOrder(orderNo) {
            manualOrder({orderNo}).then(res => {
                res.code == "000000"
                    ? this.$message({type: "success", message: "创建成功！"})
                    : "";
            });
        },
        onReset() {
            let data = {
                ifInvoice: 0,
                settlement: 0,
                total: 0,
                currentPage: 1,
                pageSize: 10
            };
            this.params = data;
            this.onSearch();
        },
        getOrderList(val) {
            this.params.currentPage = val.page;
            this.onSearch("page");
        },
        handleClick(val) {
            this.params.orderStatus = val;
            this.onSearch();
        },
        async orderAgain(orderNo) {
            let res = await reorderOrder({
                orderNo: orderNo
            })
            if(res.code === '000000') {
                this.$message({
                    message: '重新下单成功',
                    type: 'success'
                })
                this.init();
            } else {
                this.$message({
                    message: '重新下单失败',
                    type: 'error'
                })
            }
        },
        async cancelOrder(orderNo) {
            let res = await cancelOrderSubmit({
                orderNo: orderNo
            })
            if(res.code === '000000') {
                this.$message({
                    message: '删除订单成功',
                    type: 'success'
                })
                this.init();
            }else {
                this.$message({
                    message: '删除失败',
                    type: 'error'
                })
            }
        }
    }
};
