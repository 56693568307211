const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 平台查看特价附加金额接口地址：http://192.168.0.13:8080/doc.html#/yinzhilv_travel/product-controller/getAdditionMoneyUsingPOST

export default function web_common_product_getAdditionMoney(pParameter) {
  if (!pParameter) pParameter = {};
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/common/product/getAdditionMoney'
  params.data = pParameter
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}
