import Q from 'q';
import moment from 'moment';


// 城市插件
import TrainCitySelector from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/train-city-selector/1.0.1/index.vue';

// 火车票订单列表
import trains_order_platformRefundOrderList from '@/lib/data-service/train/trains_order_platformRefundOrderList';

export default {
  components: {
    TrainCitySelector
  },
  data () {
    return {
      loading: false,
      show: false,
      form: {
        currentPage: 1,
        pageSize: 10,
        orderNo: '',
        passengerName: '',
        beginStartDate: '',
        endStartDate: '',
        trainNo: '',
        beginBookingDate: '',
        endBookingDate: '',
        orderType: '',
        fromStationSn: '',
        toStationSn: '',
        refundStatus: '',
        refundName: '',
      },
      departDate: null,
      createDate: null,
      fromCity: null,
      toCity: null,
      list: [],
      allocation: {
        orderType: [
          { label: '全部', value: '' },
          { label: '预订', value: '1' },
          { label: '抢票', value: '2' },
        ],
        refundStatus: [
          { label: '全部', value: '' },
          { label: '申请退款', value: '1' },
          { label: '退款中', value: '2' },
          { label: '退款成功', value: '3' },
          { label: '退款失败', value: '4' },
          { label: '取消退款', value: '5' },
        ]
      },
      timer: null,
      pager: {
        total: 0,
        pageSizes: [10, 20, 30, 40, 50]
      }
    }
  },
  methods: {
    req_list (params, type) {
      clearInterval(this.timer);
      this.loading = true;
      const _this = this;
      Q.when()
        .then(function () {
          return trains_order_platformRefundOrderList(params);
        })
        .then(function (res) {
          if (type) document.documentElement.scrollTop = 0;
          const list = res.pageResult.pageData;
          list.forEach(function (item) {
            item.bookingDate = moment(item.bookingDate).format('YYYY-MM-DD HH:mm');
            item.refundDate = item.refundDate ? moment(item.refundDate).format('YYYY-MM-DD HH:mm') : '--';

            if (moment(item.startDate).format('YYYY-MM-DD') === moment(item.arriveDate).format('YYYY-MM-DD')) {
              item.journeyDate = moment(item.startDate).format('YYYY-MM-DD');
              item.journeyTime =  moment(item.startDate).format('HH:mm') + '-' + moment(item.arriveDate).format('HH:mm');
            }
          });
          _this.pager.total = res.pageResult.totalCount;
          _this.list = res.pageResult.pageData;
          _this.loading = false;
        })
    },
    search () {
      this.form.currentPage = 1;
      this.req_list(this.form);
    },
    reset () {
      const pageSize = this.form.pageSize;
      this.departDate = null;
      this.createDate = null;
      this.fromCity = null;
      this.toCity = null;
      this.form = {
        currentPage: 1,
        pageSize,
        orderNo: '',
        passengerName: '',
        beginStartDate: '',
        endStartDate: '',
        trainNo: '',
        beginBookingDate: '',
        endBookingDate: '',
        orderType: '',
        fromStationSn: '',
        toStationSn: '',
        refundStatus: '',
        refundName: '',
      };
      this.req_list(this.form);
    },
    fold_change () {
      this.show = !this.show;
    },
    to_detail (changeNo) {
      this.$router.push({
        name: 'admin-train-change-detail',
        query: {
          changeNo,
        }
      })
    },
    handleSizeChange (pageSize) {
      this.form.pageSize = pageSize;
      this.form.currentPage = 1;
      this.req_list(this.form, 'pageSize');
    },
    handleCurrentChange (currentPage) {
      this.form.currentPage = currentPage;
      this.req_list(this.form, 'currentPage');
    }
  },
  mounted () {

  },
  activated () {
    this.req_list(this.form);
  },
  deactivated () {
    clearInterval(this.timer);
  },
  watch: {
    departDate (val) {
      const form = this.form;
      form.beginStartDate = val ? val[0] + ' 00:00:00' : '';
      form.endStartDate = val ? val[1] + ' 23:59:59' : '';
    },
    createDate (val) {
      const form = this.form;
      form.beginBookingDate = val ? val[0] + ' 00:00:00' : '';
      form.endBookingDate = val ? val[1] + ' 23:59:59' : '';
    },
    fromCity (val) {
      this.form.fromStationSn = val ? val.sn : '';
    },
    toCity (val) {
      this.form.toStationSn = val ? val.sn : '';
    }
  }
}
