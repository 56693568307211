// web_circuit_line_add 新增线路关联旅游产品附加信息

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口地址：http://192.168.0.15:8080/doc.html#/所有接口/circuit-operate-controller/addBindingLineUsingPOST

export default function web_circuit_line_add(pParameter) {
  if (!pParameter) pParameter = {};
  /*const supplierId = pParameter.supplierId || ''
  const lineId = pParameter.lineId || ''
  const lineName = pParameter.lineName || ''
  const lineType = pParameter.lineType || ''
  const attachType = pParameter.attachType || ''
  const attachAmountType = pParameter.attachAmountType || ''
  const attachAdultAmount = pParameter.attachAdultAmount || ''
  const attachChildAmount = pParameter.attachChildAmount || ''
  const isDelete = pParameter.isDelete || 0
  const attachChildBedAmount = pParameter.attachChildBedAmount || ''
  const attachAgedAmount = pParameter.attachAgedAmount || ''
  const attachAdultPercent = pParameter.attachAdultPercent || ''
  const attachChildPercent = pParameter.attachChildPercent || ''
  const attachChildBedPercent = pParameter.attachChildBedPercent || ''
  const attachAgedPercent = pParameter.attachAgedPercent || ''
  const serviceFeeType = pParameter.serviceFeeType === undefined ? '' : pParameter.serviceFeeType
  const serviceFeePercent = pParameter.serviceFeePercent || ''*/
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/circuit/line/add'
  params.data = pParameter
  /*params.data = {
    supplierId,
    lineId,
    lineName,
    lineType,
    attachType,
    attachAmountType,
    attachAdultAmount,
    attachChildAmount,
    isDelete,
    attachChildBedAmount,
    attachAgedAmount,
    attachAdultPercent,
    attachChildPercent,
    attachChildBedPercent,
    attachAgedPercent,
    serviceFeeType,
    serviceFeePercent
  }*/
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}
