import Q from 'q'
// 账户信息
// import web_common_getCurrentUserAuditStatus from '@/lib/data-service/default/web_common_getCurrentUserAuditStatus.js';

// 验证原始密码
// import web_common_user_checkUserPassword from '@/lib/data-service/default/web_common_user_checkUserPassword';

// 修改密码
import web_common_user_updatePassword from '@/lib/data-service/default/web_common_user_updatePassword.js';


export default {
  data() {
    var password1 = (rules, value, callback) => {
      const _this = this;
      const id = this.id;
      if (value === '' || value === undefined) {
        callback(new Error('请输入原始密码'));
      } else {
        if (value.length < 6) {
          callback(new Error('密码长度不能少于6位'));
        } else {
          // if (/\W/g.test(value) || !/\d/g.test(value) || !/[a-zA-Z]/g.test(value)) {
          //   callback(new Error('密码必须数字与字母组合'));
          // } else {
            callback();
          // }
          // Q.when()
          //   .then(function () {
          //     return _this.req_verify({id: _this.id, password: _this.form.password});
          //   })
          //   .then(function (res) {
          //     if (res.checkBoolean) {
          //       callback();
          //     } else {
          //       callback(new Error('原始密码不正确'));
          //     }
          //   })
        }
      }
    };

    var newPassword = (rules, value, callback) => {
      const password = this.form.password;
      if (value === '' || value === undefined) {
        callback(new Error('请输入新密码'));
      } else if (value.length < 6) {
        callback(new Error('密码长度不能少于6位'));
      } else if (/\W/g.test(value) || !/\d/g.test(value) || !/[a-zA-Z]/g.test(value)) {
        callback(new Error('密码必须数字与字母组合'));
      } else if (value === password) {
        callback(new Error('新密码不能与原密码相同'));
      } else {
        callback();
      }
    };

    var verifyNewPassword = (rules, value, callback) => {
      const newPassword = this.form.newPassword;
      if (value === '' || value === undefined) {
        callback(new Error('请再次确认密码'));
      } else if (value.length < 6) {
        callback(new Error('密码长度不能少于6位'));
      } else if (value !== newPassword) {
        callback(new Error('两次密码不一致'));
      } else {
        callback();
      }
    };

    return {
      dialogVisible: false,
      id: '',
      input_type: 'password',
      form: {
        password: '',
        newPassword: '',
        verify: ''
      },
      rules: {
        password: [
          { required: true, validator: password1, trigger: 'blur' }
        ],
        newPassword: [
          { required: true, validator: newPassword, trigger: 'blur' }
        ],
        verify: [
          { required: true, validator: verifyNewPassword, trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    // req_info (params) {
    //   const _this = this;
    //   Q.when()
    //     .then(function () {
    //       return web_common_getCurrentUserAuditStatus(params);
    //     })
    //     .then(function (res) {
    //       _this.id = res.userVo.id;
    //     })
    //     .catch(function (err) {
    //       console.log(err)
    //     })
    // },
    // req_verify (params) {
    //   console.log(params)
    //   return Q.when()
    //     .then(function () {
    //       return web_common_user_checkUserPassword(params);
    //     })
    // },
    req_change (params) {
      const _this = this;
      Q.when()
        .then(function () {
          return web_common_user_updatePassword({oldPassword: params.password, newPassword: params.newPassword});
        })
        .then(function (res) {
          _this.dialogVisible = true;
        })
    },
    commit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.req_change(this.form);
        } else {
          return false;
        }
      })
    },
    cancel () {
      this.$refs.password.passwordVisible = false;
      this.$refs.newPassword.passwordVisible = false;
      this.$refs.verify.passwordVisible = false;
      this.$refs.form.resetFields();
    },
    before_close () {
      this.dialogVisible = false;
      this.cancel();
      const _this = this;
      setTimeout(function () {
        _this.$router.back(-1);
      }, 300)
    }
  },
  mounted () {

  },
  activated () {
    this.$forceUpdate()
    // this.req_info();
  }
};
