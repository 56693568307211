import import_latest_version_module from '@/component/admin-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/discounts-detail',
            name: 'admin-discounts-detail',
            component: () => import(/* webpackChunkName: "page-admin-buyer-management-discounts-discounts-detail" */ `./index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `优惠详情`,
                },
                entity_list_for_breadcrumb: [
                    {name: `分销商优惠管理`},
                    {name: `优惠详情`},
                ],
                data_for_left_menu: {
                    get_active_route_name(pParameter) {
                        // if (!pParameter) pParameter = {};
                        // const all_route_list = pParameter.all_route_list;
                        return `admin-buyer-discounts-list`;
                    },
                },
            },
        },
    ],
};
