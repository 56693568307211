// web_common_credit_cutCreditBillList 需要砍单的分销商列表

const __request = require(`@/lib/data-service/default/__request/__request_contentType_json`);

// 接口文档地址： http://192.168.0.15:8080/doc.html#/yinzhilv_travel/credit-account-controller/cutCreditBillListUsingPOST
export default function web_common_credit_cutCreditBillList(pParameter) {
  let params = {}
  if (!pParameter) pParameter = {};
  params.method = 'POST'
  params.urlSuffix = '/web/common/credit/cutCreditBillList'
  params.data = pParameter
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
