import import_latest_version_module from '@/component/admin-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/credit-open-detail',
            name: 'admin-credit-open-detail',
            component: () => import(/* webpackChunkName: "page-admin-credit-management-credit-open-detail" */ `./index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `开通授信`,
                },
                entity_list_for_breadcrumb: [
                    {name: `授信管理`},
                    {name: `开通授信`},
                ],
                data_for_left_menu: {
                    get_active_route_name(pParameter) {
                        return `admin-credit-open-list`;
                    },
                },
            },
        },
    ],
};
