import Q from 'q';

import moment from 'moment';

// 特价待审核产品列表
import web_common_platformSpecPricePrdController_platProductAudit from '@/lib/data-service/default/web_common_platformSpecPricePrdController_platProductAudit';


// 跳转预览
import get_preview_url from '@/lib/common-service/get_preview_url';

import Citys from '@/component/citys/index.vue';

export default {
  components: {
    Citys,
  },
  data() {
    return {
      form: {
        currentPage: 1,
        pageSize: 10,
        productNo: '',
        productName: '',
        productAreasType: '',
        specialStatus: 1,
        tripDay: '',
        productType: '',
        toProvince: '',
        toCity: '',
        fromProvince: '',
        fromCity: '',
      },
      allocation: {
        areasTypeList: [{
            label: '全部',
            value: ''
          },
          {
            label: '国内',
            value: 1
          },
          {
            label: '出境',
            value: 2
          }
        ],
        specialStatusList: [{
            label: '待审核',
            value: 1
          },
          {
            label: '审核失败',
            value: 2
          },
        ],
        tripDayList: [{
            label: '全部',
            value: ''
          },
          {
            label: '一天',
            value: 1
          },
          {
            label: '两天',
            value: 2
          },
          {
            label: '三天',
            value: 3
          },
          {
            label: '四天',
            value: 4
          },
          {
            label: '五天',
            value: 5
          },
          {
            label: '六天',
            value: 6
          },
          {
            label: '七天',
            value: 7
          },
          {
            label: '八天',
            value: 8
          },
          {
            label: '八天以上',
            value: 9
          }
        ],
        productTypeList: [{
            label: '全部',
            value: ''
          },
          {
            label: '跟团游',
            value: 1
          },
          {
            label: '自由行',
            value: 2
          },
          {
            label: '当地游',
            value: 3
          },
        ]
      },
      pager: {
        pageSizes: [10, 20, 30, 40, 50],
        totalCount: 0
      },
      productData: [],
      specialStatus: 1,
      fallVisible: false,
      show: false,
      loading: true,
      auditContent: '',
    };
  },
  methods: {
    req_procut_list(params, type) {
      this.loading = true;
      const _this = this;
      Q.when()
        .then(function () {
          return web_common_platformSpecPricePrdController_platProductAudit(params);
        })
        .then(function (res) {

          const productData = res.pageResult.pageData;
          _this.pager.totalCount = res.pageResult.totalCount;
          const reg = /\$\$/g;
          productData.forEach(function (item) {
            if (item.specialExpirationDate) item.specialExpirationDate = moment(item.specialExpirationDate).format('YYYY-MM-DD');
            item.cityName = item.fromCityName.replace(reg, ",");
            switch (item.productAreasType) {
              case 1:
                item.areasType = '国内'
                break;
              case 2:
                item.areasType = '出境'
                break;
            }

            switch (item.productLevel) {
              case 0:
                item.productLevelText = '无';
                break;
              case 1:
                item.productLevelText = '经典';
                break;
              case 2:
                item.productLevelText = '经济';
                break;
              case 3:
                item.productLevelText = '促销';
                break;
              case 4:
                item.productLevelText = '尊享';
                break;
              case 5:
                item.productLevelText = '转谈';
                break;
            }

            switch (item.specialStatus) {
              case 1:
                item.specialStatusText = '待审核'
                break;
              case 2:
                item.specialStatusText = '审核失败'
                break;
            }
            switch (item.productType) {
              case 1:
                item.productTypeText = '跟团游'
                break;
              case 4:
                item.productTypeText = '跟团游'
                break;
              case 2:
                item.productTypeText = '自由行'
                break;
              case 3:
                item.productTypeText = '当地游'
                break;
            }

          })

          _this.productData = productData;
          _this.specialStatus = _this.form.specialStatus;
          if (type) document.documentElement.scrollTop = 0;
          _this.loading = false;
        });
    },
    initialize() {
      this.req_procut_list(this.form);
    },
    getToProvince(value) {
      this.form.toProvince = value;
    },
    getToCity(value) {
      this.form.toCity = value;
    },
    getFromProvince(value) {
      this.form.fromProvince = value;
    },
    getFromCity(value) {
      this.form.fromCity = value;
    },
    search() {
      this.form.currentPage = 1;
      this.req_procut_list(this.form);
    },
    reset() {
      this.$refs.toCity.reset_toArea();
      this.$refs.fromCity.reset_fromArea();
      const pageSize = this.form.pageSize;
      this.form = {
        currentPage: 1,
        pageSize,
        productNo: '',
        productName: '',
        productAreasType: '',
        specialStatus: 1,
        tripDay: '',
        productType: '',
        toProvince: '',
        toCity: '',
        fromProvince: '',
        fromCity: '',
        isHaveTeam: '',
        productLevel: '',
        specialSource: ''
      };
      this.search();
    },
    tab_change(value) {
      this.form.currentPage = 1;
      this.form.specialStatus = value;
      this.req_procut_list(this.form);
    },
    to_audit_detail(productNo) {
      this.$router.push({
        name: 'admin-special-audit-detail',
        query: {
          productNo
        },
      })
    },
    look_fall(auditContent) {
      this.auditContent = auditContent;
      this.fallVisible = true;
    },
    handleCloseFall() {
      this.fallVisible = false;
      this.auditContent = '';
    },
    to_product_details(productNo) {
      get_preview_url(productNo)
    },
    handleSizeChange(pages) {
      this.form.pageSize = pages;
      this.form.currentPage = 1;
      this.req_procut_list(this.form, 'pager');
    },
    handleCurrentChange(page) {
      this.form.currentPage = page;
      this.req_procut_list(this.form, 'pager');
    },
    fold_change() {
      this.show = !this.show;
    }
  },
  created() {},
  mounted() {},
  activated() {
    this.initialize();
  },
  deactivated() {},
  destroyed() {},
  watch: {

  },
  computed: {}
};
