import Q from 'q';

import moment from 'moment';

// 待审核产品列表
import web_supplier_findProduct from '@/lib/data-service/default/web_supplier_findProduct';
// 跳转预览
import get_preview_url from '@/lib/common-service/get_preview_url';

import Citys from '@/component/citys/index.vue';

export default {
  components: {
    Citys,
  },
  data() {
    return {
      form: {
        currentPage: 1,
        pageSize: 10,
        productNo: '',
        productName: '',
        productAreasType: '',
        productStatus: 1,
        collectGuestType: '',
        productType: '',
        toProvince: '',
        toCity: '',
        fromProvince: '',
        fromCity: '',
      },
      allocation: {
        areasTypeList: [
          { label: '全部', value: '' },
          { label: '国内', value: 1 },
          { label: '出境', value: 2 }
        ],
        productStatusList: [
          { label: '待审核',  value: 1},
          { label: '审核失败',  value: 2},
        ],
        collectGuestTypeList: [
          { label: '全部', value: '' },
          { label: '散拼团', value: 1 },
          { label: '小包团', value: 2 },
        ],
        productTypeList: [
          { label: '全部', value: ''},
          { label: '跟团游', value: 1},
          { label: '自由行', value: 2},
          { label: '当地游', value: 3},
        ]
      },
      pager: {
        pageSizes: [10, 20, 30, 40, 50],
        totalCount: 0
      },
      productData: [],
      productStatus: 1,
      fallVisible: false,
      show: false,
      loading: false,
      auditContent: '',
    };
  },
  methods: {
    req_procut_list(params, type) {
      const _this = this;
      this.loading = true;
      Q.when()
        .then(function() {
          return web_supplier_findProduct(params);
        })
        .then(function(res) {

          let productData = res.pageResult.pageData;
          _this.pager.totalCount = res.pageResult.totalCount;
          const reg = /\$\$/g;
          productData.forEach(function (item) {
            item.cityName = item.fromCityName.replace(reg, ",");
            switch (item.collectGuestType) {
              case 1:
                item.collectGuestTypeText = '散拼团'
                break;
              case 0:
                item.collectGuestTypeText = '小包团'
                break;
            }
            switch (item.productStatus) {
              case 1:
                item.productStatusText = '待审核'
                break;
              case 2:
                item.productStatusText = '审核失败'
                break;
            }

            switch (item.productType) {
              case 1:
                item.productTypeText = '跟团游'
                break;
              case 4:
                item.productTypeText = '跟团游'
                break;
              case 2:
                item.productTypeText = '自由行'
                break;
              case 3:
                item.productTypeText = '当地游'
                break;
            }

          })
          _this.productData = productData;
          _this.productStatus = _this.form.productStatus;
          if (type) document.documentElement.scrollTop = 0;
          _this.loading = false;
        });
    },
    initialize() {
      this.req_procut_list(this.form);
    },
    getToProvince(value) {
      this.form.toProvince = value;
    },
    getToCity(value) {
      this.form.toCity = value;
    },
    getFromProvince(value) {
      this.form.fromProvince = value;
    },
    getFromCity(value) {
      this.form.fromCity = value;
    },
    search() {
      this.form.currentPage = 1;
      this.req_procut_list(this.form);
    },
    reset() {
      this.$refs.toCity.reset_toArea();
      this.$refs.fromCity.reset_fromArea();
      const pageSize = this.form.pageSize;
      this.form = {
        currentPage: 1,
        pageSize,
        productNo: '',
        productName: '',
        productAreasType: '',
        productStatus: 1,
        collectGuestType: '',
        productType: '',
        toProvince: '',
        toCity: '',
        fromProvince: '',
        fromCity: '',
        isHaveTeam: '',
        productLevel: '',
        specialSource: ''
      };
      this.search();
    },
    tab_change (value) {
      this.form.currentPage = 1;
      this.form.productStatus = value;
      this.req_procut_list(this.form);
    },
    to_audit_detail (productNo) {
      this.$router.push({
        name: 'admin-audit-detail',
        query: {productNo},
      })
    },
    look_fall (auditContent) {
      this.auditContent = auditContent;
      this.fallVisible = true;
    },
    handleCloseFall () {
      this.fallVisible = false;
      this.auditContent = '';
    },
    to_product_details(productNo) {
      get_preview_url(productNo)
    },
    to_supplier_detail (id) {
      this.$router.push({
        name: 'admin-supplier-detail',
        query: {id},
      })
    },
    handleSizeChange(pages) {
      this.form.pageSize = pages;
      this.form.currentPage = 1;
      this.req_procut_list(this.form, 'pager');
    },
    handleCurrentChange(page) {
      this.form.currentPage = page;
      this.req_procut_list(this.form, 'pager');
    },
    fold_change () {
      this.show = !this.show;
    }
  },
  created() {},
  mounted() {},
  activated() {
    this.initialize();
  },
  deactivated() {},
  destroyed() {},
  watch: {

  },
  computed: {}
};
