import _ from 'underscore';

import aRouter from '../index';
import get_routes_by_require from './get_routes_by_require';
import get_route_root from "./get_route_root";

const add_routes = function () {
    const routes_by_require = get_routes_by_require();
    const route_root = get_route_root();

    const routes = [
        route_root,

        ...routes_by_require,

        // 直接引入第三方项目的页面
        // {
        //     path: '/lyn-test',
        //     name: `lyn-test`,
        //     component: () => import(`yinzhilv-frontend-platform-v2/frontend/app-for-npm/src/page/admin/plain-order-management/plain-order-management/index.vue`),
        //     meta: {},
        // },

        {
            path: '*',
            component: () => import(`@/page/error/404/index.vue`),
            meta: {
                enable_check_token: false,
            },
        },

    ];

    aRouter.addRoutes(routes);
    return routes;
};

export default add_routes;
