import import_latest_version_module from '@/component/admin-layout/import_latest_version_module';

export default {
  path: '',
  component: () => import_latest_version_module(),
  children: [
    {
      path: '/admin/hotel-offline-order-detail',
      name: 'admin-hotel-offline-order-detail',
      component: () => import(/* webpackChunkName: "page-admin-hotel-offlin-order-hotel-offline-order-detail" */ `./index.vue`),
      meta: {
          data_for_admin_layout: {
                    data_for_history_tag: {
                              entity: {
                                        name: "线下订单详情"
                              }
                    },
                    data_for_breadcrumb: {
                              entity_list: [
                                        {
                                                  name: "酒店订单管理"
                                        },
                                        {
                                                  name: "线下订单列表"
                                        },
                                        {
                                                  name: "线下订单详情"
                                        }
                              ]
                    }
          }
},
    },
  ],
};
