// web_common_credit_cutTravelOrdList 回款详情旅游订单列表

const __request = require(`@/lib/data-service/default/__request/__request_contentType_json`);

// 接口文档地址： http://192.168.0.15:8080/doc.html#/yinzhilv_travel/credit-cut-ord-controller/cutTravelOrdListUsingPOST
export default function web_common_credit_cutTravelOrdList(pParameter) {
  let params = {}
  if (!pParameter) pParameter = {};
  params.method = 'POST'
  params.urlSuffix = '/web/common/credit/cutTravelOrdList'
  params.data = pParameter
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
