// web_buyer_alter 平台设置

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口文档地址： http://192.168.0.15:8080/doc.html#/所有接口/buyer-controller/alterBuyerUsingPOST
export default function web_buyer_alter(pParameter) {
  let params = {}
  if (!pParameter) pParameter = {};
  /*const buyerId = pParameter.buyerId || ''
  const developerId = pParameter.developerId || ''
  const developer = pParameter.developer || ''
  const carryRules = pParameter.carryRules ? pParameter.carryRules : 0
  const airAccountManagerId = pParameter.airAccountManagerId || ''
  const airAccountManager = pParameter.airAccountManager || ''
  const travelAccountManagerId = pParameter.travelAccountManagerId || ''
  const travelAccountManager = pParameter.travelAccountManager || ''
  const isOperationFlow = pParameter.isOperationFlow || 0
  const attachAmountType = pParameter.attachAmountType || 0
  const attachAdultAmount = pParameter.attachAdultAmount || ''
  const noattachChildAmount = pParameter.noattachChildAmount || ''
  const attachChildAmount = pParameter.attachChildAmount || ''
  const attachAgedAmount = pParameter.attachAgedAmount || ''*/
  params.method = 'POST'
  params.urlSuffix = '/web/buyer/alter'
  params.data = pParameter
  /*params.data = {
    buyerId,
    travelAccountManagerId, // 旅游经理
    travelAccountManager,
    airAccountManagerId,
    airAccountManager,
    developerId,
    developer,
    carryRules,
    isOperationFlow,
    attachAmountType,
    attachAdultAmount,
    noattachChildAmount,
    attachChildAmount,
    attachAgedAmount
  }*/
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
