import getBuyerTotalDataByCompanyId from '@/lib/data-service/statistics/get_buyer_total_data_by_company_id'
import getBuyerDailyDataByIdAndDate from '@/lib/data-service/statistics/get_buyer_daily_data_by_id_and_date'
import moment from 'moment'
import {awaitWrap} from "../../supplier-management/utils/common";

export default {
    components: {},
    data() {
        return {
            bizDataList: [],
            buyerData: {},
            loading: false,
            params: {
                companyId: 0,
            },
            getBuyerDailyDataParams: {
                companyId: 0,
                startDate: '',
                endDate: ''
            },
            buyer: {},
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() >= (Date.now()-8.64e7);
                },
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            dateValue: '',

            lineOption: {}
        }
    },
    filters: {
        emptyFilter(str) {
            if (str == null || str == "") {
                return "--";
            }
            return str;
        },
        toThousandFilter(num) {
            return (+num || 0).toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')
        }
    },
    methods: {
        async getBuyerTotalData(id) {
            this.loading = true
            this.params.companyId = id
            let [err, res] = await awaitWrap(getBuyerTotalDataByCompanyId(this.params))
            if (err) {
                this.loading = false
            }
            this.loading = false
            this.bizDataList = res.list
            this.buyer.companyName = res.data.companyName
            this.buyer.buyerTypeStr = res.data.buyerTypeStr
            this.buyer.userName = res.data.userName
            this.buyer.gmtLastLogin = res.data.gmtLastLogin
        },
        toBack() {
            this.$router.push({
                name: 'admin-buyer-total-report'
            })
        },
        doSearch() {
            this.getBuyerDailyDataParams.startDate = this.dateValue[0]
            this.getBuyerDailyDataParams.endDate = this.dateValue[1]
            this.getBuyerDailyData()
        },
        initDatePicker() {
            const start = moment().add(-7, 'days').format('YYYY-MM-DD')
            const end = moment().add(-1, 'days').format('YYYY-MM-DD')
            this.getBuyerDailyDataParams.startDate = start
            this.getBuyerDailyDataParams.endDate = end
            this.dateValue = [start, end]
        },
        getBuyerDailyData() {
            this.getBuyerDailyDataParams.companyId = this.id
            getBuyerDailyDataByIdAndDate(this.getBuyerDailyDataParams).then(res => {
                this.lineOption = {
                    grid: {
                        top: 70,
                        bottom: 59,
                        right: 32,
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross'
                        }
                    },
                    legend: {
                        show: true,
                        left: 'left',
                        data:['旅游', '机票', '酒店', '火车票', '预存款'],
                        zlevel: 100,
                    },
                    xAxis: {
                        type: 'category',
                        data: res.dateList || []
                    },
                    yAxis: {},
                    series: [
                        {
                            data: res.travelAmountList || [],
                            type: "line",
                            name: '旅游',
                        },
                        {
                            data: res.flightAmountList || [],
                            type: "line",
                            name: '机票',
                            markPoint: {
                                data: [
                                    {type: 'max', name: '最大值'},
                                    {type: 'min', name: '最小值'}
                                ]
                            },
                        },
                        {
                            data: res.hotelAmountList || [],
                            type: "line",
                            name: '酒店',
                            markPoint: {
                                data: [
                                    {type: 'max', name: '最大值'},
                                    {type: 'min', name: '最小值'}
                                ]
                            },
                        },
                        {
                            data: res.trainsAmountList || [],
                            type: "line",
                            name: '火车票',
                            markPoint: {
                                data: [
                                    {type: 'max', name: '最大值'},
                                    {type: 'min', name: '最小值'}
                                ]
                            },
                        },
                        {
                            data: res.advanceAmountList || [],
                            type: "line",
                            name: '预存款',
                            markPoint: {
                                data: [
                                    {type: 'max', name: '最大值'},
                                    {type: 'min', name: '最小值'}
                                ]
                            },
                        }
                    ]
                };
                setTimeout(() => {
                    this.$refs.chartDetail.resize()
                }, 1000)

            }).catch(() => {
                this.loading = false
            })

        }
    },
    beforeCreate: function () {
        const __this = this;
    },
    created() {

        // const __this = this;
    },
    async mounted() {
        this.id = this.$route.query.id
        await this.getBuyerTotalData(this.id)
        this.initDatePicker()
        this.$nextTick(() => {
            let clientWidth = this.$refs.chart.clientWidth
            console.log(clientWidth)
            this.getBuyerDailyData()
        })

    },
    activated() {
        // const __this = this;
    },
    deactivated() {
        // const __this = this;
    },
}
