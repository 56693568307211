const __request = require(`./__request/__request_contentType_json`);

// 商城名重复检查
// http://tour-alpha.yinzhilv.com/doc.html#/yinzhilv_travel/btoc-controller/checkStoreNameUsingPOST
const request = (storeName) => {

  const p = {
    method: 'post',
    urlSuffix: '/web/btoc/checkStoreName',
    data: {
      storeName,
    }
  };
  return new Promise(function (resolve, reject) {
    resolve(__request(p))
  })
};
module.exports = request;