// web_common_area_getStairAreaDataOnlyHaveHongKongAndMacaoAndTaiwan 获取不同一级地区数据仅有港澳台

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 根据id获取城市省份接口地址：http://219.128.52.2:9999/doc.html#/所有接口/area-controller/getStairAreaDataOnlyHaveHongKongAndMacaoAndTaiwanUsingPOST

export default function web_common_area_getStairAreaDataOnlyHaveHongKongAndMacaoAndTaiwan(pParameter) {
  if (!pParameter) pParameter = {};
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/common/area/getStairAreaDataOnlyHaveHongKongAndMacaoAndTaiwan'
  params.data = pParameter
  params.process_response_data = false;
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}

