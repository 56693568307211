import Q from 'q';
import moment from 'moment';


// 城市插件
import TrainCitySelector from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/train-city-selector/1.1.0/index.vue';

// 火车票订单列表
import trains_platformOrder_getPlatformOrderList from '@/lib/data-service/train/trains_platformOrder_getPlatformOrderList';
// tab
import trains_common_getOrderStatusEnum from '@/lib/data-service/train/trains_common_getOrderStatusEnum';

export default {
    components: {
        TrainCitySelector
    },
    data () {
        return {
            loading: false,
            form: {
                currentPage: 1,
                pageSize: 10,
                value: '',
            },
            departDate: null,
            createDate: null,
            fromCity: null,
            toCity: null,
            orderStatus: '',
            list: [],
            allocation: {
                orderType: [
                    { label: '全部', value: '' },
                    { label: '预订', value: '1' },
                    { label: '抢票', value: '2' },
                ],
                orderStatus: [],
            },
            timer: null,
            pager: {
                total: 0,
                pageSizes: [10, 20, 30, 40, 50]
            }
        }
    },
    methods: {
        handleSelectionChange(val) {
            console.log(val)
        },




        req_list (params, type) {
            clearInterval(this.timer);
            this.loading = true;
            const _this = this;
            _this.list = [];
            Q.when()
                .then(function () {
                    return trains_platformOrder_getPlatformOrderList(params);
                })
                .then(function (res) {
                    if (type) document.documentElement.scrollTop = 0;
                    _this.pager.total = res.pageResult.totalCount;
                    const list = res.pageResult.pageData;
                    _this.list = list;
                    _this.loading = false;
                    _this.timer = setInterval(function () {
                        _this.count_down(_this.list);
                    }, 1000);
                })
        },
        req_tab () {
            const _this = this;
            Q.when()
                .then(function () {
                    return trains_common_getOrderStatusEnum();
                })
                .then(function (res) {
                    _this.orderStatus = res.trainsContantResult[0].code;
                    _this.allocation.orderStatus = res.trainsContantResult;
                })
        },
        count_down (list) {
            const _this = this;
            list.forEach(function (item) {
                if (item.orderStatus !== 2) return;
                const now = moment();
                const last = moment(item.gmtLastPayment);
                const diff = last.diff(now, 'seconds');
                if (diff >= 0) {
                    let minute = Math.floor(diff / 60);
                    minute = minute > 9 ? minute : '0' + minute;
                    let second = diff % 60;
                    second = second > 9 ? second : '0' + second;
                    item.countdownToPayment = `${minute}分${second}秒`;
                    if (diff === 0) _this.req_list(_this.form);
                } else {
                    item.countdownToPayment = '00分00秒'
                }
            });
        },
        search () {
            this.form.currentPage = 1;
            this.req_list(this.form);
        },
        reset () {
            const pageSize = this.form.pageSize;
            const orderStatus = this.form.orderStatus;
            this.departDate = null;
            this.createDate = null;
            this.fromCity = null;
            this.toCity = null;
            this.form = {
                currentPage: 1,
                pageSize,
                orderNo: '',
                trainNo: '',
                userName: '',
                gmtStart: '',
                gmtEndTime: '',
                gmtCreateStart: '',
                gmtCreateEnd: '',
                // passengerName: '',
                fromStationCode: '',
                toStationCode: '',
                orderType: '',
                orderStatus,
            };
            this.req_list(this.form);
        },
        fold_change () {
            this.show = !this.show;
        },
        select_state (val) {
            this.form.orderStatus = val;
            this.form.currentPage = 1;
            this.req_list(this.form);
        },
        onUpdate() {
            const __this = this;
            __this.$router.push({
                name: 'admin-trip-update',
            });
        },
        to_detail (orderNo) {
            const __this = this;
            __this.$router.push({
                name: 'admin-trip-edit',
                query: {
                    orderNo,
                }
            });
        },
        handleSizeChange (pageSize) {
            this.form.pageSize = pageSize;
            this.form.currentPage = 1;
            this.req_list(this.form, 'pageSize');
        },
        handleCurrentChange (currentPage) {
            this.form.currentPage = currentPage;
            this.req_list(this.form, 'currentPage');
        }
    },
    mounted () {

    },
    activated () {
        this.req_tab();
        this.req_list(this.form);
    },
    deactivated () {
        clearInterval(this.timer);
    },
    watch: {
        departDate (val) {
            const form = this.form;
            form.gmtStart = val ? val[0] : '';
            form.gmtEndTime = val ? val[1] : '';
        },
        createDate (val) {
            const form = this.form;
            form.gmtCreateStart = val ? val[0] : '';
            form.gmtCreateEnd = val ? val[1] : '';
        },
        fromCity (val) {
            this.form.fromStationCode = val ? val.stationCode : '';
        },
        toCity (val) {
            this.form.toStationCode = val ? val.stationCode : '';
        }
    }
}
