import moment from 'moment';
import getDailyReportData from '@/lib/data-service/statistics/get_daily_report_data'


export default {
    components: {},
    data() {
        return {
            dailyDate: '',
            GetDailyReportDataRequest: {
                date: ''
            },
            dailyOrderDataList: [],
            dailyUserDataList:[],
        };
    },
    methods: {
        getYesterday() {
            this.dailyDate = moment().add(-1, 'days').format('YYYY-MM-DD');
            this.GetDailyReportDataRequest.date = this.dailyDate;
        },

        getDaily() {
            getDailyReportData(this.GetDailyReportDataRequest).then(res => {
                this.dailyOrderDataList = res.orderList;
                this.dailyUserDataList = res.userList;
            })
        },

        dailyChange() {
            const tDate = this.dailyDate;
            const dGate = moment(tDate);
            const date = dGate.format(`YYYY-MM-DD`);
            // debugger
            this.GetDailyReportDataRequest.date = date
            this.getDaily();
        },

        // dailyTableRowClassName({ row, rowIndex }) {
        //     if (row.bizType === 99) {
        //         return 'total-row';
        //     }
        //     return '';
        // }
    },
    created() {
        this.getYesterday();
        this.getDaily();
        // const __this = this;
    },
    activated() {
        // const __this = this;
    },
    deactivated() {
        // const __this = this;
    },
}
