import import_latest_version_module from '@/component/admin-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/admin/customer-person-list',
            name: 'admin-customer-person-list',
            component: () => import(/* webpackChunkName: "page-admin-customer-management-person-list" */ `@/page/admin/person-management/person-management/index.vue`),
            meta: {
                entity_for_history_tag: {
                    name: `商务代理列表`,
                },
                entity_list_for_breadcrumb: [
                    {name: `客户管理`},
                    {name: `商务代理列表`},
                ],
                onlyCheckDetail: true
            },
        },
    ],
};