// web_settleAccounts_getSettlementProcessingList 获取结算处理列表

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口地址：http://192.168.0.15:8080/doc.html#/所有接口/settle-accounts-controller/getSettlementProcessingListUsingPOST

export default function web_settleAccounts_getSettlementProcessingList(pParameter) {
  if (!pParameter) pParameter = {};
  /*const currentPage = pParameter.currentPage || 1
  const pageSize = pParameter.pageSize || 10
  const orderNo = pParameter.orderNo || ''
  const productNo = pParameter.productNo || ''
  const productName = pParameter.productName || ''
  const supplierAccount = pParameter.supplierAccount || ''
  const companyName = pParameter.companyName || ''
  const startSettleDate = pParameter.startSettleDate || ''
  const endSettleDate = pParameter.endSettleDate || ''
  const actualStartSettleDate = pParameter.actualStartSettleDate || ''
  const actualEndSettleDate = pParameter.actualEndSettleDate || ''
  const settleType = pParameter.settleType || ''
  const settleStatus = pParameter.settleStatus || ''*/

  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/settleAccounts/getSettlementProcessingList'
  /*params.data = {
    currentPage,
    pageSize,
    orderNo,
    productNo,
    productName,
    supplierAccount,
    companyName,
    startSettleDate,
    endSettleDate,
    actualStartSettleDate,
    actualEndSettleDate,
    settleType,
    settleStatus
  }*/
  params.data = pParameter
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}
