// web_staffManage_platformAddStaff 新添加平台员工信息（NEW）

const __request = require(`@/lib/data-service/default/__request/__request_contentType_json`);

// 接口地址：http://192.168.0.19:9999/doc.html#/yinzhilv_travel/staff-management-controller/platformAddStaffUsingPOST

export default function web_staffManage_platformAddStaff(pParameter) {
    if (!pParameter) pParameter = {};
    let params = {}
    params.method = 'POST'
    params.urlSuffix = '/web/staffManage/platformAddStaff'
    params.data = pParameter
    return new Promise(function (resolve, reject) {
        resolve(__request(params))
    })
}