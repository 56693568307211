import Q from 'q'
import get_token from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/get_token';
import set_token from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/set_token';
import consumer_tokenLogin from '@/lib/data-service/haolv-default/consumer_tokenLogin';

const tokenLogin = function () {
    return Q.when()
        .then(function () {
            return get_token();
        })
        .then(function (res) {
            return consumer_tokenLogin({
                token: res
            })
        })
        .then(function (res) {
            console.log('res.datas.access_token', res.datas.access_token)
            return set_token({
                token: res.datas.access_token
            })
        })
        .catch(function (error) {
            console.log('consumer_tokenLogin error', error)
        });
}

export default tokenLogin;
