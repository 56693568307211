

const __request = require(`./__request/__request_contentType_form`);

export default function (pParameter) {
    let params = {}
    params.method = 'POST'
    // params.urlSuffix = 'hotel/manager/base/hotelNoLongerShowing';
    params.urlSuffix = '/hotel/platFormBase/hotelNoLongerShowing';

    params.data = pParameter;

    return new Promise(function (resolve, reject) {
        resolve(__request(params))
    })
}
