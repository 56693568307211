import Q from 'q';
import moment from 'moment';


// 城市插件
import TrainCitySelector from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/train-city-selector/1.1.0/index.vue';

// 火车票订单列表
import trains_platformOrder_getPlatformOrderList from '@/lib/data-service/train/trains_platformOrder_getPlatformOrderList';
// tab
import trains_common_getOrderStatusEnum from '@/lib/data-service/train/trains_common_getOrderStatusEnum';
import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client";

export default {
    components: {
        TrainCitySelector
    },
    data () {
        return {
            loading: false,
            form: {
                currentPage: 1,
                pageSize: 10,
                value: '',
            },
            list: [],
            pager: {
                total: 0,
                pageSizes: [10, 20, 30, 40, 50]
            }
        }
    },
    methods: {
        handleUpload(file) {
            const __this = this;
            const size = file.size / 1024 / 1024;
            if (size > 5) {
                return this.$message.error("文件大小不能超过5M");
            }
            const aOssClient = new OssClient();
            aOssClient.multipartUpload({
                file: file.raw,
                progress: function* (p) {}
            }).then(function (data) {
                __this.$message.success("文件上传成功");
            })
        },
        req_tab (params, type) {
            this.loading = true;
            Q.when().then(function () {
                return trains_platformOrder_getPlatformOrderList(params);
            }).then(function (res) {
            })
        },
        handleSizeChange (pageSize) {
            this.form.pageSize = pageSize;
            this.form.currentPage = 1;
            this.req_list(this.form, 'pageSize');
        },
        handleCurrentChange (currentPage) {
            this.form.currentPage = currentPage;
            this.req_list(this.form, 'currentPage');
        },
        onDownload(row) {

        }
    },
    mounted () {

    },
    activated () {
        this.req_tab();
    },
    deactivated () {
    },
}
