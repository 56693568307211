import Pagination from '@/component/base-pagination/index.vue'
import web_common_user_getDeveloper from '@/lib/data-service/default/web_common_user_getDeveloper'
import web_common_credit_cutCreditBill from '@/lib/data-service/default/web_common_credit_cutCreditBill'
// import flight_travel_refund_amount from '@/lib/data-service/flight/flight_travel_refund_amount'
import web_common_credit_updateReceivable from '@/lib/data-service/default/web_common_credit_updateReceivable'
export default {
  data () {
    return {
      loading: false,
      params: {
        accountManager: '',
        accountName: '',
        companyName: '',
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      accountManagerList: [],
      list: []
    }
  },
  components: {Pagination},
  created () {
  },
  mounted () {

  },
  activated () {
    this.getDeveloperList()
    this.getCutCreditBill()
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
    getDeveloperList() {
      web_common_user_getDeveloper().then((res) => {
        this.accountManagerList = res.getAllDeveloperResult
      })
    },
    onSearch() {
      this.params.currentPage = 1
      this.getCutCreditBill()
    },
    onReset() {
      this.params = {
        accountManager: '',
        accountName: '',
        companyName: '',
        currentPage: 1,
        pageSize: 10,
        total: 0
      }
      this.onSearch()
    },
    getCutCreditBill() {
      this.loading = true
      web_common_credit_cutCreditBill(this.params).then(res => {
        this.loading = false
        this.list = res.pageResult.pageData
        this.params.total = res.pageResult.totalCount
      }).catch(() => {
        this.loading = false
      })
    },
    goDetail(val, index) {
      this.$router.push({
        name: 'admin-back-amount-detail',
        query: {
          companyId: val.companyId
        }
      })
    },
    // 更新回款信息
    updateMsg(val, index) {
      this.loading = true
      let params = {
        companyId: val.companyId,
        businessType: val.businessType,
        orderNoList: val.orderNoList
      }
      web_common_credit_updateReceivable(params).then(res => {
        // let item = this.list[index]
        // item.actualReturnTotal = res.refundAmount
        // this.list.splice(index, 1, item)
        this.loading = false
        // 更新整个list
        this.getCutCreditBill()
      }).catch(() => {
        this.loading = false
      })
    },
  }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
