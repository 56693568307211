var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-card',{staticClass:"mb-20",attrs:{"shadow":"never"}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"header-wrap"},[_c('div',{staticClass:"header"},[_c('div',[_vm._v("路线设置")]),_c('div',[_vm._v("该供应商默认设置平台服务费类型为：百分比，平台服务费为：0")]),_c('div',[_vm._v("作为供应商提供旅游商品时设置线路费用")])]),_c('div',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onBindRoute}},[_vm._v("绑定路线")])],1)])]),_c('div',[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData}},[_c('el-table-column',{attrs:{"prop":"id","label":"线路编号","align":"center"}}),_c('el-table-column',{attrs:{"prop":"lineName","label":"线路名称","align":"center"}}),_c('el-table-column',{attrs:{"prop":"attachTypeMsg","label":"附加类型","align":"center"}}),_c('el-table-column',{attrs:{"prop":"serviceFeeTypeMsg","label":"平台服务费收取方式","align":"center"}}),_c('el-table-column',{attrs:{"label":"平台服务费收取金额","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.serviceFeePercent(row)))]}}])}),_c('el-table-column',{attrs:{"label":"订金类型","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.settleDepositTypeMsg(row)))]}}])}),_c('el-table-column',{attrs:{"label":"订金收取方式","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.settleDepositType != 0 ? row.settleDepositTypeMsg : '--'))]}}])}),_c('el-table-column',{attrs:{"label":"订金收取金额","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.settleDeposit(row)))]}}])}),_c('el-table-column',{attrs:{"label":"尾款周期","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("出团前"+_vm._s(row.finalPaymentDays)+"天")]}}])}),_c('el-table-column',{attrs:{"label":"压款类型","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.pressAmountType === 0 ? '不压款' : '压款'))]}}])}),_c('el-table-column',{attrs:{"label":"压款方式","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.pressAmountType === 0 ? '--' : row.pressAmountTypeMsg))]}}])}),_c('el-table-column',{attrs:{"label":"压款金额","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.pressAmount(row)))]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"压款周期","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("出团后"+_vm._s(row.presslPaymentCycle)+"天")]}}])}),_c('el-table-column',{attrs:{"prop":"address","width":"150","label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.onEdit(row)}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"type":"danger"},on:{"click":function($event){return _vm.onDelete(row)}}},[_vm._v("删除")])]}}])})],1)],1),_c('div',{staticClass:"pagination"},[_c('el-pagination',{attrs:{"current-page":_vm.form.currentPage,"page-sizes":[10, 20, 30, 40, 50],"page-size":_vm.form.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.totalCount},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }