import flight_order_report_statistic from '@/lib/data-service/flight/flight_order_report_statistic'
import consumer_flight_report_revenueStatistics from '@/lib/data-service/haolv-default/consumer_flight_report_revenueStatistics'
import flight_order_chart_statistic from '@/lib/data-service/flight/flight_order_chart_statistic'
import consumer_flight_report_dataStatistics from '@/lib/data-service/haolv-default/consumer_flight_report_dataStatistics'
import moment from "moment";
import LineCharts from '@/component/echart-component/line/index.vue'
export default {
    data() {
        return {
            defaultText: '--',
            loading: false,
            tmcLoading: false,
            autoRefresh: false,
            setIntervalForAutoRefresh: '',
            dailyDate: [],
            form: {
                orderTimeBegin: '',
                orderTimeEnd: '',
            },
            flightDataList: [],
            tmcDataInfo: {},

            chartDailyDate: [],
            chartForm: {
                orderTimeBegin: '',
                orderTimeEnd: '',
            },
            flightChartDataList: [],
            tmcChartDataList: [],
            chartLoading: false,
            tmcChartLoading: false,

        }
    },
    components: {LineCharts},
    created() {
    },
    mounted() {
        this.autoLoading = false
        // 近一周
        const start = moment().subtract(7, "days").format("YYYY-MM-DD 00:00:00")
        const todayStart = moment().format('YYYY-MM-DD 00:00:00')
        const end = moment().format('YYYY-MM-DD 23:59:59')
        this.dailyDate = [todayStart, end]
        this.form = {
            orderTimeBegin: todayStart,
            orderTimeEnd: end,
        }
        this.chartDailyDate = [start, end]
        this.chartForm = {
            orderTimeBegin: start,
            orderTimeEnd: end,
        }
        this.search()
        this.chartSearch()
    },
    activated() {

    },
    deactivated() {

    },
    destroyed() {
        clearInterval(this.setIntervalForAutoRefresh)
    },
    watch: {},
    computed: {
        markLoading() {
            return this.loading || this.tmcLoading || this.chartLoading || this.tmcChartLoading
        },
        getDateForTodayBtn() {
            const today = moment().format('YYYY-MM-DD')
            const dailyDate = this.dailyDate
            const startDate = dailyDate && dailyDate[0] ? moment(dailyDate[0]).format('YYYY-MM-DD') : ''
            const endDate = dailyDate && dailyDate[1] ? moment(dailyDate[1]).format('YYYY-MM-DD') : ''
            if (today === startDate && today === endDate) {
                return 'primary'
            } else {
                return ''
            }
        }
    },
    filters: {},
    methods: {
        changeDate(val) {
            if (val === '' || val === null) {
                this.form.orderTimeBegin = ''
                this.form.orderTimeEnd = ''
            } else {
                this.form.orderTimeBegin = val[0] + ' 00:00:00'
                this.form.orderTimeEnd = val[1] + ' 23:59:59'
            }
        },
        search() {
            if (this.form.orderTimeBegin === '') {
                this.$message({
                    type: "warning",
                    message: '请先选择时间范围'
                })
                return;
            }
            this.loading = true
            flight_order_report_statistic(this.form).then(res => {
                this.flightDataList = res.dataList
            }).catch(() => {
                //this.flightDataList = [{typeTxt: '机票订单数', type: 1, count: 11}, {typeTxt: '机票订单数', type: 2, count: 11}, {typeTxt: '机票订单数', type: 3, count: 11}, {typeTxt: '机票订单数', type: 4, count: 11}]
            }).finally(() => {
                this.loading = false
            })
            this.tmcLoading = true
            consumer_flight_report_revenueStatistics(this.form).then(res => {
                this.tmcDataInfo = res.datas
            }).finally(() => {
                this.tmcLoading = false
            })
        },
        searchToday() {
            let today = moment().format('YYYY-MM-DD')
            this.form = {
                orderTimeBegin: today + ' 00:00:00',
                orderTimeEnd: today + ' 23:59:59'
            }
            this.dailyDate = [today, today]
            this.search()
        },
        searchWeek() {
            const start = moment().weekday(1).format('YYYY-MM-DD'); //本周一
            const end = moment().weekday(7).format('YYYY-MM-DD'); //本周日
            this.form = {
                orderTimeBegin: start + ' 00:00:00',
                orderTimeEnd: end + ' 23:59:59'
            }
            this.dailyDate =  [start, end]
            this.search()
        },
        searchMonth() {
            const start = moment().startOf('month').format('YYYY-MM-DD');
            const end = moment().endOf('month').format('YYYY-MM-DD');
            this.form = {
                orderTimeBegin: start + ' 00:00:00',
                orderTimeEnd: end + ' 23:59:59'
            }
            this.dailyDate =  [start, end]
            this.search()
        },
        getFlightColor(val) {
            // 类型，1-机票张数，2-机票交易流水，3-机票毛利，4-机票毛利率
            let className = ''
            switch(val) {
                case 1:
                    className = 'green'
                    break
                case 2:
                    className = 'red'
                    break
                case 3:
                    className = 'blue'
                    break
                case 4:
                    className = 'yellow'
                    break
                default:
                    className = ''
            }
            return className
        },
        changeChartDate(val) {
            if (val === '' || val === null) {
                this.chartForm.orderTimeBegin = ''
                this.chartForm.orderTimeEnd = ''
            } else {
                this.chartForm.orderTimeBegin = val[0] + ' 00:00:00'
                this.chartForm.orderTimeEnd = val[1] + ' 23:59:59'
            }
        },
        chartSearch() {
            if (this.chartForm.orderTimeBegin === '') {
                this.$message({
                    type: "warning",
                    message: '请先选择时间范围'
                })
                return;
            }
            this.chartLoading = true
            flight_order_chart_statistic(this.chartForm).then(res => {
                let dataList = res.dataList
                dataList.forEach(value => {
                    value.type = 1
                    value.typeText = '机票'
                })
                this.flightChartDataList = dataList
                this.$refs['flightLine'].init({
                    title: '机票张数',
                    type: 'line',
                    data: this.flightChartDataList,
                    valueForX: 'orderTime',
                    valueForY: 'orderCount',
                    valueYType: 'type',
                    showLegend: true,
                    valueForTypeName: 'typeText',
                    xLabelShowWeek: true
                })
            }).finally(() => {
                this.chartLoading = false
            })
            this.tmcChartLoading = true
            consumer_flight_report_dataStatistics(this.chartForm).then(res => {
                let data = res.datas
                data.flightList.forEach(value => {
                    value.type = 1
                    value.typeText = '机票'
                    value.orderCount = parseInt(value.orderCount)

                })
                data.hoteList.forEach(value => {
                    value.type = 2
                    value.typeText = '酒店'
                    value.orderCount = parseInt(value.orderCount)
                })
                data.trainList.forEach(value => {
                    value.type = 3
                    value.typeText = '火车票'
                    value.orderCount = parseInt(value.orderCount)
                })
                data.carList.forEach(value => {
                    value.type = 4
                    value.typeText = '用车'
                    value.orderCount = parseInt(value.orderCount)
                })
                let list = []
                list = list.concat(data.flightList, data.hoteList, data.trainList, data.carList)
                this.tmcChartDataList = list
                this.$refs['tmcLine'].init({
                    title: 'TMC各业务订单数',
                    type: 'line',
                    data: this.tmcChartDataList,
                    valueForX: 'orderTime',
                    valueForY: 'orderCount',
                    valueYType: 'type',
                    showLegend: true,
                    valueForTypeName: 'typeText',
                    xLabelShowWeek: true
                })
            }).finally(() => {
                this.tmcChartLoading = false
            })
        },
        searchChartWeek() {
            const start = moment().weekday(1).format('YYYY-MM-DD'); //本周一
            const end = moment().weekday(7).format('YYYY-MM-DD'); //本周日
            this.chartForm = {
                orderTimeBegin: start + ' 00:00:00',
                orderTimeEnd: end + ' 23:59:59'
            }
            this.chartDailyDate = [start, end]
            this.chartSearch()
        },
        searchChartMonth() {
            const start = moment().startOf('month').format('YYYY-MM-DD');
            const end = moment().endOf('month').format('YYYY-MM-DD');
            this.chartForm = {
                orderTimeBegin: start + ' 00:00:00',
                orderTimeEnd: end + ' 23:59:59'
            }
            this.chartDailyDate =  [start, end]
            this.chartSearch()
        },
        changeAutoRefresh(val) {
            if (val) {
                this.setIntervalForAutoRefresh = setInterval(() => {
                    this.search()
                    this.chartSearch()
                }, 1000 * 60 * 60)
            } else {
                clearInterval(this.setIntervalForAutoRefresh)
            }
        }
    }
}
