// web_buyer_updateSupplierBoundBuyer 可批量修改供应商和分销商流水绑定状态

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口文档地址： http://192.168.0.15:8080/doc.html#/所有接口/buyer-controller/updateSupplierBoundBuyerUsingPOST
export default function web_buyer_updateSupplierBoundBuyer(pParameter) {
  let params = {}
  if (!pParameter) pParameter = {};
  /*const buyerIds = pParameter.buyerIds || ''
  const supplierIds = pParameter.supplierIds || ''
  const boundStatus = pParameter.boundStatus || ''*/
  params.method = 'POST'
  params.urlSuffix = '/web/buyer/updateSupplierBoundBuyer'
  params.data = pParameter
  /*params.data = {
    buyerIds,
    supplierIds,
    boundStatus
  }*/
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}

