

const __request = require(`@/lib/data-service/default/__request/__request_contentType_json`);

// 取消订单列表接口地址： http://192.168.0.9:8080/doc.html#/yinzhilv_travel/credit-account-controller/countermandOdListUsingPOST
export default function web_common_credit_countermandOdList(pParameter) {
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/common/credit/countermandOdList';
  params.data = pParameter;

  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
