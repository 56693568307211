

const __request = require(`@/lib/data-service/default/__request/__request_contentType_json`);

// 启用接口地址：http://192.168.0.11:8080/swagger-ui.html#/personage-controller/startUsingPersonageUsingPOST

export default function web_personage_startUsingPersonage(pParameter) {
  if (!pParameter) pParameter = {};
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/personage/startUsingPersonage'
  params.data = pParameter
  params.process_response_data = false
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}
