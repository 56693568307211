import web_credit_getCreditProducts from '@/lib/data-service/default/web_credit_getCreditProducts' // 获取产品列表
import web_credit_updateOrCreateProduct from '@/lib/data-service/default/web_credit_updateOrCreateProduct' // 新增或修改授信产品
import Pagination from '@/component/base-pagination/index.vue'

export default {
    data () {
        return {
            loading: false,
            showMore: false,
            params: {
                allStatus: true,
                productType: '1',

                current: 1,
                size: 10,
                total: 0
            },
            form: {
                id: '',
                creditPaymentDays: '',
                creditPaymentRate: '',
                productType: '1',
                productName: '',
                remark: '',
                productStatus: 1
            },
            formRules: {
                creditPaymentDays: [
                    {required: true, message: '请输入账期天数', trigger: 'blur' }
                ],
                creditPaymentRate: [
                    {required: true, message: '请输入费率', trigger: 'blur' }
                ],
                productType: [
                    {required: true, message: '请选择产品类型', trigger: 'blur' }
                ],
            },
            list: [],
            accountManagerList: [],
            productTypeList: [
                {label: '全款授信', value: '1'},
                {label: '轻松付', value: '2'},
            ],
            dialogVisible: false
        }
    },
    components: {
        Pagination
    },
    methods: {
        getCreditProduct() {
            const __this = this;
            __this.loading = true;
            web_credit_getCreditProducts(__this.params).then(res => {
                const list = res.creditProducts;
                __this.list = list;
                __this.params.total = res.total;
                __this.loading = false
            }).catch(() => {
                __this.loading = false
            }, new Error(() => {
                __this.loading = false
            }))
        },
        handleClick() {
            const __this = this;
            __this.form.productType = __this.params.productType;
            __this.getCreditProduct();
        },
        onAdd() { // 添加
            const __this = this;
            __this.dialogVisible = true;
        },
        onEdit(row) { // 编辑
            const __this = this;
            __this.form = {
                id: row.id,
                creditPaymentDays: row.creditPaymentDays,
                creditPaymentRate: row.creditPaymentRate,
                productType: row.productType.toString(),
                productName: row.productName,
                remark: row.remark,
                productStatus: row.productStatus
            };
            __this.dialogVisible = true;
        },
        onConfirm() {
            const __this = this;
            __this.$refs['form'].validate((valid) => {
                if (valid) {
                    __this.form.productName = `${__this.form.creditPaymentDays}天,  ${__this.form.creditPaymentRate}%`;
                    web_credit_updateOrCreateProduct(__this.form).then(res => {
                        __this.dialogVisible = false;
                        __this.getCreditProduct();
                    }).catch(() => {
                        __this.dialogVisible = false;
                    }, new Error(() => {
                        __this.dialogVisible = false;
                    }))
                }
            });
        },
        onEnable(row) { // 启用
            const __this = this;
            __this.form = {
                id: row.id,
                creditPaymentDays: row.creditPaymentDays,
                creditPaymentRate: row.creditPaymentRate,
                productType: row.productType,
                remark: row.remark,
                productStatus: 1
            };
            web_credit_updateOrCreateProduct(__this.form).then(res => {
                __this.$message.success('启用成功！');
                __this.getCreditProduct();
            }).catch(() => {
                __this.$message.success('启用失败！');
            })
        },
        toStop(row) { // 停用
            const __this = this;
            __this.form = {
                id: row.id,
                creditPaymentDays: row.creditPaymentDays,
                creditPaymentRate: row.creditPaymentRate,
                productType: row.productType,
                remark: row.remark,
                productStatus: 3
            };
            web_credit_updateOrCreateProduct(__this.form).then(res => {
                __this.$message.success('停用成功！');
                __this.getCreditProduct();
            }).catch(() => {
                __this.$message.success('停用失败！');
            })
        },
        handleClose() {
            const __this = this;
            __this.$refs['form'].resetFields();
            __this.form = {
                id: '',
                creditPaymentDays: '',
                creditPaymentRate: '',
                productType: __this.params.productType,
                remark: '',
                productStatus: 1
            };
        }
    },
    activated () {
        const __this = this;
        __this.getCreditProduct();
    },
}
