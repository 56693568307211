const __request = require(`./__request/__request_contentType_json`);

// 审核微商城
// http://tour-alpha.yinzhilv.com/doc.html#/yinzhilv_travel/btoc-controller/btocCheckSumbitUsingPOST
const request = (pParameter) => {
    const p = {
        method: 'post',
        urlSuffix: '/web/btoc/check/sumbit',
        data: pParameter,
    };
    return new Promise(function (resolve) {
        resolve(__request(p))
    })
};
module.exports = request;