import extend from 'extend';
import Q from 'q';
import _ from 'underscore';

import aRouter from "../index";
import all_routes_processor from "./all_routes_processor";
import check_token_processor from "./check_token_processor";

const init_route_guard = function () {
    const get_all_route_list = all_routes_processor.get_all_route_list;

    aRouter.beforeEach((to, from, next) => {
        try {

            Q.when()
                .then(function () {
                    return check_token_processor.next({to, from, next});
                })
                .then(function () {
                    return next();
                })
                .catch(function (ex) {
                    if (ex) {
                        const enable_log_error = !ex.disable_log_error;
                        if (enable_log_error) {
                            console.error(ex);
                            console.trace();
                            if (ex.stack) {
                                console.error(ex.stack);
                            }
                        }
                    }
                    return Q.reject(ex);
                })

        } catch (ex) {
            console.error(ex);
        }
    });
};
export default init_route_guard;
