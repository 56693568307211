// flight_order_report_statistic
const __request = require(`./__request/__request_contentType_json`);

export default function flight_order_report_statistic(pParameter) {
    if (!pParameter) pParameter = {};
    let params = {}
    params.method = 'POST'
    params.urlSuffix = '/flight/order/report/statistic'
    params.data = pParameter
    return new Promise(function(resolve, reject) {
        resolve(__request(params))
    })
}