import Q from 'q';

import moment from "moment";

// 平台订单详情
import web_salesOrder_tourOrderDetails from '@/lib/data-service/default/web_salesOrder_tourOrderDetails';
import format from "element-ui/src/locale/format";


export default {
  data() {
    return {
      detail: {},
      loading: false,
      state: 1,
      stateList: [
        {text: '待确认', key: 1},
        {text: '待支付', key: 2},
        {text: '已支付', key: 3},
        {text: '确认出团', key: 4},
        {text: '已出团', key: 5},
        {text: '已回团', key: 6},
        {text: '已分账', key: 7},
      ]
    };
  },
  methods: {
    req_detail (params) {
      this.loading = true;
      const _this = this;
      Q.when()
        .then(function () {
          return web_salesOrder_tourOrderDetails(params);
        })
        .then(function (res) {
          const detail = res.result;
          const reg = /\$\$/g;
          detail.fromCityText = detail.setOutCityName.replace(reg, ',');
          detail.toCityText = detail.aimCityName.replace(reg, ',');
          switch (detail.orderState) {
            case 0:
              detail.orderStateText = '审核失败';
              _this.state = 1;
              break;
            case 1:
              detail.orderStateText = '待供应商确认';
              _this.state = 1;
              break;
            case 2:
              detail.orderStateText = '待平台确认';
              _this.state = 1;
              break;
            case 3:
              detail.orderStateText = '待支付';
              _this.state = 2;
              break;
            case 4:
              detail.orderStateText = '已支付';
              _this.state = 3;
              break;
            case 5:
              detail.orderStateText = '确认出团';
              _this.state = 4;
              break;
            case 6:
              detail.orderStateText = '已出团';
              _this.state = 5;
              break;
            case 7:
              detail.orderStateText = '已回团';
              _this.state = 6;
              break;
            case 8:
              detail.orderStateText = '已分账';
              _this.state = 7;
              break;
            case 9:
              detail.orderStateText = '新增订单';
              _this.state = 1;
              break;
            default:
              _this.state = 1;
          }


          switch (detail.attachType) {
            case 1:
              detail.attachTypeText = '加价';
              break;
            case 0:
              detail.attachTypeText = '减免';
              break;
          }
          switch (detail.depositType) {
            case 0:
              detail.depositTypeText = '无订金';
              break;
            case 1:
              detail.depositTypeText = '百分比';
              break;
            case 2:
              detail.depositTypeText = '固定金额';
              break;
          }

          switch (detail.attachAmountType) {
            case 1:
              detail.attachAmountTypeText = '固定金额';
              break;
            case 2:
              detail.attachAmountTypeText = '百分比';
              break;
          }

          switch (detail.buyerCarryRules) {
            case 0:
              detail.buyerCarryRulesText = '进位到元';
              break;
            case 1:
              detail.buyerCarryRulesText = '进位到分';
              break;
          }

          detail.tourTravellerInformations.forEach(function (item) {
            if (item.birthDate) item.birthDateText = moment(item.birthDate).format('YYYY-MM-DD');
            switch (item.crowdType) {
              case 1:
                item.crowdTypeText = '成人';
                break;
              case 2:
                item.crowdTypeText = '儿童不占床';
                break;
              case 3:
                item.crowdTypeText = '老人';
                break;
              case 4:
                item.crowdTypeText = '儿童占床';
                break;
            };

            switch (item.touristGender) {
              case 0:
                item.sex = '男';
                break;
              case 1:
                item.sex = '女';
                break;
            };


            switch (item.certificatesType) {
              case 1:
                item.certificate = '身份证';
                break;
              case 2:
                item.certificate = '护照';
                break;
              case 3:
                item.certificate = '军官证';
                break;
              case 4:
                item.certificate = '学生证';
                break;
              case 5:
                item.certificate = '其他';
                break;
            };
          });
          _this.detail = detail;
          _this.loading = false;
        })
    }
  },
  created() {},
  mounted() {},
  activated() {
    this.req_detail({id: this.$route.query.id});
  },
  deactivated() {},
  destroyed() {},
  watch: {

  },
  computed: {}
};
