// web_common_getPhone

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口文档地址： http://192.168.0.15:8080/doc.html#/所有接口/
export default function web_common_getPhone(pParameter) {
  let params = {}
  if (!pParameter) pParameter = {};
  params.method = 'POST'
  params.urlSuffix = '/web/common/getPhone'
  params.data = ''
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
