// web_managerSupplier_updateSupplierBoundBuyer 可批量修改供应商和分销商流水绑定状态

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口地址： http://192.168.0.15:8080/doc.html#/所有接口/manager-supplier-controller/updateSupplierBoundBuyerUsingPOST_1

export default function web_managerSupplier_updateSupplierBoundBuyer(pParameter) {
  if (!pParameter) pParameter = {};
  const buyerIds = pParameter.buyerIds || ''
  const supplierIds = pParameter.supplierIds || ''
  const boundStatus = pParameter.boundStatus === undefined ? '' : pParameter.boundStatus
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/managerSupplier/updateSupplierBoundBuyer'
  params.data = {
    buyerIds,
    supplierIds,
    boundStatus
  }
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
