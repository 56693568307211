import Q from 'q'
import history_tag_api from '@/component/admin-layout/1.0.0/component/history-tag/api';
// 账户信息
// import web_common_getCurrentUserAuditStatus from '@/lib/data-service/default/web_common_getCurrentUserAuditStatus.js';

// 重置
import web_user_resetPassword from '@/lib/data-service/default/web_user_resetPassword.js';


export default {
    data() {
        var newPassword = (rules, value, callback) => {
            const password = this.form.password;
            if (value === '' || value === undefined) {
                callback(new Error('请输入新密码'));
            } else if (value.length < 6) {
                callback(new Error('密码长度不能少于6位'));
            } else if (/\W/g.test(value) || !/\d/g.test(value) || !/[a-zA-Z]/g.test(value)) {
                callback(new Error('密码必须数字与字母组合'));
            } else if (value === password) {
                callback(new Error('新密码不能与原密码相同'));
            } else {
                callback();
            }
        };

        var verifyNewPassword = (rules, value, callback) => {
            const newPassword = this.form.newPassword;
            if (value === '' || value === undefined) {
                callback(new Error('请再次确认密码'));
            } else if (value.length < 6) {
                callback(new Error('密码长度不能少于6位'));
            } else if (value !== newPassword) {
                callback(new Error('两次密码不一致'));
            } else {
                callback();
            }
        };

        return {
            successVisible: false,
            failVisible: false,
            id: '',
            form: {
                userName: '',
                newPassword: '',
            },
            rules: {
                userName: [
                    {required: true, message: '请输入账号', trigger: 'blur'}
                ],
                newPassword: [
                    {required: true, validator: newPassword, trigger: 'blur'}
                ],
            },
            tipsMsg: ''
        };
    },
    methods: {
        req_info(params) {
            const _this = this;
            Q.when().then(function () {
                return web_common_getCurrentUserAuditStatus(params);
            }).then(function (res) {
                _this.id = res.userVo.id;
            }).catch(function (err) {
                console.log(err)
            })
        },
        req_reset(params) {
            const _this = this;
            Q.when().then(function () {
                return web_user_resetPassword(params);
            }).then(function (res) {
                _this.successVisible = true;
                _this.tipsMsg = '重置密码成功';
            }).catch(function (err) {
                _this.failVisible = true;
                _this.tipsMsg = err.data.msg;
            })
        },
        commit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.req_reset(this.form);
                } else {
                    return false;
                }
            })
        },
        cancel() {
            this.$refs.userName.passwordVisible = false;
            this.$refs.newPassword.passwordVisible = false;
            this.$refs.form.resetFields();
        },
        before_close() {
            const _this = this;
            _this.successVisible = false;
            _this.cancel();

            setTimeout(function () {
                history_tag_api.remove_tag_by_route({
                    route: _this.$route,
                });
                _this.$router.push({
                    name: 'admin-home'
                })
            }, 300)
        },
        fail_before_close() {
            const _this = this;
            _this.failVisible = false;
            _this.cancel();
        }
    },
    mounted() {

    },
    activated() {
        this.$forceUpdate();
        this.req_info();
    }
};
