const __request = require(`./__request/__request_contentType_json`);

// 分销商申请
// http://tour-alpha.yinzhilv.com/doc.html#/yinzhilv_travel/btoc-controller/btocApplySumbitUsingPOST_1
const request = (pParameter) => {
  if (!pParameter) pParameter = {};

  const buyerId = pParameter.buyerId

  const p = {
    method: 'post',
    urlSuffix: '/web/btoc/apply',
    data: {
      buyerId,
    }
  };
  return new Promise(function (resolve, reject) {
    resolve(__request(p))
  })
};
module.exports = request;