// web_common_credit_cutOrdRelieve 解除砍单状态

const __request = require(`@/lib/data-service/default/__request/__request_contentType_json`);

// 接口文档地址：
export default function web_common_credit_cutOrdRelieve(pParameter) {
  let params = {}
  if (!pParameter) pParameter = {};
  params.method = 'POST'
  params.urlSuffix = '/web/common/credit/cutOrdRelieve'
  params.data = pParameter
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
