import history_tag_api from "@/component/admin-layout/1.0.0/component/history-tag/api";
import web_btoc_check from "@/lib/data-service/default/web_btoc_check";
import web_btoc_update from "@/lib/data-service/default/web_btoc_update";
export default {
  data() {
    const validateStoreLimit = (rule, value, callback) => {
      const limitType = this.limitType;
      if (limitType == 2 && !value) {
        callback(new Error("请设置门店数"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      detail: {
        btocRecord: {},
        supplierTravelRequst: {},
        tUsBuyerAptitude: {},
      },
      form: {
        buyerId: '',
        storeLimit: '-1',
      },
      rules: {
        storeLimit: [{ validator: validateStoreLimit, trigger: "blur" }],
      },
      limitType: 1,
    };
  },
  methods: {
    req_detail() {
      this.loading = true;
      web_btoc_check({ buyerId: this.$route.query.id })
        .then((result) => {
          this.form.buyerId = result.tUsBuyerAptitude.buyerId
          this.detail = result;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    req_update () {
      this.loading = true;
      web_btoc_update(this.form)
      .then((result) => {
        this.loading = false;
        this.$message.success("修改成功");
        this.onBack();
      })
      .catch((err) => {
        this.loading = false;
      });
    },

    // 查看公司信息
    onCompanyDetail() {
      this.$router.push({
        path: "/admin/buyer-detail",
        query: {
          id: this.detail.tUsBuyerAptitude.buyerId,
        },
      });
    },

    change_limitType(val) {
      this.$refs.form.resetFields('storeLimit');
      if (val == 1) {
        this.form.storeLimit = "-1";
      } else if (val == 2) {
        this.form.storeLimit = "5";
      }
    },
    input_limit(val) {
      this.form.storeLimit = val.replace(/\D/g, "");
    },

    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.req_update();
        } else {
          return false;
        }
      });
    },
    // 返回
    onBack() {
      history_tag_api.remove_tag_by_route({
        route: this.$route,
      });
      this.$router.replace({
        path: "/admin/buyer-list",
      });
    },
  },
  activated() {
    this.req_detail();
  },
};
