// web_credit_dredgeCredit 开通分销商授信账户

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口文档地址： http://219.128.52.2:7777/swagger-ui.html#/credit-account-controller/dredgeCreditUsingPOST_1
export default function web_credit_dredgeCredit(pParameter) {
  let params = {}
  if (!pParameter) pParameter = {};
  params.method = 'POST'
  params.urlSuffix = '/web/credit/dredgeCredit'
  params.data = pParameter
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
