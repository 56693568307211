import Q from 'q';

import moment from 'moment';

// 平台订单详情
import web_common_tourCutOrder_getCutOrderDetail from '@/lib/data-service/default/web_common_tourCutOrder_getCutOrderDetail';
// 切位订单确认
import web_common_tourCutOrder_updateAuditCutOrder from '@/lib/data-service/default/web_common_tourCutOrder_updateAuditCutOrder';
import format from 'element-ui/src/locale/format';


export default {
  data() {
    const AuditContent = (rule, value, callback) => {
      if (this.form.AuditState === 0) {
        if (value === '' || value === undefined) {
          callback(new Error('请输入审核意见'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };

    return {
      detail: {},
      loading: false,
      dialogVisible: false,
      form: {
        cutOrderNo: '',
        AuditContent: '',
        AuditState: '',
      },
      formRules: {
        AuditContent: [
          { validator: AuditContent, trigger: 'blur', required: true }
        ],
      }
    };
  },
  methods: {
    req_detail (params) {
      this.loading = true;
      const _this = this;
      Q.when()
        .then(function () {
          return web_common_tourCutOrder_getCutOrderDetail(params);
        })
        .then(function (res) {
          const detail = res;
          if (detail.outDate) detail.outDate = moment(detail.outDate).format('YYYY-MM-DD');
          if (detail.returnDate) detail.returnDate = moment(detail.returnDate).format('YYYY-MM-DD');
          detail.orderDetailResults.forEach(item => {
            if (item.uploadTravellerDeadline) item.uploadTravellerDeadline = moment(item.uploadTravellerDeadline).format('YYYY-MM-DD');
            if (item.tailMoneyDate) item.tailMoneyDate = moment(item.tailMoneyDate).format('YYYY-MM-DD');
          })
          const reg = /\$\$/g;
          detail.fromCityText = detail.setOutCityName.replace(reg, ',');
          detail.toCityText = detail.aimCityName.replace(reg, ',');


          switch (detail.attachType) {
            case 1:
              detail.attachTypeText = '加价';
              break;
            case 0:
              detail.attachTypeText = '减免';
              break;
          }

          switch (detail.depositType) {
            case 0:
              detail.depositTypeText = '无订金';
              break;
            case 1:
              detail.depositTypeText = '百分比';
              break;
            case 2:
              detail.depositTypeText = '固定金额';
              break;
          }

          switch (detail.attachAmountType) {
            case 1:
              detail.attachAmountTypeText = '固定金额';
              break;
            case 2:
              detail.attachAmountTypeText = '百分比';
              break;
          }

          switch (detail.buyerCarryRules) {
            case 0:
              detail.buyerCarryRulesText = '进位到元';
              break;
            case 1:
              detail.buyerCarryRulesText = '进位到分';
              break;
          }

          detail.teamTimes = JSON.parse(detail.teamTimes);
          detail.outDate = '';
          detail.teamTimes.forEach(function (item) {
            if (!detail.outDate) {
              detail.outDate = item.team;
            } else {
              detail.outDate += ',' + item.team;
            }
          })

          _this.form.cutOrderNo = detail.cutOrderNo;
          _this.detail = detail;
          _this.loading = false;
        })
    },
    req_audit (params) {
      const _this = this;
      Q.when()
        .then(function () {
          return web_common_tourCutOrder_updateAuditCutOrder(params);
        })
        .then(function () {
          if (params.AuditState === 0) {
            _this.$message.success('设置审核不通过成功');
            _this.dialogVisible = false;
          } else if (params.AuditState === 1) {
            _this.$message.success('设置审核通过成功');
          }
          _this.$router.back(-1);
        })
    },
    to_team_detail (id) {
      this.$router.push({
        name: 'admin-plain-order-detail',
        query: { id },
      })
    },
    pass () {
      const _this = this;
      this.$confirm('是否确认订单通过?', '提示', {
        confirmButtonText: '通过',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.form.AuditState = 1;
        _this.req_audit(this.form);
      })
    },
    fall () {
      this.form.AuditState = 0;
      this.dialogVisible = true;
    },
    handleClose () {
      this.dialogVisible = false;
      this.form.AuditState = '';
      this.form.AuditContent = '';
    },
    commit () {
      const _this = this;
      this.$refs.form.validate((valid) => {
        if (valid) {
          _this.req_audit(_this.form);
        } else {
          return false;
        }
      });
    },

  },
  created() {},
  mounted() {},
  activated() {
    this.req_detail({cutOrderNo: this.$route.query.cutOrderNo});
  },
  deactivated() {},
  destroyed() {},
  watch: {

  },
  computed: {}
};
