// web_common_phoneCodeCheck 手机短信码验证

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口文档地址： http://192.168.0.13:8080/doc.html#/所有接口/send-sms-message-and-validation/validationUsingPOST_2
export default function web_common_phoneCodeCheck(pParameter) {
  let params = {}
  if (!pParameter) pParameter = {};
  const smsCode = pParameter.smsCode || ''
  const phone = pParameter.phone || ''
  params.method = 'POST'
  params.urlSuffix = '/web/common/phoneCodeCheck'
  params.data = {
    smsCode,
    phone
  }
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}

