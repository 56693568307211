// web_buyer_buyerDelete 删除采购商

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口地址：http://192.168.0.15:8080/doc.html#/所有接口/manager-supplier-controller/deleteSupplierUsingPOST

export default function web_buyer_buyerDelete(pParameter) {
  if (!pParameter) pParameter = {};
  const buyerId = pParameter.buyerId || ''
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/buyer/buyerDelete'
  params.data = {
    buyerId
  }
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}
