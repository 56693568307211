// web_circuit_line_list 线路设置的时候,供应商绑定的线路信息列表

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口地址：http://192.168.0.15:8080/doc.html#/所有接口/circuit-operate-controller/getLineListRequestUsingPOST

export default function web_circuit_line_list(pParameter) {
  if (!pParameter) pParameter = {};
  const supplierId = pParameter.supplierId || ''
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/circuit/line/list'
  params.data = {
    supplierId
  }
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}

