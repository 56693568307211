import web_staffManage_list from '@/lib/data-service/default/web_staffManage_list'
import web_staffManage_update from '@/lib/data-service/default/web_staffManage_update'
import web_common_user_updateUserStatus from '@/lib/data-service/default/web_common_user_updateUserStatus'
import web_common_user_updateUserPassword from '@/lib/data-service/default/web_common_user_updateUserPassword'
import Pagination from '@/component/base-pagination/index.vue'
export default {
  data () {
    const validateRePassword = (rule, value, callback) => {
      if (value === this.passwordParams.password) {
        callback()
      } else {
        callback(new Error('两次输入的密码不一致'))
      }
    };
    return {
      loading: false,
      params: {
        userName: '', // 账号
        realName: '', //姓名
        userStatus: '',
        id: '',
        userType: '',
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      typeList: [{value: '', label: '全部'}, {value: 1, label: '启用'}, {value: 2, label: '禁用'}],
      userList: [],
      multipleSelection: [],
      passwordParams: {
        id: '',
        password: '',
        rePassword: ''
      },
      rule: {
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
        ],
        rePassword: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { validator: validateRePassword, trigger: 'blur' }
        ]
      },
      dialogVisible: false
    }
  },
  components: {
    Pagination
  },
  created () {},
  mounted () {
    this.getUserList()
  },
  activated () {},
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
    onSearch() {
      this.params.currentPage = 1
      this.getUserList()
    },
    onReset() {
      this.params = {
        userName: '', // 账号
        realName: '', //姓名
        userStatus: '',
        id: '',
        userType: '',
        currentPage: 1,
        pageSize: 10,
        total: 0
      }
      this.onSearch()
    },
    getUserList() {
      this.loading = true
      web_staffManage_list(this.params).then(res => {
        this.loading = false
        this.userList = res.pageResult.pageData
        this.params.total = res.pageResult.totalCount
      }).catch(() => {
        this.loading = false
      })
    },
    handleCreate() {
      this.$router.push({
        name: 'admin-user-add'
      })
    },
    handleAllStart() {
      if (this.multipleSelection.length === 0) {
        this.$message({
          type: 'warning',
          message: '请先勾选要启用的账号'
        });
        return
      }
      this.$confirm('确定启用吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let idList = []
        this.multipleSelection.forEach(value => {
          idList.push(value.id)
        })
        web_common_user_updateUserStatus({ids: idList.join(','), userStatus: 1}).then(res => {
          if (res.code === '000000') {
            this.$refs.userTable.clearSelection()
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            this.getUserList()
          } else {
            this.$message({
              type: 'warning',
              message: res.msg
            });
          }
        })
      }).catch(() => {})
    },
    handleAllStop() {
      if (this.multipleSelection.length === 0) {
        this.$message({
          type: 'warning',
          message: '请先勾选要禁用的账号'
        });
        return
      }
      this.$confirm('确定禁用吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let idList = []
        this.multipleSelection.forEach(value => {
          idList.push(value.id)
        })
        web_common_user_updateUserStatus({ids: idList.join(','), userStatus: 2}).then(res => {
          if (res.code === '000000') {
            this.$refs.userTable.clearSelection()
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            this.getUserList()
          } else {
            this.$message({
              type: 'warning',
              message: res.msg
            });
          }
        })
      }).catch(() => {})
    },
    handleAllDel() {
      if (this.multipleSelection.length === 0) {
        this.$message({
          type: 'warning',
          message: '请选择要删除的的员工'
        });
        return
      }
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let idList = []
        this.multipleSelection.forEach(value => {
          idList.push(value.id)
        })
        web_common_user_updateUserStatus({ids: idList.join(','), userStatus: 0}).then(res => {
          if (res.code === '000000') {
            this.$refs.userTable.clearSelection()
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            this.getUserList()
          } else {
            this.$message({
              type: 'warning',
              message: res.msg
            });
          }
        })
      }).catch(() => {})
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    userStatusFormatter(val) {
      let status = ''
      if (val.userStatus === 1) {
        status = '启用'
      } else if (val.userStatus === 2) {
        status = '禁用'
      } else {
        status = '已删除'
      }
      return status
    },
    handleStart(val) {
      if (val.userStatus === 2) {
        this.loading = true
        web_staffManage_update({id: val.id, userStatus: 1}).then(res => {
          this.loading = false
          if (res.code === '000000') {
            this.$message({
              type: 'success',
              message: '启用成功!'
            });
            this.getUserList()
          } else {
            this.$message({
              type: 'warning',
              message: res.msg
            });
          }
        }).catch(() => {
          this.loading = false
        })
      }
    },
    handleStop(val) {
      this.$confirm('确认禁用?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (val.userStatus === 1) {
          this.loading = true
          web_staffManage_update({id: val.id, userStatus: 2}).then(res => {
            this.loading = false
            if (res.code === '000000') {
              this.$message({
                type: 'success',
                message: '禁用成功!'
              });
              this.getUserList()
            } else {
              this.$message({
                type: 'warning',
                message: res.msg
              });
            }
          }).catch(() => {
            this.loading = false
          })
        }
      }).catch(() => {

      });
    },
    handleDel(val) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        web_staffManage_update({id: val.id, userStatus: 4}).then(res => {
          this.loading = false
          if (res.code === '000000') {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getUserList()
          } else {
            this.$message({
              type: 'warning',
              message: res.msg
            });
          }
        }).catch(() => {
          this.loading = false
        })
      }).catch(() => {

      });
    },
    handleEdit(val) {
      this.$router.push({
        name: 'admin-user-set',
        query: {
          id: val.id
        }
      })
    },
    handleEditPassword(val) {
      this.reactPasswordParams()
      this.passwordParams.id = val.id
      this.dialogVisible = true
    },
    reactPasswordParams() {
      this.passwordParams = {
        id: '',
        password: '',
        rePassword: ''
      }
    },
    handleClose() {
      this.dialogVisible = false
    },
    handelSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          web_common_user_updateUserPassword(this.passwordParams).then(res => {
            if (res.code === '000000') {
              this.$message({
                message: '操作成功',
                type: 'success'
              });
              this.handleClose();
            } else {
              this.$message({
                message: res.msg,
                type: 'warning'
              });
            }
          }).catch(() => {

          })
        }
      })
    },
  }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
