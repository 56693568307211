import page_import_latest_version_module from '@/page/admin/statistical-report/marketing-report/import_latest_version_module';
export default {
    path: '/admin/marketing-report-x',
    name: 'admin-marketing-report-x',
    component: () => page_import_latest_version_module(),
    meta: {
        addClassForPadding: true,
        data_for_admin_layout: {
            data_for_history_tag: {
                entity: {
                    name: "营收报表"
                }
            },
            data_for_breadcrumb: {
                entity_list: [
                    {
                        name: "数据统计"
                    },
                    {
                        name: "营收报表"
                    }
                ]
            }
        }
    },
};
  