import Q from 'q';


// 火车票设置
import trains_systemConfig_setSystemConfig from '@/lib/data-service/train/trains_systemConfig_setSystemConfig';
// 火车票平台设置回显
import trains_systemConfig_getSystemConfig from '@/lib/data-service/train/trains_systemConfig_getSystemConfig';

// 火车票平台设置操作日志
import trains_systemConfig_getSystemConfigLogList from '@/lib/data-service/train/trains_systemConfig_getSystemConfigLogList';


export default {
  data() {
    return {
      loading: false,
      showType: 1,
      robTicketsType: '',
      serviceChargeType: '',
      allocation: {
        showType: [
          { label: 1, value: '产品设置' },
          { label: 2, value: '日志记录' }
        ],
        robTicketsType: [
          { label: '', text: '定额' }
        ],
        serviceChargeType: [
          { label: '', text: '定额' }
        ]
      },
      form: {
        robTickets: '',
        serviceCharge: '',
      },
      formRules: {
        robTickets: [
          { required: true, message: '请输入服务费', trigger: 'blur' },
        ],
        serviceCharge: [
          { required: true, message: '请输入抢票费', trigger: 'blur' },
        ],
      },
      logForm: {
        currentPage: 1,
        pageSize: 10,
      },
      logPager: {
        pageSizes: [10, 20, 30, 40, 50],
        total: 0,
      },
      logList: [],
    };
  },
  methods: {
    req_detail () {
      const _this = this;
      Q.when()
        .then(function () {
          return trains_systemConfig_getSystemConfig()
        })
        .then(function (res) {
          _this.form.serviceCharge = res.servicePrice;
          _this.form.robTickets = res.robTickets;
        })
    },
    req_set (params) {
      const _this = this;
      Q.when()
        .then(function () {
          return trains_systemConfig_setSystemConfig(params);
        })
        .then(function (res) {
          _this.$message.success('设置成功');
          _this.req_log_list(_this.logForm);
          
        })
    },
    req_log_list (params) {
      this.loading = true;
      const _this = this;
      Q.when()
        .then(function () {
          return trains_systemConfig_getSystemConfigLogList(params);
        })
        .then(function (res) {
          _this.logPager.total = res.pageResult.totalCount;
          _this.logList = res.pageResult.pageData;
          document.documentElement.scrollTop = 0;
          _this.loading = false;
        })
    },
    change_show (val) {
      if (val === 2) this.req_log_list(this.logForm);
    },
    input_number (val, key) {
      const reg = /\D/g;
      const form = this.form;
      form[key] = val.replace(reg, '');
    },
    save () {
      const _this = this;
      _this.$confirm('确定保存吗？?', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.$refs.form.validate((valid) => {
          if (valid) {
            _this.req_set(_this.form);
          } else {
            return false;
          }
        })
      }).catch(() => {
             
      });
      
    },
    cancel () {
      this.form = {
        robTickets: '',
        serviceCharge: '',
      };
    },
    change_log_size(pageSize) {
      this.logForm.pageSize = pageSize;
      this.logForm.currentPage = 1;
      this.req_log_list(this.logForm);
    },
    change_log_current(currentPage) {
      this.logForm.currentPage = currentPage;
      this.req_log_list(this.logForm);
    },
  },
  mounted() {},
  activated() {
    this.req_detail();
  },
  watch: {}
};
