
const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口文档地址： http://192.168.0.15:8080/doc.html#/yinzhilv_travel/tour-order-controller/cutDownOrderUsingPOST
export default function web_tourismFullReduction_selectByOrderNo(pParameter) {
    if (!pParameter) pParameter = {};
    let params = {};
    params.method = 'POST';
    params.urlSuffix = '/web/tourismFullReduction/selectByOrderNo';
    params.data = {
        orderNo: pParameter.orderNo
    };
    return new Promise(function (resolve, reject) {
        resolve(__request(params))
    })
}