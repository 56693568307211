

const __request = require(`@/lib/data-service/default/__request/__request_contentType_json`);

// 平台合同信息设置回显接口地址： http://192.168.0.9:8080/doc.html#/yinzhilv_travel/es-ign-platform-controller/platformSettingEchoUsingPOST
export default function web_esIgn_esIgnPlatformController_platformSettingEcho(pParameter) {
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/esIgn/esIgnPlatformController/platformSettingEcho';
  params.data = pParameter;

  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}
