import web_common_user_getDeveloper from '@/lib/data-service/default/web_common_user_getDeveloper'
import web_common_area_getAreaDataByAreaType from '@/lib/data-service/default/web_common_area_getAreaDataByAreaType'
import web_common_area_getInlandAreaChildListByParentId from '@/lib/data-service/default/web_common_area_getInlandAreaChildListByParentId'
import web_buyer_findAll from '@/lib/data-service/default/web_buyer_findAll'
import web_credit_closeCredit from '@/lib/data-service/default/web_credit_closeCredit'
import web_credit_startCredit from '@/lib/data-service/default/web_credit_startCredit'
import web_credit_getCreditProduct from '@/lib/data-service/default/web_credit_getCreditProduct' // 获取产品列表
import web_common_getPhone from '@/lib/data-service/default/web_common_getPhone' // 获取发送验证码所需的手机号
import web_common_kaptcha from '@/lib/data-service/default/web_common_kaptcha' // 获取图形码
import web_common_register from '@/lib/data-service/default/web_common_register' // 验证图形码并发送短信code
import web_common_phoneCodeCheck from '@/lib/data-service/default/web_common_phoneCodeCheck' // 检验短信验证码
import web_credit_dredgeCredit from '@/lib/data-service/default/web_credit_dredgeCredit'
import Pagination from '@/component/base-pagination/index.vue'
import moment from 'moment'
export default {
  data () {
    const checkOverdueFineRate = (rule, value, callback) => {
      if (this.formParams.overdueFineStatus === 1) {
        if (value >= 0) {
          callback()
        } else {
          callback(new Error('不能为负数'))
        }
      } else {
        callback()
      }
    };
    const checkCreditAmount = (rule, value, callback) => {
      if (value >= 0) {
        callback()
      } else {
        callback(new Error('不能为负数'))
      }
    };
    const checkMsgCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('不能为空'))
      } else {
        callback()
      }
    };
    return {
      loading: false,
      params: {
        period: [],

        currentPage: 1,
        pageSize: 10,
        userName: '',
        companyName: '',
        cityId: '',
        regionId: '',
        provinceId: '',
        buyerType: '',
        startTime: '',
        endTime: '',
        phone: '',
        accountManagerId: '',
        developerId: '',
        creditStatus: '',
        officeId: '',
        total: 0
      },
      userTypeList: [{value: '', label: '全部'}, {value: 1, label: '机票'}, {value: 2, label: '旅游'}, {value: '1+2', label: '机票+旅游'}],
      developerList: [],
      provinceList: [],
      cityList: [],
      statusList: [{value: '', label: '全部'}, {value: 0, label: '未开通'}, {value: 1, label: '启用'}, {value: 2, label: '停用'}, {value: 3, label: '冻结'}],
      showMore: false,
      userList: [],
      fileUrl: '',
      creditProductList: [],
      dialogFormVisible: false,
      formParams: {
        overdueFineRate: 0,
        overdueFineStatus: 1,
        creditAmount: 0,
        productName: '',
        creditPaymentDays: '',
        creditPaymentRate: '',
        productId: '',
        refundGraceDays: 0,

        accountName: '', // 分销商用户名
        companyId: '',
        companyName: '',

        code: '',
      },
      formParamsRule: {
        overdueFineRate: [
          { validator: checkOverdueFineRate, trigger: 'blur' }
        ],
        creditAmount: [
          { validator: checkCreditAmount, trigger: 'blur' }
        ],
        refundGraceDays: [
          { validator: checkCreditAmount, trigger: 'blur' }
        ],
        code: [
          { validator: checkMsgCode, trigger: 'blur' }
        ]
      },
      getCodeText: '获取短信验证码',
      innerVisible: false,
      picCodeUrl: '',
      picParams: {
        phoneNumber: '',
        verificationCode: '',
        roleType: 2, // 角色类型:1-供应商 2-采购商 3-平台
        uuid: ''
      },
      picParamsRule: {
        verificationCode: [
          { required: true, message: '请输入图形码', trigger: 'blur' },
        ]
      },
      canGetCode: true,
      inerval: '', // 倒计时
      inervalNum: 0,
      moment: moment
    }
  },
  components: {
    Pagination
  },
  created () {
    //this.getDeveloperList()
    //this.getAreaDataByAreaType()
    //this.getBuyerList()
  },
  mounted () {},
  activated () {
    this.getDeveloperList()
    this.getAreaDataByAreaType()
    this.getBuyerList()
  },
  deactivated() {},
  destroyed () {
    clearInterval(this.inerval)
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
    carryRulesFormatter(val) {
      let type = ''
      if (val.carryRules === 0) {
        type = '进位到元'
      } else {
        type = '进位到分'
      }
      return type
    },
    travelDepositTypeFormatter(val) {
      let type = ''
      if (val.travelDepositType === 0) {
        type = '无订金'
      } else if (val.travelDepositType === 1) {
        type = '百分比'
      } else {
        type = '固定金额'
      }
      return type
    },
    handleFilter() {
      this.onSearch()
    },
    showOrHideForm() {
      const showMore = this.showMore
      this.showMore = !showMore
    },
    changeParamsTime(val) {
      if (val === '' || val === null) {
        this.params.startTime = ''
        this.params.endTime = ''
      } else {
        this.params.startTime = val[0]
        this.params.endTime = val[1]
      }
    },
    changeProvince(val) {
      this.params.cityId = ''
      this.cityList = []
      if (val !== '') {
        this.getCity(val)
      }
    },
    onSearch() {
      this.params.currentPage = 1
      this.getBuyerList()
    },
    onReset() {
      this.params = {
        period: [],
        currentPage: 1,
        pageSize: 10,
        userName: '',
        companyName: '',
        cityId: '',
        regionId: '',
        provinceId: '',
        buyerType: '',
        startTime: '',
        endTime: '',
        phone: '',
        accountManagerId: '',
        developerId: '',
        creditStatus: '',
        officeId: '',
        total: 0
      }
      this.onSearch()
    },
    getBuyerList() {
      this.loading = true
      web_buyer_findAll(this.params).then((res) => {
        this.userList = res.pageResult.pageData
        this.params.total = res.pageResult.totalCount
        this.loading = false
      }).catch(() => {
        this.loading = false
      }, new Error(() => {
        this.loading = false
      }))
    },
    // 获取开发者列表 - （机票经理）
    getDeveloperList() {
      web_common_user_getDeveloper().then((res) => {
        this.developerList = res.getAllDeveloperResult
      })
    },
    // 获取省份
    getAreaDataByAreaType(areaType = 1) {
      web_common_area_getAreaDataByAreaType({areaType}).then(res => {
        this.provinceList = res
      })
    },
    // 获取城市
    getCity(id) {
      web_common_area_getInlandAreaChildListByParentId({id}).then(res => {
        this.cityList = res
      })
    },
    buyerStatusFormatter(val) {
      let statusText = ''
      if (val.creditStatus === 0 || val.creditStatus === null) {
        statusText = '未开通'
      } else if (val.creditStatus === 1) {
        statusText = '启用'
      } else if (val.creditStatus === 2) {
        statusText = '停用'
      } else {
        statusText = '停用'
      }
      return statusText
    },
    handleStopStatus(index, row) {
      this.$confirm('确定要停用该分销商的授信账户吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        web_credit_closeCredit({companyId: row.buyerId}).then(res => {
          if (res.code === '000000') {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            this.getBuyerList()
          }
        })
      }).catch(() => {});
    },
    handleCreateStatus(index, row) {
      this.reactFormParams()
      this.formParams.accountName = row.userName
      this.formParams.companyId = row.buyerId
      this.formParams.companyName = row.companyName
      web_credit_getCreditProduct().then(res => {
        let creditProducts = res.creditProducts
        creditProducts.forEach((value, index) => {
          value.label = `${value.creditPaymentDays}天 , ${value.creditPaymentRate}%`
          if (index === 0) {
            this.formParams.productId = value.id
            this.formParams.creditPaymentDays = value.creditPaymentDays
            this.formParams.creditPaymentRate = value.creditPaymentRate
            this.formParams.productName = value.productName
          }
        })
        this.creditProductList = creditProducts

        this.dialogFormVisible = true
      })
    },
    reactFormParams() {
      this.formParams = {
        overdueFineRate: 0,
        overdueFineStatus: 1,
        creditAmount: 0,
        productName: '',
        creditPaymentDays: '',
        creditPaymentRate: '',
        productId: '',
        refundGraceDays: 0,

        accountName: '', // 分销商用户名
        companyId: '',
        companyName: '',

        code: ''
      }
    },
    changeProductId(val) {
      for (let i = 0, l = this.creditProductList.leading; i < l; i++) {
        if (this.creditProductList[i].id === val) {
          this.formParams.creditPaymentDays = this.creditProductList[i].creditPaymentDays
          this.formParams.creditPaymentRate = this.creditProductList[i].creditPaymentRate
          this.formParams.productName = this.creditProductList[i].productName
          break
        }
      }

    },
    getMsgCode() {
      if (!this.canGetCode) {
        return
      }
      this.reactPicParams()
      web_common_getPhone().then(res => {
        this.picParams.phoneNumber = res.phone
        web_common_kaptcha().then(result => {
          if (result.code === '000000') {
            this.picCodeUrl = result.img
            this.picParams.uuid = result.uuid
            this.innerVisible = true
          }
        })
      })
    },
    reactPicParams() {
      this.picParams = {
        phoneNumber: '',
        verificationCode: '',
        roleType: 2,
        uuid: ''
      }
    },
    submitPicCode() {
      this.$refs['picForm'].validate((valid) => {
        if (valid) {
          web_common_register(this.picParams).then(res => {
            if (res.code === '000000') {
              this.$message({
                message: '验证码已发送，请查看您的手机',
                type: 'success'
              });
              this.innerVisible = false
              clearInterval(this.inerval)
              this.canGetCode = false
              this.inervalNum = 59
              this.getCodeText = `${this.inervalNum}秒重新发送`
              this.inerval = setInterval(() => {
                if (this.inervalNum > 1) {
                  this.inervalNum--
                  this.getCodeText = `${this.inervalNum}秒重新发送`
                } else {
                  this.getCodeText = '重新发送'
                  this.canGetCode = true
                  clearInterval(this.inerval)
                }
              }, 1000)

            } else {
              this.$message.error(res.msg);
              web_common_kaptcha().then(result => {
                if (result.code === '000000') {
                  this.picCodeUrl = result.img
                  this.picParams.uuid = result.uuid
                }
              })
            }
          })
        }
      })
    },
    submit() {
      this.$refs['creditForm'].validate((valid) => {
        if (valid) {
          let params = {
            smsCode: this.formParams.code,
            phone: this.picParams.phoneNumber
          }
          web_common_phoneCodeCheck(params).then(res => {
            if (res.code === '000000') {
              this.dialogFormVisible = false
              web_credit_dredgeCredit(this.formParams).then(result => {
                this.getBuyerList()
              })
            }
          })
        }
      })
    },
    handleOpenStatus(index, row) {
      this.$confirm('确定要启用该分销商的授信账户吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        web_credit_startCredit({companyId: row.buyerId}).then(res => {
          if (res.code === '000000') {
            this.$message({
              type: 'success',
              message: '操作成功'
            });
            this.getBuyerList()
          }
        })
      }).catch(() => {});
    }
  }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
