import web_common_area_getAreaDataByAreaType from '@/lib/data-service/default/web_common_area_getAreaDataByAreaType'
import web_common_area_getInlandAreaChildListByParentId from '@/lib/data-service/default/web_common_area_getInlandAreaChildListByParentId'
import web_managerSupplier_unchecked from '@/lib/data-service/default/web_managerSupplier_unchecked'
import web_managerSupplier_notAllPass from '@/lib/data-service/default/web_managerSupplier_notAllPass'
import Pagination from '@/component/base-pagination/index.vue'
export default {
  data () {
    return {
      loading: false,
      params: {
        currentPage: 1,
        pageSize: 10,
        userName: '',
        companyName: '',
        cityId: '',
        regionId: '',
        provinceId: '',
        applicationType: '',
        supplierStatus: ''
      },
      userTypeList: [{value: '', label: '全部'}, {value: 1, label: '机票'}, {value: 2, label: '旅游'}, {value: '1+2', label: '机票+旅游'}],
      provinceList: [],
      cityList: [],
      activeName: 'unchecked',
      userList: [],
      uncheckTotal: 0,
      failureUserList: [], // 失败名单
      failureTotal: 0,
      dialogVisible: false,
      failDetail: {},
      shownUm: 0
    }
  },
  components: {
    Pagination
  },
  created () {
  },
  mounted () {

  },
  activated () {
    this.getAreaDataByAreaType()
    this.getUnchecked()
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
    handleFilter() {
      this.onSearch()
    },
    changeProvince(val) {
      this.params.cityId = ''
      this.cityList = []
      if (val !== '') {
        this.getCity(val)
      }
    },
    onSearch() {
      this.params.currentPage = 1
      if (this.activeName === 'unchecked') {
        this.getUnchecked()
      } else {
        this.getNotAllPass()
      }
    },
    onReset() {
      this.params = {
        currentPage: 1,
        pageSize: 10,
        userName: '',
        companyName: '',
        cityId: '',
        regionId: '',
        provinceId: '',
        applicationType: '',
        supplierStatus: ''
      }
      this.onSearch()
    },
    // 获取省份
    getAreaDataByAreaType(areaType = 1) {
      web_common_area_getAreaDataByAreaType({areaType}).then(res => {
        this.provinceList = res
      })
    },
    // 获取城市
    getCity(id) {
      web_common_area_getInlandAreaChildListByParentId({id}).then(res => {
        this.cityList = res
      })
    },
    getUnchecked() {
      web_managerSupplier_unchecked(this.params).then(res => {
        this.userList = res.pageResult.pageData
        this.uncheckTotal = res.pageResult.totalCount
      })
    },
    getNotAllPass() {
      web_managerSupplier_notAllPass(this.params).then(res => {
        this.failureUserList = res.pageResult.pageData
        this.failureTotal = res.pageResult.totalCount
      })
    },
    handleClick() {
      this.onSearch()
    },
    applicationTypeFormatter(val) {
      if (val.applicationType === '1') {
        return '机票'
      } else if (val.applicationType === '2') {
        return '旅游'
      } else {
        return '机票+旅游'
      }
    },
    handleCheck(val) {
      this.$router.push({
        name: 'admin-supplier-check',
        query: {
          id: val.supplierId
        }
      })
    },
    handleCheckReason(val) {
      this.failDetail = val
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false
    }
  }
}
