// web_circuit_line_delete 判断线路是否关联旅游产品,可否删除

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口地址：http://192.168.0.15:8080/doc.html#/所有接口/circuit-operate-controller/confirmRelationUsingPOST

export default function web_circuit_line_delete(pParameter) {
  if (!pParameter) pParameter = {};
  const supplierId = pParameter.supplierId || ''
  const lineId = pParameter.lineId || ''
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/circuit/line/delete'
  params.data = {
    supplierId,
    lineId
  }
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}

