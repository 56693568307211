// web_common_updateSolrProduct 手动更新solr库

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口地址：http://192.168.0.15:8080/doc.html#/所有接口/user-controller/getUserNameVerifyUsingPOST

export default function web_common_updateSolrProduct(pParameter) {
  if (!pParameter) pParameter = {};
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/common/updateSolrProduct'
  params.data = pParameter
  // params.process_response_data = false;
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}

