const __request = require(`./__request/__request_contentType_json`);

// http://219.128.52.2:8765/doc.html#/btoc-biz-service/BTOC%20-%20%E7%BA%BF%E8%B7%AF/selectUsingPOST
const biz_line_select = (pParameter) => {
  if (!pParameter) pParameter = {};

  const buyerId = pParameter.buyerId
  const currentPage = pParameter.currentPage
  const pageSize = pParameter.pageSize

  const p = {
    method: 'post',
    urlSuffix: '/biz/line/select',
    data: {
      buyerId,
      currentPage,
      pageSize,
    }
  };
  return new Promise(function (resolve, reject) {
    resolve(__request(p))
  }).then(data => {
    return data.datas
  })
};
module.exports = biz_line_select;