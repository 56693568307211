import web_common_source_GetResourceByResourceType from '@/lib/data-service/default/web_common_source_GetResourceByResourceType' // 获取权限列表
import web_common_department_list from '@/lib/data-service/default/web_common_department_list' // 获取部门列表
import web_common_user_checkUserName from '@/lib/data-service/default/web_common_user_checkUserName' // 检查名字是否可以使用
import web_staffManage_add from '@/lib/data-service/default/web_staffManage_add'
import web_data_dataOperation from '@/lib/data-service/default/web_data_dataOperation' // 新增或修改维度
import web_common_source_getPlatformAuthority from '@/lib/data-service/default/web_common_source_getPlatformAuthority'
import web_staffManage_platformAddStaff from '@/lib/data-service/default/web_staffManage_platformAddStaff'
import limits from './../../component/limits'

import is_mobile from 'yinzhilv-js-sdk/src/common-service/other/is_mobile';

export default {
  data () {
    const validateUserName = (rule, value, callback) => {
      web_common_user_checkUserName({userName: value}).then(res => {
        if (res) {
          callback()
        } else {
          callback(new Error('该用户名已经存在'))
        }
      })
    };
    const validateRePassword = (rule, value, callback) => {
      if (value === this.params.password) {
        callback()
      } else {
        callback(new Error('两次输入的密码不一致'))
      }
    };
    const validatePhone = (rule, value, callback) => {
      if (is_mobile(value)) {
        callback()
      } else {
        callback(new Error('不符合手机号码格式'))
      }
    };
    const validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback()
        return
      }
      const regExp = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
      if (regExp.test(value)) {
        callback()
      } else {
        callback(new Error('邮件格式不正确'))
      }
    };
    const validateQQ = (rule, value, callback) => {
      if (value === '') {
        callback()
        return
      }
      const regExp = /^[1-9][0-9]{4,9}$/gim
      if (regExp.test(value)) {
        callback()
      } else {
        callback(new Error('QQ格式不正确'))
      }
    };
    return {
      loading: false,
      params: {
        resourceIds: '',
        userName: '', // 账号
        password: '', // 密码
        realName: '', // 真实名字
        phone: '', // 手机号
        qq: '',
        eMail: '',
        deptId: '',
        companyId: '',
        roleId: '',
        userStatus: '',
        rePassword: '' // 重复密码
      },
      rule: {
        userName: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { min: 4, max: 16, message: '长度在 4 到 16 个字符', trigger: 'blur' },
          { validator: validateUserName, trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
        ],
        rePassword: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { validator: validateRePassword, trigger: 'blur' }
        ],
        realName: [
          { required: true, message: '请输入真实姓名', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: validatePhone, trigger: 'blur' }
        ],
        qq: [
          { validator: validateQQ, trigger: 'blur' }
        ],
        eMail: [
          { validator: validateEmail, trigger: 'blur' }
        ]
      },
      departmentList: [], // 部门列表
      resourceList: [],
      limit: {
        '旅游产品管理': 'platform:tourProduct', // 13
        '旅游订单管理': 'platform:order', // 8
        '笛风云订单管理': 'platform:dfyOrder', // 201
        '酒店订单管理': 'platform:hotelOrder', // 206
        '酒店产品管理': 'platform:hotel', // 210
        '火车票订单管理': 'platform:trainsOrder', // 280
        '火车票产品设置': 'platform:productSet', // 281
        '国内机票订单管理': 'platform:flightOrder', // 220
        '授信管理': 'platform:creditManager', // 282
        '退款管理': 'platform:refundManager', // 283
        '结算管理': 'platform:settle', // 173
        '供应商管理': 'platform:supplier', // 10
        '分销商管理': 'platform:buyer', // 12
        '商务代理管理': 'platform:personManager', // 临时渠道管理
        '客户管理': 'platform:clientManager', // 310
        '客户订单管理': 'platform:clientOrder', // 311
        '系统管理': 'platform:system', // 14
        '分销商优惠管理': 'platform:discountsSet',
      },
      selectParams: {
        id: '',
        dataType: '1',
        resourceId: ''
      },

      treeLoading: false,
      expandedKey: [],
      checkResourceIds: [],
      defaultProps: {
        children: 'children',
        label: 'resourceName'
      }
    }
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
    this.reactSelectParams()
    this.getDepartmentList()
    this.getResource()
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
    handleCheckChange(data, checked, indeterminate) {
      console.log(data, checked, indeterminate);
    },
    reactSelectParams() {
      this.selectParams = {
        id: '',
        dataType: '1',
        resourceId: ''
      }
    },
    getDepartmentList() {
      web_common_department_list().then(res => {
        let departmentList = res.treeList
        departmentList.forEach(value => {
          value.value = value.id
          value.label = value.deptName
          if (value.childDeptList.length > 0) {
            value.children = value.childDeptList
            value.children = this.setDepartment(value.children)
            /*value.children.forEach(val => {
              val.value = val.id
              val.label = val.deptName
              if (val.childDeptList.length > 0) {
                val.children = val.childDeptList
                val.children = this.setDepartment(val.children)
              }
            })*/
          }
        })
        this.departmentList = departmentList
      })
    },
    setDepartment(val) {
      if (val.length > 0) {
        val.forEach(value => {
          value.value = value.id
          value.label = value.deptName
          if (value.childDeptList.length > 0) {
            value.children = value.childDeptList
            value.children = this.setDepartment(value.children)
          }
        })
      }
      return val
    },
    getResource() {
      this.treeLoading = true
      web_common_source_getPlatformAuthority({isAll: true}).then(res => {
        let result = res.resourceResultList
        let expandedKey = []
        let checkResourceIds = []
        result.forEach(value => {
          checkResourceIds.push(value.resourceId)
          if (value.resourceLevel === '1' || value.resourceLevel === '2' || value.resourceLevel === '3') {
            expandedKey.push(value.resourceId)
          }
        })
        this.expandedKey = expandedKey
        this.checkResourceIds = checkResourceIds
        let resourceList = this.translateDataToTree(result)
        console.log(resourceList)
        this.resourceList = resourceList
      }).finally(() => {
        this.treeLoading = false
      });


      /*web_common_source_GetResourceByResourceType().then(res => {
        let result = res.permissionList
        let resourceList = this.translateDataToTree(result)
        // 排序
        resourceList = this.getAuthoritySort(resourceList)
        resourceList.forEach(value => {
          value.checkedArr = []
          value.isIndeterminate = false
          value.checkAll = false
          let parentId = value.resourceId
          if (value.children && value.children.length > 0) {
            value.children.forEach(val => {
              val.id = `${parentId}-${val.resourceId}`
            })
          }
        })
        this.resourceList = resourceList
      })*/
    },
    getAuthoritySort(authorityList) {
      let arr = []
      Object.values(limits).map((key, index) => {
        authorityList.map(item => {
          if (item.permission === key) {
            arr.push(item)
          }
        })
      })
      return arr
    },
    /**
     * 该方法用于将有父子关系的数组转换成树形结构的数组
     * 接收一个具有父子关系的数组作为参数
     * 返回一个树形结构的数组
     */
    translateDataToTree (data) {
      // 没有父节点的数据
      const parents = data.filter(value => value.parentId === 0 || value.parentId === undefined || value.parentId === null)
      // 有父节点的数据
      const children = data.filter(value => value.parentId !== 0)
      // 定义转换方法的具体实现
      const translator = (parents, children) => {
        // 遍历父节点数据
        parents.forEach((parent) => {
            // 遍历子节点数据
            children.forEach((current, index) => {
                // 此时找到父节点对应的一个子节点
                if (current.parentId === parent.resourceId) {
                  // 对子节点数据进行深复制，这里只支持部分类型的数据深复制，对深复制不了解的童靴可以先去了解下深复制
                  const temp = JSON.parse(JSON.stringify(children))
                  // 让当前子节点从temp中移除，temp作为新的子节点数据，这里是为了让递归时，子节点的遍历次数更少，如果父子关系的层级越多，越有利
                  temp.splice(index, 1)
                  // 让当前子节点作为唯一的父节点，去递归查找其对应的子节点
                  translator([current], temp)
                  // 把找到子节点放入父节点的children属性中
                  typeof parent.children !== 'undefined' ? parent.children.push(current) : parent.children = [current]
                }
              }
            )
          }
        )
      }
      // 调用转换方法
      translator(parents, children)
      // 返回最终的结果
      return parents
    },
    handleCheckAllChange(index, id, val) {
      let resourceList = this.resourceList
      if (val) {
        let checkArr = []
        resourceList[index].children.forEach(value => {
          checkArr.push(value.id)
        })
        resourceList[index].checkedArr = checkArr
      } else {
        resourceList[index].checkedArr = []
      }
      resourceList[index].isIndeterminate = false
      this.resourceList = resourceList
    },
    handleCheckedCitiesChange(index, id, val) {
      let checkedCount = val.length
      this.resourceList[index].checkAll = checkedCount === this.resourceList[index].children.length
      this.resourceList[index].isIndeterminate = checkedCount > 0 && checkedCount < this.resourceList[index].children.length
    },
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true

          let checkedKey = []
          let checkedKeyNodes = this.$refs.tree.getCheckedNodes(true, false)
          checkedKeyNodes.forEach(value => {
            checkedKey.push(value.resourceId)
          })
          this.params.resourceIds = checkedKey  // .join(',')
          web_staffManage_platformAddStaff(this.params).then(res => {
            this.selectParams.resourceId = res.userId
            web_data_dataOperation(this.selectParams).then(result => {
              this.loading = false
              this.$message({
                message: '添加成功',
                type: 'success'
              });
              this.back()
            }).catch(() => {
              this.loading = false
            })
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },
    back() {
      this.$refs.form.resetFields();
      this.$refs.form.clearValidate();
      this.$router.go(-1)
    }
  }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
