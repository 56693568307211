import Pagination from "@/component/base-pagination/index.vue";
import getList from '@/lib/data-service/hotel/hotel_manager_base_queryHotelList'
import addMoney from '@/lib/data-service/hotel/hotel_manager_base_updateHotelRoomArrayAdditionMoney'
import hotelNoLoinger from '@/lib/data-service/hotel/hotel_manager_base_hotelNoLongerShowing'
import hotelUpOnline from '@/lib/data-service/hotel/hotel_manager_base_hotelUpOnline'


export default {
    name: "hotelManage",
    data() {
        return {
            params: {
                total: 0,
                currentPage: 1,
                pageSize: 10,
                areaType: '1',
                star: '1'
            },
            activeName: "1",
            navList: [
                { label: "已上架", value: "1" },
                { label: "已下架", value: "2" }
            ],
            starList: [
                { label: "全部", value: "1" },
                { label: "二星", value: "2" },
                { label: "三星", value: "3" },
                { label: "四星", value: "4" },
                { label: "五星", value: "5" },
            ],
            cityList: [
                { label: "全部", value: "1" },
                { label: "国内", value: "2" },
                { label: "港澳台", value: "3" },
                { label: "出境", value: "4" },
            ],
            orderList: [],
            loading: false,
            selectList: [],
            dialogVisible: false,
            hotelId: '',
            addMoneyForm: {
                attachType: 1,
                attachAmountType: 1,
                attachAmount: ''
            }
        };
    },
    components: { Pagination },
    methods: {
        onSearch(type) {
            let data = this.params
            type ? '' : data.currentPage = 1
            this.loading = true
            getList(data).then(res => {
                console.log(res)
                this.loading = false
                this.orderList = res.pageResult.pageData
                this.params.total = res.pageResult.totalCount
            })
        },
        goAmount(id) {
            this.addMoneyForm.attachAmount = ''
            this.dialogVisible = true
            this.hotelId = id
        },
        tapeOut(id) {
            let text = this.activeName == '1' ? '确定要下架吗？' : '确定要上架吗？'
            if (id == 'batch') {
                if (this.selectList.length == 0) {
                    return this.$message({ type: 'warning', message: '请勾选酒店！' })
                }
            }
            this.selectList.length > 0 ? id = this.selectList.join(',') : ''
            this.$confirm(text, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                if (this.activeName == 1) {
                    hotelNoLoinger({ hotelIds: id }).then(res => {
                        res.code == '000000' ? (this.$message({ type: "success", message: "操作成功！" }), this.onSearch()) : ''
                    })
                } else {
                    hotelUpOnline({ hotelIds: id }).then(res => {
                        res.code == '000000' ? (this.$message({ type: "success", message: "操作成功！" }), this.onSearch()) : ''
                    })
                }
            }).catch(() => {

            });
        },
        saveMoney() {
            let data = this.addMoneyForm
            console.log(data, this.selectList);
            if (!this.addMoneyForm.attachAmount) return this.$message({ type: "warning", message: "请输入附加金额" })
            if (this.hotelId) {
                data.hotelIds = this.hotelId
                this._addMoney(data)
            } else {
                if (this.selectList.length == 0) return this.$message({ type: "warning", message: "请勾选酒店" })
                data.hotelIds = this.selectList.join(',')
                this._addMoney(data)
            }
        },
        _addMoney(data) {
            addMoney(data).then(res => {
                console.log(res)
                res.code == '000000' ? this.$message({ type: 'success', message: '操作成功！' }) : ''
                this.dialogVisible = false
            })
            this.hotelId = ''
        },
        handleSelectionChange(val) {
            this.selectList = val.map(item => {
                return item.hotelId
            })
        },
        onReset() {
            let data = {
                total: 0,
                currentPage: 1,
                pageSize: 10,
                areaType: '1',
                star: '1'
            }
            this.params = Object.assign({}, data)
            this.onSearch()
        },
        setMoney() {
            if (this.selectList.length == 0) {
                return this.$message({ type: "warning", message: "请勾选酒店！" })
            }
            this.dialogVisible = true
            this.addMoneyForm.attachAmount = ''
        },
        getOrderList(val) {
            this.params.currentPage = val.page
            this.onSearch('page')
        },
        handleClick(val) {
            this.params.hotelState = val
            this.onSearch()
        }
    },
    created() {
        this.onSearch()
    }
};
