
const __request = require(`@/lib/data-service/default/__request/__request_contentType_json`);

// 个人信息平台设置接口地址：http://192.168.0.11:8080/doc.html#/yinzhilv_travel/personage-controller/personagePlatformSetUsingPOST

export default function web_personage_personagePlatformSet(pParameter) {
  if (!pParameter) pParameter = {};
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/personage/personagePlatformSet'
  params.data = pParameter;
  params.process_response_data = false
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}
