// web_common_user_checkUserName 查询用户表用户名是否存在

const __request = require(`@/lib/data-service/default/__request/__request_contentType_form`);

// 接口地址：http://192.168.0.15:8080/doc.html#/所有接口/user-controller/getUserNameVerifyUsingPOST

export default function web_common_user_checkUserName(pParameter) {
  if (!pParameter) pParameter = {};
  const userName = pParameter.userName || ''

  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/common/user/checkUserName'
  params.data = {
    userName
  }
  params.process_response_data = false;
  return new Promise(function (resolve, reject) {
    resolve(__request(params))
  })
}
